import { Redirect, Route, useLocation } from "react-router-dom";
import * as React from "react";
import { FunctionComponent } from "react";
import { LOGIN_ROUTE } from "./Routes";
import { usageStatisticsService } from "services/statistics/UsageStatisticsService";

export const SecuredRoute = (props: {
    component: FunctionComponent;
    authenticated: boolean;
    secured: boolean;
    path: string;
    key: number;
}): JSX.Element => {
    const location = useLocation();

    React.useEffect(() => {
        usageStatisticsService.sendPageView(location.pathname);
    }, [location]);

    return (
        <Route
            exact={true}
            path={props.path}
            key={props.key}
            render={({ location }) =>
                props.authenticated || !props.secured ? (
                    <>{React.createElement(props.component)}</>
                ) : (
                    <Redirect
                        to={{
                            pathname: LOGIN_ROUTE.path,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

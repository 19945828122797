import { DownloadPageConfiguration } from "domain/downloadPage";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

/**
 * Handles interactions with get download page configuration API.
 */

class DownloadPageService {
    public async getConfiguration(licenseKey: string): Promise<DownloadPageConfiguration | string> {
        return apiGatewayService.invokeApi("/tenant-info/get-download-app-configuration", "GET", null, {
            refreshSession: false,
            apiType: ApiType.MAINSTREAM,
            headers: { license_key: licenseKey },
            includeCredentials: false,
        });
    }
}

export const downloadPageService = new DownloadPageService();

import * as React from "react";
import { RefObject, useEffect, useContext, useState, useCallback } from "react";
import { Column, useBlockLayout, useResizeColumns, useTable } from "react-table";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import style from "./table.scss";
import * as TableRepository from "services/table/tableRepository";
import { RepositoryKey } from "services/utils/repository";
import { TableData } from "domain/table";
import ReactTooltip from "react-tooltip";
import { viewActionsContext } from "components/layout/ApplicationLayout";
import EmptyStateIcon from "components/icons/EmptyState";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";
import { TABLE_RESIZE_VALUE_BASE, TABLE_RESIZE_MIN_HEIGHT } from "globalConstants";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";

declare module "react-table" {
    // eslint-disable-next-line @typescript-eslint/ban-types
    export interface HeaderGroup<D extends object = {}> extends ColumnInstance<D>, UseTableHeaderGroupProps<D> {
        isResizing: boolean;

        getResizerProps(): JSX.ElementAttributesProperty;
    }
}

interface TableProps<T extends TableData> {
    data: T[];
    columns: Column<T>[];
    loaded: boolean;
    tableIdentity: RepositoryKey;
    failureMessage?: string;
    scrollTo?: number;
    tooltips?: boolean;
    emptyMessage?: string;
    testId?: string;
    autoResizeAdjustmentClass?: string;
    loading?: boolean;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

function Table<D extends TableData>(props: TableProps<D> & ConnectedProps<typeof connector>): JSX.Element {
    const { data, failureMessage, loaded, tableIdentity, emptyMessage, loading } = props;

    if (failureMessage !== undefined && failureMessage !== "") {
        return <div className={style.failureMessage}>{failureMessage}</div>;
    }

    if (!loaded) {
        return <LoadingIndicator />;
    }

    const computeAvailableSpace = (): number => {
        const tableElements = document.getElementsByTagName("tbody");
        if (tableElements.length === 0) {
            return TABLE_RESIZE_MIN_HEIGHT;
        }
        const tableTop = tableElements[0].getBoundingClientRect().top;
        let adjustedHeight = window.innerHeight - (tableTop + TABLE_RESIZE_VALUE_BASE);
        if (props.autoResizeAdjustmentClass) {
            const adjustmentElements = document.getElementsByClassName(props.autoResizeAdjustmentClass);
            if (adjustmentElements.length > 0) {
                const element = adjustmentElements[0] as HTMLElement;
                const style = window.getComputedStyle(element);
                adjustedHeight -= element.offsetHeight;
                adjustedHeight -= parseFloat(style["marginTop"]) || 0;
                adjustedHeight -= parseFloat(style["marginBottom"]) || 0;
            }
        }
        return Math.max(adjustedHeight, TABLE_RESIZE_MIN_HEIGHT);
    };

    const { current: initialState } = React.useRef(TableRepository.getTableState(tableIdentity));
    const { current: columns } = React.useRef(props.columns);
    const scrollRef: RefObject<HTMLTableRowElement> = React.createRef<HTMLTableRowElement>();
    const [dragEnd, setDragEnd] = React.useState<boolean>(false);
    const [draggedColumn, setDraggedColumn] = React.useState("");
    const { getTableProps, state, headerGroups, rows, prepareRow } = useTable<D>(
        { columns, data, initialState },
        useBlockLayout,
        useResizeColumns
    );

    const scrollToRef = () => {
        const currentScrollRef = scrollRef.current;
        if (currentScrollRef) {
            currentScrollRef.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    useEffect(() => {
        TableRepository.setTableState(state, tableIdentity);
        headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column) => {
                if (column.isResizing) {
                    setDraggedColumn(column.id);
                    setDragEnd(true);
                }
            })
        );
        if (props.scrollTo) {
            scrollToRef();
        }
    });

    const sendColumnResizeEvent = () => {
        if (dragEnd) {
            usageStatisticsService.sendEvent({
                category: Category.REPORTS,
                action: Action.ADJUST_COLUMN_WIDTH,
                label: tableIdentity.toString() + " " + draggedColumn,
            });
        }
    };

    const [tableHeight, setTableHeight] = useState<number>();
    const adjustTableHeight = useCallback(() => {
        setTableHeight(computeAvailableSpace());
    }, []);
    window.addEventListener("resize", adjustTableHeight);

    useEffect(() => {
        setTableHeight(computeAvailableSpace());
        return () => {
            window.removeEventListener("resize", adjustTableHeight);
        };
    }, [loading]);

    const viewActionsForNoRecord = useContext(viewActionsContext);

    return (
        <div className={style.tableContainer}>
            {props.tooltips === true ? <ReactTooltip type="light" className={style.tooltip} clickable={true} /> : ""}
            <table {...getTableProps()} className={style.table} data-testid={props.testId}>
                <thead>
                    {headerGroups.map((headerGroup, rowIndex) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={rowIndex}>
                            {headerGroup.headers.map((column, cellIndex) => (
                                <th {...column.getHeaderProps()} key={cellIndex}>
                                    <span>{column.render("Header")} </span>
                                    <span
                                        {...column.getResizerProps()}
                                        className={column.isResizing ? style.resizing : style.resizer}
                                        onClick={sendColumnResizeEvent}
                                    />
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody style={{ height: tableHeight }}>
                    {rows.length < 1 ? (
                        <tr className={style.emptyStateMessage}>
                            <td>
                                <EmptyStateIcon
                                    ellipseBackgroundColor={props.theme.emptyStateEllipseBackgroundColor}
                                    biggestCircleBackgroundColor={props.theme.emptyStateBiggestCircleBackgroundColor}
                                    smallestCircleBackgroundColor={props.theme.emptyStateSmallestCircleBackgroundColor}
                                    personBackgroundColor={props.theme.emptyStatePersonColor}
                                />{" "}
                                <br />
                                <p>{emptyMessage}</p>
                                {viewActionsForNoRecord ?? null}
                            </td>
                        </tr>
                    ) : (
                        rows.map((row, rowIndex) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    key={rowIndex}
                                    className={
                                        props.scrollTo !== undefined && rowIndex === props.scrollTo - 1
                                            ? style.rowBeforeScrollTo
                                            : ""
                                    }
                                    ref={rowIndex === props.scrollTo ? scrollRef : null}
                                >
                                    {row.cells.map((cell, cellIndex) => {
                                        return (
                                            <td {...cell.getCellProps()} key={cellIndex}>
                                                <span>{cell.render("Cell")}</span>
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default connector(Table);

import { UserData, CheckEmailAvailability } from "domain/users";
import { apiGatewayService } from "services/api/ApiGatewayService";

interface EmailCheckDto {
    email_is_available: boolean;
}

function ToEmailCheck(dto: EmailCheckDto): CheckEmailAvailability {
    return {
        emailIsAvailable: dto.email_is_available,
    };
}
class UserService {
    public async fetchUsers(abortController: AbortController): Promise<UserData[]> {
        return await apiGatewayService
            .invokeApi("/users", "GET", null, { abortController })
            .then((userData: UserData[]) => {
                return Promise.resolve(userData);
            })
            .catch(() => {
                return Promise.reject("Failed to fetch users.");
            });
    }

    public createUser(fullName: string, email: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi("/users", "POST", { email, name: fullName }, { abortController });
    }

    public deleteUser(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi("/users/" + uuid, "DELETE", null, { abortController: abortController });
    }

    public checkEmailAvailability(email: string, abortController: AbortController): Promise<CheckEmailAvailability> {
        return apiGatewayService
            .invokeApi(`/check-email-availability?email=` + email, "GET", null, { abortController })
            .then((dtos: EmailCheckDto) => ToEmailCheck(dtos));
    }
}

export const userService = new UserService();

import style from "components/login/create-new-password/create-new-password.scss";
import * as React from "react";
import CreateNewPasswordForm from "components/login/create-new-password/CreateNewPasswordForm";
import { useTranslation } from "react-i18next";
import modalStyle from "components/modal/modal.scss";
import { authenticationService } from "services/security/AuthenticationService";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { useHistory, useLocation } from "react-router-dom";
import form from "styles/form.scss";
import classNames from "classnames";
import buttons from "styles/buttons.scss";
import { LOGIN_ROUTE } from "components/router/Routes";

interface FulfilledPasswordProvisions {
    length: boolean;
    containsUpper: boolean;
    containsLower: boolean;
    containsDigit: boolean;
    containsSymbol: boolean;
}

export interface ProvisionProperties {
    fulfilled: () => boolean;
    translationKey: string;
}

enum Provision {
    LENGTH,
    UPPER,
    LOWER,
    DIGIT,
    SYMBOL,
}

const NO_FULFILLED_PROVISIONS = {
    length: false,
    containsUpper: false,
    containsLower: false,
    containsDigit: false,
    containsSymbol: false,
};

enum RequestResult {
    NOT_REQUESTED,
    SUCCESS,
    FAILURE,
}

const CreateNewPasswordView = (): JSX.Element => {
    const [fulfilledPasswordProvisions, setFulfilledPasswordProvisions] =
        React.useState<FulfilledPasswordProvisions>(NO_FULFILLED_PROVISIONS);
    const [requesting, setRequesting] = React.useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();
    const [requestResult, setRequestResult] = React.useState<RequestResult>(RequestResult.NOT_REQUESTED);

    const { t, ready } = useTranslation();
    if (!ready || requesting) {
        return <LoadingIndicator />;
    }

    let content: JSX.Element;

    const navigateToLogin = () => history.push(LOGIN_ROUTE.path);

    switch (requestResult) {
        case RequestResult.NOT_REQUESTED: {
            const provisionPropertiesMap: Map<Provision, ProvisionProperties> = new Map([
                [
                    Provision.LENGTH,
                    {
                        fulfilled: () => fulfilledPasswordProvisions.length,
                        translationKey: "passwordPolicyLength",
                    },
                ],
                [
                    Provision.UPPER,
                    {
                        fulfilled: () => fulfilledPasswordProvisions.containsUpper,
                        translationKey: "passwordPolicyContainsUpper",
                    },
                ],
                [
                    Provision.LOWER,
                    {
                        fulfilled: () => fulfilledPasswordProvisions.containsLower,
                        translationKey: "passwordPolicyContainsLower",
                    },
                ],
                [
                    Provision.DIGIT,
                    {
                        fulfilled: () => fulfilledPasswordProvisions.containsDigit,
                        translationKey: "passwordPolicyContainsDigit",
                    },
                ],
                [
                    Provision.SYMBOL,
                    {
                        fulfilled: () => fulfilledPasswordProvisions.containsSymbol,
                        translationKey: "passwordPolicyContainsSymbol",
                    },
                ],
            ]);

            const validatePassword = (value: string): void => {
                setFulfilledPasswordProvisions(() => {
                    return {
                        length: value.length >= 10 && value.length <= 64,
                        containsUpper: /[A-Z]/.test(value),
                        containsLower: /[a-z]/.test(value),
                        containsDigit: /\d/.test(value),
                        containsSymbol: /[^A-Za-z0-9]/.test(value),
                    };
                });
            };

            const submitEnabled =
                fulfilledPasswordProvisions.length &&
                fulfilledPasswordProvisions.containsUpper &&
                fulfilledPasswordProvisions.containsLower &&
                fulfilledPasswordProvisions.containsDigit &&
                fulfilledPasswordProvisions.containsSymbol;

            const queryParams = new URLSearchParams(location.search);
            const emailAddress = queryParams.get("emailAddress");
            const verificationCode = queryParams.get("verificationCode");
            const passwordCallback = (value: string): void => {
                if (emailAddress == null || verificationCode == null) {
                    return;
                }
                setRequesting(true);
                authenticationService
                    .passwordReset(emailAddress, verificationCode, value)
                    .then(() => setRequestResult(RequestResult.SUCCESS))
                    .catch((e) => {
                        setRequestResult(RequestResult.FAILURE);
                        console.error("Failure when creating new password", e);
                    })
                    .finally(() => setRequesting(false));
            };
            content = (
                <>
                    <div className={style.passwordPolicyText}>
                        {t("CreateNewPasswordView.passwordPolicyIntro")}
                        <ul>
                            {Array.from(provisionPropertiesMap.values()).map((provisionProperty) => {
                                return (
                                    <li key={provisionProperty.translationKey}>
                                        {t(`CreateNewPasswordView.${provisionProperty.translationKey}`)}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <CreateNewPasswordForm
                        validatePassword={validatePassword}
                        submitDisabled={!submitEnabled}
                        passwordCallback={passwordCallback}
                        cancelCallback={navigateToLogin}
                        provisionProperties={Array.from(provisionPropertiesMap.values())}
                    />
                </>
            );
            break;
        }
        case RequestResult.SUCCESS:
        case RequestResult.FAILURE:
            content = (
                <>
                    <div className={style.result}>
                        {requestResult == RequestResult.SUCCESS
                            ? t("CreateNewPasswordView.requestSuccess")
                            : t("CreateNewPasswordView.requestFailure")}
                    </div>
                    <div className={form.buttonContainer}>
                        <button
                            type="button"
                            className={classNames(buttons.primaryButton, buttons.medium, form.submitButton)}
                            onClick={navigateToLogin}
                        >
                            {t("CreateNewPasswordView.backToLogin")}
                        </button>
                    </div>
                </>
            );
            break;
        default:
            throw new Error("Unsupported state");
    }

    return (
        <div className={style.createNewPassword}>
            <div className={style.formContainer}>
                <div className={modalStyle.standardModalTitle}>{t("CreateNewPasswordView.title")}</div>
                {content}
            </div>
        </div>
    );
};

export default CreateNewPasswordView;

import * as React from "react";
import { ErrorMessage, Formik, FormikProps, FormikHelpers, FormikErrors } from "formik";
import { object, string } from "yup";
import { Translation } from "react-i18next";
import { TFunction } from "i18next";

import classNames from "classnames";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { EMAIL_MAX_LENGTH, NOTES_MAX_LENGTH, NAME_MAX_LENGTH } from "globalConstants";
import Tooltip from "components/tooltip/Tooltip";

import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import style from "./add-tenant.scss";

import testIds from "testIds.json";
import { CheckEmailAvailability } from "domain/users";
import { userService } from "services/user/users/UserService";

interface Props {
    submitEventHandler: (values: FormValues) => void;
    closeHandler: () => void;
    regionsList: string[];
}

interface CodedName {
    name: string;
    code: string;
}

export interface FormValues {
    customerName: string;
    region: string;
    country: string;
    notes: string;
    mainContactName: string;
    email: string;
}

interface FormState {
    loading: boolean;
    notesCharactersLeft: number;
    formValues: FormValues;
    miniloader: boolean;
}

export default class AddTenantForm extends React.Component<Props, FormState> {
    private regions: Array<CodedName>;
    private countries: Array<CodedName>;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            miniloader: false,
            notesCharactersLeft: NOTES_MAX_LENGTH,
            formValues: {
                customerName: "",
                region: "",
                country: "",
                notes: "",
                mainContactName: "",
                email: "",
            },
        };
    }

    public setLoading(loading: boolean): void {
        this.setState({
            loading,
        });
    }

    public setMiniloader(miniloader: boolean): void {
        this.setState({
            miniloader,
        });
    }

    private generateRegions(t: TFunction): Array<CodedName> {
        const awsRegions = [
            { name: t("AddTenantForm.UsEastNorthVirginia"), code: "us-east-1" },
            { name: t("AddTenantForm.UsEastOhio"), code: "us-east-2" },
            { name: t("AddTenantForm.UsWestOregon"), code: "us-west-2" },
            { name: t("AddTenantForm.UsWestNorthernCalifornia"), code: "us-west-1" },
            { name: t("AddTenantForm.SouthAmericaSaoPaulo"), code: "sa-east-1" },
            { name: t("AddTenantForm.EuropeFrankfurt"), code: "eu-central-1" },
            { name: t("AddTenantForm.EuropeLondon"), code: "eu-west-2" },
            { name: t("AddTenantForm.EuropeParis"), code: "eu-west-3" },
            { name: t("AddTenantForm.MiddleEastBahrain"), code: "me-south-1" },
            { name: t("AddTenantForm.EuropeIreland"), code: "eu-west-1" },
            { name: t("AddTenantForm.EuropeMilan"), code: "eu-south-1" },
            { name: t("AddTenantForm.EuropeStockholm"), code: "eu-north-1" },
            { name: t("AddTenantForm.AwsAfricaCapeTown"), code: "af-south-1" },
            { name: t("AddTenantForm.MainlandChinaBeijing"), code: "cn-north-1" },
            { name: t("AddTenantForm.MainlandChinaNingxia"), code: "cn-northwest-1" },
            { name: t("AddTenantForm.AsiaPacificSydney"), code: "ap-southeast-2" },
            { name: t("AddTenantForm.AsiaPacificOsaka"), code: "ap-northeast-3" },
            { name: t("AddTenantForm.AsiaPacificTokyo"), code: "ap-northeast-1" },
            { name: t("AddTenantForm.AsiaPacificMumbai"), code: "ap-south-1" },
            { name: t("AddTenantForm.AsiaPacificSeoul"), code: "ap-northeast-2" },
            { name: t("AddTenantForm.AsiaPacificHongKong"), code: "ap-east-1" },
        ];

        this.regions = awsRegions.filter((region) => this.props.regionsList.some((reg: string) => region.code === reg));
        this.regions.sort((a, b) => (a.name > b.name ? 1 : -1));
        return this.regions;
    }

    private generateCountries(t: TFunction): Array<CodedName> {
        if (this.countries != null) {
            return this.countries;
        }

        const countries = [
            { name: t("AddTenantForm.USA"), code: "US" },
            { name: t("AddTenantForm.UnitedKingdom"), code: "UK" },
            { name: t("AddTenantForm.France"), code: "FR" },
            { name: t("AddTenantForm.Germany"), code: "DE" },
            { name: t("AddTenantForm.Finland"), code: "FI" },
            { name: t("AddTenantForm.Ireland"), code: "IE" },
            { name: t("AddTenantForm.India"), code: "IN" },
            { name: t("AddTenantForm.Japan"), code: "JP" },
            { name: t("AddTenantForm.China"), code: "CN" },
            { name: t("AddTenantForm.Brazil"), code: "BR" },
            { name: t("AddTenantForm.SouthAfrica"), code: "SA" },
        ];
        countries.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.countries = countries;
        return this.countries;
    }

    private createCustomerNameSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        return (
            <div className={classNames(form.formFields, style.topMarginGap)}>
                <label
                    htmlFor="customerName"
                    className={classNames(form.label, {
                        [form.inputError]: formikProps.errors.customerName,
                    })}
                >
                    {t("Common.customerName")}:
                </label>
                <input
                    id="customerName"
                    className={classNames(form.input, form.fixedWidthInput, {
                        [form.inputError]: formikProps.errors.customerName,
                    })}
                    maxLength={NAME_MAX_LENGTH}
                    data-testid={testIds.workArea.tenant.addTenantDialog.nameInput.itself}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.customerName}
                />
                <div className={form.error} data-testid={testIds.workArea.tenant.addTenantDialog.nameInput.errorLabel}>
                    <ErrorMessage name="customerName" />
                </div>
            </div>
        );
    }

    private createRegionSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        return (
            <div className={style.regionAndInfoContainer}>
                <div className={form.formFields}>
                    <label
                        htmlFor="region"
                        className={classNames(form.label, {
                            [form.inputError]: formikProps.errors.region,
                        })}
                    >
                        {t("AddTenantForm.tenantFormRegion")}:
                    </label>
                    <select
                        id="region"
                        className={classNames(form.select, form.fixedWidthInput, {
                            [form.inputError]: formikProps.errors.region,
                        })}
                        data-testid={testIds.workArea.tenant.addTenantDialog.regionSelect.itself}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.region}
                    >
                        <option>{t("AddTenantForm.SelectRegion")}</option>
                        {this.generateRegions(t).map((value, index) => (
                            <option key={index} value={value.code}>
                                {value.name}
                            </option>
                        ))}
                    </select>
                    <div
                        className={form.error}
                        data-testid={testIds.workArea.tenant.addTenantDialog.regionSelect.errorLabel}
                    >
                        <ErrorMessage name="region" />
                    </div>
                </div>
                <Tooltip
                    content={
                        <div className={style.tooltipOpen}>
                            {t("AddTenantForm.tooltip")}
                            <br />
                            <a
                                href="https://aws.amazon.com/about-aws/global-infrastructure/regions_az/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                {t("AddTenantForm.tooltipOpen")}
                            </a>
                        </div>
                    }
                    placement={"top"}
                >
                    <a
                        href="https://aws.amazon.com/about-aws/global-infrastructure/regions_az/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <div className={style.info} />
                    </a>
                </Tooltip>
            </div>
        );
    }

    private createCountrySection(t: TFunction, formikProps: FormikProps<FormValues>) {
        return (
            <div className={form.formFields}>
                <label
                    htmlFor="country"
                    className={classNames(form.label, {
                        [form.inputError]: formikProps.errors.country,
                    })}
                >
                    {t("Common.country")}:
                </label>
                <select
                    id="country"
                    className={classNames(form.select, form.fixedWidthInput, {
                        [form.inputError]: formikProps.errors.country,
                    })}
                    data-testid={testIds.workArea.tenant.addTenantDialog.countrySelect.itself}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.country}
                >
                    <option>{t("AddTenantForm.SelectCountry")}</option>
                    {this.generateCountries(t).map((value, index) => (
                        <option key={index} value={value.code}>
                            {value.name}
                        </option>
                    ))}
                </select>
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.addTenantDialog.countrySelect.errorLabel}
                >
                    <ErrorMessage name="country" />
                </div>
            </div>
        );
    }

    private createNotesSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        const notesChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            this.setState({
                notesCharactersLeft: event.target.maxLength - event.target.value.length,
            });
        };

        return (
            <div className={classNames(form.formFields, form.formFieldsFlex)}>
                <div className={form.formFieldsAlignItemsTop}>
                    <span className={form.optional}>{t("Common.optional")}</span>
                    <label
                        htmlFor="notes"
                        className={classNames(form.label, {
                            [form.inputError]: formikProps.errors.notes,
                        })}
                    >
                        {t("Common.notes")}:
                    </label>
                </div>
                <div className={style.notesContainer}>
                    <textarea
                        id="notes"
                        className={classNames(
                            form.input,
                            form.fixedWidthInput,
                            { [form.inputError]: formikProps.errors.notes },
                            style.notesHeight
                        )}
                        maxLength={NOTES_MAX_LENGTH}
                        data-testid={testIds.workArea.tenant.addTenantDialog.noteTextArea.itself}
                        onChange={(e) => {
                            formikProps.handleChange(e);
                            notesChangeHandler(e);
                        }}
                        value={formikProps.values.notes}
                    />
                    <span className={form.optional}>
                        {t("AddTenantForm.charactersLeft", {
                            remainingCharacters: this.state.notesCharactersLeft.toString(),
                            notesMaxLength: NOTES_MAX_LENGTH.toString(),
                        })}
                    </span>
                </div>
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.addTenantDialog.noteTextArea.errorLabel}
                >
                    <ErrorMessage name="notes" />
                </div>
            </div>
        );
    }

    private createMainContactSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        return (
            <div className={classNames(form.formFields, style.topMarginGap)}>
                <span className={form.optional}>{t("Common.optional")}</span>
                <label
                    htmlFor="mainContactName"
                    className={classNames(form.label, {
                        [form.inputError]: formikProps.errors.mainContactName,
                    })}
                >
                    {t("AddTenantForm.contactName")}:
                </label>
                <input
                    id="mainContactName"
                    className={classNames(form.input, form.fixedWidthInput, {
                        [form.inputError]: formikProps.errors.mainContactName,
                    })}
                    maxLength={NAME_MAX_LENGTH}
                    data-testid={testIds.workArea.tenant.addTenantDialog.contactNameInput.itself}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.mainContactName}
                />
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.addTenantDialog.contactNameInput.errorLabel}
                >
                    <ErrorMessage name="mainContactName" />
                </div>
            </div>
        );
    }

    private createEmailSection(t: TFunction, formikProps: FormikProps<FormValues>) {
        const loader = this.state.miniloader ? (
            <div className={style.loaderContainer}>
                <LoadingIndicator small={true} />
            </div>
        ) : (
            ""
        );
        return (
            <div className={form.formFieldsWithOptionalLabel}>
                <span className={form.optional}>{t("Common.optional")}</span>
                <label
                    htmlFor="email"
                    className={classNames(form.label, {
                        [form.inputError]: formikProps.errors.email,
                    })}
                >
                    {t("Common.email")}:
                </label>
                <input
                    id="email"
                    className={classNames(form.input, form.fixedWidthInput, {
                        [form.inputError]: formikProps.errors.email,
                    })}
                    maxLength={EMAIL_MAX_LENGTH}
                    data-testid={testIds.workArea.tenant.addTenantDialog.contactEmailInput.itself}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.email}
                />
                {loader}
                <div
                    className={form.error}
                    data-testid={testIds.workArea.tenant.addTenantDialog.contactEmailInput.errorLabel}
                >
                    <ErrorMessage name="email" />
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        const abortController = new AbortController();
        const getSubmitHandler = async (
            t: TFunction,
            values: FormValues,
            {
                setErrors,
                setSubmitting,
            }: { setErrors: (errors: FormikErrors<FormValues>) => void; setSubmitting: (isSubmitting: boolean) => void }
        ) => {
            this.setState({
                loading: false,
                // Keep these values in case tenant creation fails. The form is
                // shown to the user again and the old values need to be there.
                formValues: values,
            });

            if (typeof values.email !== "undefined" && values.email !== "") {
                setSubmitting(false);
                try {
                    this.setMiniloader(true);
                    const emailAvailability: CheckEmailAvailability = await userService.checkEmailAvailability(
                        this.state.formValues.email,
                        abortController
                    );
                    if (emailAvailability.emailIsAvailable) {
                        this.setState({
                            loading: true,
                        });
                        this.props.submitEventHandler(values);
                        setSubmitting(true);
                    } else {
                        this.setMiniloader(false);
                        setErrors({ email: t("Common.emailNotAvailable") });
                    }
                } catch (e) {
                    this.setMiniloader(false);
                    setErrors({ email: t("Common.emailNotAvailable") });
                }
            } else {
                this.setState({
                    loading: true,
                });
                this.props.submitEventHandler(values);
            }
        };

        return this.state.loading ? (
            <div className={style.loadingIndicator}>
                <LoadingIndicator />
            </div>
        ) : (
            <Translation>
                {(t) => (
                    <div className={style.formContainer}>
                        <Formik
                            initialValues={this.state.formValues}
                            onSubmit={(values: FormValues, { setErrors, setSubmitting }: FormikHelpers<FormValues>) => {
                                return getSubmitHandler(t, values, { setErrors, setSubmitting });
                            }}
                            validationSchema={object().shape({
                                customerName: string().required(t("AddTenantForm.nameRequired")).max(NAME_MAX_LENGTH),
                                region: string().required(t("AddTenantForm.regionRequired")),
                                country: string().required(t("AddTenantForm.countryRequired")),
                                notes: string().max(NOTES_MAX_LENGTH),
                                mainContactName: string().max(NAME_MAX_LENGTH),
                                email: string().email(t("Common.invalidEmail")).max(EMAIL_MAX_LENGTH),
                            })}
                            validateOnChange={false}
                            validateOnBlur={false}
                        >
                            {(formikProps) => (
                                <form onSubmit={formikProps.handleSubmit}>
                                    <h2>1. {t("AddTenantForm.generic")}</h2>
                                    <div className={style.thinGrayLine} />
                                    {this.createCustomerNameSection(t, formikProps)}
                                    {this.createRegionSection(t, formikProps)}
                                    {this.createCountrySection(t, formikProps)}
                                    {this.createNotesSection(t, formikProps)}
                                    <br />
                                    <h2>2. {t("AddTenantForm.details")}</h2>
                                    <div className={style.thinGrayLine} />
                                    {this.createMainContactSection(t, formikProps)}
                                    {this.createEmailSection(t, formikProps)}
                                    <div className={form.buttonContainer}>
                                        <button
                                            type="submit"
                                            className={classNames(
                                                buttons.primaryButton,
                                                buttons.medium,
                                                form.submitButton
                                            )}
                                            disabled={formikProps.isSubmitting}
                                            data-testid={testIds.workArea.tenant.addTenantDialog.submitButton}
                                        >
                                            {t("AddTenantView.addTenant")}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                )}
            </Translation>
        );
    }
}

import * as React from "react";
import ReactJson from "react-json-view";
import classNames from "classnames";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import StaticTable from "../StaticTable";
import Tooltip from "components/tooltip/Tooltip";
import { getPublicApiUrl } from "services/login/endpointRepository";

import PlainCopy from "components/icons/PlainCopy";
import checkMark from "assets/images/icons/checkMark.svg";
import style from "./endpoints-view.scss";
import commonStyle from "../api-guide.scss";

const API_KEY_HEADER = `-H "X-BLANCCO-API-KEY: 8574e755-1f80-4af4-b7ae-a8187afb3909"`;
const COMMAND_PART_CURL = "curl";
const COMMAND_PART_DATA = "-d";
const COMMAND_PART_XPOST = "-XPOST";
const CONTENT_TYPE_JSON_HEADER = `-H "Content-Type: application/json"`;
const INDENT = "    ";
const TOOLTIP_TIMEOUT = 300;

interface Endpoint {
    linkId: string;
    title: string;
    introduction: string;
    details?: string;
    url: string;
    method: string;
    format: string;
    requestExample?: Record<string, unknown>;
    responseExample: JSX.Element;
    curlSection: JSX.Element;
    parameterSection?: JSX.Element;
}

interface RequestParameter {
    name: string;
    required: boolean;
    description: string;
}

enum CommandPartType {
    PLAINTEXT,
    JSON,
}

class CommandPart {
    value: string;
    type: CommandPartType;
    indent: boolean;

    constructor(value: string, type: CommandPartType, indent = true) {
        this.type = type;
        this.value = value;
        this.indent = indent;
    }
}

const createPlainTextCommandPart = (value: string, indent?: boolean) => {
    return new CommandPart(value, CommandPartType.PLAINTEXT, indent);
};

const createJsonCommandPart = (value: string, indent?: boolean) => {
    return new CommandPart(value, CommandPartType.JSON, indent);
};

function createUrlSection(t: TFunction, endpoint: Endpoint): JSX.Element {
    const copyText = t("Common.copyToClipboard");
    const [copyTooltipText, setCopyTooltipText] = React.useState<string>(copyText);

    function createContent(label: string, value: string, copyClickHandler?: () => void): JSX.Element {
        let copyDiv = null;
        if (copyClickHandler != null) {
            copyDiv = (
                <Tooltip content={copyTooltipText}>
                    <div onClick={copyClickHandler} className={style.urlSubItem}>
                        <PlainCopy />
                    </div>
                </Tooltip>
            );
        }
        const content = (
            <>
                <div className={classNames(style.urlTitle, style.urlSubItem)}>{label}</div>
                {/* 400px is just a guess */}
                <Tooltip maxWidth={400} content={value}>
                    <div className={classNames(style.urlItemValueContainer, style.urlSubItem)}>{value}</div>
                </Tooltip>
                {copyDiv}
            </>
        );
        return content;
    }

    function copyUrl() {
        navigator.clipboard.writeText(endpoint.url);
        setCopyTooltipText(t("Common.copied"));
        // This is a bit of a hack but so far it's the only thing that works. When user clicks the copy icon, the
        // tooltip is hidden and then we'll need to change the text before user is able to move mouse cursor from over
        // the tooltip and back to it. Tippy.js has multiple event handler props like onTrigger and onUntrigger but for
        // some reason they weren't of use here.
        // With 500ms timeout it was possible to see the wrong tooltip when you click, move mouse cursor out, and back
        // in really fast. With 300ms it didn't seem possible and the highest feasible value seems the way to go in case
        // at some point there's some kind of fade-out animation when copy icon is clicked. Then the "Copied!" text
        // would remain as long as the tooltip is visible but it's replaced with the "Copy to clipboard" text before the
        // user can have it visible again.
        setTimeout(() => {
            setCopyTooltipText(copyText);
        }, TOOLTIP_TIMEOUT);
    }

    const items = [
        createContent(t("Support.apiGuide.url"), endpoint.url, copyUrl),
        createContent(t("Support.apiGuide.httpMethod"), endpoint.method),
        createContent(t("Support.apiGuide.format"), endpoint.format),
    ];
    return (
        <div className={style.urlSection}>
            {items.map((each, index) => (
                <div key={index} className={style.urlItem}>
                    {each}
                </div>
            ))}
        </div>
    );
}

function createTextExample(title: string, text: string): JSX.Element {
    return (
        <div className={style.exampleItem}>
            <div className={style.exampleTitle}>{title}</div>
            <div className={style.exampleTextContainer}>{text}</div>
        </div>
    );
}

function createJsonExample(title: string, json: Record<string, unknown>) {
    return (
        <div className={style.exampleItem}>
            <div className={style.exampleTitle}>{title}</div>
            <div className={style.exampleJsonContainer}>
                <ReactJson
                    collapsed={false}
                    // Can't set displayArrayKey to false at this time.
                    // See https://github.com/mac-s-g/react-json-view/issues/380.
                    displayDataTypes={false}
                    displayObjectSize={false}
                    indentWidth={4}
                    name={false}
                    sortKeys={true}
                    src={json}
                />
            </div>
        </div>
    );
}

function createBodyExamples(t: TFunction, endpoint: Endpoint): JSX.Element {
    return (
        <div className={style.exampleSection}>
            {endpoint.requestExample &&
                createJsonExample(t("Support.apiGuide.requestExample"), endpoint.requestExample)}
            {endpoint.responseExample}
        </div>
    );
}

function createCollapsibleSection(showText: string, hideText: string, body?: JSX.Element): JSX.Element | undefined {
    if (body == null) {
        return;
    }
    const [visible, setVisible] = React.useState(false);
    const visibilityText = visible ? hideText : showText;
    const effectiveBody = visible ? body : null;
    return (
        <div>
            <a onClick={() => setVisible((current) => !current)}>{visibilityText}</a>
            {effectiveBody}
        </div>
    );
}

const minifyJson = (postBody: string) => {
    const withoutQuotes = postBody.trim().replace(/^'/, "").replace(/'$/, "");
    return singleQuote(JSON.stringify(JSON.parse(withoutQuotes)));
};

const indentJson = (value: string) => {
    return value
        .split("\n")
        .map((line) => INDENT + line)
        .join("\n");
};

function createCurlSection(t: TFunction, commandParts: CommandPart[]): JSX.Element {
    const copyText = t("Common.copyToClipboard");
    const [copyTooltipText, setCopyTooltipText] = React.useState<string>(copyText);

    const copiedCommand = commandParts
        .map((part) => (part.type === CommandPartType.JSON ? minifyJson(part.value) : part.value))
        .join(" ");

    function clickHandler() {
        navigator.clipboard.writeText(copiedCommand);
        setCopyTooltipText(t("Common.copied"));
        setTimeout(() => {
            setCopyTooltipText(copyText);
        }, TOOLTIP_TIMEOUT);
    }

    const displayedCommand = commandParts
        .map((part) => {
            return part.indent
                ? part.type === CommandPartType.PLAINTEXT
                    ? INDENT + part.value
                    : indentJson(part.value)
                : part.value;
        })
        .join("\n");
    return (
        <div className={style.curlExample}>
            <textarea readOnly={true} wrap={"hard"} value={displayedCommand} data-resize={"true"}></textarea>
            <Tooltip content={copyTooltipText} maxWidth={400}>
                <span onClick={clickHandler} className={style.curlExampleCopyIcon}>
                    <PlainCopy />
                </span>
            </Tooltip>
        </div>
    );
}

function createParameterSection(
    t: TFunction,
    urlParameters: Array<RequestParameter> = [],
    bodyParameters: Array<RequestParameter> = []
): JSX.Element | undefined {
    const missingUrls = urlParameters.length == 0;
    const missingBodies = bodyParameters.length == 0;
    if (missingUrls && missingBodies) {
        return;
    }

    const headers = [
        { className: style.parameterColumn, value: t("Support.apiGuide.parameterTable.header.parameter") },
        { className: style.requiredColumn, value: t("Support.apiGuide.parameterTable.header.required") },
        { value: t("Support.apiGuide.parameterTable.header.description") },
    ];

    const cellMapper = (each: RequestParameter) => [
        each.name,
        each.required ? <img src={checkMark} /> : null,
        each.description,
    ];

    const urlPart = !missingUrls && (
        <div>
            <div className={commonStyle.normalHeader}>{t("Support.apiGuide.urlRequestParameters")}</div>
            <StaticTable headers={headers} cells={urlParameters.map(cellMapper)} />
        </div>
    );
    const bodyPart = !missingBodies && (
        <div>
            <div className={commonStyle.normalHeader}>{t("Support.apiGuide.bodyRequestParameters")}</div>
            <StaticTable headers={headers} cells={bodyParameters.map(cellMapper)} />
        </div>
    );
    return (
        <div>
            {urlPart}
            {bodyPart}
        </div>
    );
}

function createExportReportWithUuidEndpoint(t: TFunction, baseUrl: string): Endpoint {
    return {
        linkId: "export-single-report",
        title: t("Support.apiGuide.endpoint.exportSingleReport.title"),
        introduction: t("Support.apiGuide.endpoint.exportSingleReport.introduction"),
        url: baseUrl + "/{uuid}",
        method: "GET",
        format: t("Common.na"),
        responseExample: createTextExample(
            t("Support.apiGuide.response"),
            t("Support.apiGuide.endpoint.exportSingleReport.responseDescription")
        ),
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(`${baseUrl}/4715f169-1229-4315-90b4-c125a031e865`),
        ]),
        parameterSection: createParameterSection(t, [
            {
                name: "uuid",
                required: true,
                description: t("Support.apiGuide.endpoint.exportSingleReport.uuidParameter"),
            },
        ]),
    };
}

function createExportReportGetEndpoint(t: TFunction, baseUrl: string, cursor: RequestParameter): Endpoint {
    // TODO BCC-938 Consider whether it would be good to have multiple curl examples. Here we'd have with and
    // without IMEI filtering. Add them if there's time.
    return {
        linkId: "export-reports-get",
        title: t("Support.apiGuide.endpoint.exportReportsGet.title"),
        introduction: t("Support.apiGuide.endpoint.exportReportsGet.introduction"),
        url: baseUrl,
        method: "GET",
        format: t("Common.na"),
        responseExample: createTextExample(t("Support.apiGuide.response"), t("Support.apiGuide.zipResponse")),
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(`${baseUrl}?imei=355324081989399`),
        ]),
        parameterSection: createParameterSection(t, [
            {
                name: "imei",
                required: false,
                description: t("Support.apiGuide.endpoint.exportReportsGet.imeiFilterDescription"),
            },
            cursor,
        ]),
    };
}

const singleQuote = (value: string) => {
    return "'" + value + "'";
};

function createExportReportPostEndpoint(t: TFunction, baseUrl: string, cursor: RequestParameter): Endpoint {
    const postCurlExample = {
        filter: { date: { gt: "2021-12-31T23:59:59Z" }, fields: [{ like: "355324081989399", name: "@imei" }] },
    };
    const filter = "filter";
    const filterParameters = [filter, "category", "date", "fields"].map((each) => ({
        name: (each === filter ? "" : "filter.") + each,
        required: false,
        description: t("Support.apiGuide.endpoint.exportReportsPost.parameterDescription." + each),
    }));
    return {
        linkId: "export-reports-post",
        title: t("Support.apiGuide.endpoint.exportReportsPost.title"),
        introduction: t("Support.apiGuide.endpoint.exportReportsPost.introduction"),
        url: baseUrl,
        method: "POST",
        format: "application/json",
        requestExample: {
            cursor: "",
            filter: {
                category: "ERASURE",
                date: { gt: "2021-12-31T23:59:59-0500" },
                fields: [{ name: "@imei", like: "355324081989399" }],
            },
        },
        responseExample: createTextExample(t("Support.apiGuide.response"), t("Support.apiGuide.zipResponse")),
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(COMMAND_PART_XPOST),
            createPlainTextCommandPart(CONTENT_TYPE_JSON_HEADER),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(COMMAND_PART_DATA),
            createJsonCommandPart(singleQuote(JSON.stringify(postCurlExample, null, 4))),
            createPlainTextCommandPart(baseUrl),
        ]),
        parameterSection: createParameterSection(t, [cursor], [cursor, ...filterParameters]),
    };
}

export default function EndpointsView(): JSX.Element {
    const { t } = useTranslation();
    const reportExportUrl = getPublicApiUrl() + "/report/export";
    const cursorParameter = {
        name: "cursor",
        required: false,
        description: t("Support.apiGuide.endpoint.exportReportsPost.parameterDescription.cursor"),
    };
    const endpoints = [
        createExportReportWithUuidEndpoint(t, reportExportUrl),
        createExportReportGetEndpoint(t, reportExportUrl, cursorParameter),
        createExportReportPostEndpoint(t, reportExportUrl, cursorParameter),
    ];
    const links = endpoints.map((each) => (
        <div key={each.linkId}>
            <a href={"#" + each.linkId}>{each.title}</a>
        </div>
    ));
    const endpointSections = endpoints.map((each) => (
        <div key={each.linkId} id={each.linkId} className={style.endpointSections}>
            <div className={commonStyle.sectionHeader}>{each.title}</div>
            <div className={commonStyle.sectionBody}>{each.introduction}</div>
            {createUrlSection(t, each)}
            {createBodyExamples(t, each)}
            {each.details && <div>{each.details}</div>}
            {createCollapsibleSection(t("Support.apiGuide.showCurl"), t("Support.apiGuide.hideCurl"), each.curlSection)}
            {createCollapsibleSection(
                t("Support.apiGuide.showParameters"),
                t("Support.apiGuide.hideParameters"),
                each.parameterSection
            )}
        </div>
    ));
    React.useEffect(() => {
        // It's really difficult to place the copy icon to the upper right corner of the textarea while also preparing
        // for possible scrollbars. Here the issue is solved by eliminating the scrollbars completely and always
        // resizing the textarea to fit the content. Given that the textareas aren't always visible when the page loads,
        // we need to do this in useEffect.
        const areas = document.querySelectorAll('textarea[data-resize="true"]');
        for (let i = 0; i < areas.length; i++) {
            areas[i].setAttribute("style", "height:" + areas[i].scrollHeight + "px");
        }
    });
    return (
        <div className={style.mainContainer}>
            <div className={commonStyle.sectionHeader}>{t("Support.apiGuide.availableEndpoints")}</div>
            <div>{links}</div>
            <div>{endpointSections}</div>
        </div>
    );
}

import * as React from "react";
import classNames from "classnames";
import { Formik, FormikConfig, FormikProps, Form, Field } from "formik";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";

import { usageStatisticsService, Action, Category } from "services/statistics/UsageStatisticsService";

import buttonStyles from "styles/buttons.scss";
import form from "styles/form.scss";
import style from "./add-configuration.scss";

import testIds from "testIds.json";

export interface AddConfigurationFormProps {
    configuration?: string;
    failureMessage: string;
    onSuccess: (values: FormValues) => Promise<void>;
}

export interface FormValues {
    configuration: string;
}

export default function AddConfigurationForm(props: AddConfigurationFormProps): JSX.Element {
    const { t } = useTranslation();
    const submitHandler: FormikConfig<FormValues>["onSubmit"] = (values) => props.onSuccess(values);

    const jsonCheck = (value: string) => {
        let error;
        try {
            JSON.parse(value);
        } catch {
            error = t("configuration.AddConfigureDialog.errorMessage");
        }
        return error;
    };
    return (
        <Formik
            initialValues={{ configuration: props.configuration }}
            onSubmit={submitHandler}
            validationSchema={object().shape({
                configuration: string().required(t("configuration.AddConfigureDialog.configRequired")),
            })}
            validateOnChange={true}
            validateOnBlur={true}
            validateOnMount
        >
            {({
                values,
                resetForm,
                handleChange,
                handleBlur,
                errors,
                isSubmitting,
                isValid,
            }: FormikProps<FormValues>) => {
                return props.failureMessage ? (
                    props.failureMessage
                ) : (
                    <Form>
                        <div className={form.formFields}>
                            <Field
                                as="textarea"
                                id="configuration"
                                className={classNames(style.textarea, form.input, form.fixedWidthInput, {
                                    [form.inputError]: errors.configuration,
                                })}
                                name="configuration"
                                onChange={handleChange}
                                validate={jsonCheck}
                                onBlur={handleBlur}
                                value={values.configuration}
                                data-testid={testIds.workArea.license.configure.configInput.itself}
                            ></Field>
                            <div
                                className={form.error}
                                data-testid={testIds.workArea.license.configure.configInput.errorLabel}
                            >
                                {errors.configuration}
                            </div>
                        </div>
                        <div className={form.buttonContainer}>
                            <button
                                type="reset"
                                className={classNames(
                                    buttonStyles.primaryButton,
                                    buttonStyles.medium,
                                    form.submitButton,
                                    style.addButton
                                )}
                                disabled={isSubmitting}
                                onClick={() => {
                                    resetForm();
                                    usageStatisticsService.sendEvent({
                                        category: Category.LICENSE,
                                        action: Action.RESET_LICENSE_CONFIGURATION,
                                    });
                                }}
                            >
                                {t("configuration.AddConfigureDialog.resetButton")}
                            </button>
                            <button
                                type="submit"
                                disabled={!isValid}
                                className={classNames(
                                    !isValid ? buttonStyles.disabledButton : buttonStyles.primaryButton,
                                    buttonStyles.medium,
                                    form.submitButton
                                )}
                                data-testid={testIds.workArea.license.configure.addConfigureButton}
                            >
                                {t("configuration.AddConfigureDialog.saveButton")}
                            </button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

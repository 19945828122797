import { UserDetails } from "domain/user";
import { getObject, remove, setObject, RepositoryKey } from "services/utils/repository";

export function getUser(): UserDetails | null {
    return getObject(RepositoryKey.USER_DETAILS);
}

export function setUser(userDetails: UserDetails): void {
    setObject(RepositoryKey.USER_DETAILS, userDetails);
}

export function clear(): void {
    remove(RepositoryKey.USER_DETAILS);
}

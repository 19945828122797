import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps, FormikValues } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import style from "components/login/create-new-password/create-new-password.scss";
import form from "styles/form.scss";
import buttons from "styles/buttons.scss";
import buttonsStyle from "styles/buttons.scss";
import { FormikHelpers } from "formik/dist/types";
import { ProvisionProperties } from "./CreateNewPasswordView";

interface Props {
    validatePassword: (value: string) => void;
    submitDisabled: boolean;
    passwordCallback: (value: string) => void;
    cancelCallback: () => void;
    provisionProperties: ProvisionProperties[];
}

const NEW_PASSWORD_ID = "newPassword";
const CONFIRM_PASSWORD_ID = "confirmPassword";
const MAXIMUM_NUMBER_OF_RULES = 4;
const MINIMUM_NUMBER_OF_RULES = 0;

const CreateNewPasswordForm = (props: Props): JSX.Element => {
    const { t } = useTranslation();

    const onFormSubmit = (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => {
        if (values[NEW_PASSWORD_ID] !== values[CONFIRM_PASSWORD_ID]) {
            formikHelpers.setErrors({ [CONFIRM_PASSWORD_ID]: t("CreateNewPasswordForm.passwordsMustMatch") });
            return;
        }
        props.passwordCallback(values[NEW_PASSWORD_ID]);
    };

    const found = props.provisionProperties.find((e) => !e.fulfilled());
    const translationKey = found ? found.translationKey : "";
    const strengthIndicatorIndex = -1 + props.provisionProperties.filter((e) => e.fulfilled()).length;

    const [hideIndicator, setHideIndicator] = React.useState(false);

    const showIndicator = () => {
        return (
            !hideIndicator &&
            strengthIndicatorIndex >= MINIMUM_NUMBER_OF_RULES &&
            strengthIndicatorIndex <= MAXIMUM_NUMBER_OF_RULES
        );
    };

    return (
        <Formik
            initialValues={{ [NEW_PASSWORD_ID]: "", [CONFIRM_PASSWORD_ID]: "" }}
            onSubmit={onFormSubmit}
            validateOnBlur={true}
        >
            {({ errors }: FormikProps<FormikValues>) => (
                <>
                    <Form>
                        <div className={style.createNewPasswordGrid}>
                            <label htmlFor={NEW_PASSWORD_ID} className={style.createNewPasswordLabel}>
                                {t("CreateNewPasswordForm.newPassword")}
                            </label>
                            <div>
                                <Field name={NEW_PASSWORD_ID} validate={props.validatePassword}>
                                    {({ field }: FieldProps) => (
                                        <input
                                            id={NEW_PASSWORD_ID}
                                            type="password"
                                            {...field}
                                            onFocus={() => {
                                                setHideIndicator(false);
                                            }}
                                            className={classNames(style.createNewPasswordInput, {
                                                [style.invalidInput]:
                                                    errors[CONFIRM_PASSWORD_ID] ||
                                                    (strengthIndicatorIndex < MAXIMUM_NUMBER_OF_RULES &&
                                                        strengthIndicatorIndex > MINIMUM_NUMBER_OF_RULES),
                                                [style.validInput]:
                                                    !errors[CONFIRM_PASSWORD_ID] &&
                                                    strengthIndicatorIndex >= MAXIMUM_NUMBER_OF_RULES,
                                            })}
                                        />
                                    )}
                                </Field>
                                <div>
                                    <div className={classNames({ [style.strengthMeter]: showIndicator() })}>
                                        <div
                                            className={classNames(style.strengthMeterUnfilled, {
                                                [style.strengthMeterFill]:
                                                    strengthIndicatorIndex >= MINIMUM_NUMBER_OF_RULES,
                                            })}
                                            data-strength={
                                                strengthIndicatorIndex >= MINIMUM_NUMBER_OF_RULES
                                                    ? strengthIndicatorIndex.toString()
                                                    : ""
                                            }
                                        >
                                            {}
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            showIndicator()
                                                ? classNames(style.strengthMeterText, {
                                                      [style.weakPassword]:
                                                          strengthIndicatorIndex < MAXIMUM_NUMBER_OF_RULES,
                                                      [style.strongPassword]:
                                                          strengthIndicatorIndex >= MAXIMUM_NUMBER_OF_RULES,
                                                  })
                                                : undefined
                                        }
                                    >
                                        {showIndicator()
                                            ? translationKey !== ""
                                                ? t("CreateNewPasswordView.passwordPolicyIntro") +
                                                  " " +
                                                  t(`CreateNewPasswordView.${translationKey}`)
                                                : t("CreateNewPasswordView.passwordPolicySuccess")
                                            : ""}
                                    </div>
                                </div>
                            </div>
                            <label htmlFor={CONFIRM_PASSWORD_ID} className={style.createNewPasswordLabel}>
                                {t("CreateNewPasswordForm.confirmNewPassword")}
                            </label>
                            <div>
                                <Field name={CONFIRM_PASSWORD_ID}>
                                    {({ field }: FieldProps) => (
                                        <input
                                            id={CONFIRM_PASSWORD_ID}
                                            type="password"
                                            {...field}
                                            onFocus={() => {
                                                setHideIndicator(true);
                                            }}
                                            className={classNames(style.createNewPasswordInput, {
                                                [style.invalidInput]: errors[CONFIRM_PASSWORD_ID],
                                            })}
                                        />
                                    )}
                                </Field>
                                <div className={form.error}>
                                    <ErrorMessage name={CONFIRM_PASSWORD_ID} />
                                </div>
                            </div>
                        </div>
                        <div className={form.buttonContainer}>
                            <button
                                type="button"
                                className={classNames(
                                    buttons.secondaryButton,
                                    buttons.medium,
                                    style.cancelButton,
                                    form.submitButton
                                )}
                                onClick={props.cancelCallback}
                            >
                                {t("Common.cancel")}
                            </button>
                            <button
                                type="submit"
                                className={classNames(
                                    props.submitDisabled ? buttonsStyle.disabledButton : buttonsStyle.primaryButton,
                                    buttons.medium,
                                    form.submitButton
                                )}
                                disabled={props.submitDisabled}
                            >
                                {t("CreateNewPasswordForm.changePassword")}
                            </button>
                        </div>
                    </Form>
                </>
            )}
        </Formik>
    );
};

export default CreateNewPasswordForm;

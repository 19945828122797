import { createSlice } from "@reduxjs/toolkit";
import defaultColor from "styles/colors/default-color.scss";
import tenantColor from "styles/colors/tenant-color.scss";
import defaultdarkColor from "styles/colors/default-dark-color.scss";
import tenantdarkColor from "styles/colors/tenant-dark-color.scss";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import * as settingRepository from "services/settings/settingsRepository";
import { ThemeMode } from "domain/themeMode";

export enum ThemeName {
    DEFAULT = "defaultTheme",
    TENANT = "tenantTheme",
    DEFAULT_DARK = "defaultDarkTheme",
    TENANT_DARK = "tenantDarkTheme",
}

interface ThemeState {
    theme: typeof defaultColor | typeof tenantColor;
    themeName: ThemeName;
}

const DEFAULT_STATE: ThemeState = {
    theme: defaultColor,
    themeName: ThemeName.DEFAULT,
};

const DEFAULT_DARK_STATE: ThemeState = {
    theme: defaultdarkColor,
    themeName: ThemeName.DEFAULT_DARK,
};

const TENANT_STATE: ThemeState = {
    theme: tenantColor,
    themeName: ThemeName.TENANT,
};

const TENANT_DARK_STATE: ThemeState = {
    theme: tenantdarkColor,
    themeName: ThemeName.TENANT_DARK,
};

const THEME_SLICE = createSlice({
    name: "themeSlice",
    initialState: hasTenantCookie()
        ? settingRepository.getThemeMode() == ThemeMode.DARK
            ? TENANT_DARK_STATE
            : TENANT_STATE
        : settingRepository.getThemeMode() == ThemeMode.DARK
        ? DEFAULT_DARK_STATE
        : DEFAULT_STATE,
    reducers: {
        switchTheme(state) {
            if (state.themeName === ThemeName.DEFAULT || state.themeName === ThemeName.DEFAULT_DARK) {
                if (settingRepository.getThemeMode() == ThemeMode.DARK) {
                    state.themeName = TENANT_DARK_STATE.themeName;
                    state.theme = TENANT_DARK_STATE.theme;
                } else {
                    state.themeName = TENANT_STATE.themeName;
                    state.theme = TENANT_STATE.theme;
                }
            } else {
                if (settingRepository.getThemeMode() == ThemeMode.DARK) {
                    state.themeName = DEFAULT_DARK_STATE.themeName;
                    state.theme = DEFAULT_DARK_STATE.theme;
                } else {
                    state.themeName = DEFAULT_STATE.themeName;
                    state.theme = DEFAULT_STATE.theme;
                }
            }
        },
        applyMode(state) {
            if (state.themeName === ThemeName.TENANT || state.themeName === ThemeName.TENANT_DARK) {
                if (settingRepository.getThemeMode() == ThemeMode.DARK) {
                    state.themeName = TENANT_DARK_STATE.themeName;
                    state.theme = TENANT_DARK_STATE.theme;
                } else {
                    state.themeName = TENANT_STATE.themeName;
                    state.theme = TENANT_STATE.theme;
                }
            } else {
                if (settingRepository.getThemeMode() == ThemeMode.DARK) {
                    state.themeName = DEFAULT_DARK_STATE.themeName;
                    state.theme = DEFAULT_DARK_STATE.theme;
                } else {
                    state.themeName = DEFAULT_STATE.themeName;
                    state.theme = DEFAULT_STATE.theme;
                }
            }
        },
    },
});

export const { switchTheme, applyMode } = THEME_SLICE.actions;

export default THEME_SLICE.reducer;

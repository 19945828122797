import * as React from "react";
import KebabIcon from "components/icons/KebabIcon";
import ReactTooltip from "react-tooltip";
import style from "./kebab-menu.scss";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";
import { Wrapper, Button, WrapperState } from "react-aria-menubutton";
import classNames from "classnames";
import testIds from "testIds.json";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const KebabMenu = (props: { children: React.ReactElement } & ConnectedProps<typeof connector>): JSX.Element => {
    const [iconVisibility, setIconVisibility] = React.useState<boolean>(false);
    const iconClass: string = classNames(style.kebabMenuButton, { [style.kebabMenuButtonBlue]: iconVisibility });
    const onMenuToggle = (state: WrapperState) => {
        setIconVisibility(state.isOpen);
    };
    React.useEffect(() => {
        if (iconVisibility) {
            // This is for rebinding all tooltips - fixes displaying the tooltips for Access Tenant icon and Edit Tenant icon
            // Those two tooltips only exist after they've become visible and by default ReactTooltip doesn't notice that
            // they've appeared and thus there's no tooltip without rebinding
            ReactTooltip.rebuild();
        }
    }, [iconVisibility]);

    return (
        <Wrapper onMenuToggle={onMenuToggle} className={style.kebabIconContainer}>
            <Button data-testid={testIds.common.table.kebabMenu.button}>
                <KebabIcon color={props.theme.primaryButtonBackgroundColor} className={iconClass} />
            </Button>
            {props.children}
        </Wrapper>
    );
};
export default connector(KebabMenu);

import * as React from "react";
import { MutableRefObject, useEffect, useState } from "react";
import useScript from "react-script-hook";
import { Profile, WorkflowEditorDto } from "services/workflows/WorkflowService";

import style from "./visual-editor.scss";
import { Workflow } from "domain/workflows";

export interface WorkflowEditor {
    serializeWorkflow(): string;
}

interface LoadTarget extends Window {
    WorkflowEditor: WorkflowEditor;
}

interface Props {
    profile: Profile;
    version?: string;
    workflow?: Workflow;
    workflowEditorReference: MutableRefObject<WorkflowEditor | undefined>;
    workflowEditors: WorkflowEditorDto[];
    setWorkflow: (workflow: Workflow) => void;
}

declare let window: LoadTarget;

const generateId = (length = 16) => {
    const characters = "WorkflowEditorContainer-abcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

const VisualEditorView: React.FunctionComponent<Props> = (props) => {
    const EDITOR_CONTAINER_ID = generateId();
    const [workflowEditorUrl, setWorkflowEditorUrl] = useState("");
    useScript({
        src: workflowEditorUrl.length > 0 ? workflowEditorUrl : null,
        onload: () => onWorkflowEditorLoaded(),
    });

    const pickWorkflowEditor = (profile: Profile, version?: string) => {
        const sorted = props.workflowEditors
            .filter((editor) => editor.profile === profile)
            .sort((left, right) => {
                const versionLeft = left.version.toLowerCase();
                const versionRight = right.version.toLowerCase();
                if (versionLeft < versionRight) {
                    return -1;
                }
                if (versionLeft > versionRight) {
                    return 1;
                }
                return 0;
            });

        if (version) {
            const editor = sorted.find((editor) => editor.version === version);
            if (editor) {
                return editor;
            }
        }

        return sorted[sorted.length - 1];
    };

    const onWorkflowEditorLoaded = () => {
        props.workflowEditorReference.current = new (window as { [key: string]: any })["WorkflowEditor"](
            document.getElementById(EDITOR_CONTAINER_ID),
            typeof props.workflow !== "undefined" ? JSON.stringify(props.workflow) : null,
            null
        );
    };

    useEffect(() => {
        setWorkflowEditorUrl(pickWorkflowEditor(props.profile, props.version).url);

        return () => {
            delete window.WorkflowEditor;
            props.workflowEditorReference.current = undefined;
        };
    }, []);

    return (
        <>
            <div id={EDITOR_CONTAINER_ID} className={style.editorContainer}></div>
        </>
    );
};

export default VisualEditorView;

import { authenticationService } from "services/security/AuthenticationService";
import * as UserSessionRepository from "./userSessionRepository";
import { CommonUserDetails, UserDetails } from "domain/user";
import { apiGatewayService } from "../api/ApiGatewayService";
import { removeTenantCookie } from "../tenants/tenantCookieService";
import { removeUser } from "store/user";
import * as endpointRepository from "../login/endpointRepository";

interface UserDetailsDto extends CommonUserDetails {
    tenant_name: string;
    tenant_type: string;
    tenant_uuid: string;
    usage_statistics: boolean;
    erasure_client_endpoint: string;
    eula_accepted_date: string;
}

function toUserDetails(dto: UserDetailsDto): UserDetails {
    return {
        created: dto.created,
        email: dto.email,
        modified: dto.modified,
        name: dto.name,
        username: dto.username,
        uuid: dto.uuid,
        tenantName: dto.tenant_name,
        tenantType: dto.tenant_type,
        tenantUuid: dto.tenant_uuid,
        usageStatistics: dto.usage_statistics,
        erasureClientEndpoint: dto.erasure_client_endpoint,
        eulaAcceptedDate: dto.eula_accepted_date,
    };
}

class UserSessionService {
    private static removeSession(): void {
        UserSessionRepository.clear();
        endpointRepository.clear();
    }

    public async login(username: string, password: string): Promise<UserDetails> {
        try {
            await authenticationService
                .authenticate(username.toLowerCase(), password)
                .then((response) => {
                    const loginData = response;
                    endpointRepository.setUrl(
                        loginData.message.stan,
                        loginData.message.oliver,
                        loginData.message.publicApi,
                        loginData.message.publicApiDocumentation
                    );
                })
                .catch(() => {
                    return Promise.reject("Unable to authenticate user");
                });

            return await this.fetchUserDetails();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public storeUser(user: UserDetails) {
        UserSessionRepository.setUser(user);
    }

    public async logout() {
        await authenticationService.logout();
        try {
            UserSessionService.removeSession();
        } catch (e) {
            console.error("Unable to remove user session", e);
        }

        removeTenantCookie();
        removeUser();

        window.location.replace("/login");
        return Promise.resolve();
    }

    public currentUserDetails(): UserDetails | null {
        try {
            return UserSessionRepository.getUser();
        } catch (e) {
            console.error("Unable to get the user details", e);
        }
        return null;
    }

    public fetchUserDetails(): Promise<UserDetails> {
        return apiGatewayService
            .invokeApi("/users/profile", "get")
            .then((dto: UserDetailsDto) => {
                return toUserDetails(dto);
            })
            .catch(() => {
                return Promise.reject("Unable to retrieve user data");
            });
    }
}

export const userSessionService = new UserSessionService();

import { LanguageDetails } from "domain/language";
import { getObject, setObject, RepositoryKey } from "services/utils/repository";

// Note that UI testing automation will come to rely on the _code_ format xx_YY
// so it can't be changed.
const LANGUAGES: LanguageDetails[] = [
    { locale: "EN", title: "English", code: "en_US" },
    { locale: "FR", title: "Français", code: "fr_FR" },
    { locale: "DE", title: "Deutsch", code: "de_DE" },
    { locale: "JA", title: "日本語", code: "ja_JP" },
];

const DEFAULT_LANGUAGE = LANGUAGES[0];

export function getAllLanguages(): LanguageDetails[] {
    return LANGUAGES;
}

export function getLanguage(): LanguageDetails {
    if (getObject(RepositoryKey.LOCALE) === null) {
        setLanguage(DEFAULT_LANGUAGE.locale);
    }
    const locale = getObject(RepositoryKey.LOCALE);
    return LANGUAGES.find((language) => language.locale === locale) || DEFAULT_LANGUAGE;
}

export function setLanguage(language: string): void {
    setObject(RepositoryKey.LOCALE, language);
}

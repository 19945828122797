import * as React from "react";
import { MouseEventHandler } from "react";
import Tick from "./Tick";
import style from "./chart.scss";
import { StoreState } from "store";
import { connect, ConnectedProps } from "react-redux";

interface CheckboxProps {
    checked: boolean;
    tickColor: string;
    Label: string;
    onClick: MouseEventHandler;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const Checkbox = (props: CheckboxProps & ConnectedProps<typeof connector>): JSX.Element => (
    <div onClick={props.onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
            <g id="checkbox" transform="translate(-542 -330)">
                <g id="Group_2635" data-name="Group 2635" transform="translate(542 330)">
                    <g
                        id="Rectangle_133"
                        data-name="Rectangle 133"
                        fill={props.theme.inputBackgroundColor}
                        stroke={props.theme.contentDividerColor}
                        strokeWidth="1"
                    >
                        <rect width="17" height="17" rx="4" stroke="none" />
                        {props.checked ? <Tick tickColor={props.tickColor} /> : <></>}
                        <rect x="0.5" y="0.5" width="16" height="16" rx="3.5" fill="none" />
                    </g>
                </g>
            </g>
        </svg>
        <span className={style.legendCheckboxLabel}>{props.Label}</span>
    </div>
);

export default connector(Checkbox);

import { TableData } from "./table";

export enum UserStatus {
    UNCONFIRMED = "UNCONFIRMED",
    CONFIRMED = "CONFIRMED",
    ARCHIVED = "ARCHIVED",
    COMPROMISED = "COMPROMISED",
    UNKNOWN = "UNKNOWN",
    RESET_REQUIRED = "RESET_REQUIRED",
    FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
}

export interface UserData extends TableData {
    name: string;
    status: UserStatus;
    createdDate: string;
    email: string;
    uuid: string;
    emailVerified: boolean;
}

export interface CheckEmailAvailability {
    emailIsAvailable: boolean;
}

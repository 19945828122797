import * as React from "react";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";

import DateCell from "components/table/DateCell";
import KebabMenu from "components/kebab-menu/KebabMenu";
import Modal from "components/modal/Modal";
import Star, { Color as StarColor } from "components/icons/Star";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import WorkflowEditDialog from "./workflow-edit-dialog/WorkflowEditDialog";
import WorkflowKebabMenu from "components/workflows/WorkflowKebabMenu";
import { Profile, profileToNameMap } from "services/workflows/WorkflowService";
import { RepositoryKey } from "services/utils/repository";
import { WorkflowTableData } from "domain/workflows";

import style from "./all-workflows-table.scss";
import testIds from "testIds.json";

interface TableState {
    workflowsData: WorkflowTableData[];
}

export function AllWorkflowsTable(props: {
    count: number;
    onUpdate: () => void;
    workflowService: { fetchWorkflows: (abortController: AbortController) => Promise<WorkflowTableData[]> };
}): JSX.Element {
    const { t } = useTranslation();
    const [tableState, setTableState] = React.useState<TableState>({ workflowsData: [] });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [workflowUuid, setWorkflowUuid] = React.useState<string>();
    const [editWorkflowVisible, setEditWorkflowVisible] = React.useState(false);
    const [confirmCancelVisible, setConfirmCancelVisible] = React.useState(false);
    const hideEditWorkflow = (doCancel: boolean) => {
        if (doCancel) {
            setEditWorkflowVisible(false);
        }
        setConfirmCancelVisible(false);
    };
    const showEditWorkflow = (uuid: string) => {
        setWorkflowUuid(uuid);
        setConfirmCancelVisible(false);
        setEditWorkflowVisible(true);
    };

    const fetchData = (abortController: AbortController) => {
        setLoading(true);
        props.workflowService
            .fetchWorkflows(abortController)
            .then((data) => {
                setTableState((prevState) => ({
                    ...prevState,
                    workflowsData: data,
                }));
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("WorkflowsTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                }
            });
    };

    const columns: Array<Column<WorkflowTableData>> = [
        {
            Header: () => <TextWithTooltip text={t("WorkflowsTable.name")} key="name" />,
            accessor: "name",
            Cell: (cellInfo) => (
                <>
                    <KebabMenu>
                        <WorkflowKebabMenu
                            uuid={cellInfo.cell.row.original.uuid}
                            name={cellInfo.cell.row.original.name}
                            own={cellInfo.cell.row.original.own}
                            defaultWorkflow={cellInfo.cell.row.original.defaultWorkflow.own}
                            onUpdate={props.onUpdate}
                        />
                    </KebabMenu>
                    <div className={style.nameLinkContainer}>
                        <a
                            className={style.nameLinkContainer}
                            onClick={() => {
                                showEditWorkflow(cellInfo.cell.row.original.uuid);
                            }}
                        >
                            <TextWithTooltip text={cellInfo.value} />
                        </a>
                    </div>
                </>
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("WorkflowsTable.uuid")} key="uuid" />,
            accessor: "uuid",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("WorkflowsTable.product")} key="product" />,
            accessor: "product",
            Cell: ({ cell: { value } }) => (
                <TextWithTooltip text={profileToNameMap.get(value.toLowerCase() as Profile)} />
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("WorkflowsTable.createdDate")} key="createdDate" />,
            accessor: "createdDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("WorkflowsTable.creator")} key="creator" />,
            accessor: "creator",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("WorkflowsTable.default")} key="defaultWorkflow.own" />,
            accessor: "defaultWorkflow",
            id: "defaultWorkflow.own",
            Cell: ({ cell: { value } }) =>
                value.own === true && (
                    <TextWithTooltip text={t("WorkflowsTable.defaultTooltip")}>
                        <Star color={StarColor.GREEN} />
                    </TextWithTooltip>
                ),
        },
        {
            Header: () => <TextWithTooltip text={t("WorkflowsTable.otherDefaults")} key="defaultWorkflow.other" />,
            accessor: "defaultWorkflow",
            id: "defaultWorkflow.other",
            Cell: ({ cell: { value } }) => {
                const usernames = value.other.usernames;
                const suffix = usernames.length < value.other.count ? ",<br/>..." : "";
                return (
                    usernames.length > 0 && (
                        <TextWithTooltip
                            text={
                                t("WorkflowsTable.otherDefaultsTooltip") + "<br/>" + usernames.join(",<br/>") + suffix
                            }
                            multiline={true}
                        >
                            <Star color={StarColor.GRAY} />
                        </TextWithTooltip>
                    )
                );
            },
        },
    ];
    React.useEffect(() => {
        const abortController = new AbortController();
        fetchData(abortController);

        return () => {
            abortController.abort();
        };
    }, [props.count]);

    return (
        <>
            <Table
                tableIdentity={RepositoryKey.WORKFLOWS_TABLE}
                data={tableState.workflowsData}
                columns={columns}
                loaded={!loading}
                failureMessage={requestFailureMessage}
                tooltips={true}
                emptyMessage={t("WorkflowsTable.emptyStateMessage")}
                data-testId={testIds.workArea.workflows.table}
            />
            <Modal
                isOpen={editWorkflowVisible}
                hideModal={() => setConfirmCancelVisible(true)}
                modalTitle={t("ManageWorkflowDialog.title.manage")}
            >
                <WorkflowEditDialog
                    onCancel={hideEditWorkflow}
                    confirmCancelVisible={confirmCancelVisible}
                    onUpdateParentView={props.onUpdate}
                    workflowUuid={workflowUuid}
                />
            </Modal>
        </>
    );
}

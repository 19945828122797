import * as React from "react";

import style from "./plain-copy.scss";

// Copy icon with colored lines and no fill
const PlainCopy = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="icon_copy_plain" transform="translate(1 1)">
                <rect
                    id="Rectangle_1712"
                    data-name="Rectangle 1712"
                    width="10"
                    height="10"
                    rx="2"
                    transform="translate(6 6)"
                    fill="none"
                    className={style.shape}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <path
                    id="Path_2249"
                    data-name="Path 2249"
                    d="M4.472,12.71H3.648A1.648,1.648,0,0,1,2,11.063V3.648A1.648,1.648,0,0,1,3.648,2h7.415A1.648,1.648,0,0,1,12.71,3.648v.824"
                    transform="translate(-2 -2)"
                    fill="none"
                    className={style.shape}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
};

export default PlainCopy;

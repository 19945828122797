import * as React from "react";

import style from "./loading-indicator.scss";

import testIds from "testIds.json";

export const LoadingIndicator = (props: { small?: boolean }): JSX.Element => {
    return (
        <div
            className={props.small === true ? style.smallGrid : style.skCubeGrid}
            data-testid={testIds.common.inProgressElement}
        >
            <div className={style.skCube1} />
            <div className={style.skCube2} />
            <div className={style.skCube3} />
            <div className={style.skCube4} />
            <div className={style.skCube5} />
            <div className={style.skCube6} />
            <div className={style.skCube7} />
            <div className={style.skCube8} />
            <div className={style.skCube9} />
        </div>
    );
};

import * as React from "react";
import { useTranslation } from "react-i18next";

import ApplicationLayout from "components/layout/ApplicationLayout";
import AssignKeysView from "./assign-bms-keys/AssignKeysView";
import { LICENSES_ROUTE } from "components/router/Routes";
import LicenseKeysTable from "./LicenseKeysTable";

export default function BmsLicenseKeysView(): JSX.Element {
    const { t } = useTranslation();
    const [state, setState] = React.useState(0);
    function incrementState(): void {
        setState((previous) => previous + 1);
    }

    return (
        <ApplicationLayout
            viewTitle={t("LicenseKeys.bmsLicenseKeys")}
            view={<LicenseKeysTable initialLicensekeys={[]} count={state} />}
            mainRoute={LICENSES_ROUTE}
            viewActions={<AssignKeysView updateTable={incrementState} />}
        />
    );
}

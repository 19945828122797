import * as React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Modal from "components/modal/Modal";
import buttonsStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import mainStyle from "./../add-license/add-license.scss";
import AddConfigurationFormContainer from "./AddConfigurationFormContainer";
import { FormValues } from "./AddConfigurationForm";
import { licenseService } from "services/licenses/LicenseService";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { usageStatisticsService, Action, Category } from "services/statistics/UsageStatisticsService";

const AddConfigurationView = (props: { onConfigAdded: () => void }): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [addConfigFormVisible, setAddConfigFormVisible] = React.useState(false);
    const [message, setMessage] = React.useState<string>("");
    const [loading, setLoading] = React.useState(false);

    const addConfigSubmitEventHandler = async ({ configuration }: FormValues): Promise<void> => {
        setLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;

        await licenseService
            .addConfiguration(configuration, abortController)
            .then(() => {
                setMessage(t("AddConfigurationView.successMessage"));
            })
            .catch((e) => {
                if (!signal.aborted) {
                    const error = JSON.parse(e.message);
                    const errorMessage = error.message;
                    if (errorMessage === "Configuration already exists") {
                        setMessage(t("AddConfigurationView.configPresent"));
                    } else {
                        setMessage(t("AddConfigurationView.failureMessage"));
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const showAddConfig = () => {
        setMessage("");
        setAddConfigFormVisible(true);
        usageStatisticsService.sendEvent({
            category: Category.LICENSE,
            action: Action.ADD_LICENSE_CONFIGURATION,
        });
    };

    const hideResult = () => {
        setAddConfigFormVisible(false);
        props.onConfigAdded();
    };
    return (
        <div>
            <button className={classNames(buttonsStyle.primaryButton, buttonsStyle.small)} onClick={showAddConfig}>
                {t("configuration.ConfigureButtonText")}
            </button>

            <Modal
                isOpen={addConfigFormVisible}
                hideModal={hideResult}
                modalTitle={t("configuration.ConfigureButtonText")}
            >
                {loading ? (
                    <LoadingIndicator />
                ) : message !== "" ? (
                    <>
                        <p>{message}</p>
                        <div className={mainStyle.okButtonContainer}>
                            <button
                                className={classNames(
                                    buttonsStyle.primaryButton,
                                    buttonsStyle.medium,
                                    mainStyle.okButton
                                )}
                                onClick={hideResult}
                            >
                                {t("Common.ok")}
                            </button>
                        </div>
                    </>
                ) : (
                    <div className={formStyle.fixedWidthModal}>
                        <AddConfigurationFormContainer onSuccess={addConfigSubmitEventHandler} />
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default AddConfigurationView;

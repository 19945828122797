import React from "react";
import { MenuItem, Menu } from "react-aria-menubutton";
import style from "./language-menu.scss";
import { connect, ConnectedProps } from "react-redux";
import * as LanguageRepository from "services/language/languageRepository";
import { StoreState } from "store";
import { setLanguage } from "store/language";
import { LanguageDetails } from "domain/language";
import i18n from "i18n";
import testIds from "testIds.json";
import classNames from "classnames";
import { usageStatisticsService, Category, Action } from "services/statistics/UsageStatisticsService";

const mapState = (state: StoreState) => ({
    lang: state.languageReducer.lang,
});
const connector = connect(mapState, { setLanguage });
const LANGUAGES = LanguageRepository.getAllLanguages();

const LanguageMenu = (props: ConnectedProps<typeof connector>): JSX.Element => {
    const onLanguageClicked = (locale: string) => {
        LanguageRepository.setLanguage(locale);
        const langValue: LanguageDetails = LanguageRepository.getLanguage();
        props.setLanguage(langValue);
        i18n.changeLanguage(langValue.code);
        usageStatisticsService.sendEvent({
            category: Category.HEADER,
            action: Action.CHANGE_LANGUAGE,
            label: langValue.code.toLowerCase(),
        });
    };

    const selectedLanguageOption = props.lang.locale || LanguageRepository.getLanguage().locale;
    LANGUAGES.sort((a, b) => (a.locale > b.locale ? 1 : -1));
    const menuItems = LANGUAGES.map((lang, index) => (
        <li key={index} onClick={() => onLanguageClicked(lang.locale)}>
            <MenuItem className={style.menuSpace}>
                <label className={classNames(style.label, { [style.active]: selectedLanguageOption === lang.locale })}>
                    {lang.locale}
                </label>
                <div className={style.language}>
                    <span className={style.langTitle}>{lang.title}</span>
                    <span className={style.selectedDot}>
                        <input
                            type="radio"
                            value={lang.locale}
                            name="lang"
                            data-languageCode={lang.code}
                            checked={selectedLanguageOption === lang.locale}
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onChange={() => {
                                /* do nothing to resolve MenuPanel testcases warning */
                            }}
                        />
                        <label className={style.radioLabel} />
                    </span>
                </div>
            </MenuItem>
        </li>
    ));

    return (
        <form data-testid={testIds.header.languageMenu.itself}>
            <Menu className={style.localeMenu}>
                <ul>{menuItems}</ul>
            </Menu>
        </form>
    );
};

export default connector(LanguageMenu);

import * as React from "react";
import { useTranslation } from "react-i18next";
import style from "components/licenses/license-keys-table.scss";
import TextWithTooltip from "components/table/TextWithTooltip";
import testIds from "testIds.json";
import PlainCopy from "components/icons/PlainCopy";

export function ShortUrlTableCell(props: { shortUrl: string }): JSX.Element {
    const { t } = useTranslation();
    const [copied, setCopied] = React.useState(false);
    const [showTooltip, setShowTooltip] = React.useState(false);
    const [position, setPosition] = React.useState("0px");
    const copyToClipboard = (url: string) => {
        navigator.clipboard.writeText(url).then(() => setCopied(true));
    };
    // This value is not necessarily randomly picked. This one is most suitable
    // for setting a good position for the tooltip.
    const OFFSET = 55;

    return (
        <>
            <span hidden={!showTooltip} className={style.tooltip} style={{ top: position }}>
                {copied ? t("Common.copied") : t("Common.copyToClipboard")}
            </span>
            <div className={style.shortUrlCell} onMouseLeave={() => setCopied(false)}>
                <div
                    className={style.shortUrl}
                    onClick={() => {
                        window.open(props.shortUrl, "_blank")?.focus();
                    }}
                >
                    <TextWithTooltip text={props.shortUrl}>
                        <a>{props.shortUrl}</a>
                    </TextWithTooltip>
                </div>
                <div
                    className={style.copyIcon}
                    onClick={() => {
                        copyToClipboard(props.shortUrl);
                    }}
                    data-testid={testIds.workArea.license.bms.table.copyUrlButton}
                    onMouseOver={(event) => {
                        setShowTooltip(true);
                        setPosition((event.nativeEvent.clientY - OFFSET).toString() + "px");
                    }}
                    onMouseLeave={() => setShowTooltip(false)}
                    hidden={props.shortUrl == null}
                >
                    <PlainCopy />
                </div>
            </div>
        </>
    );
}

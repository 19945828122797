import React from "react";
import { useTranslation } from "react-i18next";
import testIds from "testIds.json";
import style from "./page.scss";

const Footer = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className={style.footer} data-testid={testIds.workArea.downloadPage.copyright}>
            &copy; {t("DownloadPage.copyright", { year: new Date().getFullYear().toString() })}
        </div>
    );
};

export default Footer;

import * as React from "react";
import style from "components/header/header.scss";
import logo from "assets/images/logo/blanccoLogoWhite.svg";
import MenuPanel from "./MenuPanel";
import { Link } from "react-router-dom";

export const Header = (): JSX.Element => (
    <div className={style.header}>
        <Link to="/">
            <div className={style.logo}>
                <img src={logo} alt="Management Portal" />
                <div className={style.version}>{"Management Portal"}</div>
            </div>
        </Link>

        <div className={style.rightMenu}>
            <MenuPanel />
        </div>
    </div>
);

import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import * as React from "react";
import { userSessionService } from "services/user/UserSessionService";
import { useTranslation } from "react-i18next";
import style from "./logout.scss";

const LogoutView = (): JSX.Element => {
    const { t } = useTranslation();
    const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);

    React.useEffect(() => {
        userSessionService
            .logout()
            .then(() => {
                window.location.replace("/login");
            })
            .catch((e) => {
                setShowErrorMessage(true);
                console.error("Failed to logout", e);
            });
    }, []);

    return showErrorMessage ? (
        <div className={style.errorMessage}>{t("Logout.errorMessage")}</div>
    ) : (
        <LoadingIndicator />
    );
};

export default LogoutView;

import * as React from "react";
import classNames from "classnames";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";

import DateCell from "components/table/DateCell";
import ErasureReportModal from "./ErasureReportModal";
import StatusCell, { Status } from "components/table/StatusCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import UuidLinkCell from "components/table/UuidLinkCell";
import { ErasureData } from "domain/reports";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { RepositoryKey } from "services/utils/repository";
import { reportService } from "services/report/erasure/ReportService";

import buttonStyle from "styles/buttons.scss";
import style from "./erasure-reports-table.scss";

import checkIcon from "assets/images/icons/checkMarkInCircle.svg";
import crossIcon from "assets/images/icons/cross.svg";

import testIds from "testIds.json";
import ReportsSearchView from "./ReportsSearchView";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import bytes from "bytes";

export interface TableState {
    erasureData: ErasureData[];
    cursor: string[];
    scrollPosition: number;
    owned: boolean;
    total: number;
}

const ReportVerificationCell = (props: { values: string[]; tooltip?: boolean }): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div>
            {props.values.map((value, index) => {
                const successful: boolean = value.trim().toLowerCase() === "true";
                const text = successful
                    ? t("ErasureReportsTable.passedVerification")
                    : t("ErasureReportsTable.failedVerification");

                return props.tooltip ? (
                    <TextWithTooltip text={text} key={index}>
                        <img key={index} src={successful ? checkIcon : crossIcon} alt={text} />
                    </TextWithTooltip>
                ) : (
                    <img key={index} src={successful ? checkIcon : crossIcon} alt={text} title={text} />
                );
            })}
        </div>
    );
};

const TEXT_TO_STATUS = new Map([
    ["successful", Status.Ok],
    ["failed", Status.Error],
]);

function toStatusCellStatus(value: string): Status {
    const status = TEXT_TO_STATUS.get(value.trim().toLowerCase());
    return status != null ? status : Status.Warning;
}

const convertValues = (values: string) => {
    if (values.length === 0) {
        return "0 B";
    }
    return values
        .split(",")
        .map((val) => {
            return bytes(Number(val));
        })
        .join(",");
};

const getSearchHint = (text: string, searchQuery?: string) => {
    if (searchQuery == null || searchQuery === "") {
        return <div className={style.searchResultHint}>{text}</div>;
    }

    return (
        <div className={style.searchResultHint}>
            {text.split(searchQuery).map((part, i) => {
                if (i !== 0) {
                    return part;
                }

                return (
                    <>
                        {part}
                        <b>{searchQuery}</b>
                    </>
                );
            })}
        </div>
    );
};

const ErasureReportsTable = (props: TableState): JSX.Element => {
    const [tableState, setTableState] = React.useState<TableState>({
        erasureData: props.erasureData,
        cursor: props.cursor,
        scrollPosition: props.scrollPosition,
        owned: props.owned,
        total: props.total,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [reportUuid, setReportUuid] = React.useState<string>("");
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [searchQuery, setSearchQuery] = React.useState("");
    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportService
            .fetchReports(props.owned, "ERASURE", abortController, initialLoading ? [] : tableState.cursor, searchQuery)
            .then((data) => {
                setTableState((prevState) => ({
                    cursor: data.cursor,
                    scrollPosition: prevState.erasureData.length,
                    erasureData: prevState.erasureData.concat(data.erasureData),
                    owned: prevState.owned,
                    total: data.total,
                }));
                setLoading(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("ErasureReportsTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        if (props.erasureData.length > 0) {
            return;
        }

        setTableState({ erasureData: [], cursor: [], scrollPosition: 0, owned: props.owned, total: 0 });

        fetchData(true);

        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [searchQuery]);

    const { t } = useTranslation();
    const columns: Array<Column<ErasureData>> = [
        {
            Header: () => <TextWithTooltip text={t("ErasureReportsTable.uuid")} key="1" />,
            accessor: "uuid",
            Cell: ({ cell: { value } }) => (
                <UuidLinkCell
                    value={value}
                    handler={() => {
                        setReportUuid(value);
                        usageStatisticsService.sendEvent({
                            category: Category.REPORTS,
                            action: Action.VIEW_REPORT_PROPERTIES,
                            label: Label.ERASURE,
                        });
                    }}
                    tooltip={true}
                />
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("ErasureReportsTable.product")} key="2" />,
            accessor: "product",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("ErasureReportsTable.erasureStatus")} key="3" />,
            accessor: "erasureStatus",
            Cell: ({ cell: { value } }) => (
                <StatusCell values={value.map((v) => ({ status: toStatusCellStatus(v), title: v }))} tooltip={true} />
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("ErasureReportsTable.erasureDate")} key="4" />,
            accessor: "erasureDate",
            Cell: ({ cell: { value } }) => <DateCell value={value} tooltip={true} />,
        },
        {
            Header: () => <TextWithTooltip text={t("ErasureReportsTable.diskCapacity")} key="5" />,
            accessor: "diskCapacity",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={convertValues(value)} />,
        },
        {
            Header: () => <TextWithTooltip text={t("ErasureReportsTable.availableRam")} key="6" />,
            accessor: "availableRam",
            Cell: ({ cell: { value } }) => (
                <TextWithTooltip text={/[a-zA-Z]/g.test(value) ? value : convertValues(value)} />
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("ErasureReportsTable.diskSerial")} key="7" />,
            accessor: "diskSerial",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("ErasureReportsTable.reportVerification")} key="8" />,
            accessor: "reportVerification",
            Cell: ({ cell: { value } }) => <ReportVerificationCell values={value} tooltip={true} />,
        },
    ];

    let searchHint = null;
    let searchEmptyMessage = "";
    if (requestFailureMessage === "" && !loading) {
        const trimmed = searchQuery.trim();
        const quoted = `"${trimmed}"`;
        if (tableState.erasureData.length > 0) {
            searchHint =
                trimmed === ""
                    ? getSearchHint(t("Common.defaultSearchResultHint", { dataCount: tableState.total }))
                    : getSearchHint(
                          t("ErasureReportsTable.searchResultHint", {
                              searchCount: tableState.erasureData.length,
                              searchQuery: quoted,
                          }),
                          quoted
                      );
        }

        searchEmptyMessage =
            trimmed !== ""
                ? t("ErasureReportsTable.searchEmptyStateMessage", { searchQuery: quoted })
                : t("ErasureReportsTable.emptyStateMessage");
    }
    return (
        <>
            <div
                className={style.search}
                onClick={() =>
                    usageStatisticsService.sendEvent({
                        category: Category.REPORTS,
                        action: Action.EXECUTE_QUICK_SEARCH,
                        label: Label.ERASURE,
                    })
                }
            >
                <ReportsSearchView
                    setReportsSearch={setSearchQuery}
                    searchInProgress={loading}
                    tableName={Label.ERASURE}
                />
            </div>
            <div className={style.searchResultHintContainer}>{searchHint}</div>

            <Table
                tableIdentity={RepositoryKey.ERASURE_TABLE}
                data={tableState.erasureData}
                columns={columns}
                loaded={!initialLoading}
                failureMessage={requestFailureMessage}
                scrollTo={tableState.scrollPosition}
                tooltips={true}
                emptyMessage={searchEmptyMessage}
                testId={testIds.workArea.report.erasure.table}
                autoResizeAdjustmentClass={style.loadMoreButton}
                loading={loading}
            />
            {tableState.cursor.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(buttonStyle.primaryButton, style.loadMoreButton)}
                        onClick={() => {
                            fetchData(false);
                            usageStatisticsService.sendEvent({
                                label: Label.ERASURE,
                                action: Action.LOAD_MORE,
                                category: Category.REPORTS,
                            });
                        }}
                        disabled={loading}
                        data-testId={testIds.workArea.report.erasure.loadMoreButton}
                    >
                        {t("ErasureReportsTable.loadMore")}
                    </button>
                ))}

            <ErasureReportModal reportUuid={reportUuid} closeHandler={() => setReportUuid("")} />
        </>
    );
};

ErasureReportsTable.defaultProps = {
    erasureData: [],
    cursor: [],
    scrollPosition: 0,
    total: 0,
};

export default ErasureReportsTable;

import * as React from "react";
import { Translation } from "react-i18next";
import style from "./chart.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";
import { commonFunctions } from "./CommonFunctions";
import { DashboardDataList } from "domain/dashboard";

interface ChartProps {
    displayLegend?: boolean;
    widgetData: DashboardDataList[];
    dataFetched: boolean;
}

interface ChartState {
    data?: number[];
    businessTravelEmissions: number;
    freightGoodsEmissions: number;
    hotelStaysEmissions: number;
    deliveryTrucksEmissions: number;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

type Props = ChartProps & ConnectedProps<typeof connector>;

class Co2EquivalentsChart extends React.Component<Props, ChartState> {
    private readonly numberFormatOptions = { maximumFractionDigits: 0 };
    private readonly distance = 1000;

    constructor(props: Props) {
        super(props);

        this.state = {
            data: [],
            businessTravelEmissions: 0.302396,
            freightGoodsEmissions: 0.01592,
            hotelStaysEmissions: 44.3830188679245,
            deliveryTrucksEmissions: 0.85049,
        };
    }

    computePreventedEmissions(factor: number): string {
        return (
            (commonFunctions.computeTotalOfCo2EmissionsPrevented(this.props.widgetData) * 1000) /
            factor
        ).toLocaleString(undefined, this.numberFormatOptions);
    }

    computePreventedFreightedGoodsEmissions(factor: number): string {
        const tonsOfGoodsPerKilometer =
            (commonFunctions.computeTotalOfCo2EmissionsPrevented(this.props.widgetData) * 1000) / factor;
        // Return the amount of emissions caused by transporting freighted goods 1000 km
        return (tonsOfGoodsPerKilometer / this.distance).toLocaleString(undefined, this.numberFormatOptions);
    }

    public render(): JSX.Element {
        if (!this.props.dataFetched) {
            return (
                <div className={style.loaderContainer}>
                    <LoadingIndicator />
                </div>
            );
        }
        let totalTonnesOfCo2 = commonFunctions.computeTotalOfCo2EmissionsPrevented(this.props.widgetData);
        let tonnage = "tonnes";
        if (totalTonnesOfCo2 > 1_000_000) {
            totalTonnesOfCo2 /= 1_000_000;
            tonnage = "megatonnes";
        }
        return (
            <Translation>
                {(t) => (
                    <div className={style.chartContainer}>
                        <div className={style.co2EquivalentsSummaryContainer}>
                            <p className={style.summaryText}>
                                <span className={style.summaryCount}>
                                    {t("EsgDashboardView.co2EmissionsPreventedChart.totalOfCo2EmissionsPrevented", {
                                        totalEmissionsPrevented: totalTonnesOfCo2.toFixed(),
                                        tonnage,
                                    })}
                                </span>
                                <br />
                                {t("EsgDashboardView.co2EquivalentsChart.co2EquivalentsSubtitle", {
                                    firstMonth: commonFunctions.computeFirstMonth(this.props.widgetData),
                                    lastMonth: commonFunctions.computeLastMonth(this.props.widgetData),
                                })}
                            </p>
                        </div>
                        <div className={style.co2EquivalentsContainer}>
                            <div className={style.co2EquivalentsRow}>
                                <div className={style.item}>
                                    <div className={style.truckIcon} />
                                    {t("EsgDashboardView.co2EquivalentsChart.truckEquivalentText", {
                                        amount: this.computePreventedEmissions(this.state.deliveryTrucksEmissions),
                                    })}
                                </div>
                                <div className={style.item}>
                                    <div className={style.freightIcon} />
                                    {t("EsgDashboardView.co2EquivalentsChart.freightGoodsEquivalentText", {
                                        amount: this.computePreventedFreightedGoodsEmissions(
                                            this.state.freightGoodsEmissions
                                        ),
                                        distance: this.distance,
                                    })}
                                </div>
                            </div>
                            <div className={style.co2EquivalentsRow}>
                                <div className={style.item}>
                                    <div className={style.hotelIcon} />
                                    {t("EsgDashboardView.co2EquivalentsChart.hotelsEquivalentText", {
                                        // Hotel stays per night are multiplied by 365 to get the number of stays
                                        // the number of stays during one year
                                        amount: this.computePreventedEmissions(this.state.hotelStaysEmissions),
                                    })}
                                </div>
                                <div className={style.item}>
                                    <div className={style.flightsIcon} />
                                    {t("EsgDashboardView.co2EquivalentsChart.flightsEquivalentText", {
                                        amount: this.computePreventedEmissions(this.state.businessTravelEmissions),
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connector(Co2EquivalentsChart);

import * as React from "react";
import ReactJson, { InteractionProps } from "react-json-view";

import style from "./json-editor.scss";
import { Workflow, isWorkflow } from "domain/workflows";

interface Props {
    workflow?: Workflow;
    setWorkflow: (workflow: Workflow) => void;
}

const JsonEditorView: React.FunctionComponent<Props> = (props) => {
    const handleJsonChange = (event: InteractionProps) => {
        if (isWorkflow(event.updated_src)) {
            props.setWorkflow(event.updated_src);
            return true;
        }
        return false;
    };

    return (
        <div className={style.editorContainer}>
            <ReactJson
                name={null}
                src={props.workflow ?? {}}
                onAdd={handleJsonChange}
                onEdit={handleJsonChange}
                onDelete={handleJsonChange}
            />
        </div>
    );
};

export default JsonEditorView;

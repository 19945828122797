import * as React from "react";
import TextWithTooltip from "components/table/TextWithTooltip";

// TODO BCC-206: Adjust to become consistent with the rest of BCC
const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const formatTimestamp = (singleValue: string): string => {
    const date = new Date(singleValue);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}, ${hours}:${minutes}`;
};

const DateCell = (props: { value: string[] | string; tooltip?: boolean; withoutTime?: boolean }): JSX.Element => {
    const formatDateWithoutTime = (singleValue: string) => {
        const date = new Date(singleValue.replace("Z", ""));
        return `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
    };

    const getContent = (value: string) => {
        return props.tooltip ? <TextWithTooltip text={value} /> : value;
    };

    const format = props.withoutTime ? formatDateWithoutTime : formatTimestamp;

    if (Array.isArray(props.value)) {
        return <>{getContent(props.value.map((singleValue) => format(singleValue)).join("; "))} </>;
    }

    return <>{getContent(format(props.value))}</>;
};

DateCell.defaultProps = {
    tooltip: false,
};

export default DateCell;

import * as React from "react";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { SETTINGS_ROUTE } from "components/router/Routes";
import { useTranslation } from "react-i18next";
import { AllApiKeysTable } from "./AllApiKeysTable";

export default function AllApiKeysView(): JSX.Element {
    const { t } = useTranslation();

    return (
        <ApplicationLayout
            viewTitle={t("Common.allApiKeys")}
            view={<AllApiKeysTable initialApiKeyData={[]} />}
            mainRoute={SETTINGS_ROUTE}
        />
    );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2VW8Y3PjHbg40U2EqBse7C{text-align:center;margin-top:50px}.defaultTheme ._2VW8Y3PjHbg40U2EqBse7C{color:#db2828}.tenantTheme ._2VW8Y3PjHbg40U2EqBse7C{color:#db2828}.defaultDarkTheme ._2VW8Y3PjHbg40U2EqBse7C{color:#f46464}.tenantDarkTheme ._2VW8Y3PjHbg40U2EqBse7C{color:#f46464}", ""]);
// Exports
exports.locals = {
	"activeBackgroundColor": "#db2828",
	"activeForegroundColor": "#db2828",
	"primaryButtonBackgroundColor": "#db2828",
	"secondaryButtonForegroundColor": "#f46464",
	"bannerBackgroundColor": "rgba(219,40,40,.5)",
	"linkTextColor": "#f46464",
	"primaryButtonBackgroundHoverColor": "#f46464",
	"secondaryButtonBackgroundHoverColor": "#f0f0f0",
	"destructiveButtonBackgroundHoverColor": "#f46464",
	"primaryButtonBackgroundActiveColor": "#f46464",
	"secondaryButtonBackgroundActiveColor": "#f7f7f7",
	"destructiveButtonBackgroundColor": "#f09191",
	"chartNegativeColor": "#f09191",
	"chartPositiveColor": "#5fe3a1",
	"chartNeutralColor": "#cecece",
	"errorBackgroundColor": "#f46464",
	"errorBorderColor": "#f46464",
	"errorTextColor": "#f46464",
	"warningBackgroundColor": "#f2711c",
	"warningBorderColor": "#f2711c",
	"warningTextColor": "#f2711c",
	"secondaryWarningBackgroundColor": "#ffc915",
	"secondaryWarningBorderColor": "#ffc915",
	"secondaryWarningTextColor": "#ffc915",
	"successBorderColor": "#5fe3a1",
	"successTextColor": "#5fe3a1",
	"pageBackgroundColor": "#222",
	"wrapperBackgroundColor": "#f2c5c5",
	"wrapperBorderColor": "#f09191",
	"modelBoxBackgroundColor": "#4c4c4c",
	"contentBackgroundColor": "#333",
	"secondaryButtonBackgroundColor": "#333",
	"textColor": "#fff",
	"primaryButtonForegroundColor": "#fff",
	"bannerForegroundColor": "#4c4c4c",
	"chartOutlineColor": "#222",
	"modalOverlayColor": "rgba(34,34,34,.4)",
	"contentDividerColor": "#222",
	"contentBorderColor": "#111",
	"disabledButtonBackgroundColor": "#222",
	"disabledButtonForegroundColor": "#4c4c4c",
	"inputHintColor": "#cecece",
	"inputBackgroundColor": "#4c4c4c",
	"hoverBackgroundColor": "#111",
	"toggleCheckedBackgroundColor": "#91b9f0",
	"toggleUncheckedBackgroundColor": "#cecece",
	"toggleForegroundColor": "#fff",
	"mapForegroundColor": "#f0f0f0",
	"mapBackgroundColor": "#222",
	"emptyStateEllipseBackgroundColor": "#111",
	"emptyStateBiggestCircleBackgroundColor": "#3c3960",
	"emptyStateSmallestCircleBackgroundColor": "#111",
	"emptyStatePersonColor": "#4a4a52",
	"defaultTheme": "defaultTheme",
	"tenantTheme": "tenantTheme",
	"defaultDarkTheme": "defaultDarkTheme",
	"tenantDarkTheme": "tenantDarkTheme",
	"errorMessage": "_2VW8Y3PjHbg40U2EqBse7C"
};
module.exports = exports;

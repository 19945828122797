import * as React from "react";
import { Formik, FormikConfig, FormikProps, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import form from "styles/form.scss";
import buttons from "styles/buttons.scss";
import style from "./edit-config.scss";

import { object, string } from "yup";
import testIds from "testIds.json";

import { usageStatisticsService, Action, Category } from "services/statistics/UsageStatisticsService";

interface Props {
    configuration: string;
    uuid: string;
    created: string;
    modified: string;
    edit: boolean;
    onEdit?: () => void;
    onSuccess: (values: FormValues) => Promise<void>;
    onClose: () => void;
}

export interface FormValues {
    configuration: string;
}

const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export default function EditConfigForm(props: Props): JSX.Element {
    const { t } = useTranslation();
    const submitHandler: FormikConfig<FormValues>["onSubmit"] = (values) => props.onSuccess(values);

    const jsonCheck = (val: string) => {
        let error;
        try {
            JSON.parse(val);
        } catch {
            error = t("configuration.AddConfigureDialog.errorMessage");
        }
        return error;
    };

    const formatTimestamp = (singleValue: string) => {
        const date = new Date(singleValue);
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        return `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()} ${hours}:${minutes}`;
    };

    const modifiedDate = props.modified ? (
        <span>
            {t("configuration.viewConfigureDialog.modified", {
                modified: formatTimestamp(props.modified),
            })}
        </span>
    ) : null;

    return (
        <>
            <div
                className={style.createModified}
                data-testid={testIds.workArea.license.configure.manageConfigurationDialog.createdModified}
            >
                <span>
                    {t("configuration.viewConfigureDialog.created", {
                        created: formatTimestamp(props.created),
                    })}{" "}
                </span>
                {modifiedDate}
            </div>
            <Formik
                initialValues={{ configuration: props.configuration }}
                onSubmit={submitHandler}
                validationSchema={object().shape({
                    configuration: string().required(t("configuration.AddConfigureDialog.configRequired")),
                })}
                validateOnChange={true}
                validateOnBlur={true}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    errors,
                    isSubmitting,
                    resetForm,
                    isValid,
                }: FormikProps<FormValues>) => {
                    return (
                        <Form>
                            <div className={form.formFields}>
                                <Field
                                    as="textarea"
                                    id="configuration"
                                    className={classNames(style.textarea, form.input, form.fixedWidthInput, {
                                        [form.inputError]: errors.configuration,
                                    })}
                                    name="configuration"
                                    onChange={handleChange}
                                    validate={jsonCheck}
                                    onBlur={handleBlur}
                                    value={values.configuration}
                                    disabled={!props.edit}
                                    data-testid={
                                        testIds.workArea.license.configure.manageConfigurationDialog.configInput.itself
                                    }
                                ></Field>
                                <div className={form.error}>{errors.configuration}</div>
                            </div>
                            <div className={form.buttonContainer}>
                                {props.edit === false ? (
                                    <>
                                        <button
                                            type="button"
                                            data-testid={
                                                testIds.workArea.license.configure.manageConfigurationDialog.editButton
                                            }
                                            className={classNames(
                                                buttons.primaryButton,
                                                { disabled: buttons.disabledButton },
                                                buttons.medium,
                                                form.submitButton,
                                                style.editButton
                                            )}
                                            onClick={props.onEdit}
                                        >
                                            {t("configuration.viewConfigureDialog.editButton")}
                                        </button>
                                        <button
                                            type="reset"
                                            className={classNames(
                                                buttons.primaryButton,
                                                buttons.medium,
                                                form.submitButton
                                            )}
                                            disabled={isSubmitting}
                                            onClick={props.onClose}
                                            data-testid={
                                                testIds.workArea.license.configure.manageConfigurationDialog.closeButton
                                            }
                                        >
                                            {t("configuration.viewConfigureDialog.closeButton")}
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            type="reset"
                                            className={classNames(
                                                buttons.primaryButton,
                                                buttons.medium,
                                                form.submitButton,
                                                style.resetButton
                                            )}
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                resetForm();
                                                usageStatisticsService.sendEvent({
                                                    category: Category.LICENSE,
                                                    action: Action.RESET_LICENSE_CONFIGURATION,
                                                });
                                            }}
                                            data-testid={
                                                testIds.workArea.license.configure.manageConfigurationDialog.resetButton
                                            }
                                        >
                                            {t("configuration.editConfigureDialog.resetButton")}
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={!isValid}
                                            className={classNames(
                                                buttons.primaryButton,
                                                { disabled: buttons.disabledButton },
                                                buttons.medium,
                                                form.submitButton
                                            )}
                                            data-testid={
                                                testIds.workArea.license.configure.manageConfigurationDialog.saveButton
                                            }
                                        >
                                            {t("configuration.editConfigureDialog.saveButton")}
                                        </button>
                                    </>
                                )}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

import { apiGatewayService } from "services/api/ApiGatewayService";
import { ESG_DATA_VERSION } from "globalConstants";
import { EsgData } from "domain/dashboard";

class DashboardService {
    public fetchDashboardData(abortController: AbortController): Promise<EsgData> {
        return apiGatewayService.invokeApi("/esg/global/" + ESG_DATA_VERSION, "GET", null, { abortController });
    }
}

export const dashboardService = new DashboardService();

import React from "react";
import { ErrorMessage, Form, Formik, FormikProps, FormikConfig } from "formik";
import { object, string, number, date } from "yup";
import { useTranslation } from "react-i18next";

import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { LICENSE_USAGE_MAX_VALUE } from "globalConstants";
import classNames from "classnames";
import style from "./add-license.scss";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

interface Props {
    submitEventHandler: (values: FormValues) => Promise<void>;
}

export interface FormValues {
    licenseType: string;
    licenseUsage: string;
    expirationDate: string;
}

export default function AddLicenseForm(props: Props): JSX.Element {
    const { t } = useTranslation();
    const submitHandler: FormikConfig<FormValues>["onSubmit"] = (values) => props.submitEventHandler(values);

    // Here productID is LDK Product ID
    const LICENSE_TYPES = [
        { productName: t("Products.1"), productId: "1" },
        { productName: t("Products.2"), productId: "2" },
        { productName: t("Products.3"), productId: "3" },
        { productName: t("Products.6"), productId: "6" },
        { productName: t("Products.11"), productId: "11" },
        { productName: t("Products.12"), productId: "12" },
        { productName: t("Products.16"), productId: "16" },
        { productName: t("Products.17"), productId: "17" },
        { productName: t("Products.18"), productId: "18" },
        { productName: t("Products.20"), productId: "20" },
        { productName: t("Products.24"), productId: "24" },
        { productName: t("Products.26"), productId: "26" },
        { productName: t("Products.27"), productId: "27" },
        { productName: t("Products.28"), productId: "28" },
        { productName: t("Products.30"), productId: "30" },
        { productName: t("Products.31"), productId: "31" },
        { productName: t("Products.32"), productId: "32" },
        { productName: t("Products.33"), productId: "33" },
        { productName: t("Products.34"), productId: "34" },
        { productName: t("Products.35"), productId: "35" },
        { productName: t("Products.36"), productId: "36" },
        { productName: t("Products.38"), productId: "38" },
        { productName: t("Products.39"), productId: "39" },
        { productName: t("Products.40"), productId: "40" },
        { productName: t("Products.42"), productId: "42" },
        { productName: t("Products.43"), productId: "43" },
        { productName: t("Products.44"), productId: "44" },
        { productName: t("Products.46"), productId: "46" },
        { productName: t("Products.47"), productId: "47" },
        { productName: t("Products.48"), productId: "48" },
        { productName: t("Products.49"), productId: "49" },
        { productName: t("Products.50"), productId: "50" },
        { productName: t("Products.bms-validation"), productId: "bms-validation" },
        { productName: t("Products.bms-bbti"), productId: "bms-bbti" },
    ];

    LICENSE_TYPES.sort((a, b) => a.productName.localeCompare(b.productName));

    const minDate = new Date().toISOString().substr(0, 10);

    return (
        <Formik
            initialValues={{ licenseType: "", licenseUsage: "", expirationDate: "" }}
            onSubmit={submitHandler}
            validationSchema={object().shape({
                licenseType: string().required(t("AddLicenseForm.licenseTypeRequired")),
                licenseUsage: number()
                    .required(t("AddLicenseForm.licenseUsageRequired"))
                    .max(
                        LICENSE_USAGE_MAX_VALUE,
                        t("AddLicenseForm.licenseUsageInvalidMaxValue", {
                            licenseUsageMaxValue: LICENSE_USAGE_MAX_VALUE,
                        })
                    )
                    .min(1, t("AddLicenseForm.licenseUsageInvalidMinValue")),
                expirationDate: date()
                    .required(t("AddLicenseForm.expirationDateRequired"))
                    .min(
                        minDate,
                        t("AddLicenseForm.expirationDateInvalidMinDate", { minDate: new Date().toLocaleDateString() })
                    ),
            })}
            validateOnChange={true}
            validateOnBlur={true}
        >
            {({ values, errors, isSubmitting, handleChange, handleBlur }: FormikProps<FormValues>) => {
                if (isSubmitting) {
                    return <LoadingIndicator />;
                }
                return (
                    <Form>
                        <div className={style.modalSubHeading}> {t("AddLicenseForm.addLicenseFormSubtitle")}</div>
                        <div className={form.formFields}>
                            <label
                                htmlFor="licenseType"
                                className={classNames(form.label, {
                                    [form.inputError]: errors.licenseType,
                                })}
                            >
                                {t("AddLicenseForm.licenseType")}
                            </label>
                            <select
                                id="licenseType"
                                className={classNames(form.select, form.fixedWidthInput, {
                                    [form.inputError]: errors.licenseType,
                                })}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.licenseType}
                            >
                                <option value="">{t("AddLicenseForm.selectLicenseType")}</option>
                                {LICENSE_TYPES.map((value) => (
                                    <option key={value.productId} value={value.productId}>
                                        {value.productName}
                                    </option>
                                ))}
                            </select>
                            <div className={form.error}>
                                <ErrorMessage name="licenseType" />
                            </div>
                        </div>

                        <div className={form.formFields}>
                            <label
                                htmlFor="licenseUsage"
                                className={classNames(form.label, {
                                    [form.inputError]: errors.licenseUsage,
                                })}
                            >
                                {t("AddLicenseForm.numberOfLicenses")}
                            </label>

                            <input
                                id="licenseUsage"
                                className={classNames(form.input, form.fixedWidthInput, {
                                    [form.inputError]: errors.licenseUsage,
                                })}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.licenseUsage}
                                min="1"
                                max={LICENSE_USAGE_MAX_VALUE}
                            />
                            <div className={form.error}>
                                <ErrorMessage name="licenseUsage" />
                            </div>
                        </div>
                        <div className={form.formFields}>
                            <label
                                htmlFor="expirationDate"
                                className={classNames(form.label, {
                                    [form.inputError]: errors.expirationDate,
                                })}
                            >
                                {t("AddLicenseForm.expirationDate")}
                            </label>

                            <input
                                id="expirationDate"
                                type="date"
                                className={classNames(form.input, form.fixedWidthInput, {
                                    [form.inputError]: errors.expirationDate,
                                })}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.expirationDate}
                                // Disable past dates
                                min={minDate}
                                // Prevent keyboard inputs, to avoid manual mistakes
                                // onKeyDown={(e) => e.preventDefault()}
                            />
                            <div className={form.error}>
                                <ErrorMessage name="expirationDate" />
                            </div>
                        </div>
                        <div className={form.buttonContainer}>
                            <button
                                type="submit"
                                className={classNames(buttons.primaryButton, buttons.medium, form.submitButton)}
                                disabled={isSubmitting}
                            >
                                {t("AddLicenseForm.licenseButton")}
                            </button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

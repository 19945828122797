import * as React from "react";
import classNames from "classnames";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";
import DateCell from "components/table/DateCell";
import PercentageCell from "components/table/PercentageCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { DiagnosticData, DiagnosticReportResponse } from "domain/reports";
import Modal from "components/modal/Modal";
import style from "./erasure-reports-table.scss";
import testIds from "testIds.json";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import DeviceDetailView from "components/reports/device-detail/DeviceDetailView";
import BuybackTradeinView from "components/reports/buyback-tradein/BuybackTradeinView";
import { DiagnosticSummaryView } from "components/reports/diagnostics-summary/DiagnosticSummaryView";
import buttonStyle from "styles/buttons.scss";
import "react-tabs/style/react-tabs.css";
import ReportsSearchView from "./ReportsSearchView";
import { reportService } from "services/report/erasure/ReportService";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import UuidLinkCell from "components/table/UuidLinkCell";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { RepositoryKey } from "services/utils/repository";
import ExportDiagnosticReport from "components/reports/ExportDiagnosticReport";

export interface TableState {
    cursor: string[];
    scrollPosition: number;
    owned: boolean;
    diagnosticData: DiagnosticData[];
    total: number;
}

const getSearchHint = (text: string, searchQuery?: string) => {
    if (searchQuery == null || searchQuery === "") {
        return <div className={style.searchResultHint}>{text}</div>;
    }

    return (
        <div className={style.searchResultHint}>
            {text.split(searchQuery).map((part, i) => {
                if (i !== 0) {
                    return part;
                }

                return (
                    <>
                        {part}
                        <b>{searchQuery}</b>
                    </>
                );
            })}
        </div>
    );
};

const DiagnosticReportsTable = (props: TableState): JSX.Element => {
    const { t } = useTranslation();
    const [diagnosticReportVisibility, setDiagnosticReportVisibility] = React.useState(false);

    const [tableState, setTableState] = React.useState<TableState>({
        diagnosticData: props.diagnosticData,
        cursor: props.cursor,
        scrollPosition: props.scrollPosition,
        owned: props.owned,
        total: props.total,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(true);

    const [diagnosticReportResponse, setDiagnosticReportResponse] = React.useState<DiagnosticReportResponse>();

    const [searchQuery, setSearchQuery] = React.useState("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [diagnosticReportLoading, setDiagnosticReportLoading] = React.useState<boolean>(false);
    const [bbtiDataType, setBbtiDataType] = React.useState<boolean>(false);
    const showDiagnosticReport = async (report: DiagnosticData) => {
        const abortController = new AbortController();
        try {
            setDiagnosticReportLoading(true);
            setBbtiDataType(report.feature === "Blancco Mobile Solutions - Buy-Back / Trade In");
            abortControllers.push(abortController);
            setDiagnosticReportVisibility(true);
            setActiveDiagnosticReportDetails(report);
            setDiagnosticReportResponse(await reportService.diagnosticReports(report.uuid, abortController));
            setDiagnosticReportLoading(false);
        } catch (error) {
            console.error(error);
            hideDiagnosticReport();
            if (!abortController.signal.aborted) {
                console.error("Abort error");
            }
        }
    };

    const hideDiagnosticReport = () => {
        setDiagnosticReportResponse({
            deviceDetail: {},
            diagnosticSummary: [],
            buybackTradein: {},
        });
        setDiagnosticReportVisibility(false);
    };

    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportService
            .fetchDiagnosticsReports(
                props.owned,
                "DIAGNOSTIC",
                abortController,
                initialLoading ? [] : tableState.cursor,
                searchQuery
            )
            .then((data) => {
                setTableState((prevState) => ({
                    cursor: data.cursor,
                    scrollPosition: prevState.diagnosticData.length,
                    diagnosticData: prevState.diagnosticData.concat(data.reportData),
                    owned: prevState.owned,
                    total: data.total,
                }));

                setLoading(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("ErasureReportsTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        if (props.diagnosticData.length > 0) {
            return;
        }

        setTableState({
            diagnosticData: [],
            cursor: [],
            scrollPosition: 0,
            owned: props.owned,
            total: 0,
        });

        fetchData(true);

        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [searchQuery]);

    const COLUMNS: Array<Column<DiagnosticData>> = [
        {
            Header: () => <TextWithTooltip text={t("DiagnosticReportsTable.id")} key="uuid" />,
            accessor: "uuid",
            Cell: (cellInfo) => (
                <>
                    <div
                        className={style.reportIdCell}
                        onClick={() => {
                            usageStatisticsService.sendEvent({
                                category: Category.REPORTS,
                                action: Action.VIEW_REPORT_PROPERTIES,
                                label: Label.DIAGNOSTIC,
                            });
                            showDiagnosticReport(cellInfo.cell.row.original);
                        }}
                    >
                        <UuidLinkCell
                            value={cellInfo.cell.row.original.uuid}
                            handler={() => undefined}
                            tooltip={true}
                        />
                    </div>
                </>
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("DiagnosticReportsTable.feature")} key="feature" />,
            accessor: "feature",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("DiagnosticReportsTable.manufacturer")} key="make" />,
            accessor: "make",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("DiagnosticReportsTable.model")} key="model" />,
            accessor: "model",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("DiagnosticReportsTable.passRate")} key="testPassRate" />,
            accessor: "testPassRate",
            Cell: ({ cell: { value } }) => <PercentageCell value={parseInt(value)} tooltip={true} />,
        },
        {
            Header: () => <TextWithTooltip text={t("DiagnosticReportsTable.lastDate")} key="reportDate" />,
            accessor: "reportDate",
            Cell: ({ cell: { value } }) => <DateCell value={value} tooltip={true} />,
        },
    ];

    const [activeDiagnosticReportDetails, setActiveDiagnosticReportDetails] = React.useState<DiagnosticData>();

    let buyBackDisplayTab, buyBackDisplayTabPanel;
    if (bbtiDataType) {
        buyBackDisplayTab = (
            <Tab
                onClick={() => {
                    usageStatisticsService.sendEvent({
                        category: Category.REPORTS,
                        action: Action.VIEW_DIAGNOSTIC_BUYBACK_TRADEIN,
                    });
                }}
            >
                {t("DiagnosticReportsTable.reportTabs.buyBackTradeIn")}
            </Tab>
        );
        buyBackDisplayTabPanel = (
            <TabPanel>
                <BuybackTradeinView diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse} />
            </TabPanel>
        );
    }

    const modal =
        activeDiagnosticReportDetails != null ? (
            <Modal
                key={1}
                isOpen={diagnosticReportVisibility}
                hideModal={hideDiagnosticReport}
                modalTitle={t("DiagnosticReportsTable.title", {
                    id: activeDiagnosticReportDetails.uuid,
                    make: activeDiagnosticReportDetails.make,
                    model: activeDiagnosticReportDetails.model,
                })}
            >
                {diagnosticReportLoading ? (
                    <LoadingIndicator />
                ) : (
                    <Tabs>
                        <TabList>
                            <Tab
                                onClick={() =>
                                    usageStatisticsService.sendEvent({
                                        category: Category.REPORTS,
                                        action: Action.VIEW_DEVICE_DETAILS,
                                        label: Label.DIAGNOSTIC,
                                    })
                                }
                            >
                                {t("DiagnosticReportsTable.reportTabs.deviceDetails")}
                            </Tab>
                            <Tab
                                onClick={() => {
                                    usageStatisticsService.sendEvent({
                                        category: Category.REPORTS,
                                        action: Action.VIEW_DIAGNOSTIC_SUMMARY,
                                        label: Label.DIAGNOSTIC,
                                    });
                                }}
                            >
                                {t("DiagnosticReportsTable.reportTabs.diagnosticsSummary")}
                            </Tab>

                            {buyBackDisplayTab}
                            <div className={style.exportDiagnosticReportButton}>
                                <ExportDiagnosticReport reportUuid={activeDiagnosticReportDetails.uuid} />
                            </div>
                        </TabList>

                        <TabPanel>
                            <DeviceDetailView
                                diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse}
                            />
                        </TabPanel>
                        <TabPanel>
                            <DiagnosticSummaryView
                                diagnosticReportResponse={diagnosticReportResponse as DiagnosticReportResponse}
                            />
                        </TabPanel>
                        {buyBackDisplayTabPanel}
                    </Tabs>
                )}
            </Modal>
        ) : null;

    let searchHint = null;
    let searchEmptyMessage = "";
    if (requestFailureMessage === "" && !loading) {
        const trimmed = searchQuery.trim();
        const quoted = `"${trimmed}"`;
        if (tableState.diagnosticData.length > 0) {
            searchHint =
                trimmed === ""
                    ? getSearchHint(t("Common.defaultSearchResultHint", { dataCount: tableState.total }))
                    : getSearchHint(
                          t("ErasureReportsTable.searchResultHint", {
                              searchCount: tableState.diagnosticData.length,
                              searchQuery: quoted,
                          }),
                          quoted
                      );
        }

        searchEmptyMessage =
            trimmed !== ""
                ? t("ErasureReportsTable.searchEmptyStateMessage", { searchQuery: quoted })
                : t("ErasureReportsTable.emptyStateMessage");
    }

    return (
        <>
            <div className={style.search}>
                <ReportsSearchView
                    setReportsSearch={setSearchQuery}
                    searchInProgress={loading}
                    tableName={Label.DIAGNOSTIC}
                />
            </div>
            <div className={style.searchResultHintContainer}>{searchHint}</div>
            <Table
                tableIdentity={RepositoryKey.DIAGNOSTIC_TABLE}
                data={tableState.diagnosticData}
                columns={COLUMNS}
                loaded={!initialLoading}
                failureMessage={requestFailureMessage}
                scrollTo={tableState.scrollPosition}
                tooltips={true}
                emptyMessage={searchEmptyMessage}
                testId={testIds.workArea.report.erasure.table}
                autoResizeAdjustmentClass={style.loadMoreButton}
                loading={loading}
            />
            {tableState.cursor.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(buttonStyle.primaryButton, style.loadMoreButton)}
                        onClick={() => {
                            fetchData(false);
                            usageStatisticsService.sendEvent({
                                label: Label.DIAGNOSTIC,
                                action: Action.LOAD_MORE,
                                category: Category.REPORTS,
                            });
                        }}
                        disabled={loading}
                        data-testid={testIds.workArea.report.erasure.loadMoreButton}
                    >
                        {t("ErasureReportsTable.loadMore")}
                    </button>
                ))}
            {modal}
        </>
    );
};

DiagnosticReportsTable.defaultProps = {
    diagnosticData: [],
    cursor: [],
    scrollPosition: 0,
    total: 0,
};

export default DiagnosticReportsTable;

import * as React from "react";
import style from "./navigation-toggle-panel.scss";
import { Button, Wrapper, openMenu, closeMenu } from "react-aria-menubutton";
import { useState, useEffect, ReactElement } from "react";
import Chevron from "components/icons/Chevron";
import classNames from "classnames";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";

interface NavigationToggleableProps {
    title: string;
    isOpen: boolean;
    id: string;
    openId?: string;
    closeId?: string;
    children: ReactElement;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const NavigationTogglePanel = (props: NavigationToggleableProps & ConnectedProps<typeof connector>): JSX.Element => {
    const [menuExpanded, toggleMenu] = useState<boolean>(props.isOpen);
    const openClass: string = menuExpanded ? style.activeLeftTitle : style.leftTitle;

    const chevronColor: string = menuExpanded ? props.theme.primaryButtonForegroundColor : props.theme.linkTextColor;
    const chevronClass: string = classNames(style.arrow, { [style.down]: menuExpanded });

    useEffect(() => {
        if (props.openId !== undefined) {
            openMenu(props.openId as string);
        }
        if (props.closeId !== undefined) {
            closeMenu(props.closeId as string);
        }
    }, []);

    return (
        <Wrapper
            id={props.id}
            className={openClass}
            onMenuToggle={(state) => {
                toggleMenu(state.isOpen);
            }}
            closeOnSelection={false}
            closeOnBlur={false}
        >
            <Button className={style.button}>
                <span>{props.title}</span>
                <Chevron color={chevronColor} className={chevronClass} />
            </Button>
            {props.children}
        </Wrapper>
    );
};

export default connector(NavigationTogglePanel);

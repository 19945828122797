import * as React from "react";
import { Redirect } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { useFeature } from "flagged";
import { useTranslation } from "react-i18next";

import LoginForm, { FormValues } from "./LoginForm";
import { DASHBOARD_ROUTE, REPORTS_ROUTE } from "components/router/Routes";
import { FLAG_DASHBOARD } from "services/feature/FeatureFlagService";
import { ForgotPasswordModal } from "./ForgotPasswordModal";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { StoreState } from "store";
import { UserDetails } from "domain/user";
import { setUser } from "store/user";
import { userSessionService } from "services/user/UserSessionService";
import { setDataCollection } from "services/settings/settingsRepository";

import loginStyle from "./login.scss";
import Modal from "components/modal/Modal";
import buttonsStyle from "styles/buttons.scss";
import Export from "components/icons/Export";
import testIds from "testIds.json";
import { apiGatewayService } from "services/api/ApiGatewayService";

const MAP_STATE = (state: StoreState) => ({
    authenticated: state.userReducer.user,
    theme: state.themeReducer.theme,
});
const MAP_DISPATCH = { setUser };
const CONNECTOR = connect(MAP_STATE, MAP_DISPATCH);

enum ViewState {
    SHOW_LOGIN_FORM,
    SHOW_LOADING_INDICATOR,
    REQUIRE_EULA_ACCEPTANCE,
    REDIRECT_TO_APPLICATION,
}

const LoginView = (props: ConnectedProps<typeof CONNECTOR>) => {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    // The user might be already authenticated when entering this view, if that is the case then redirects directly
    // to the application. Otherwise goes through the normal login flow.
    const [viewState, setViewState] = React.useState<ViewState>(
        props.authenticated ? ViewState.REDIRECT_TO_APPLICATION : ViewState.SHOW_LOGIN_FORM
    );
    const [acceptEula, setAcceptEula] = React.useState<boolean>(false);
    const [userDetails, setUserDetails] = React.useState<UserDetails | null>(null);
    const loginSuccessfulHandler = (values: FormValues) => {
        setViewState(ViewState.SHOW_LOADING_INDICATOR);
        userSessionService
            .login(values.email, values.password)
            .then((user: UserDetails) => {
                setUserDetails(user);
                setViewState(
                    user.eulaAcceptedDate &&
                        Date.parse(user.eulaAcceptedDate) >= Date.parse(process.env.EULA_RELEASE_DATE as string)
                        ? ViewState.REDIRECT_TO_APPLICATION
                        : ViewState.REQUIRE_EULA_ACCEPTANCE
                );
                setDataCollection(user.usageStatistics);
            })
            .catch((e) => {
                console.error("Authentication failed", values.email, e);
                setErrorMessage(t("LoginForm.incorrectCredentials"));
                setViewState(ViewState.SHOW_LOGIN_FORM);
            });
    };
    const onAcceptEulaClicked = () => {
        setAcceptEula(!acceptEula);
    };
    const onContinueClicked = () => {
        if (acceptEula) {
            setViewState(ViewState.REDIRECT_TO_APPLICATION);
            // Fire-and-forget style invocation. Doesn't keep the user from logging in even if this call fails.
            apiGatewayService.invokeApi("/eula/accept", "post").then(() => {
                userSessionService.fetchUserDetails().then((user: UserDetails) => {
                    storeAndUpdateUserDetails(user);
                });
            });
        }
    };
    const onCloseClicked = () => {
        setUserDetails(null);
        setViewState(ViewState.SHOW_LOADING_INDICATOR);
        userSessionService.logout();
    };
    const storeAndUpdateUserDetails = (user: UserDetails) => {
        userSessionService.storeUser(user);
        props.setUser(user);
    };
    React.useEffect(() => {
        if (viewState === ViewState.REDIRECT_TO_APPLICATION && userDetails) {
            storeAndUpdateUserDetails(userDetails);
        }
    }, [viewState]);

    const [forgotVisible, setForgotVisible] = React.useState<boolean>(false);
    const authenticatedRoute = useFeature(FLAG_DASHBOARD) ? DASHBOARD_ROUTE : REPORTS_ROUTE;

    switch (viewState) {
        case ViewState.SHOW_LOGIN_FORM:
            return (
                <div className={loginStyle.viewContainer}>
                    <div className={loginStyle.formContainer}>
                        <div className={loginStyle.formPanel}>
                            <div className={loginStyle.logo} />
                            <div className={loginStyle.version}>{"Management Portal"}</div>
                            <h1 className={loginStyle.header}>{t("Common.loginTitle")}</h1>
                            <LoginForm
                                successfulLoginHandler={loginSuccessfulHandler}
                                loginErrorMessage={errorMessage}
                            />
                            <div className={loginStyle.forgotPasswordLinkContainer}>
                                <span onClick={() => setForgotVisible(true)} className={loginStyle.forgotPasswordLink}>
                                    {t("Login.forgotPasswordLink")}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={loginStyle.imageContainer} />
                    <ForgotPasswordModal hide={() => setForgotVisible(false)} visible={forgotVisible} />
                </div>
            );
        case ViewState.SHOW_LOADING_INDICATOR:
            return <LoadingIndicator />;
        case ViewState.REQUIRE_EULA_ACCEPTANCE:
            return (
                <Modal isOpen={true} hideModal={onCloseClicked} modalTitle={t("Login.acceptEulaModal.title")}>
                    <div className={loginStyle.eulaActionsContainer}>
                        <Export color={props.theme.linkTextColor} />
                        <a
                            href="/public/eula/eula.pdf"
                            target="_blank"
                            data-testid={testIds.login.eula.downloadPdfLink}
                        >
                            {t("Login.acceptEulaModal.downloadPdf")}
                        </a>
                    </div>
                    <iframe
                        src="/public/eula/eula.html"
                        className={loginStyle.eulaContainer}
                        data-testid={testIds.login.eula.contentIframe}
                    />
                    <div className={loginStyle.acceptEulaCheckbox}>
                        <label>
                            <input
                                type="checkbox"
                                checked={acceptEula}
                                onClick={onAcceptEulaClicked}
                                data-testid={testIds.login.eula.acceptEulaCheckbox}
                            />{" "}
                            {t("Login.acceptEulaModal.agreeLabel")}
                        </label>
                    </div>
                    <div>
                        <button
                            className={acceptEula ? buttonsStyle.primaryButton : buttonsStyle.disabledButton}
                            disabled={!acceptEula}
                            onClick={onContinueClicked}
                            data-testid={testIds.login.eula.continueButton}
                        >
                            {t("Login.acceptEulaModal.continueButton")}
                        </button>
                    </div>
                </Modal>
            );
        case ViewState.REDIRECT_TO_APPLICATION:
            return <Redirect to={authenticatedRoute.path} />;
    }
};

export default CONNECTOR(LoginView);

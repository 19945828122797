// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ax3Tcu_617xvBpCqLP6xJ{cursor:pointer}", ""]);
// Exports
exports.locals = {
	"link": "_1ax3Tcu_617xvBpCqLP6xJ"
};
module.exports = exports;

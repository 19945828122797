import * as React from "react";
import style from "./chart.scss";
import { Bar } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { Translation } from "react-i18next";
import { commonFunctions } from "./CommonFunctions";
import { DashboardDataList } from "domain/dashboard";

interface ChartProps {
    displayLegend?: boolean;
    widgetData: DashboardDataList[];
    dataFetched: boolean;
}

interface ChartState {
    data?: [];
    displayLegend?: boolean;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

type Props = ChartProps & ConnectedProps<typeof connector>;

class TotalProcessedDevicesChart extends React.Component<Props, ChartState> {
    public static defaultProps = {
        displayLegend: false,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            data: [],
            displayLegend: this.props.displayLegend,
        };
    }

    public getChartData(): ChartData {
        const monthData: number[] = [];
        Object.values(this.props.widgetData).forEach((dashboardData) => {
            const monthAmount =
                dashboardData.content.Laptop.weight +
                dashboardData.content.Desktop.weight +
                dashboardData.content.Server.weight +
                dashboardData.content["Loose drive"].weight +
                dashboardData.content.Smartphone.weight +
                dashboardData.content.Tablet.weight;
            monthData.push(monthAmount / 1000);
        });

        return {
            datasets: [
                {
                    data: monthData,
                    fill: true,
                    backgroundColor: "#7187BE",
                },
            ],
            labels: commonFunctions.fetchMonths(this.props.widgetData),
        };
    }

    public render(): JSX.Element {
        if (!this.props.dataFetched) {
            return (
                <div className={style.loaderContainer}>
                    <LoadingIndicator />
                </div>
            );
        }
        let totalOfDevicesProcessed = Number(commonFunctions.computeTotalTonnesOfErasedDevices(this.props.widgetData));
        let tonnageOfTotalDevicesProcessed = "tonnes";
        let averageWeightProcessedPerDay = Number(
            commonFunctions.computeAverageWeightProcessPerDay(this.props.widgetData)
        );
        let tonnageOfAverageDevicesProcessed = "tonnes";
        if (totalOfDevicesProcessed > 1000) {
            totalOfDevicesProcessed /= 1000;
            tonnageOfTotalDevicesProcessed = "kilotonnes";
        }
        if (averageWeightProcessedPerDay > 1000) {
            averageWeightProcessedPerDay /= 1000;
            tonnageOfAverageDevicesProcessed = "kilotonnes";
        }
        return (
            <Translation>
                {(t) => (
                    <div className={style.chartContainer}>
                        <p className={style.summaryText}>
                            <span className={style.summaryCount}>
                                {t("EsgDashboardView.totalOfProcessedDevicesChart.totalOfProcessedDevices", {
                                    totalOfProcessedDevices: totalOfDevicesProcessed.toFixed(),
                                    tonnage: tonnageOfTotalDevicesProcessed,
                                })}
                            </span>
                            <br />
                            {t("EsgDashboardView.referencedMonths", {
                                firstMonth: commonFunctions.computeFirstMonth(this.props.widgetData),
                                lastMonth: commonFunctions.computeLastMonth(this.props.widgetData),
                            })}
                            <br />
                            {t("EsgDashboardView.averagePerDay", {
                                averagePerDay: averageWeightProcessedPerDay.toFixed(),
                                tonnage: tonnageOfAverageDevicesProcessed,
                                numberOfMonths: commonFunctions.fetchMonths(this.props.widgetData).length,
                            })}
                        </p>
                        <div className={style.topSummaryContainer} />
                        <br />
                        <Bar
                            data={this.getChartData()}
                            options={getChartOptions(this.props.theme.contentBorderColor, this.props.theme.textColor)}
                        />
                    </div>
                )}
            </Translation>
        );
    }
}

function getChartOptions(gridLineColor: string, textColor: string): ChartOptions {
    return {
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontColor: textColor,
                    },
                },
            ],
            yAxes: [
                {
                    display: true,
                    gridLines: {
                        color: gridLineColor,
                        zeroLineColor: gridLineColor,
                    },
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 6,
                        callback(n: number) {
                            if (n < 1e6) return n;
                            if (n >= 1e6) {
                                return +(n / 1e6).toFixed(1) + "M";
                            }
                        },
                        fontColor: textColor,
                    },
                },
            ],
        },
        tooltips: {
            enabled: true,
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            labels: {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                render: () => {},
            },
        },
    };
}

export default connector(TotalProcessedDevicesChart);

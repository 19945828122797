import * as React from "react";
import style from "./chart.scss";
import { Line } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { Translation } from "react-i18next";
import { commonFunctions } from "./CommonFunctions";
import { DashboardDataList } from "domain/dashboard";

interface ChartProps {
    displayLegend?: boolean;
    widgetData: DashboardDataList[];
    dataFetched: boolean;
}

interface ChartState {
    data: [];
    displayLegend?: boolean;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

type Props = ChartProps & ConnectedProps<typeof connector>;

class EmissionsPreventedChart extends React.Component<Props, ChartState> {
    public static defaultProps = {
        displayLegend: false,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            data: [],
            displayLegend: this.props.displayLegend,
        };
    }

    computeAverageCo2ProcessPerDay(): number {
        return parseInt(String(commonFunctions.computeTotalOfCo2EmissionsPrevented(this.props.widgetData) / 365));
    }

    fetchCo2Emissions(deviceType: string): number[] {
        const co2Emissions: number[] = [];
        Object.values(this.props.widgetData).forEach((deviceData) => {
            Object.entries(deviceData.content).forEach(([key, value]) => {
                if (deviceType === key) {
                    co2Emissions.push(parseInt(String(value.co2 / 1000)));
                }
            });
        });
        return co2Emissions;
    }

    public getChartData(): ChartData {
        return {
            datasets: [
                {
                    label: "Laptop",
                    backgroundColor: "#C7D2EF",
                },
                {
                    label: "Desktop",
                    backgroundColor: "#3A5DAB",
                },
                {
                    label: "Server",
                    backgroundColor: "#02164A",
                },
                {
                    label: "Loose drive",
                    backgroundColor: "#A1B0D5",
                },
                {
                    label: "Smartphone",
                    backgroundColor: "#7187BE",
                },
                {
                    label: "Tablet",
                    backgroundColor: "#243F81",
                },
            ].map((each) => {
                return Object.assign({}, each, {
                    data: this.fetchCo2Emissions(each["label"]),
                    fill: true,
                });
            }),
            labels: commonFunctions.fetchMonths(this.props.widgetData),
        };
    }

    public render(): JSX.Element {
        if (!this.props.dataFetched) {
            return (
                <div className={style.loaderContainer}>
                    <LoadingIndicator />
                </div>
            );
        }
        let averageCo2EmissionsPerDay = this.computeAverageCo2ProcessPerDay();
        let tonnageForTotalEmissions = "tonnes";
        let totalCo2Emissions = commonFunctions.computeTotalOfCo2EmissionsPrevented(this.props.widgetData);
        let tonnageForAverageEmissions = "tonnes";
        if (totalCo2Emissions > 1_000_000) {
            totalCo2Emissions /= 1_000_000;
            tonnageForTotalEmissions = "megatonnes";
        }
        if (averageCo2EmissionsPerDay > 1000) {
            averageCo2EmissionsPerDay /= 1000;
            tonnageForAverageEmissions = "kilotonnes";
        }
        return (
            <Translation>
                {(t) => (
                    <div className={style.chartContainer}>
                        <p className={style.summaryText}>
                            <span className={style.summaryCount}>
                                {t("EsgDashboardView.co2EmissionsPreventedChart.totalOfCo2EmissionsPrevented", {
                                    totalEmissionsPrevented: totalCo2Emissions.toFixed(),
                                    tonnage: tonnageForTotalEmissions,
                                })}
                            </span>
                            <br />
                            {t("EsgDashboardView.referencedMonths", {
                                firstMonth: commonFunctions.computeFirstMonth(this.props.widgetData),
                                lastMonth: commonFunctions.computeLastMonth(this.props.widgetData),
                            })}
                            <br />
                            {t("EsgDashboardView.averagePerDay", {
                                averagePerDay: averageCo2EmissionsPerDay.toFixed(),
                                tonnage: tonnageForAverageEmissions,
                                numberOfMonths: commonFunctions.fetchMonths(this.props.widgetData).length,
                            })}
                        </p>
                        <div className={style.topSummaryContainer} />
                        <br />
                        <Line
                            data={this.getChartData()}
                            options={getChartOptions(this.props.theme.contentBorderColor, this.props.theme.textColor)}
                        />
                    </div>
                )}
            </Translation>
        );
    }
}

function getChartOptions(gridLineColor: string, textColor: string): ChartOptions {
    return {
        legend: {
            display: true,
            position: "bottom",
            labels: {
                usePointStyle: true,
                fontColor: textColor,
            },
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontColor: textColor,
                    },
                },
            ],
            yAxes: [
                {
                    display: true,
                    gridLines: {
                        color: gridLineColor,
                        zeroLineColor: gridLineColor,
                    },
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        fontColor: textColor,
                    },
                    stacked: true,
                },
            ],
        },
        elements: {
            line: {
                tension: 0,
            },
            point: {
                radius: 1,
            },
        },
        plugins: {
            labels: {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                render: () => {},
            },
        },
        tooltips: {
            enabled: true,
        },
        responsive: true,
        maintainAspectRatio: false,
    };
}

export default connector(EmissionsPreventedChart);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LicensesState {
    hasBmsLicenses: boolean;
    hasBmsValidationLicenses: boolean;
    hasBmsBbtiLicenses: boolean;
}

const INITIAL_STATE: LicensesState = {
    hasBmsLicenses: false,
    hasBmsValidationLicenses: false,
    hasBmsBbtiLicenses: false,
};

const LICENSES_SLICE = createSlice({
    name: "licensesStore",
    initialState: INITIAL_STATE,
    reducers: {
        setHasBmsLicenses(state, action: PayloadAction<boolean>) {
            state.hasBmsLicenses = action.payload;
        },
        setHasBmsValidationLicenses(state, action: PayloadAction<boolean>) {
            state.hasBmsValidationLicenses = action.payload;
        },
        setHasBmsBbtiLicenses(state, action: PayloadAction<boolean>) {
            state.hasBmsBbtiLicenses = action.payload;
        },
    },
});

export const { setHasBmsLicenses, setHasBmsValidationLicenses, setHasBmsBbtiLicenses } = LICENSES_SLICE.actions;

export default LICENSES_SLICE.reducer;

import * as React from "react";
import { NavLink } from "react-router-dom";
import { RouteDefinition } from "components/router/Routes";
import { StoreState } from "store";
import { connect, ConnectedProps } from "react-redux";
import { useFeature } from "flagged";
import { useTranslation } from "react-i18next";

import testIds from "testIds.json";

interface Props {
    menuClass: string;
    currentItemClass: string;
    childItemClass?: string;
    routes: RouteDefinition[];
    appendChildRoutes: boolean;
}

const connector = connect((state: StoreState) => ({
    user: state.userReducer.user,
}));

// TODO BCC-207: Reconsider this whole implementation
const Navigation = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    let index = 0;
    const { t } = useTranslation();
    const menuItems = props.routes.map((route) => {
        if (!route.isValidUser(props.user)) {
            return null;
        } else {
            const flag = route.getFeatureFlag();
            if (flag != null && !useFeature(flag)) {
                return null;
            }
        }
        // Add the current route first
        const links = [
            <li key={index++}>
                <NavLink
                    activeClassName={props.currentItemClass}
                    exact={props.appendChildRoutes}
                    to={route.path}
                    data-testid={route.testId}
                >
                    {t(route.title)}
                </NavLink>
            </li>,
        ];

        const childMenuItems = props.appendChildRoutes
            ? route.childRoutes.map((childRoute) => {
                  return (
                      <li key={index++}>
                          <NavLink
                              activeClassName={props.currentItemClass}
                              className={props.childItemClass}
                              exact={true}
                              to={childRoute.path}
                          >
                              {t(childRoute.title)}
                          </NavLink>
                      </li>
                  );
              })
            : [];
        // Append current route's children to follow it
        links.push(...childMenuItems);

        return links;
    });

    return (
        <nav className={props.menuClass} data-testid={testIds.navigation.itself}>
            <ul>{menuItems}</ul>
        </nav>
    );
};

const connectedNavigation = connector(Navigation);
export { connectedNavigation as Navigation };

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-2!lato-font/css/lato-font.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "._3WkQHdIBnw9hG5Y_RwxQzc{font-size:13px;font-family:\"Lato\",Arial,sans-serif}._2Pmfe4LYFoUZi35z5jsST{font-size:12px}._28OaCC1MT6Hk7NKPjO7xmC{font-size:13px;line-height:16px}.defaultTheme ._28OaCC1MT6Hk7NKPjO7xmC{color:#4c4c4c}.tenantTheme ._28OaCC1MT6Hk7NKPjO7xmC{color:#4c4c4c}.defaultDarkTheme ._28OaCC1MT6Hk7NKPjO7xmC{color:#fff}.tenantDarkTheme ._28OaCC1MT6Hk7NKPjO7xmC{color:#fff}._1C3KVBeaa_gYPk4cG-X-DK{width:450px;height:500px;resize:none;border:1px solid #999}._2MrY4sCVorULen--VzDjfw,._3-TwkJ9iSbGQXFzzuxRWZk{margin-right:20px}._1-jx2AxWSg-71i8KSegM3F{display:flex;justify-content:center;margin-top:20px}", ""]);
// Exports
exports.locals = {
	"smallestScreen": "1400px",
	"screen-sm": "960px",
	"screen-md": "1368px",
	"screen-lg": "1600px",
	"activeBackgroundColor": "#db2828",
	"activeForegroundColor": "#db2828",
	"primaryButtonBackgroundColor": "#db2828",
	"secondaryButtonForegroundColor": "#f46464",
	"bannerBackgroundColor": "rgba(219,40,40,.5)",
	"linkTextColor": "#f46464",
	"primaryButtonBackgroundHoverColor": "#f46464",
	"secondaryButtonBackgroundHoverColor": "#f0f0f0",
	"destructiveButtonBackgroundHoverColor": "#f46464",
	"primaryButtonBackgroundActiveColor": "#f46464",
	"secondaryButtonBackgroundActiveColor": "#f7f7f7",
	"destructiveButtonBackgroundColor": "#f09191",
	"chartNegativeColor": "#f09191",
	"chartPositiveColor": "#5fe3a1",
	"chartNeutralColor": "#cecece",
	"errorBackgroundColor": "#f46464",
	"errorBorderColor": "#f46464",
	"errorTextColor": "#f46464",
	"warningBackgroundColor": "#f2711c",
	"warningBorderColor": "#f2711c",
	"warningTextColor": "#f2711c",
	"secondaryWarningBackgroundColor": "#ffc915",
	"secondaryWarningBorderColor": "#ffc915",
	"secondaryWarningTextColor": "#ffc915",
	"successBorderColor": "#5fe3a1",
	"successTextColor": "#5fe3a1",
	"pageBackgroundColor": "#222",
	"wrapperBackgroundColor": "#f2c5c5",
	"wrapperBorderColor": "#f09191",
	"modelBoxBackgroundColor": "#4c4c4c",
	"contentBackgroundColor": "#333",
	"secondaryButtonBackgroundColor": "#333",
	"textColor": "#fff",
	"primaryButtonForegroundColor": "#fff",
	"bannerForegroundColor": "#4c4c4c",
	"chartOutlineColor": "#222",
	"modalOverlayColor": "rgba(34,34,34,.4)",
	"contentDividerColor": "#222",
	"contentBorderColor": "#111",
	"disabledButtonBackgroundColor": "#222",
	"disabledButtonForegroundColor": "#4c4c4c",
	"inputHintColor": "#cecece",
	"inputBackgroundColor": "#4c4c4c",
	"hoverBackgroundColor": "#111",
	"toggleCheckedBackgroundColor": "#91b9f0",
	"toggleUncheckedBackgroundColor": "#cecece",
	"toggleForegroundColor": "#fff",
	"mapForegroundColor": "#f0f0f0",
	"mapBackgroundColor": "#222",
	"emptyStateEllipseBackgroundColor": "#111",
	"emptyStateBiggestCircleBackgroundColor": "#3c3960",
	"emptyStateSmallestCircleBackgroundColor": "#111",
	"emptyStatePersonColor": "#4a4a52",
	"defaultTheme": "defaultTheme",
	"tenantTheme": "tenantTheme",
	"defaultDarkTheme": "defaultDarkTheme",
	"tenantDarkTheme": "tenantDarkTheme",
	"latoFontFamily": "\"Lato\",Arial,sans-serif",
	"latoMediumFontFamily": "\"Lato Medium\",Arial,sans-serif",
	"robotoFontFamily": "\"Roboto\",Arial,sans-serif",
	"robotoMediumFontFamily": "\"Roboto Medium\",Arial,sans-serif",
	"loginPopupBodyFont": "_3WkQHdIBnw9hG5Y_RwxQzc",
	"formExplanation": "_2Pmfe4LYFoUZi35z5jsST",
	"createModified": "_28OaCC1MT6Hk7NKPjO7xmC",
	"textarea": "_1C3KVBeaa_gYPk4cG-X-DK",
	"editButton": "_2MrY4sCVorULen--VzDjfw",
	"resetButton": "_3-TwkJ9iSbGQXFzzuxRWZk",
	"okButtonContainer": "_1-jx2AxWSg-71i8KSegM3F"
};
module.exports = exports;

import * as React from "react";
import checkMark from "assets/images/icons/checkMark.svg";
import style from "./introduction-view.scss";
import commonStyle from "../api-guide.scss";
import { useTranslation } from "react-i18next";
import StaticTable from "../StaticTable";

const IntroductionView = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <>
            <div className={style.chapterHeaderFirst}>{t("Support.apiGuide.introduction.title")}</div>
            <div className={commonStyle.sectionBody}>{t("Support.apiGuide.introduction.description")}</div>
            <div className={commonStyle.sectionBody}>{t("Support.apiGuide.introduction.examples")}</div>
            <div className={commonStyle.sectionHeader}>{t("Support.apiGuide.authentication.title")}</div>
            <div className={commonStyle.sectionBody}>{t("Support.apiGuide.authentication.info")}</div>
            <StaticTable
                headers={[
                    { className: style.firstColumnHeader, value: "Header" },
                    { className: style.secondColumnHeader, value: "Required" },
                    { value: "Description" },
                ]}
                cells={[
                    [
                        "X-BLANCCO-API-KEY",
                        <img key="not-really-needed-but-compiler-demands" src={checkMark} />,
                        t("Support.apiGuide.authentication.apiKeyDescription"),
                    ],
                ]}
            />
            <div className={commonStyle.sectionHeader}>{t("Support.apiGuide.pagination.title")}</div>
            <div className={commonStyle.sectionBody}>{t("Support.apiGuide.pagination.description")}</div>
            <div className={commonStyle.sectionHeader}>{t("Support.apiGuide.paths.title")}</div>
            <div className={commonStyle.sectionBody}>{t("Support.apiGuide.paths.description")}</div>
            <div className={commonStyle.sectionHeader}>Error responses</div>
            <StaticTable
                headers={[{ className: style.secondColumnHeader, value: "Code" }, { value: "Description" }]}
                cells={[
                    ["400", t("Support.apiGuide.errorResponse.400")],
                    ["401", t("Support.apiGuide.errorResponse.401")],
                    ["403", t("Support.apiGuide.errorResponse.403")],
                    ["404", t("Support.apiGuide.errorResponse.404")],
                ]}
            />
        </>
    );
};

export default IntroductionView;

import * as React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Modal from "components/modal/Modal";
import buttonsStyle from "styles/buttons.scss";
import formStyle from "styles/form.scss";
import mainStyle from "./add-license.scss";
import AddLicenseForm, { FormValues } from "./AddLicenseForm";
import { getTenantName, hasTenantCookie } from "services/tenants/tenantCookieService";
import { licenseService } from "services/licenses/LicenseService";

interface Result {
    title: string;
    message: string;
}

const AddLicenseView = (props: { onLicenseAdded: () => void }): JSX.Element => {
    const { t } = useTranslation();

    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [addLicenseFormVisible, setAddLicenseFormVisible] = React.useState(false);
    const [resultVisible, setResultVisible] = React.useState(false);

    React.useEffect(() => {
        return () => abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort());
    });

    const addLicenseSubmitEventHandler = async ({
        licenseType,
        licenseUsage,
        expirationDate,
    }: FormValues): Promise<void> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            const dateValue = new Date(expirationDate);
            dateValue.setHours(23, 59, 59);

            // Remove milliseconds as per BCC-405 requirement
            const EXPIRATION_DATE_ISO_FORMAT = dateValue.toISOString().replace(/.000/, "");

            await licenseService.addLicenses(
                licenseType,
                parseInt(licenseUsage),
                EXPIRATION_DATE_ISO_FORMAT,
                abortController
            );
        } catch (e) {
            if (!signal.aborted) {
                setAddLicenseFormVisible(false);
                showResult({
                    title: t("AddLicenseView.licenseFailure"),
                    message: t("AddLicenseView.failureMessage"),
                });
                console.error("Failed to add license:", e);
                setAddLicenseFormVisible(true);
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        setAddLicenseFormVisible(false);
        showResult({
            title: t("AddLicenseView.licenseSuccess"),
            message: t("AddLicenseView.successMessage", {
                customerName: getTenantName(),
                usageCount: licenseUsage,
                licenseType: t("Products." + licenseType),
            }),
        });
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };

    const hideSuccessDialog = () => {
        setResultVisible(false);
        props.onLicenseAdded();
    };

    return (
        <div>
            {hasTenantCookie() ? (
                <button
                    className={classNames(buttonsStyle.primaryButton, buttonsStyle.small)}
                    onClick={() => setAddLicenseFormVisible(true)}
                >
                    {t("AddLicenseView.licenseButton")}
                </button>
            ) : (
                ""
            )}

            <Modal
                isOpen={addLicenseFormVisible}
                hideModal={() => setAddLicenseFormVisible(false)}
                modalTitle={t("AddLicenseForm.title", { customerName: getTenantName() })}
            >
                <div className={formStyle.fixedWidthModal}>
                    <AddLicenseForm submitEventHandler={addLicenseSubmitEventHandler} />
                </div>
            </Modal>
            <Modal isOpen={resultVisible} hideModal={hideSuccessDialog} modalTitle={result.title}>
                <div className={mainStyle.resultContainer}>{result.message}</div>
                <div className={mainStyle.okButtonContainer}>
                    <button
                        className={classNames(buttonsStyle.primaryButton, buttonsStyle.medium, mainStyle.okButton)}
                        onClick={hideSuccessDialog}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default AddLicenseView;

import * as React from "react";
import ApplicationLayout from "components/layout/ApplicationLayout";
import Overview from "./Overview";
import { DASHBOARD_ROUTE } from "components/router/Routes";
import { useTranslation } from "react-i18next";

export default function DashboardOverview(): JSX.Element {
    const { t } = useTranslation();

    return (
        <ApplicationLayout
            viewTitle={t("Overview.bmsDiagnostics")}
            view={<Overview />}
            viewContainer={false}
            mainRoute={DASHBOARD_ROUTE}
        />
    );
}

import * as React from "react";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";
import DateCell from "components/table/DateCell";
import KebabMenu from "components/kebab-menu/KebabMenu";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { ViewTenantDetails } from "components/customer-details/ViewTenantDetails";
import Modal from "components/modal/Modal";
import { RepositoryKey } from "services/utils/repository";
import { Tenant } from "domain/tenants";
import { tenantService } from "services/tenants/TenantService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";

import style from "./tenants.scss";

import testIds from "testIds.json";
import TenantMenuItems from "components/tenants/TenantMenuItems";
import StatusCell, { Status } from "components/table/StatusCell";

interface TableState {
    tenants: Tenant[];
}

export function TenantsTable(props: {
    count: number;
    onTenantEdit: () => void;
    initialTenantData: Tenant[];
}): JSX.Element {
    const { t } = useTranslation();
    const [tenantProfileVisibility, setTenantProfileVisibility] = React.useState(false);
    const showTenantProfile = (tenant: Tenant) => {
        setTenantProfileVisibility(true);
        setActiveTenatDetails(tenant);
    };

    const hideTenantProfile = () => {
        setTenantProfileVisibility(false);
    };

    const columns: Array<Column<Tenant>> = [
        {
            Header: () => <TextWithTooltip text={t("Common.customerName")} key="1" />,
            accessor: "name",
            Cell: (cellInfo) => (
                <>
                    <KebabMenu>
                        <TenantMenuItems
                            uuid={cellInfo.cell.row.original.uuid}
                            customerName={cellInfo.value}
                            status={cellInfo.cell.row.original.status}
                            onTenantEdit={props.onTenantEdit}
                        />
                    </KebabMenu>
                    <div
                        className={style.customerNameCell}
                        onClick={() => {
                            showTenantProfile(cellInfo.cell.row.original);
                            usageStatisticsService.sendEvent({
                                category: Category.TENANT,
                                action: Action.VIEW_TENANT_DETAILS,
                            });
                        }}
                    >
                        <TextWithTooltip text={cellInfo.value} />
                    </div>
                </>
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("Common.status")} key="9" />,
            accessor: "status",
            Cell: ({ cell: { value } }) => (
                <StatusCell
                    values={[
                        {
                            status: value ? Status.Ok : Status.Warning,
                            title: value ? t("TenantsTable.enabled") : t("TenantsTable.disabled"),
                        },
                    ]}
                    tooltip={true}
                />
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("TenantsTable.region")} key="2" />,
            accessor: "region",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("Common.country")} key="3" />,
            accessor: "countryCode",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("TenantsTable.contactName")} key="4" />,
            accessor: "contactName",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("TenantsTable.contactEmail")} key="5" />,
            accessor: "contactEmail",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("TenantsTable.creationDate")} key="6" />,
            accessor: "createdTimestamp",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("TenantsTable.createdBy")} key="7" />,
            accessor: "createdBy",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("Common.notes")} key="8" />,
            accessor: "notes",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
    ];
    const [state, setState] = React.useState<TableState>({ tenants: props.initialTenantData });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [activeTenantDetails, setActiveTenatDetails] = React.useState<Tenant>(props.initialTenantData[0]);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);

    const fetchData = (initialLoading: boolean) => {
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        tenantService
            .fetchTenants(abortController)
            .then((tenants) => {
                setState({ tenants });
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("TenantsTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        if (props.initialTenantData.length > 0) {
            return;
        }
        fetchData(true);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [props.count]);

    const modal =
        activeTenantDetails != null ? (
            <Modal
                key={1}
                isOpen={tenantProfileVisibility}
                hideModal={hideTenantProfile}
                modalTitle={t("TenantProfile.title", { tenantName: activeTenantDetails.name })}
            >
                <ViewTenantDetails activeTenant={activeTenantDetails} />
            </Modal>
        ) : null;

    let dataCount = null;
    if (state.tenants.length > 0) {
        dataCount = t("Common.defaultSearchResultHint", { dataCount: state.tenants.length });
    }

    return (
        <>
            {dataCount}
            <Table
                tableIdentity={RepositoryKey.TENANT_TABLE}
                data={state.tenants}
                columns={columns}
                loaded={!initialLoading}
                failureMessage={requestFailureMessage}
                tooltips={true}
                emptyMessage={t("TenantsTable.emptyStateMessage")}
                testId={testIds.workArea.tenant.table}
            />
            {modal}
        </>
    );
}

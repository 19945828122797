import * as React from "react";
import { MenuItem, Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import UserProfileView from "components/users/profile/UserProfileView";
import SettingsView from "components/settings/SettingsView";
import style from "./user-menu.scss";
import { useState } from "react";
import Modal from "components/modal/Modal";
import testIds from "testIds.json";
import { usageStatisticsService, Action, Category } from "services/statistics/UsageStatisticsService";

export const UserMenu = (): JSX.Element => {
    const [t] = useTranslation();
    const [userProfileVisibility, setUserProfileVisibility] = useState(false);
    const [settingsVisibility, setSettingsVisibility] = useState(false);
    const showUserProfile = () => {
        usageStatisticsService.sendEvent({
            category: Category.HEADER,
            action: Action.VIEW_USER_PROFILE,
        });
        setUserProfileVisibility(true);
    };

    const hideUserProfile = () => {
        setUserProfileVisibility(false);
    };

    const showSettings = () => {
        usageStatisticsService.sendEvent({
            category: Category.HEADER,
            action: Action.VIEW_USER_SETTINGS,
        });
        setSettingsVisibility(true);
    };

    const hideSettings = () => {
        setSettingsVisibility(false);
    };

    const menuItems: JSX.Element[] = [
        <a key="1" onClick={showUserProfile} data-testid={testIds.header.userMenu.profileLink}>
            {t("Routes.profile")}
        </a>,
        <a key="2" onClick={showSettings} data-testid={testIds.header.userMenu.settingLink}>
            {t("Routes.settings")}
        </a>,
        <a
            key="3"
            href="/logout"
            data-testid={testIds.header.userMenu.logoutLink}
            onClick={() => {
                usageStatisticsService.sendEvent({
                    category: Category.HEADER,
                    action: Action.LOGOUT,
                });
            }}
        >
            {t("Routes.logout")}
        </a>,
    ];

    return (
        <>
            <Menu className={style.menu}>
                <ul>
                    {menuItems.map((item, key) => (
                        <li key={key}>
                            <MenuItem className={style.item}>{item}</MenuItem>
                        </li>
                    ))}
                </ul>
            </Menu>
            <Modal isOpen={userProfileVisibility} hideModal={hideUserProfile} modalTitle={t("Routes.profile")}>
                <UserProfileView />
            </Modal>
            <Modal isOpen={settingsVisibility} hideModal={hideSettings} modalTitle={t("Routes.settings")}>
                <SettingsView />
            </Modal>
        </>
    );
};

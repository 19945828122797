import * as React from "react";
import style from "./bms-diagnostics-chart.scss";
import { Doughnut } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import "chartjs-plugin-labels";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { StoreState } from "store";
import { connect, ConnectedProps } from "react-redux";

interface ChartState {
    chartData?: ChartData | null;
    displayLegend?: boolean;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

interface ChartProps {
    displayLegend?: boolean;
}

type Props = ChartProps & ConnectedProps<typeof connector>;

class BmsDiagnosticsChart extends React.Component<Props, ChartState> {
    public static defaultProps = {
        displayLegend: false,
    };

    private chartData: ChartData = {
        labels: ["10% fail", "60% pass", "30% not run"],
        datasets: [
            {
                label: "#Devices",
                // ToDo, This data should be fetched & populated dynamically from API
                data: [10, 60, 30],
                backgroundColor: [
                    // Theme reload will not work without view refresh.
                    this.props.theme.chartNegativeColor,
                    this.props.theme.chartPositiveColor,
                    this.props.theme.chartNeutralColor,
                ],
                borderWidth: 0,
                hoverBorderWidth: 1,
                hoverBorderColor: this.props.theme.chartOutlineColor,
            },
        ],
    };
    private readonly timeout: ReturnType<typeof setTimeout>;

    constructor(props: Props) {
        super(props);
        this.state = {
            chartData: null,
            displayLegend: this.props.displayLegend,
        };
        // TODO : Remove this delay when actual data loading is in place
        this.timeout = setTimeout(() => {
            this.setState({ chartData: this.chartData });
        }, 3000);
    }

    public componentWillUnmount(): void {
        clearTimeout(this.timeout);
    }

    public render(): JSX.Element {
        return !this.state.chartData ? (
            <div className={style.bmsDiagnosticsChartLoaderContainer}>
                <LoadingIndicator />
            </div>
        ) : (
            <div className={style.bmsDiagnosticsChartContainer}>
                <div className={style.topSummaryContainer}>
                    <p className={style.summaryText}>
                        {/* ToDo This data should be fetched & populated dynamically from API */}
                        <span className={style.summaryCount}>600</span>
                        <br />
                        total devices tested
                        <br />
                        (avg. 2 devices/ day since 1 Oct 2019)
                    </p>
                    <p className={style.chartTitle}>Total test result breakdown</p>
                </div>
                <Doughnut data={this.state.chartData} options={this.getChartOptions(this.props.theme.textColor)} />
                <div className={style.seeBmsDiagnosticsContainer}>
                    <button className={style.seeBmsDiagnostics}>See BMS diagnostics</button>
                </div>
            </div>
        );
    }

    private getChartOptions(fontColor: string): ChartOptions {
        return {
            plugins: {
                labels: {
                    render: "label",
                    precision: 0,
                    position: "outside",
                    textMargin: 10,
                    fontSize: 12,
                    fontColor,
                },
            },
            legend: {
                display: this.state.displayLegend,
            },
            tooltips: {
                enabled: false,
            },
            cutoutPercentage: 75,
        };
    }
}

export default connector(BmsDiagnosticsChart);

import * as React from "react";
import { switchTheme } from "store/theme";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";
import style from "./license-configuration.scss";
import { MenuItem, Menu } from "react-aria-menubutton";
import Edit from "components/icons/Edit";

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
});

const connector = connect(mapState, { switchTheme });

const EditConfigurationButton = (
    props: ConnectedProps<typeof connector> & {
        uuid: string;
        created: string;
        modified: string;
        showEditConfig: (uuid: string, created: string, modified: string) => void;
    }
): JSX.Element => {
    const showClientConfiguration = () => {
        props.showEditConfig(props.uuid, props.created, props.modified);
    };

    return (
        <div className={style.configurationEditIcon}>
            <Menu className={style.kebabMenu}>
                <ul>
                    <MenuItem onClick={() => showClientConfiguration()}>
                        <Edit color={props.theme.primaryButtonBackgroundColor} />
                    </MenuItem>
                </ul>
            </Menu>
        </div>
    );
};

export default connector(EditConfigurationButton);

import Cookie from "js-cookie";

const TENANT_UUID_COOKIE_NAME = "TENANT_UUID";
const TENANT_NAME_COOKIE_NAME = "TENANT_NAME";

export function hasTenantCookie(): boolean {
    return Cookie.get(TENANT_UUID_COOKIE_NAME) !== undefined;
}

export function removeTenantCookie(): void {
    const domain = "." + window.location.hostname;
    Cookie.remove(TENANT_UUID_COOKIE_NAME, { domain: domain });
    Cookie.remove(TENANT_NAME_COOKIE_NAME, { domain: domain });
}

export function getTenantName(): string {
    return Cookie.get(TENANT_NAME_COOKIE_NAME) || "";
}

export function getTenantUuid(): string | undefined {
    return Cookie.get(TENANT_UUID_COOKIE_NAME);
}

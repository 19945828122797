import * as React from "react";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";
import DateCell from "components/table/DateCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import Modal from "components/modal/Modal";
import EditConfigurationView from "./EditConfigurationView";
import { ConfigureData } from "domain/licenses";
import { RepositoryKey } from "services/utils/repository";
import { ConfigurationData, licenseService } from "services/licenses/LicenseService";
import testIds from "testIds.json";
import style from "./license-configuration.scss";
import KebabMenu from "components/kebab-menu/KebabMenu";
import EditConfigurationButton from "./EditConfigurationButton";
import { StoreState } from "store";
import { connect, ConnectedProps } from "react-redux";
import { setHasBmsBbtiLicenses, setHasBmsLicenses, setHasBmsValidationLicenses } from "store/license";

interface Props {
    initialConfiguration: ConfigureData[];
    count: number;
}

interface TableState {
    configuration: ConfigurationData;
}

interface ActiveConfigurationViewState {
    uuid: string;
    created: string;
    modified: string;
}

const mapState = (state: StoreState) => ({
    hasBmsLicenses: state.licensesReducer.hasBmsLicenses,
});
const connector = connect(mapState, { setHasBmsLicenses, setHasBmsValidationLicenses, setHasBmsBbtiLicenses });

const ConfigureTable = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [count, setCount] = React.useState({ count: 0 });
    const [tenantConfigurationVisibility, setTenantConfigurationVisibility] = React.useState(false);
    const [tenantEditConfigurationVisibility, setTenantEditConfigurationVisibility] = React.useState(false);
    const [activeTenantConfiguration, setActiveTenantConfiguration] =
        React.useState<ActiveConfigurationViewState | null>(null);

    const onConfigEdit = () => {
        return setCount((prevState) => ({ count: prevState.count + 1 }));
    };

    const showClientConfiguration = (uuid: string, created: string, modified: string) => {
        setTenantConfigurationVisibility(true);
        setActiveTenantConfiguration({
            uuid,
            created,
            modified,
        });
    };

    const hideTenantConfiguration = () => {
        setTenantConfigurationVisibility(false);
        setActiveTenantConfiguration(null);
    };

    const showEditTenantConfiguration = () => {
        setTenantConfigurationVisibility(false);
        setTenantEditConfigurationVisibility(true);
    };

    const showEditConfig = (uuid: string, created: string, modified: string) => {
        setActiveTenantConfiguration({
            uuid,
            created,
            modified,
        });
        setTenantConfigurationVisibility(false);
        setTenantEditConfigurationVisibility(true);
    };

    const hideTenantEditConfiguration = () => {
        setTenantEditConfigurationVisibility(false);
        setActiveTenantConfiguration(null);
    };

    const viewTenantConfiguration = (
        <Modal
            isOpen={tenantConfigurationVisibility}
            hideModal={hideTenantConfiguration}
            modalTitle={t("configuration.viewConfigureDialog.title")}
        >
            <EditConfigurationView
                uuid={activeTenantConfiguration ? activeTenantConfiguration.uuid : ""}
                created={activeTenantConfiguration ? activeTenantConfiguration.created : ""}
                modified={activeTenantConfiguration ? activeTenantConfiguration.modified : ""}
                onClose={hideTenantConfiguration}
                onEdit={showEditTenantConfiguration}
                edit={false}
            />
        </Modal>
    );

    const editTenantConfiguration = (
        <Modal
            isOpen={tenantEditConfigurationVisibility}
            hideModal={hideTenantEditConfiguration}
            modalTitle={t("configuration.editConfigureDialog.title")}
        >
            <EditConfigurationView
                uuid={activeTenantConfiguration ? activeTenantConfiguration.uuid : ""}
                created={activeTenantConfiguration ? activeTenantConfiguration.created : ""}
                modified={activeTenantConfiguration ? activeTenantConfiguration.modified : ""}
                onClose={hideTenantEditConfiguration}
                onEdit={onConfigEdit}
                edit={true}
            />
        </Modal>
    );

    const columns: Array<Column<ConfigureData>> = [
        {
            Header: () => <TextWithTooltip text={t("configuration.ConfigureTable.uuid")} key="1" />,
            accessor: "uuid",
            Cell: (row) => (
                <>
                    <KebabMenu>
                        <EditConfigurationButton
                            uuid={row.row.original.uuid}
                            created={row.row.original.created}
                            modified={row.row.original.modified}
                            showEditConfig={showEditConfig}
                        />
                    </KebabMenu>
                    <div
                        className={style.customerConfigurationUUIDCell}
                        onClick={() =>
                            showClientConfiguration(
                                row.row.original.uuid,
                                row.row.original.created,
                                row.row.original.modified
                            )
                        }
                    >
                        <TextWithTooltip text={row.row.original.uuid} />
                    </div>
                </>
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("configuration.ConfigureTable.product")} key="2" />,
            accessor: "product",
            Cell: ({ cell: { value } }) => (
                <TextWithTooltip text={value ? value : t("configuration.ConfigureTable.bms")} />
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("configuration.ConfigureTable.creationDate")} key="3" />,
            accessor: "created",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("configuration.ConfigureTable.editedDate")} key="4" />,
            accessor: "modified",
            Cell: ({ cell: { value } }) =>
                value ? (
                    <DateCell tooltip={true} value={value} />
                ) : (
                    <TextWithTooltip text={t("configuration.ConfigureTable.not-available")} />
                ),
        },
    ];
    const [state, setConfigureData] = React.useState<TableState>({
        configuration: { configuration: props.initialConfiguration },
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const fetchData = (initialLoading: boolean) => {
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licenseService
            .fetchConfigurationData(abortController)
            .then((configuration) => {
                setConfigureData({ configuration });
                props.setHasBmsLicenses(true);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("configuration.ConfigureTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };
    React.useEffect(() => {
        if (props.initialConfiguration.length > 0) {
            return;
        }
        fetchData(true);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [props.count, count]);

    let dataCount = null;
    if (state.configuration.configuration.length > 0) {
        dataCount = t("Common.defaultSearchResultHint", { dataCount: state.configuration.configuration.length });
    }

    return (
        <>
            {dataCount}
            <Table
                tableIdentity={RepositoryKey.CONFIGURATION_TABLE}
                data={state.configuration.configuration}
                columns={columns}
                loaded={!initialLoading}
                failureMessage={requestFailureMessage}
                tooltips={true}
                emptyMessage={t("configuration.ConfigureTable.emptyStateMessage")}
                testId={testIds.workArea.license.bms.table.itself}
            />
            {viewTenantConfiguration}
            {editTenantConfiguration}
        </>
    );
};

ConfigureTable.defaultProps = {
    initialConfiguration: [],
    requestFailureMessage: "",
};

export default connector(ConfigureTable);

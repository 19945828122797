import * as React from "react";

import style from "./static-table.scss";

interface Header {
    className?: string;
    value: string | JSX.Element;
}

interface Props {
    headers: Array<Header>;
    cells: Array<Array<string | JSX.Element | null>>;
}

export default function StaticTable(props: Props): JSX.Element {
    const headers = props.headers.map((each, index) => {
        return (
            <th className={each.className} key={index}>
                {each.value}
            </th>
        );
    });
    const rows = props.cells.map((row, rowIndex) => {
        const cells = row.map((each, cellIndex) => <td key={cellIndex}>{each}</td>);
        return <tr key={rowIndex}>{cells}</tr>;
    });
    return (
        <table className={style.table}>
            <thead>
                <tr>{headers}</tr>
            </thead>
            <tbody>{rows}</tbody>
        </table>
    );
}

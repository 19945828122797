import * as React from "react";
import { useState } from "react";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { USERS_ROUTE } from "components/router/Routes";
import AddUserView from "components/users/add-user/AddUserView";
import { useTranslation } from "react-i18next";
import { UsersTable } from "components/users/UsersTable";

export default function AllUsersView(): JSX.Element {
    const { t } = useTranslation();

    const [state, setState] = useState({ count: 0 });

    function onUserAdded() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    function onUserDelete() {
        return setState((prevState) => ({ count: prevState.count - 1 }));
    }

    return (
        <ApplicationLayout
            viewTitle={t("Common.userTitle")}
            view={<UsersTable count={state.count} onUserDelete={onUserDelete} />}
            mainRoute={USERS_ROUTE}
            viewActions={<AddUserView onUserAdded={onUserAdded} />}
        />
    );
}

import * as React from "react";
import TextWithTooltip from "./TextWithTooltip";
import style from "./uuid-link-cell.scss";

const UuidLinkCell = (props: { value: string; handler: () => void; tooltip?: boolean }): JSX.Element => (
    <a className={style.link} onClick={props.handler}>
        {props.tooltip ? <TextWithTooltip text={props.value.toLowerCase()} /> : props.value.toLowerCase()}
    </a>
);

export default UuidLinkCell;

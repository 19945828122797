import * as ReactDom from "react-dom";
import React from "react";
import ReactModal from "react-modal";
import { FlagsProvider } from "flagged";
import { Provider } from "react-redux";

import BlanccoCommonCloud from "components/BlanccoCommonCloud";
import { featureFlagService } from "services/feature/FeatureFlagService";

import { store } from "store";

const APP_ELEMENT_ID = "blancco-common-cloud";

ReactDom.render(
    <Provider store={store}>
        <FlagsProvider features={featureFlagService.getFlags()}>
            <BlanccoCommonCloud />
        </FlagsProvider>
    </Provider>,
    document.getElementById(APP_ELEMENT_ID),
    () => {
        ReactModal.setAppElement(document.getElementById(APP_ELEMENT_ID) as HTMLElement);
    }
);

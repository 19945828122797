import {
    DeviceDetail,
    DiagnosticData,
    DiagnosticReportResponse,
    DiagnosticSummary,
    BuyBackAndTradeIn,
    ErasureData,
    Estimate,
} from "domain/reports";
import { apiGatewayService } from "../../api/ApiGatewayService";
import { DiagnosticPath } from "./DiagnosticPath";
import { getOliverUrl } from "services/login/endpointRepository";
import { getLanguage } from "services/language/languageRepository";

interface RequestData {
    cursor?: string[];
    paths: string[];
    size: number;
    search?: string;
    owned: boolean;
    type: string;
}

interface Entry {
    path: string;
    values: string[];
}

interface Report {
    uuid: string;
    date: string;
    entries: Entry[];
}

interface ReportResponse {
    count: bigint;
    total: number;
    cursor: string[];
    reports: Report[];
}

interface Reports {
    erasureData: ErasureData[];
    cursor: string[];
    total: number;
}

interface DiagnosticDataReports {
    reportData: DiagnosticData[];
    cursor: string[];
    total: number;
}

interface DeviceDetailDto {
    id?: number;
    make?: string;
    model?: string;
    color?: string;
    imei?: string;
    carrier?: string;
    capacity?: string;
    os?: string;
    location?: string;
    timezone?: string;
    software_version?: string;
    tag_code?: string;
}

interface DiagnosticReport {
    PASSED: string[];
    FAILED: string[];
    SKIPPED: string[];
    INDETERMINATE: string[];
    REPORT_DATE: string;
    "Overall-Result": string;
}

interface QuoteDto {
    date: string;
    trade_id: string;
    eligible: boolean;
    price: number;
    expiration: string;
    currency: string;
    channel: string;
}

interface BuyBackAndTradeInDto {
    estimate?: Estimate;
    quote?: QuoteDto;
}

interface DiagnosticsReportDto {
    uuid: string;
    blancco_asset_id: string;
    journey_id: string;
    tenant_uuid: string;
    asset_properties: DeviceDetailDto;
    report: DiagnosticReport;
    bbti: BuyBackAndTradeInDto;
}

enum Path {
    UUID = "report.uuid",
    PRODUCT_ID = "report.product_id",
    ERASURE_STATE = "blancco_erasure_report.erasures.erasure.state",
    ERASURE_TIMESTAMP = "blancco_erasure_report.erasures.erasure.timestamp",
    DISK_CAPACITY = "blancco_hardware_report.disks.disk.capacity",
    MEMORY_BANK_CAPACITY = "blancco_hardware_report.memory.memory_bank.capacity",
    DISK_SERIAL = "blancco_hardware_report.disks.disk.serial",
    VERIFIED = "blancco_data.description.description_entries.verified",
    MEMORY_BANK_CAPACITY_BMDE = "blancco_hardware_report.system.ram",
}

const PRODUCTS: Map<number, string> = new Map([
    [0, "Blancco Demo"],
    [1, "Blancco Asset Manager"],
    [2, "BMDE - Device Image Validation"],
    [3, "Blancco PC Edition"],
    [4, "Blancco Server Edition"],
    [5, "Blancco HMG"],
    [6, "Blancco Network Device Eraser"],
    [7, "Blancco Stealth (Erasure)"],
    [8, "Blancco Mobile"],
    [9, "Blancco Data Centre Edition"],
    [10, "Blancco Profiler"],
    [11, "Blancco File Eraser"],
    [12, "Blancco Removable Media Eraser"],
    [13, "Blancco SPARC"],
    [14, "Intelligent Business Routing - Mobile Erasure and Diagnostics Workflows"],
    [15, "Intelligent Business Routing - Drive Eraser Workflows"],
    [16, "BMDE - Carrier ID Check"],
    [17, "BMDE - FMiP Check"],
    [18, "Blancco Drive Eraser - High End Drive (per drive)"],
    [19, "Blancco PC Edition (per HDD)"],
    [20, "Blancco Volume Edition (per HDD)"],
    [21, "Blancco HMG (per HDD)"],
    [22, "Blancco Mobile Diagnostics"],
    [23, "Blancco Mobile Reporting Tool"],
    [24, "Blancco Mobile Diagnostics and Erasure - Carrier Insights Extension"],
    [25, "Blancco Data Centre Edition (per HDD)"],
    [26, "Blancco LUN Eraser"],
    [29, "Blancco SPARC (per HDD)"],
    [30, "Blancco Mobile Diagnostics and Erasure"],
    [31, "Blancco Mobile Diagnostics and Erasure - Asset Report"],
    [32, "Blancco Mobile Diagnostics and Erasure - License Reuse"],
    [33, "Blancco Mobile Diagnostics - Integration"],
    [34, "Blancco Mobile Diagnostics and Erasure - Diagnostics"],
    [35, "Blancco Mobile Diagnostics and Erasure - Activation Lock"],
    [36, "Blancco Drive Eraser - Enterprise Volume Edition"],
    [37, "Blancco HMG (per GB)"],
    [39, "Blancco Virtual Machine Eraser"],
    [41, "Blancco Data Centre Edition (per GB)"],
    [42, "Blancco Check For FeliCa"],
    [43, "Blancco Mobile For FeliCa"],
    [45, "Blancco SPARC (per GB)"],
    [46, "Blancco Mobile Diagnostics and Erasure - Enterprise Edition"],
    [47, "Blancco Evaluation Erasure Report"],
    [48, "Degausser"],
    [49, "Blancco PreInstall"],
    [50, "Blancco Drive Eraser"],
    [51, "Blancco Management Console"],
]);

const BMSPRODUCTS: Map<string, string> = new Map([
    ["validation", "Blancco Mobile Solutions - Validation"],
    ["bbti", "Blancco Mobile Solutions - Buy-Back / Trade In"],
]);

function toDiagnosticReport(dto: DiagnosticsReportDto): DiagnosticReportResponse {
    const deviceDetail: DeviceDetail = {
        id: dto.blancco_asset_id,
        capacity: dto.asset_properties.capacity,
        carrier: dto.asset_properties.carrier,
        color: dto.asset_properties.color,
        imei: dto.asset_properties.imei,
        location: dto.asset_properties.location,
        make: dto.asset_properties.make,
        model: dto.asset_properties.model,
        operatingSystem: dto.asset_properties.os,
        softwareVersion: dto.asset_properties.software_version,
        timezone: dto.asset_properties.timezone,
        tagCode: dto.asset_properties.tag_code,
    };

    const quoteData =
        typeof dto.bbti !== "undefined" && typeof dto.bbti.quote !== "undefined"
            ? {
                  eligible: dto.bbti.quote.eligible,
                  price: dto.bbti.quote.price,
                  expiration: dto.bbti.quote.expiration,
                  date: dto.bbti.quote.date,
                  tradeId: dto.bbti.quote.trade_id,
                  currency: dto.bbti.quote.currency,
                  channel: dto.bbti.quote.channel,
              }
            : undefined;

    const buybackTradein: BuyBackAndTradeIn = {
        estimate: typeof dto.bbti !== "undefined" ? dto.bbti.estimate : undefined,
        quote: quoteData,
    };

    const diagnosticSummary: DiagnosticSummary[] = [];
    dto.report.PASSED.map((test) => {
        diagnosticSummary.push({
            test: test,
            pass: 1,
            fail: 0,
            skip: 0,
            indeterminate: 0,
        });
    });
    dto.report.FAILED.map((test) => {
        diagnosticSummary.push({
            test: test,
            pass: 0,
            fail: 1,
            skip: 0,
            indeterminate: 0,
        });
    });
    dto.report.SKIPPED.map((test) => {
        diagnosticSummary.push({
            test: test,
            pass: 0,
            fail: 0,
            skip: 1,
            indeterminate: 0,
        });
    });
    dto.report.INDETERMINATE.map((test) => {
        diagnosticSummary.push({
            test: test,
            pass: 0,
            fail: 0,
            skip: 0,
            indeterminate: 1,
        });
    });
    diagnosticSummary.sort((a, b) => (a.test > b.test ? 1 : -1));
    return {
        deviceDetail,
        diagnosticSummary,
        buybackTradein,
    };
}

// TODO BCC-580 Split into ErasureReportService and DiagnosticsReportService
class ReportService {
    private static toErasureData(report: Report): ErasureData {
        const pathToEntryValues: Map<Path, string[]> = new Map();
        report.entries.forEach((entry) => pathToEntryValues.set(<Path>entry.path, entry.values));
        return {
            uuid: this.toCommaSeparatedString(pathToEntryValues.get(Path.UUID)),
            product: this.toCommaSeparatedString(pathToEntryValues.get(Path.PRODUCT_ID), (value) => {
                const intValue = parseInt(value);
                return PRODUCTS.has(intValue) ? (PRODUCTS.get(intValue) as string) : "";
            }),
            availableRam: this.toCommaSeparatedString(
                pathToEntryValues.get(Path.MEMORY_BANK_CAPACITY)?.length != 0
                    ? pathToEntryValues.get(Path.MEMORY_BANK_CAPACITY)
                    : pathToEntryValues.get(Path.MEMORY_BANK_CAPACITY_BMDE)
            ),
            diskCapacity: this.toCommaSeparatedString(pathToEntryValues.get(Path.DISK_CAPACITY)),
            diskSerial: this.toCommaSeparatedString(pathToEntryValues.get(Path.DISK_SERIAL)),
            erasureDate: pathToEntryValues.get(Path.ERASURE_TIMESTAMP) || [],
            erasureStatus: pathToEntryValues.get(Path.ERASURE_STATE) || [],
            reportVerification: pathToEntryValues.get(Path.VERIFIED) || [],
        };
    }

    private static toDiagnosticData(report: Report): DiagnosticData {
        const pathToEntryValues: Map<DiagnosticPath, string[]> = new Map();
        report.entries.forEach((entry) => pathToEntryValues.set(<DiagnosticPath>entry.path, entry.values));
        return {
            feature: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.JOURNEY_TYPE), (value) => {
                return BMSPRODUCTS.has(value) ? (BMSPRODUCTS.get(value) as string) : "";
            }),
            audioEarpiece: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.AUDIO_EARPIECE)),
            audioHeadphone: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.AUDIO_HEADPHONE)),
            audioHeadsetMicrophone: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.AUDIO_HEADSET_MICROPHONE)
            ),
            audioMicrophone: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.AUDIO_MICROPHONE)),
            audioMicrophoneRecording: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.AUDIO_MICROPHONE_RECORDING)
            ),
            audioNoiseCancellation: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.AUDIO_NOISE_CANCELLATION)
            ),
            audioSpeaker: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.AUDIO_SPEAKER)),
            audioSpeakerMicrophone: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.AUDIO_SPEAKER_MICROPHONE)
            ),
            batteryCharge: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BATTERY_CHARGE)),
            batteryChargeHold: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BATTERY_CHARGE_HOLD)),
            batteryDrain: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BATTERY_DRAIN)),
            batteryHealth: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BATTERY_HEALTH)),
            batteryTemperature: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BATTERY_TEMPERATURE)),
            buttonBack: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_BACK)),
            buttonHome: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_HOME)),
            buttonMute: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_MUTE)),
            buttonPower: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_POWER)),
            buttonRecentApp: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_RECENT_APP)),
            buttonSide: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_SIDE)),
            buttonVolumeDown: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_VOLUME_DOWN)),
            buttonVolumeUp: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.BUTTON_VOLUME_UP)),
            cameraBack: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_BACK)),
            cameraBackAuto: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_BACK_AUTO)),
            cameraBackFlash: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_BACK_FLASH)),
            cameraBackVideo: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_BACK_VIDEO)),
            cameraFront: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_FRONT)),
            cameraFrontAuto: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_FRONT_AUTO)),
            cameraFrontFlash: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_FRONT_FLASH)),
            cameraFrontVideo: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_FRONT_VIDEO)),
            cameraMultiCheck: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.CAMERA_MULTI_CHECK)),
            carrierSignalCheckOne: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.CARRIER_SIGNAL_CHECK_ONE)
            ),
            carrierSignalCheckTwo: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.CARRIER_SIGNAL_CHECK_TWO)
            ),
            detectBluetooth: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DETECT_BLUETOOTH)),
            detectNfc: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DETECT_NFC)),
            detectSim: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DETECT_SIM)),
            deviceAutoRotate: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_AUTO_ROTATE)),
            deviceFmRadio: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_FM_RADIO)),
            deviceLcdBacklight: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_LCD_BACKLIGHT)),
            deviceOtg: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_OTG)),
            devicePerformance: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_PERFORMANCE)),
            deviceSdCard: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_SD_CARD)),
            deviceUsb: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_USB)),
            deviceVibration: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DEVICE_VIBRATION)),
            displayBlackInkSpots: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.DISPLAY_BLACK_INK_SPOTS)
            ),
            displayColouredVerticalLines: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.DISPLAY_COLOURED_VERTICAL_LINES)
            ),
            displayCrack: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_CRACK)),
            displayDragGesture: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_DRAG_GESTURE)),
            displayGhostImages: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_GHOST_IMAGES)),
            displayLcdColor: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_LCD_COLOR)),
            displayLcdPink: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_LCD_PINK)),
            displayLcdStarring: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_LCD_STARRING)),
            displayMultiTouch: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_MULTI_TOUCH)),
            displayPixel: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_PIXEL)),
            displayPressure: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_PRESSURE)),
            displayPurpleInkStain: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.DISPLAY_PURPLE_INK_STAIN)
            ),
            displayTouch: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.DISPLAY_TOUCH)),
            enclosureBack: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.ENCLOSURE_BACK)),
            faceId: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.FACE_ID)),
            journeyId: parseInt(this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.JOURNEY_ID))),
            liveCall: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.LIVE_CALL)),
            longAssisstedQuickCheck: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.LONG_ASSISSTED_QUICK_CHECK)
            ),
            longGroupTestQuickCheck: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.LONG_GROUP_TEST_QUICK_CHECK)
            ),
            longStress: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.LONG_STRESS)),
            make: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.MAKE)),
            model: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.MODEL)),
            networkCellular: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.NETWORK_CELLULAR)),
            networkWifi: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.NETWORK_WIFI)),
            passcodeStatus: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.PASSCODE_STATUS)),
            sensorAccelerometer: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.SENSOR_ACCELEROMETER)
            ),
            sensorAltimeter: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_ALTIMETER)),
            sensorBarometer: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_BAROMETER)),
            sensorCompass: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_COMPASS)),
            sensorFingerprint: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_FINGERPRINT)),
            sensorGyroscope: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_GYROSCOPE)),
            sensorHall: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_HALL)),
            sensorHeartRate: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_HEART_RATE)),
            sensorInfrared: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_INFRARED)),
            sensorIris: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_IRIS)),
            sensorLight: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_LIGHT)),
            sensorMagnetometer: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_MAGNETOMETER)),
            sensorProximity: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SENSOR_PROXIMITY)),
            sensorThreeDimenTouch: this.toCommaSeparatedString(
                pathToEntryValues.get(DiagnosticPath.SENSOR_THREE_DIMEN_TOUCH)
            ),
            signalAgps: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SIGNAL_AGPS)),
            signalGps: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.SIGNAL_GPS)),
            testPassRate: this.toCommaSeparatedString(pathToEntryValues.get(DiagnosticPath.TEST_PASS_RATE)),
            uuid: report.uuid,
            reportDate: report.date,
        };
    }

    private static toCommaSeparatedString(
        values: string[] = [],
        callback: (value: string) => string = (value) => value
    ): string {
        return values.map((value) => callback(value)).join(", ");
    }

    public fetchReports(
        owned: boolean,
        reportType: string,
        abortController?: AbortController,
        cursor?: string[],
        search?: string,
        refreshSession?: boolean
    ): Promise<Reports> {
        refreshSession = refreshSession ?? true;

        const requestData: RequestData = {
            size: 100,
            paths: [
                Path.UUID,
                Path.PRODUCT_ID,
                Path.ERASURE_STATE,
                Path.ERASURE_TIMESTAMP,
                Path.DISK_CAPACITY,
                Path.MEMORY_BANK_CAPACITY,
                Path.DISK_SERIAL,
                Path.VERIFIED,
                Path.MEMORY_BANK_CAPACITY_BMDE,
            ],
            cursor,
            owned,
            type: reportType,
        };
        if (search != null && search.trim() !== "") {
            requestData.search = search.trim();
        }
        const url = getOliverUrl() + "/reports/view";

        const init = <RequestInit>{
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
            signal: abortController !== undefined ? abortController.signal : undefined,
        };

        return fetch(url, init)
            .then((response) => {
                if (refreshSession && [401, 403].includes(response.status)) {
                    return apiGatewayService
                        .invokeApi("/authentication/refresh", "get", null, {
                            abortController,
                            refreshSession: false,
                        })
                        .then(() => {
                            return fetch(url, init);
                        })
                        .catch(() => {
                            window.location.replace("/logout");
                        });
                }
                return response;
            })
            .then((response) => {
                if (response) {
                    return response.json() as Promise<ReportResponse>;
                }
            })
            .then((reportResponse: ReportResponse) => ({
                cursor: reportResponse.cursor,
                erasureData: reportResponse.reports.map((report) => ReportService.toErasureData(report)),
                total: reportResponse.total,
            }));
    }

    public fetchDiagnosticsReports(
        owned: boolean,
        reportType: string,
        abortController?: AbortController,
        cursor?: string[],
        search?: string,
        refreshSession?: boolean
    ): Promise<DiagnosticDataReports> {
        refreshSession = refreshSession ?? true;

        const requestData: RequestData = {
            size: 100,
            paths: [
                DiagnosticPath.JOURNEY_TYPE,
                DiagnosticPath.AUDIO_EARPIECE,
                DiagnosticPath.CAMERA_FRONT,
                DiagnosticPath.JOURNEY_ID,
                DiagnosticPath.MAKE,
                DiagnosticPath.MODEL,
                DiagnosticPath.TEST_PASS_RATE,
            ],
            cursor,
            owned,
            type: reportType,
        };
        if (search != null && search.trim() !== "") {
            requestData.search = search.trim();
        }

        const url = getOliverUrl() + "/reports/view";

        const init = <RequestInit>{
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
            signal: abortController !== undefined ? abortController.signal : undefined,
        };
        return fetch(url, init)
            .then((response) => {
                if (refreshSession && [401, 403].includes(response.status)) {
                    return apiGatewayService
                        .invokeApi("/authentication/refresh", "get", null, {
                            abortController,
                            refreshSession: false,
                        })
                        .then(() => {
                            return fetch(url, init);
                        })
                        .catch(() => {
                            window.location.replace("/logout");
                        });
                }
                return response;
            })
            .then((response) => {
                if (response) {
                    return response.json() as Promise<ReportResponse>;
                }
            })
            .then((reportResponse: ReportResponse) => ({
                cursor: reportResponse.cursor,
                reportData: reportResponse.reports.map((report) => ReportService.toDiagnosticData(report)),
                total: reportResponse.total,
            }));
    }

    // TODO : BCC-523 refactoring the fetch report functions

    public diagnosticReports(
        uuid: string,
        abortController?: AbortController,
        refreshSession?: boolean
    ): Promise<DiagnosticReportResponse> {
        refreshSession = refreshSession ?? true;
        const url = getOliverUrl() + "/reports/diagnostics/" + uuid;
        const init = <RequestInit>{
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            signal: abortController !== undefined ? abortController.signal : undefined,
        };
        return fetch(url, init)
            .then((response) => {
                if (refreshSession && [401, 403].includes(response.status)) {
                    return apiGatewayService
                        .invokeApi("/authentication/refresh", "get", null, {
                            abortController,
                            refreshSession: false,
                        })
                        .then(() => {
                            return fetch(url, init);
                        })
                        .catch(() => {
                            window.location.replace("/logout");
                        });
                }
                return response;
            })
            .then((response: Response) => response.json())
            .then((dto: DiagnosticsReportDto) => toDiagnosticReport(dto));
    }

    public exportDiagnosticReport(reportUuid: string): Promise<void | Response> {
        const languageCode = getLanguage().code;
        const abortController = new AbortController();
        const url = getOliverUrl() + "/reports/diagnostics/" + reportUuid + "?language=" + languageCode + "&format=csv";
        const init = <RequestInit>{
            method: "GET",
            credentials: "include",
            headers: {},
            signal: abortController.signal,
        };
        return fetch(url, init).then((response) => {
            if ([401, 403].includes(response.status)) {
                return apiGatewayService
                    .invokeApi("/authentication/refresh", "get", null, {
                        abortController,
                        refreshSession: false,
                    })
                    .then(fetch)
                    .catch(() => {
                        window.location.replace("/logout");
                    });
            }
            window.location.href = url;
        });
    }
}

export const reportService = new ReportService();

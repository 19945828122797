import { Layouts } from "react-grid-layout";

import { getObject, setObject, RepositoryKey } from "services/utils/repository";

export function getCardOrderLayout(): Layouts | null {
    return getObject(RepositoryKey.DASHBOARD_CARD_LAYOUT);
}

export function setCardOrderLayout(layouts: Layouts): void {
    setObject(RepositoryKey.DASHBOARD_CARD_LAYOUT, layouts);
}

//For ESG Dashboard

export function getESGCardOrderLayout(): Layouts | null {
    return getObject(RepositoryKey.ESG_DASHBOARD_CARD_LAYOUT);
}

export function setESGCardOrderLayout(layouts: Layouts): void {
    setObject(RepositoryKey.ESG_DASHBOARD_CARD_LAYOUT, layouts);
}

import * as React from "react";
import style from "./layout.scss";
import { getTenantName, removeTenantCookie } from "services/tenants/tenantCookieService";
import { useTranslation } from "react-i18next";
import { AccessTenantDetailsLink } from "./AccessTenantDetailsLink";
import { restoreUrls } from "services/login/endpointRepository";

export const DropTenantAccessBanner = (): JSX.Element => {
    const { t } = useTranslation();
    const dropAccessHandler = () => {
        removeTenantCookie();
        restoreUrls();
        window.location.replace("/");
    };
    return (
        <>
            <div className={style.tenantAccessLinkAlignment}>
                <AccessTenantDetailsLink />
            </div>
            <div className={style.dropTenantAlignment}>
                {t("DropTenantAccessBanner.message", { customerName: getTenantName() })}
                <a href="#" onClick={() => dropAccessHandler()}>
                    {t("DropTenantAccessBanner.dropAccess")}
                </a>
            </div>
        </>
    );
};

import * as React from "react";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";
import DateCell from "components/table/DateCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { ApiKeysData } from "domain/apiKeys";
import { RepositoryKey } from "services/utils/repository";
import { apiKeysService } from "services/api-keys/ApiKeysService";

interface TableState {
    apiKeysData: ApiKeysData[];
}
export function AllApiKeysTable(props: { initialApiKeyData: ApiKeysData[] }): JSX.Element {
    const { t } = useTranslation();

    const columns: Array<Column<ApiKeysData>> = [
        {
            Header: () => <TextWithTooltip text={t("ApiKeys.name")} key="1" />,
            accessor: "name",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("ApiKeys.description")} key="2" />,
            accessor: "description",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("ApiKeys.creationDate")} key="3" />,
            accessor: "created",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("ApiKeys.owner")} key="4" />,
            accessor: "owner",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
    ];

    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [tableState, setTableState] = React.useState<TableState>({ apiKeysData: props.initialApiKeyData });
    const fetchData = (abortController: AbortController) => {
        setInitialLoading(true);
        apiKeysService
            .fetchApiKeys(false, abortController)
            .then((data) => {
                setTableState((prevState) => ({
                    ...prevState,
                    apiKeysData: data.sort(
                        (a, b) => a.name.localeCompare(b.name) || a.created.localeCompare(b.created)
                    ),
                }));
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("ApiKeys.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        if (props.initialApiKeyData.length > 0) {
            return;
        }
        const abortController = new AbortController();
        fetchData(abortController);

        return () => {
            abortController.abort();
        };
    }, []);

    return (
        <>
            <Table
                tableIdentity={RepositoryKey.ALL_API_KEYS_TABLE}
                data={tableState.apiKeysData}
                columns={columns}
                loaded={!initialLoading}
                failureMessage={requestFailureMessage}
                tooltips={true}
                emptyMessage={t("ApiKeys.emptyStateMessage")}
            />
        </>
    );
}

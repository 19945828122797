import * as React from "react";
import style from "./api-key-detail-view.scss";
import { useTranslation } from "react-i18next";
import testIds from "testIds.json";
import { ApiKeyDetail } from "domain/apiKeys";
import { createDateLabel } from "services/utils/format";
import PlainCopy from "components/icons/PlainCopy";
import Tooltip from "components/tooltip/Tooltip";

export const ApiKeyDetailView = (props: { activeApiKey: ApiKeyDetail }): JSX.Element => {
    const { t } = useTranslation();
    const [copied, setCopied] = React.useState(false);

    function copyToClipboard() {
        navigator.clipboard.writeText(props.activeApiKey.uuid);
        setCopied(true);
    }

    return (
        <div className={style.dialogBox}>
            <div className={style.standardModalTitle}>{t("ApiKeysProfile.details")}</div>
            <div className={style.apiKeyProfile}>
                <div className={style.label}>{t("ApiKeysProfile.name")}</div>
                <div data-testid={testIds.workArea.settings.apiKeys.viewApiKeyDialog.nameLabel}>
                    {props.activeApiKey.name}
                    <Tooltip content={copied ? t("Common.copied") : t("Common.copyToClipboard")}>
                        <div
                            onClick={copyToClipboard}
                            className={style.plainCopyIcon}
                            data-testid={testIds.workArea.settings.apiKeys.viewApiKeyDialog.copyButton}
                            onMouseLeave={() => {
                                setCopied(false);
                            }}
                        >
                            <PlainCopy />
                        </div>
                    </Tooltip>
                </div>

                {props.activeApiKey.description === "" ? (
                    ""
                ) : (
                    <>
                        <div className={style.label}>{t("ApiKeysProfile.description")}</div>
                        <div data-testid={testIds.workArea.settings.apiKeys.viewApiKeyDialog.descriptionLabel}>
                            {props.activeApiKey.description}
                        </div>
                    </>
                )}
                <div className={style.label}>{t("ApiKeysProfile.creationDate")}</div>
                <div data-testid={testIds.workArea.settings.apiKeys.viewApiKeyDialog.creationDateLabel}>
                    {createDateLabel(props.activeApiKey.created)}
                </div>
            </div>
        </div>
    );
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icons/infoLightMode.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/icons/infoDarkMode.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "._1zaE6pmG0T5WME8ptWYVVn{width:19px;height:19px;background-image:var(--path);margin-left:5px;margin-bottom:-6px;display:inline-block}.defaultTheme ._1zaE6pmG0T5WME8ptWYVVn{--path:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.tenantTheme ._1zaE6pmG0T5WME8ptWYVVn{--path:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.defaultDarkTheme ._1zaE6pmG0T5WME8ptWYVVn{--path:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.tenantDarkTheme ._1zaE6pmG0T5WME8ptWYVVn{--path:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
exports.locals = {
	"activeBackgroundColor": "#db2828",
	"activeForegroundColor": "#db2828",
	"primaryButtonBackgroundColor": "#db2828",
	"secondaryButtonForegroundColor": "#f46464",
	"bannerBackgroundColor": "rgba(219,40,40,.5)",
	"linkTextColor": "#f46464",
	"primaryButtonBackgroundHoverColor": "#f46464",
	"secondaryButtonBackgroundHoverColor": "#f0f0f0",
	"destructiveButtonBackgroundHoverColor": "#f46464",
	"primaryButtonBackgroundActiveColor": "#f46464",
	"secondaryButtonBackgroundActiveColor": "#f7f7f7",
	"destructiveButtonBackgroundColor": "#f09191",
	"chartNegativeColor": "#f09191",
	"chartPositiveColor": "#5fe3a1",
	"chartNeutralColor": "#cecece",
	"errorBackgroundColor": "#f46464",
	"errorBorderColor": "#f46464",
	"errorTextColor": "#f46464",
	"warningBackgroundColor": "#f2711c",
	"warningBorderColor": "#f2711c",
	"warningTextColor": "#f2711c",
	"secondaryWarningBackgroundColor": "#ffc915",
	"secondaryWarningBorderColor": "#ffc915",
	"secondaryWarningTextColor": "#ffc915",
	"successBorderColor": "#5fe3a1",
	"successTextColor": "#5fe3a1",
	"pageBackgroundColor": "#222",
	"wrapperBackgroundColor": "#f2c5c5",
	"wrapperBorderColor": "#f09191",
	"modelBoxBackgroundColor": "#4c4c4c",
	"contentBackgroundColor": "#333",
	"secondaryButtonBackgroundColor": "#333",
	"textColor": "#fff",
	"primaryButtonForegroundColor": "#fff",
	"bannerForegroundColor": "#4c4c4c",
	"chartOutlineColor": "#222",
	"modalOverlayColor": "rgba(34,34,34,.4)",
	"contentDividerColor": "#222",
	"contentBorderColor": "#111",
	"disabledButtonBackgroundColor": "#222",
	"disabledButtonForegroundColor": "#4c4c4c",
	"inputHintColor": "#cecece",
	"inputBackgroundColor": "#4c4c4c",
	"hoverBackgroundColor": "#111",
	"toggleCheckedBackgroundColor": "#91b9f0",
	"toggleUncheckedBackgroundColor": "#cecece",
	"toggleForegroundColor": "#fff",
	"mapForegroundColor": "#f0f0f0",
	"mapBackgroundColor": "#222",
	"emptyStateEllipseBackgroundColor": "#111",
	"emptyStateBiggestCircleBackgroundColor": "#3c3960",
	"emptyStateSmallestCircleBackgroundColor": "#111",
	"emptyStatePersonColor": "#4a4a52",
	"defaultTheme": "defaultTheme",
	"tenantTheme": "tenantTheme",
	"defaultDarkTheme": "defaultDarkTheme",
	"tenantDarkTheme": "tenantDarkTheme",
	"info": "_1zaE6pmG0T5WME8ptWYVVn"
};
module.exports = exports;

import * as React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Modal from "components/modal/Modal";
import buttons from "styles/buttons.scss";
import style from "./generate-api-key.scss";
import GenerateApiKeyForm, { FormValues } from "./GenerateApiKeyForm";
import { apiKeysService } from "services/api-keys/ApiKeysService";
import { GenerateApiKeyResponse } from "domain/apiKeys";
import PlainCopy from "components/icons/PlainCopy";
import Tooltip from "components/tooltip/Tooltip";

interface Result {
    title: string;
    message: string | JSX.Element;
    showingCopy: boolean;
}

const GenerateApiKeyView = (props: { onApiKeyGenerated: () => void }): JSX.Element => {
    const { t } = useTranslation();
    const [result, setResult] = React.useState<Result>({
        title: "",
        message: "",
        showingCopy: false,
    });
    const [generateApiKeyFormVisible, setGenerateApiKeyFormVisible] = React.useState(false);
    const [resultVisible, setResultVisible] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [apiKeyResponse, setApiKeyResponse] = React.useState("");
    const form = React.useRef<GenerateApiKeyForm>(null);

    React.useEffect(() => {
        return () => abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort());
    });

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };

    const [copied, setCopied] = React.useState(false);

    function copyToClipboard() {
        navigator.clipboard.writeText(apiKeyResponse);
        setCopied(true);
    }

    const addApikeySubmitEventHandler = async ({ name, description }: FormValues): Promise<void> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            const generateApiKeyResponse: GenerateApiKeyResponse = await apiKeysService.generateApiKey(
                name,
                description,
                abortController
            );
            setApiKeyResponse(generateApiKeyResponse.apiKey);
        } catch (e) {
            if (!signal.aborted) {
                setGenerateApiKeyFormVisible(false);
                showResult({
                    title: t("GenerateApiKeyView.failureTitle"),
                    message: t("GenerateApiKeyView.failureMessage"),
                    showingCopy: false,
                });
                form.current?.setLoading(false);
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        setGenerateApiKeyFormVisible(false);
        showResult({
            title: t("GenerateApiKeyView.successTitle"),
            message: t("GenerateApiKeyView.successMessage", { name }),
            showingCopy: true,
        });
    };

    const hideSuccessDialog = () => {
        setResultVisible(false);
        props.onApiKeyGenerated();
    };

    return (
        <div>
            <button
                className={classNames(buttons.primaryButton, buttons.small)}
                onClick={() => setGenerateApiKeyFormVisible(true)}
            >
                {t("GenerateApiKeyView.generateApiKeyButton")}
            </button>
            <Modal
                isOpen={generateApiKeyFormVisible}
                hideModal={() => setGenerateApiKeyFormVisible(false)}
                modalTitle={t("GenerateApiKeyView.title")}
            >
                <GenerateApiKeyForm
                    closeHandler={() => setGenerateApiKeyFormVisible(false)}
                    submitEventHandler={addApikeySubmitEventHandler}
                    ref={form}
                />
            </Modal>
            <Modal isOpen={resultVisible} hideModal={hideSuccessDialog} modalTitle={result.title}>
                <div className={style.successMessageContainer}>
                    {result.message}
                    <Tooltip content={copied ? t("Common.copied") : t("Common.copyToClipboard")}>
                        <div
                            className={style.plainCopyIcon}
                            style={{ display: result.showingCopy ? "inline-block" : "none" }}
                            onClick={copyToClipboard}
                            onMouseLeave={() => {
                                setCopied(false);
                            }}
                        >
                            <PlainCopy />
                        </div>
                    </Tooltip>
                </div>
                <div className={style.successButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, style.successButton)}
                        onClick={hideSuccessDialog}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};
export default GenerateApiKeyView;

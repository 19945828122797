import * as React from "react";

const Export = (props: { color: string }): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.217" height="18.384" viewBox="0 0 22.217 18.384">
        <g id="exportCoud" transform="translate(1.144 1.02)">
            <path
                id="Path_1880"
                data-name="Path 1880"
                d="M8,17l4,4,4-4"
                transform="translate(-1.997 -4.636)"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <line
                id="Line_750"
                data-name="Line 750"
                y2="9"
                transform="translate(10.003 7.364)"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                id="Path_1881"
                data-name="Path 1881"
                d="M19.061,16.443a4.408,4.408,0,0,0,1.717-4.989,4.534,4.534,0,0,0-4.334-3.109H15.3A7.225,7.225,0,0,0,9.091,3.035,7.3,7.3,0,0,0,1.814,6.821a7.019,7.019,0,0,0,1,8.018"
                transform="translate(-0.99 -2.988)"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </g>
    </svg>
);

export default Export;

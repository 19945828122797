import * as React from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/modal/Modal";
import { tenantService } from "services/tenants/TenantService";
import { Tenant } from "domain/tenants";
import { ViewTenantDetails } from "components/customer-details/ViewTenantDetails";
import { getTenantName, getTenantUuid } from "services/tenants/tenantCookieService";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";

export const AccessTenantDetailsLink = (): JSX.Element => {
    const { t } = useTranslation();
    const [tenantProfileVisibility, setTenantProfileVisibility] = React.useState(false);
    const [resultModalVisibility, setResultModalVisibility] = React.useState(false);
    const [tenantDetails, setTenantDetails] = React.useState<Tenant>();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const fetchData = () => {
        setTenantProfileVisibility(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        tenantService
            .fetchTenant(getTenantUuid(), abortController)
            .then((tenantDetails) => {
                setTenantDetails(tenantDetails);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setTenantProfileVisibility(false);
                    setResultModalVisibility(true);
                }
            });
    };

    const child = tenantDetails != null ? <ViewTenantDetails activeTenant={tenantDetails} /> : <LoadingIndicator />;
    const modal = (
        <Modal
            key={1}
            isOpen={tenantProfileVisibility}
            hideModal={() => setTenantProfileVisibility(false)}
            modalTitle={t("TenantProfile.title", { tenantName: getTenantName() })}
        >
            {child}
        </Modal>
    );

    const resultModal = (
        <Modal
            key={2}
            isOpen={resultModalVisibility}
            hideModal={() => setResultModalVisibility(false)}
            modalTitle={t("TenantProfile.title", { tenantName: getTenantName() })}
        >
            {t("TenantProfile.requestFailed", { tenantName: getTenantName() })}
        </Modal>
    );

    return (
        <>
            <a href="#" onClick={() => fetchData()}>
                {t("DropTenantAccessBanner.viewTenantDetails")}
            </a>
            {modal}
            {resultModal}
        </>
    );
};

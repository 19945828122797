import ReactGA from "react-ga";
import { store } from "store";
import * as LanguageRepository from "services/language/languageRepository";

const UST_ID = process.env.UST_ID ? process.env.UST_ID : "";
const LANGUAGE_CODE = LanguageRepository.getLanguage().code;

try {
    ReactGA.initialize(UST_ID, {
        titleCase: false,
    });
    ReactGA.ga("set", "anonymizeIp", true);
    ReactGA.ga("set", "language", LANGUAGE_CODE.toLowerCase());
} catch (e) {
    console.error("Exception occurred while initializing GA: ", e);
}

export interface Event {
    category: string;
    action: string;
    label?: string;
}

export enum Action {
    // Header actions
    CHANGE_LANGUAGE = "change language",
    VIEW_USER_PROFILE = "view user profile",
    VIEW_USER_SETTINGS = "view user settings",
    SWITCH_USER_THEME = "switch user theme",
    VIEW_TERMS_AND_CONDITIONS = "view terms and conditions",
    DOWNLOAD_TERMS_AND_CONDITIONS = "download terms and conditions",
    LOGOUT = "logout",

    // Licenses view actions
    RESET_LICENSE_CONFIGURATION = "reset license configuration",
    VIEW_LICENSE_CONFIGURATION = "view license configuration",
    EDIT_LICENSE_CONFIGURATION = "edit license configuration",
    ADD_LICENSE_CONFIGURATION = "add license configuration",
    ASSIGN_LICENSE_KEY = "assign license keys",
    CHANGE_LICENSE_KEY_IDENTIFIER = "change license key identifier",
    CHANGE_LICENSE_KEY_USAGE_HOURS = "change license key usage hours",
    CHANGE_NUMBER_OF_LICENSE_KEYS = "change number of license keys",
    CHANGE_LICENSE_KEY_START_DATE = "change license key start date",
    CHANGE_LICENSE_KEY_END_DATE = "change license key end date",
    CHANGE_LICENSE_KEY_ASSIGNED_COUNT = "change license key assigned count",
    EXPORT_LICENSE_KEYS = "export license keys",

    // Reports view actions
    ADJUST_COLUMN_WIDTH = "adjust column width",
    EXPORT_DIAGNOSTIC_REPORT = "export diagnostic report",
    EXPORT_ERASURE_REPORT = "export erasure report",
    EXECUTE_QUICK_SEARCH = "execute quick search",
    LOAD_MORE = "load more",
    VIEW_REPORT_PROPERTIES = "view report properties",
    VIEW_DEVICE_DETAILS = "view device details",
    VIEW_DIAGNOSTIC_SUMMARY = "view diagnostic summary",
    VIEW_DIAGNOSTIC_BUYBACK_TRADEIN = "buy-back / trade-in",

    // Tenants view actions
    ADD_TENANT = "add tenant",
    VIEW_TENANT_DETAILS = "view tenant details",
    ACCESS_TENANT = "access tenant",
    EDIT_TENANT = "edit tenant",
    ENABLE_TENANT = "enable tenant",

    // Users view actions
    ADD_USER = "add user",
    DELETE_USER = "delete user",

    //API keys view action
    DELETE_API_KEY = "delete api key",
    EDIT_API_KEY = "edit api key",

    // Workflow actions
    SET_DEFAULT_WORKFLOW = "set default workflow",
}

export enum Category {
    API_KEYS = "api keys",
    HEADER = "header",
    LICENSE = "license",
    REPORTS = "reports",
    TENANT = "tenant",
    USER = "user",
    WORKFLOW = "workflow",
}

export enum Label {
    ERASURE = "erasure",
    DIAGNOSTIC = "diagnostic",
}

class UsageStatisticsService {
    public static isEnabled(): boolean {
        const storeElement = store.getState();
        let usageStatistics = true;
        if (storeElement.userReducer.user !== null) {
            usageStatistics = storeElement.userReducer.user.usageStatistics;
        }
        return usageStatistics;
    }

    public sendPageView(currentPath: string): void {
        if (UsageStatisticsService.isEnabled() && UST_ID !== "") {
            ReactGA.pageview(currentPath);
        }
    }

    public sendEvent(event: Event): void {
        if (UsageStatisticsService.isEnabled() && UST_ID !== "") {
            if (event.label === Action.CHANGE_LANGUAGE) {
                ReactGA.ga("send", "pageview", { sessionControl: "start" });
                ReactGA.ga("set", "language", event.label);
            }
            ReactGA.event(event);
        }
    }
}

export const usageStatisticsService = new UsageStatisticsService();

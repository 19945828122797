import React from "react";
import classNames from "classnames";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import style from "./generate-api-key.scss";
import { NOTES_MAX_LENGTH, NAME_MAX_LENGTH } from "globalConstants";
import { Translation } from "react-i18next";
import { Formik } from "formik";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import testIds from "testIds.json";

interface Props {
    submitEventHandler: (values: FormValues) => void;
    closeHandler: () => void;
}
export interface FormValues {
    name: string;
    description: string;
}

interface FormState {
    loading: boolean;
    descriptionCharactersLeft: number;
    formValues: FormValues;
}

export default class GenerateApiKeyForm extends React.Component<Props, FormState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            descriptionCharactersLeft: NOTES_MAX_LENGTH,
            formValues: {
                name: "",
                description: "",
            },
        };
    }
    public setLoading(loading: boolean): void {
        this.setState({
            loading,
        });
    }

    disabled = true;

    nameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState((prevState) => ({
            formValues: {
                ...prevState.formValues,
                name: event.target.value,
            },
        }));

        if (!event.target.value.replace(/\s/g, "").length) {
            this.disabled = true;
        } else {
            this.disabled = false;
        }
    };

    descriptionChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        this.setState({
            descriptionCharactersLeft: event.target.maxLength - event.target.value.length,
        });
    };
    render(): JSX.Element {
        const getSubmitHandler = async (values: FormValues) => {
            this.setState({
                loading: true,
            });
            this.props.submitEventHandler(values);
        };
        return this.state.loading ? (
            <div className={style.loadingIndicator}>
                <LoadingIndicator />
            </div>
        ) : (
            <Translation>
                {(t) => (
                    <div className={style.formContainer}>
                        <Formik
                            initialValues={this.state.formValues}
                            onSubmit={(values: FormValues) => {
                                return getSubmitHandler(values);
                            }}
                        >
                            {(formikProps) => (
                                <form onSubmit={formikProps.handleSubmit}>
                                    <div className={style.summaryText}>
                                        {t("GenerateApiKeyForm.generateApiKeySummaryText")}
                                    </div>
                                    <div className={form.formFields}>
                                        <label htmlFor="name" className={form.label}>
                                            {t("GenerateApiKeyForm.name")}
                                        </label>
                                        <input
                                            id="name"
                                            className={classNames(form.input, form.fixedWidthInput)}
                                            maxLength={NAME_MAX_LENGTH}
                                            autoFocus
                                            name="name"
                                            onChange={(e) => {
                                                formikProps.handleChange(e);
                                                this.nameChangeHandler(e);
                                            }}
                                            onBlur={formikProps.handleBlur}
                                            value={formikProps.values.name}
                                            data-testid={
                                                testIds.workArea.settings.apiKeys.manageApiKeyDialog.nameInput.itself
                                            }
                                        />
                                    </div>

                                    <div className={classNames(form.formFields, form.formFieldsFlex)}>
                                        <div className={form.formFieldsAlignItemsTop}>
                                            <span className={form.optional}>{t("Common.optional")}</span>
                                            <label htmlFor="description" className={classNames(form.label)}>
                                                {t("GenerateApiKeyForm.description")}
                                            </label>
                                        </div>
                                        <div className={style.descriptionContainer}>
                                            <textarea
                                                id="description"
                                                className={classNames(
                                                    form.input,
                                                    form.fixedWidthInput,
                                                    style.descriptionHeight
                                                )}
                                                maxLength={NOTES_MAX_LENGTH}
                                                onChange={(e) => {
                                                    this.descriptionChangeHandler(e);
                                                    formikProps.handleChange(e);
                                                }}
                                                data-testid={
                                                    testIds.workArea.settings.apiKeys.manageApiKeyDialog
                                                        .descriptionInput.itself
                                                }
                                            />
                                            <span className={form.optional}>
                                                {t("GenerateApiKeyForm.charactersLeft", {
                                                    remainingCharacters:
                                                        this.state.descriptionCharactersLeft.toString(),
                                                    descriptionMaxLength: NOTES_MAX_LENGTH.toString(),
                                                })}
                                            </span>
                                        </div>
                                    </div>
                                    <br />

                                    <div className={form.buttonContainer}>
                                        <button
                                            type="submit"
                                            disabled={this.disabled}
                                            className={classNames(
                                                buttons.medium,
                                                form.submitButton,
                                                this.disabled ? buttons.disabledButton : buttons.primaryButton
                                            )}
                                            data-testid={
                                                testIds.workArea.settings.apiKeys.manageApiKeyDialog.generateButton
                                            }
                                        >
                                            {t("GenerateApiKeyForm.generateButton")}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                )}
            </Translation>
        );
    }
}

import React from "react";

import { Form, Formik, FormikProps } from "formik";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";
import reportView from "./report-view.scss";
import testIds from "testIds.json";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";
import Cross from "components/icons/Cross";
import classNames from "classnames";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import SearchIcon from "components/icons/Search";

export interface FormValues {
    searchQuery: string;
}

interface Props {
    setReportsSearch: (searchQuery: string) => void;
    searchInProgress: boolean;
    tableName: string;
}
const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const ReportsSearchView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const searchInputMaxLength = 36;
    const [clearButtonVisibility, setClearButtonVisibility] = React.useState<boolean>(false);

    return (
        <Formik
            initialValues={{ searchQuery: "" }}
            onSubmit={(values, actions) => {
                actions.setSubmitting(false);

                props.setReportsSearch(values.searchQuery);
            }}
            validationSchema={object().shape({
                searchQuery: string().max(searchInputMaxLength),
            })}
            validateOnChange={true}
        >
            {({ values, handleChange, resetForm }: FormikProps<FormValues>) => {
                return (
                    <Form>
                        <div className={reportView.inputWithButton}>
                            <div className={reportView.searchIcon}>
                                <SearchIcon color={props.theme.activeBackgroundColor} />
                            </div>
                            <input
                                id="searchQuery"
                                type="text"
                                maxLength={searchInputMaxLength}
                                value={values.searchQuery}
                                autoComplete="off"
                                placeholder={t("Common.search")}
                                disabled={props.searchInProgress}
                                onChange={handleChange}
                                data-testid={testIds.workArea.report.erasure.searchInput.itself}
                                tabIndex={1}
                                onInput={(e) => {
                                    if (e.currentTarget.value.length < 1) {
                                        setClearButtonVisibility(false);
                                    } else {
                                        setClearButtonVisibility(true);
                                    }
                                }}
                                ref={(input) => input && input.focus()}
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        usageStatisticsService.sendEvent({
                                            category: Category.REPORTS,
                                            action: Action.EXECUTE_QUICK_SEARCH,
                                            label: props.tableName,
                                        });
                                    }
                                }}
                            />
                            <div
                                data-testid={testIds.workArea.report.erasure.searchInput.clearButton}
                                className={classNames(
                                    clearButtonVisibility ? reportView.clearButton : reportView.hideClearButton,
                                    props.searchInProgress ? reportView.disabledClearButton : ""
                                )}
                                onClick={() => {
                                    resetForm();
                                    setClearButtonVisibility(false);
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter" && !props.searchInProgress) {
                                        e.preventDefault();
                                        resetForm();
                                        setClearButtonVisibility(false);
                                    }
                                }}
                                tabIndex={2}
                            >
                                <Cross color={props.theme.secondaryButtonBackgroundColor} />
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default connector(ReportsSearchView);

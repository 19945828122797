import { FeatureFlags } from "flagged";
import { getObject, RepositoryKey } from "services/utils/repository";

export const FLAG_DASHBOARD = "dashboard";
export const FLAG_OVERVIEW_DASHBOARD = "overview_dashboard";
export const FLAG_SUPPORT = "support";
export const FLAG_SETTINGS = "settings";
export const FLAG_WORKFLOWS = "workflows";
export const FLAG_SUPPORT_INTRODUCTION = "introduction";
export const FLAG_SUPPORT_ENDPOINTS = "endpoints";

// In production there won't be any overriding values in LocalStorage so this
// constant alone defines the flags there.
export const DEFAULT_FLAGS: FeatureFlags = {
    [FLAG_OVERVIEW_DASHBOARD]: false,
    [FLAG_DASHBOARD]: true,
    [FLAG_SUPPORT]: true,
    [FLAG_SETTINGS]: true,
    [FLAG_WORKFLOWS]: true,
    [FLAG_SUPPORT_INTRODUCTION]: true,
    [FLAG_SUPPORT_ENDPOINTS]: true,
};

class FeatureFlagService {
    private flags: FeatureFlags;

    constructor() {
        this.flags = Object.assign({}, DEFAULT_FLAGS);
    }

    getFlags(): FeatureFlags {
        return this.flags;
    }

    override(overriding: FeatureFlags): FeatureFlagService {
        this.flags = Object.assign({}, DEFAULT_FLAGS, overriding);
        return this;
    }
}

function readLocalStorageFlags(): FeatureFlags {
    return getObject<FeatureFlags>(RepositoryKey.FEATURE_FLAGS) ?? {};
}

export const featureFlagService = new FeatureFlagService().override(readLocalStorageFlags());

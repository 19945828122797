import * as React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import AddTenantForm, { FormValues } from "./AddTenantForm";
import Modal from "components/modal/Modal";
import UnexpectedErrorMessage from "components/unexpected-error-message/UnexpectedErrorMessage";
import { tenantService, CreateTenantResponse } from "services/tenants/TenantService";
import { usageStatisticsService, Category, Action } from "services/statistics/UsageStatisticsService";

import buttons from "styles/buttons.scss";
import style from "./add-tenant.scss";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string;
}

const AddTenantView = (props: { onTenantAdded: () => void }): JSX.Element => {
    const { t } = useTranslation();

    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const [warning, setWarning] = React.useState<JSX.Element>(<></>);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const form = React.useRef<AddTenantForm>(null);
    const [availableRegions, setAvailableRegions] = React.useState<string[]>([]);
    const [addTenantFormModalTitle, setAddTenantFormModalTitle] = React.useState<string>("");

    const showAddTenantFormModal = async () => {
        usageStatisticsService.sendEvent({
            category: Category.TENANT,
            action: Action.ADD_TENANT,
        });
        try {
            setAddTenantFormModalTitle(t("Common.pleaseWait"));
            setAddTenantFormModalVisibility(true);
            const regionResponse = await tenantService.fetchRegionsList();
            setAvailableRegions(regionResponse.regions);
        } catch (error) {
            hideAddTenantFormModal();
            showResult({
                title: t("AddTenantView.failure"),
                message: t("AddTenantView.fetchRegionFailure"),
            });
        }
        form.current?.setLoading(false);
        setAddTenantFormModalTitle(t("AddTenantForm.title"));
    };

    const hideAddTenantFormModal = () => {
        setAddTenantFormModalVisibility(false);
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };

    const hideResult = () => {
        setResultVisible(false);
        props.onTenantAdded();
    };

    React.useEffect(() => {
        return () => abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort());
    });

    const addTenantSubmitEventHandler = async ({
        customerName,
        region,
        country,
        notes,
        mainContactName,
        email,
    }: FormValues): Promise<void> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        let createTenantResponse: CreateTenantResponse;
        try {
            createTenantResponse = await tenantService.createTenant(
                customerName,
                region,
                country,
                notes,
                mainContactName,
                email,
                abortController
            );
        } catch (e) {
            if (!signal.aborted) {
                showResult({
                    title: t("AddTenantView.failure"),
                    message: getErrorMessage(e.message, customerName),
                });
                console.error("Failed to add a customer:", e);
                form.current?.setLoading(false);
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        hideAddTenantFormModal();
        if (createTenantResponse.warnings && createTenantResponse.warnings.indexOf("USER_CREATION_FAILED") >= 0) {
            setWarning(
                <div>
                    <strong>{t("AddTenantView.warnings.error") + ": "}</strong>
                    {t("AddTenantView.warnings.userCreationFailedMessage")}
                </div>
            );
        }
        showResult({
            title: t("AddTenantView.successTitle"),
            message: t("AddTenantView.successMessage", { customerName }),
        });
    };

    const getErrorMessage = (e: string, customerName: string): string => {
        const error = JSON.parse(e);
        const code = error.error.code;
        const requestId = error.error.requestId;
        let errorMessage = error.error.message;
        if (code == "TENANT_NAME_REQUIRED") {
            errorMessage = t("AddTenantView.errorMessages.missingTenantName");
        } else if (code == "INVALID_TENANT_NAME") {
            errorMessage = t("AddTenantView.errorMessages.invalidTenantName", { tenantName: customerName });
        } else if (code == "TENANT_NAME_NOT_AVAILABLE") {
            errorMessage = t("AddTenantView.errorMessages.conflictingTenantName", {
                tenantName: customerName,
            });
        } else if (code == "INVALID_NOTES") {
            errorMessage = t("AddTenantView.errorMessages.invalidNotes");
        } else if (code == "INVALID_CONTACT_NAME") {
            errorMessage = t("AddTenantView.errorMessages.invalidContactName");
        } else if (code == "INVALID_COUNTRY_CODE") {
            errorMessage = t("AddTenantView.errorMessages.invalidCountryCode");
        } else if (code == "REGION_REQUIRED") {
            errorMessage = t("AddTenantView.errorMessages.invalidRegion");
        } else if (code == "INVALID_EMAIL") {
            errorMessage = t("AddTenantView.errorMessages.emailNotValid");
        } else {
            errorMessage = <UnexpectedErrorMessage requestId={requestId} />;
        }
        return errorMessage;
    };

    const [addTenantFormModalVisibility, setAddTenantFormModalVisibility] = React.useState(false);
    const [resultVisible, setResultVisible] = React.useState(false);

    return (
        <div>
            <button
                className={classNames(buttons.primaryButton, buttons.small)}
                onClick={showAddTenantFormModal}
                data-testid={testIds.workArea.tenant.addTenantButton}
            >
                {t("AddTenantView.addTenant")}
            </button>
            <Modal
                isOpen={addTenantFormModalVisibility}
                hideModal={hideAddTenantFormModal}
                modalTitle={addTenantFormModalTitle}
            >
                <AddTenantForm
                    submitEventHandler={addTenantSubmitEventHandler}
                    closeHandler={hideAddTenantFormModal}
                    regionsList={availableRegions}
                    ref={form}
                />
            </Modal>
            <Modal isOpen={resultVisible} hideModal={hideResult} modalTitle={result.title}>
                <div className={style.successMessageContainer}>{result.message}</div>
                <div className={style.warningMessageContainer}>{warning}</div>
                <div className={style.successButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, style.successButton)}
                        onClick={hideResult}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default AddTenantView;

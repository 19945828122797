import { DashboardDataList } from "domain/dashboard";

class CommonFunctions {
    private MONTHS: string[] = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    public computeTotalTonnesOfErasedDevices(data: DashboardDataList[]): number {
        if (data == null || data.length < 1) {
            return 0;
        }
        let sum = 0;
        Object.values(data).forEach((set) => {
            sum +=
                set.content.Laptop.weight +
                set.content.Desktop.weight +
                set.content.Server.weight +
                set.content.Tablet.weight +
                set.content.Smartphone.weight +
                set.content["Loose drive"].weight;
        });

        return parseInt(String(sum / 1000));
    }

    public computeAverageWeightProcessPerDay(data: DashboardDataList[]): number {
        return parseInt(String(this.computeTotalTonnesOfErasedDevices(data) / 365));
    }

    public fetchMonths(data: DashboardDataList[]): string[] {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if (data == null || data.length < 1) {
            return [];
        }
        const validMonths: string[] = [];
        Object.values(data).forEach((set) => {
            const [year, month] = set.month.split("-");
            validMonths.push(months[Number(month) - 1] + " " + year.substr(2));
        });
        return validMonths;
    }

    public computeFirstMonth(data: DashboardDataList[]): string {
        if (data == null || data.length < 1) {
            return "";
        }
        const [shortFirstMonth, yearOfFirstMonth] = this.fetchMonths(data)[0].split(" ");
        for (const longMonth of this.MONTHS) {
            if (longMonth.startsWith(shortFirstMonth)) {
                return longMonth + " 20" + yearOfFirstMonth;
            }
        }
        return "";
    }

    public computeLastMonth(data: DashboardDataList[]): string {
        if (data == null || data.length < 1) {
            return "";
        }
        const numberOfMonths = this.fetchMonths(data).length;
        const [shortLastMonth, yearOfFirstMonth] = this.fetchMonths(data)[numberOfMonths - 1].split(" ");
        for (const longMonth of this.MONTHS) {
            if (longMonth.startsWith(shortLastMonth)) {
                return longMonth + " 20" + yearOfFirstMonth;
            }
        }
        return "";
    }
    public computeTotalOfCo2EmissionsPrevented(data: DashboardDataList[]): number {
        if (data == null || data.length < 1) {
            return 0;
        }
        let sum = 0;
        Object.values(data).forEach((set) => {
            sum +=
                set.content.Laptop["co2"] +
                set.content.Desktop["co2"] +
                set.content.Server["co2"] +
                set.content.Tablet["co2"] +
                set.content.Smartphone["co2"] +
                set.content["Loose drive"]["co2"];
        });
        return parseInt(String(sum / 1000));
    }
}

export const commonFunctions = new CommonFunctions();

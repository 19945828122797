import { FunctionComponent } from "react";

import AllErasureReportsView from "components/reports/AllErasureReportsView";
import AllLicensesView from "components/licenses/AllLicensesView";
import AllTenantsView from "components/tenants/AllTenantsView";
import AllUsersView from "components/users/AllUsersView";
import ApiGuideView from "components/support/api-guide/ApiDocumentationView";
import BmsLicenseKeysView from "components/licenses/BmsLicenseKeysView";
import MobileSolutionsReportsView from "components/reports/MobileSolutionsReportsView";
import ConfigureView from "components/licenses/ConfigureView";
import CreateNewPasswordView from "components/login/create-new-password/CreateNewPasswordView";
import DashboardOverview from "components/dashboard/DashboardOverview";
import DashboardView from "components/dashboard/DashboardView";
import DiagnosticReportsView from "components/reports/DiagnosticReportsView";
import ErasureReportsView from "components/reports/ErasureReportsView";
import LicensesView from "components/licenses/LicensesView";
import LoginView from "components/login/LoginView";
import LogoutView from "components/login/LogoutView";
import MyErasureReportsView from "components/reports/MyErasureReportsView";
import ReportsView from "components/reports/ReportsView";
import SupportView from "components/support/api-guide/SupportView";
import TenantsView from "components/tenants/TenantsView";
import UserProfileView from "components/users/profile/UserProfileView";
import UsersView from "components/users/UsersView";
import DownloadPageView from "components/download-page/DownloadPageView";
import {
    FLAG_DASHBOARD,
    FLAG_SUPPORT,
    FLAG_SETTINGS,
    FLAG_OVERVIEW_DASHBOARD,
    FLAG_WORKFLOWS,
} from "services/feature/FeatureFlagService";
import { UserDetails } from "domain/user";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import GeneralSettingView from "components/api-keys-settings/GeneralSettingView";
import ApiKeysView from "components/api-keys-settings/ApiKeysView";
import SettingsView from "components/api-keys-settings/SettingsView";
import AllApiKeysView from "components/api-keys-settings/AllApiKeysView";
import MyApiKeysView from "components/api-keys-settings/MyApiKeysView";
import testIds from "testIds.json";
import EsgDashboardView from "components/dashboard/esg/EsgDashboardView";
import WorkflowsView from "components/workflows/WorkflowsView";
import AllWorkflowsView from "components/workflows/AllWorkflowsView";

export class RouteDefinition {
    public path: string;
    public title: string;
    public component: FunctionComponent;
    public secured: boolean;
    public childRoutes: RouteDefinition[];
    public testId?: string;
    public featureFlag?: string;

    private userValidator: (user: UserDetails | null) => boolean = () => true;

    constructor(
        path: string,
        title: string,
        component: FunctionComponent,
        secured = true,
        childRoutes: RouteDefinition[] = [],
        testId?: string,
        featureFlag?: string
    ) {
        this.path = path;
        this.title = title;
        this.component = component;
        this.secured = secured;
        this.childRoutes = childRoutes;
        this.testId = testId;
        this.featureFlag = featureFlag;
    }

    isValidUser(user: UserDetails | null): boolean {
        return this.userValidator(user);
    }

    setUserValidator(userValidator: (user: UserDetails | null) => boolean): void {
        this.userValidator = userValidator;
    }

    getFeatureFlag(): string | undefined {
        return this.featureFlag;
    }
}

export function enableLicenseChildRoutes(bms: boolean): void {
    resetLicenseChildRoutes();
    if (bms) {
        LICENSES_ROUTE.childRoutes.push(LICENSES_BMS_LICENSES_ROUTE);
        LICENSES_ROUTE.childRoutes.push(CONFIGURATION_LICENSES_ROUTE);
    }
}

export function resetLicenseChildRoutes(): void {
    LICENSES_ROUTE.childRoutes = [];
    LICENSES_ROUTE.childRoutes.push(LICENSES_ALL_LICENSES_ROUTE);
}

export const DASHBOARD_OVERVIEW_ROUTE = new RouteDefinition(
    "/dashboard/overview",
    "Routes.overviewDashboard",
    DashboardOverview,
    true,
    [],
    undefined,
    FLAG_OVERVIEW_DASHBOARD
);
export const DASHBOARD_ESG_ROUTE = new RouteDefinition(
    "/dashboard/sustainability",
    "Routes.esgDashboard",
    EsgDashboardView
);
export const DASHBOARD_ROUTE = new RouteDefinition(
    "/dashboard",
    "Routes.dashboard",
    DashboardView,
    true,
    [DASHBOARD_ESG_ROUTE, DASHBOARD_OVERVIEW_ROUTE],
    testIds.navigation.dashboardLink,
    FLAG_DASHBOARD
);
export const LOGIN_ROUTE = new RouteDefinition("/login", "Routes.login", LoginView, false);
export const LOGOUT_ROUTE = new RouteDefinition("/logout", "Routes.logout", LogoutView);
export const PROFILE_ROUTE = new RouteDefinition("/users/profile", "Routes.profile", UserProfileView);
export const CREATE_NEW_PASSWORD_ROUTE = new RouteDefinition(
    "/create-new-password",
    "Routes.createNewPassword",
    CreateNewPasswordView,
    false
);

export const REPORTS_MOBILE_SOLUTIONS_ROUTE = new RouteDefinition(
    "/reports/diagnostics/mobileSolutions",
    "Common.mobileSolutionsReports",
    MobileSolutionsReportsView
);
export const REPORTS_DIAGNOSTIC_ROUTE = new RouteDefinition(
    "/reports/diagnostics",
    "Routes.diagnosticReports",
    DiagnosticReportsView,
    true,
    [REPORTS_MOBILE_SOLUTIONS_ROUTE]
);
export const REPORTS_ALL_ERASURE_ROUTE = new RouteDefinition(
    "/reports/erasure/all",
    "Common.allErasureReports",
    AllErasureReportsView
);
export const REPORTS_OWN_ERASURE_ROUTE = new RouteDefinition(
    "/reports/erasure/own",
    "Common.myErasureReports",
    MyErasureReportsView
);
REPORTS_OWN_ERASURE_ROUTE.setUserValidator((user: UserDetails | null) => user !== null && !hasTenantCookie());

export const REPORTS_ERASURE_ROUTE = new RouteDefinition(
    "/reports/erasure",
    "Routes.erasureReports",
    ErasureReportsView,
    true,
    [REPORTS_ALL_ERASURE_ROUTE, REPORTS_OWN_ERASURE_ROUTE]
);

export const REPORTS_ROUTE = new RouteDefinition(
    "/reports",
    "Routes.reports",
    ReportsView,
    true,
    [REPORTS_DIAGNOSTIC_ROUTE, REPORTS_ERASURE_ROUTE],
    testIds.navigation.reportLink
);

export const USERS_ALL_USERS_ROUTE = new RouteDefinition("/users/all", "Common.userTitle", AllUsersView);
export const USERS_ROUTE = new RouteDefinition(
    "/users",
    "Common.users",
    UsersView,
    true,
    [USERS_ALL_USERS_ROUTE],
    testIds.navigation.userLink
);
export const TENANTS_ALL_TENANTS_ROUTE = new RouteDefinition("/customers/all", "Common.customerTitle", AllTenantsView);
export const TENANTS_ROUTE = new RouteDefinition(
    "/customers",
    "Common.customers",
    TenantsView,
    true,
    [TENANTS_ALL_TENANTS_ROUTE],
    testIds.navigation.tenantLink
);

[TENANTS_ALL_TENANTS_ROUTE, TENANTS_ROUTE].forEach((route) =>
    route.setUserValidator(
        (user: UserDetails | null) => user !== null && user.tenantType === "INTERNAL" && !hasTenantCookie()
    )
);

export const API_GUIDE_ROUTE = new RouteDefinition("/support/api", "Routes.apiGuide", ApiGuideView);
export const SUPPORT_ROUTE = new RouteDefinition(
    "/support",
    "Routes.support",
    SupportView,
    true,
    [API_GUIDE_ROUTE],
    testIds.navigation.supportLink,
    FLAG_SUPPORT
);

export const LICENSES_ALL_LICENSES_ROUTE = new RouteDefinition("/licenses/all", "Licenses.licenses", AllLicensesView);
export const LICENSES_BMS_LICENSES_ROUTE = new RouteDefinition(
    "/licenses/bms",
    "LicenseKeys.bmsLicenseKeys",
    BmsLicenseKeysView
);

export const CONFIGURATION_LICENSES_ROUTE = new RouteDefinition(
    "/licenses/configure",
    "configuration.title",
    ConfigureView
);
export const LICENSES_ROUTE = new RouteDefinition(
    "/licenses",
    "Licenses.licenses",
    LicensesView,
    true,
    [LICENSES_ALL_LICENSES_ROUTE],
    testIds.navigation.licenseLink
);

export const GENERAL_SETTINGS_ROUTE = new RouteDefinition(
    "/settings/generalSettings",
    "GeneralSettings.title",
    GeneralSettingView
);

export const ALL_API_KEYS_ROUTE = new RouteDefinition("/settings/apiKeys/all", "Common.allApiKeys", AllApiKeysView);

export const MY_API_KEYS_ROUTE = new RouteDefinition("/settings/apiKeys/own", "Common.myApiKeys", MyApiKeysView);
export const API_KEYS_ROUTE = new RouteDefinition("/settings/apiKeys", "Routes.apiKeys", ApiKeysView, true, [
    ALL_API_KEYS_ROUTE,
    MY_API_KEYS_ROUTE,
]);

export const SETTINGS_ROUTE = new RouteDefinition(
    "/settings",
    "Routes.settings",
    SettingsView,
    true,
    [GENERAL_SETTINGS_ROUTE, API_KEYS_ROUTE],
    testIds.navigation.settingsLink,
    FLAG_SETTINGS
);
[GENERAL_SETTINGS_ROUTE, ALL_API_KEYS_ROUTE, MY_API_KEYS_ROUTE, API_KEYS_ROUTE, SETTINGS_ROUTE].forEach((route) =>
    route.setUserValidator((user: UserDetails | null) => user !== null && !hasTenantCookie())
);

export const DOWNLOAD_PAGE_ROUTE = new RouteDefinition("/download/bms/:licenseKey?", "", DownloadPageView, false);

export const ALL_WORKFLOWS_ROUTE = new RouteDefinition("/workflows/all", "Common.workflowsTitle", AllWorkflowsView);
export const WORKFLOWS_ROUTE = new RouteDefinition(
    "/workflows",
    "Routes.workflows",
    WorkflowsView,
    true,
    [ALL_WORKFLOWS_ROUTE],
    testIds.navigation.workflowsLink,
    FLAG_WORKFLOWS
);

// Note: child routes must precede parent routes. E.g. REPORTS_OWN_ERASURE_ROUTE must come before REPORTS_ROUTE.
export const ROUTES: RouteDefinition[] = [
    API_GUIDE_ROUTE,
    CREATE_NEW_PASSWORD_ROUTE,
    DASHBOARD_ESG_ROUTE,
    DASHBOARD_OVERVIEW_ROUTE,
    DASHBOARD_ROUTE,
    LOGIN_ROUTE,
    LOGOUT_ROUTE,
    PROFILE_ROUTE,
    REPORTS_ALL_ERASURE_ROUTE,
    REPORTS_OWN_ERASURE_ROUTE,
    REPORTS_MOBILE_SOLUTIONS_ROUTE,
    REPORTS_DIAGNOSTIC_ROUTE,
    REPORTS_ERASURE_ROUTE,
    REPORTS_ROUTE,
    SUPPORT_ROUTE,
    TENANTS_ALL_TENANTS_ROUTE,
    TENANTS_ROUTE,
    USERS_ALL_USERS_ROUTE,
    USERS_ROUTE,
    MY_API_KEYS_ROUTE,
    ALL_API_KEYS_ROUTE,
    API_KEYS_ROUTE,
    GENERAL_SETTINGS_ROUTE,
    SETTINGS_ROUTE,
    LICENSES_ALL_LICENSES_ROUTE,
    LICENSES_BMS_LICENSES_ROUTE,
    CONFIGURATION_LICENSES_ROUTE,
    LICENSES_ROUTE,
    DOWNLOAD_PAGE_ROUTE,
    ALL_WORKFLOWS_ROUTE,
    WORKFLOWS_ROUTE,
];

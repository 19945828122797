// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._32E7IFbK_XT0b05SCZFGiu{box-shadow:0 3px 6px var(--boxShadowColor);border-radius:3px;border:1px solid;box-sizing:border-box;padding:10px;text-align:center;font:normal normal normal 10px/12px Lato;letter-spacing:0px}.defaultTheme ._32E7IFbK_XT0b05SCZFGiu{background-color:#fff}.tenantTheme ._32E7IFbK_XT0b05SCZFGiu{background-color:#fff}.defaultDarkTheme ._32E7IFbK_XT0b05SCZFGiu{background-color:#333}.tenantDarkTheme ._32E7IFbK_XT0b05SCZFGiu{background-color:#333}.defaultTheme ._32E7IFbK_XT0b05SCZFGiu{--boxShadowColor:rgba(76,76,76,.15)}.tenantTheme ._32E7IFbK_XT0b05SCZFGiu{--boxShadowColor:rgba(76,76,76,.15)}.defaultDarkTheme ._32E7IFbK_XT0b05SCZFGiu{--boxShadowColor:rgba(34,34,34,.4)}.tenantDarkTheme ._32E7IFbK_XT0b05SCZFGiu{--boxShadowColor:rgba(34,34,34,.4)}.defaultTheme ._32E7IFbK_XT0b05SCZFGiu{border-color:#f0f0f0}.tenantTheme ._32E7IFbK_XT0b05SCZFGiu{border-color:#f0f0f0}.defaultDarkTheme ._32E7IFbK_XT0b05SCZFGiu{border-color:#111}.tenantDarkTheme ._32E7IFbK_XT0b05SCZFGiu{border-color:#111}", ""]);
// Exports
exports.locals = {
	"activeBackgroundColor": "#db2828",
	"activeForegroundColor": "#db2828",
	"primaryButtonBackgroundColor": "#db2828",
	"secondaryButtonForegroundColor": "#f46464",
	"bannerBackgroundColor": "rgba(219,40,40,.5)",
	"linkTextColor": "#f46464",
	"primaryButtonBackgroundHoverColor": "#f46464",
	"secondaryButtonBackgroundHoverColor": "#f0f0f0",
	"destructiveButtonBackgroundHoverColor": "#f46464",
	"primaryButtonBackgroundActiveColor": "#f46464",
	"secondaryButtonBackgroundActiveColor": "#f7f7f7",
	"destructiveButtonBackgroundColor": "#f09191",
	"chartNegativeColor": "#f09191",
	"chartPositiveColor": "#5fe3a1",
	"chartNeutralColor": "#cecece",
	"errorBackgroundColor": "#f46464",
	"errorBorderColor": "#f46464",
	"errorTextColor": "#f46464",
	"warningBackgroundColor": "#f2711c",
	"warningBorderColor": "#f2711c",
	"warningTextColor": "#f2711c",
	"secondaryWarningBackgroundColor": "#ffc915",
	"secondaryWarningBorderColor": "#ffc915",
	"secondaryWarningTextColor": "#ffc915",
	"successBorderColor": "#5fe3a1",
	"successTextColor": "#5fe3a1",
	"pageBackgroundColor": "#222",
	"wrapperBackgroundColor": "#f2c5c5",
	"wrapperBorderColor": "#f09191",
	"modelBoxBackgroundColor": "#4c4c4c",
	"contentBackgroundColor": "#333",
	"secondaryButtonBackgroundColor": "#333",
	"textColor": "#fff",
	"primaryButtonForegroundColor": "#fff",
	"bannerForegroundColor": "#4c4c4c",
	"chartOutlineColor": "#222",
	"modalOverlayColor": "rgba(34,34,34,.4)",
	"contentDividerColor": "#222",
	"contentBorderColor": "#111",
	"disabledButtonBackgroundColor": "#222",
	"disabledButtonForegroundColor": "#4c4c4c",
	"inputHintColor": "#cecece",
	"inputBackgroundColor": "#4c4c4c",
	"hoverBackgroundColor": "#111",
	"toggleCheckedBackgroundColor": "#91b9f0",
	"toggleUncheckedBackgroundColor": "#cecece",
	"toggleForegroundColor": "#fff",
	"mapForegroundColor": "#f0f0f0",
	"mapBackgroundColor": "#222",
	"emptyStateEllipseBackgroundColor": "#111",
	"emptyStateBiggestCircleBackgroundColor": "#3c3960",
	"emptyStateSmallestCircleBackgroundColor": "#111",
	"emptyStatePersonColor": "#4a4a52",
	"defaultTheme": "defaultTheme",
	"tenantTheme": "tenantTheme",
	"defaultDarkTheme": "defaultDarkTheme",
	"tenantDarkTheme": "tenantDarkTheme",
	"tooltip": "_32E7IFbK_XT0b05SCZFGiu"
};
module.exports = exports;

import * as React from "react";
import { Column } from "react-table";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { RepositoryKey } from "services/utils/repository";
import { useTranslation } from "react-i18next";
import { DiagnosticSummary } from "domain/reports";
import checkIcon from "assets/images/icons/checkMarkInCircle.svg";
import testIds from "testIds.json";
import crossIcon from "assets/images/icons/cross.svg";
import indeterminateIcon from "assets/images/icons/quickIndeterminate.svg";
import { DiagnosticReportResponse } from "domain/reports";
import { TOOLTIP_DELAY } from "globalConstants";

export function DiagnosticSummaryView(props: { diagnosticReportResponse: DiagnosticReportResponse }): JSX.Element {
    const { t } = useTranslation();
    const [initialLoading, setInitialLoading] = React.useState<boolean>(true);
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [diagData, setData] = React.useState<DiagnosticReportResponse>({
        deviceDetail: {},
        diagnosticSummary: [],
        buybackTradein: {},
    });

    React.useEffect(() => {
        const abortController = new AbortController();
        setData(props.diagnosticReportResponse);
        if (!props.diagnosticReportResponse || !props.diagnosticReportResponse.diagnosticSummary) {
            setRequestFailureMessage(t("DiagnosticReportsTable.diagnosticSummary.requestFailed"));
        }
        setInitialLoading(false);
        return () => {
            abortController.abort();
        };
    }, [props.diagnosticReportResponse]);

    const columns: Array<Column<DiagnosticSummary>> = [
        {
            Header: [<TextWithTooltip text={t("DiagnosticReportsTable.diagnosticSummary.test")} key="1" />],
            accessor: "test",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <img
                            key="1"
                            src={checkIcon}
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.passed")}
                            data-delay-show={TOOLTIP_DELAY}
                        />
                    }
                    key="2"
                />,
            ],
            accessor: "pass",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <img
                            key="1"
                            src={crossIcon}
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.failed")}
                            data-delay-show={TOOLTIP_DELAY}
                        />
                    }
                    key="3"
                />,
            ],
            accessor: "fail",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <img
                            key="1"
                            src={indeterminateIcon}
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.skipped")}
                            data-delay-show={TOOLTIP_DELAY}
                        />
                    }
                    key="4"
                />,
            ],
            accessor: "skip",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: [
                <TextWithTooltip
                    text={
                        <img
                            key="1"
                            src={indeterminateIcon}
                            data-tip={t("DiagnosticReportsTable.diagnosticSummary.indeterminate")}
                            data-delay-show={TOOLTIP_DELAY}
                        />
                    }
                    key="5"
                />,
            ],
            accessor: "indeterminate",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
    ];

    return (
        <Table
            tableIdentity={RepositoryKey.DIAGNOSTIC_SUMMARY_TABLE}
            data={diagData.diagnosticSummary}
            columns={columns}
            failureMessage={requestFailureMessage}
            loaded={!initialLoading}
            tooltips={true}
            emptyMessage={t("DiagnosticReportsTable.diagnosticSummary.emptyStateMessage")}
            testId={testIds.workArea.report.erasure.table}
        />
    );
}
DiagnosticSummaryView.defaultProps = {
    initialSummaryData: [],
};

import * as React from "react";
import { Column } from "react-table";

import DateCell from "components/table/DateCell";
import StatusCell, { Status } from "components/table/StatusCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { RepositoryKey } from "services/utils/repository";
import { UserData, UserStatus } from "domain/users";
import { useTranslation } from "react-i18next";
import { userService } from "services/user/users/UserService";
import testIds from "testIds.json";
import KebabMenu from "components/kebab-menu/KebabMenu";

import style from "./users.scss";
import UserKebabMenu from "./UserKebabMenu";

const USER_STATUS_TO_STATUS = new Map([
    [UserStatus.UNCONFIRMED, Status.Warning],
    [UserStatus.CONFIRMED, Status.Ok],
    [UserStatus.ARCHIVED, Status.Warning],
    [UserStatus.COMPROMISED, Status.Warning],
    [UserStatus.UNKNOWN, Status.Warning],
    [UserStatus.RESET_REQUIRED, Status.Warning],
    [UserStatus.FORCE_CHANGE_PASSWORD, Status.Warning],
]);

function toStatusCellStatus(userStatus: UserStatus): Status {
    const status = USER_STATUS_TO_STATUS.get(userStatus);
    return status != null ? status : Status.Warning;
}

interface TableState {
    userData: UserData[];
}

export function UsersTable(props: { count: number; onUserDelete: () => void }): JSX.Element {
    const { t } = useTranslation();
    const [tableState, setTableState] = React.useState<TableState>({ userData: [] });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(true);

    const fetchData = (abortController: AbortController, initialLoading: boolean) => {
        setInitialLoading(initialLoading);
        userService
            .fetchUsers(abortController)
            .then((data) => {
                setTableState((prevState) => ({
                    ...prevState,
                    userData: data,
                }));
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("UsersTable.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };

    const columns: Array<Column<UserData>> = [
        {
            Header: () => <TextWithTooltip text={t("Common.name")} key="name" />,
            accessor: "name",
            Cell: (cellInfo) => (
                <>
                    <KebabMenu>
                        <UserKebabMenu
                            uuid={cellInfo.cell.row.original.uuid}
                            userName={
                                typeof cellInfo.value === "undefined" || cellInfo.value.trim() === ""
                                    ? cellInfo.cell.row.original.email
                                    : cellInfo.value
                            }
                            onUserDelete={props.onUserDelete}
                        />
                    </KebabMenu>
                    <div className={style.userNameCell}>
                        <TextWithTooltip text={cellInfo.value} />
                    </div>
                </>
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("UsersTable.userStatus")} key="status" />,
            accessor: "status",
            Cell: ({ cell: { value } }) => (
                <StatusCell values={[{ status: toStatusCellStatus(value), title: value }]} tooltip={true} />
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("Common.email")} key="email" />,
            accessor: "email",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("UsersTable.emailStatus")} key="emailVerified" />,
            accessor: "emailVerified",
            Cell: ({ cell: { value } }) => (
                <StatusCell
                    values={[
                        value
                            ? {
                                  status: Status.Ok,
                                  title: t("UsersTable.emailVerified"),
                              }
                            : {
                                  status: Status.Error,
                                  title: t("UsersTable.emailUnverified"),
                              },
                    ]}
                    tooltip={true}
                />
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("UsersTable.createdDate")} key="createdDate" />,
            accessor: "createdDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
        },
    ];
    React.useEffect(() => {
        const abortController = new AbortController();
        fetchData(abortController, true);

        return () => {
            abortController.abort();
        };
    }, [props.count]);

    let dataCount = null;
    if (tableState.userData.length > 0) {
        dataCount = t("Common.defaultSearchResultHint", { dataCount: tableState.userData.length });
    }

    return (
        <>
            {dataCount}
            <Table
                tableIdentity={RepositoryKey.USER_TABLE}
                data={tableState.userData}
                columns={columns}
                loaded={!initialLoading}
                failureMessage={requestFailureMessage}
                tooltips={true}
                emptyMessage={t("UsersTable.emptyStateMessage")}
                testId={testIds.workArea.user.table}
            />
        </>
    );
}

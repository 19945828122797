export enum RepositoryKey {
    ALL_API_KEYS_TABLE = "allApiKeysTable",
    CONFIGURATION_TABLE = "configuration",
    DASHBOARD_CARD_LAYOUT = "dashboardCardLayout",
    DATA_COLLECTION = "dataCollection",
    DIAGNOSTIC_SUMMARY_TABLE = "diagnosticSummaryTable",
    DIAGNOSTIC_TABLE = "diagnosticReportsTable",
    ERASURE_TABLE = "erasureReportsTable",
    ESG_DASHBOARD_CARD_LAYOUT = "esgDashboardCardLayoutV1",
    FEATURE_FLAGS = "featureFlags",
    LICENSE_TABLE = "licensesTable",
    LOCALE = "locale",
    MODE = "mode",
    MY_API_KEYS_TABLE = "myApiKeysTable",
    ORIGINAL_OLIVER_URL = "originalOliverUrl",
    ORIGINAL_PUBLIC_API_DOCUMENTATION_URL = "originalPublicApiDocumentationUrl",
    ORIGINAL_PUBLIC_API_URL = "originalPublicApiUrl",
    ORIGINAL_STAN_URL = "originalStanUrl",
    OLIVER_URL = "oliverUrl",
    PUBLIC_API_DOCUMENTATION_URL = "publicApiDocumentationUrl",
    PUBLIC_API_URL = "publicApiUrl",
    STAN_URL = "stanUrl",
    TENANT_TABLE = "tenantsTable",
    USER_DETAILS = "user.details",
    USER_TABLE = "usersTable",
    WORKFLOWS_TABLE = "workflowsTable",
}

export function setObject<T>(key: RepositoryKey, object: T): void {
    window.localStorage.setItem(key, JSON.stringify(object));
}

export function getObject<T>(key: RepositoryKey): T | null {
    const object = window.localStorage.getItem(key);
    if (object === null) {
        return null;
    }
    return JSON.parse(object);
}

export function remove(key: RepositoryKey): void {
    window.localStorage.removeItem(key);
}

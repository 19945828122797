import React from "react";
import { useTranslation } from "react-i18next";

import testIds from "testIds.json";

interface Props {
    requestId: string;
}

const UnexpectedErrorMessage = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const requestId = props.requestId;
    const subject = encodeURI("Blancco Management Portal - Unexpected Error Report: " + requestId);
    const body = encodeURI(
        "Hi there\n\n" +
            "I've experienced a persistent issue with the ID: " +
            requestId +
            "\nWould you be able to assist?\n\n"
    );
    const mailto = "mailto:support@blancco.com?subject=" + subject + "&body=" + body;
    return (
        <>
            <div data-testid={testIds.common.unexpectedErrorDialog.serviceDeskLink}>
                {t("AddUserView.errorMessages.unexpectedError.unexpected")}{" "}
                <a
                    href="https://servicedesk.blancco.com/servicedesk/customer/portal/6"
                    target="_blank"
                    rel="noreferrer"
                >
                    https://servicedesk.blancco.com/servicedesk/customer/portal/6
                </a>
            </div>
            <div data-testid={testIds.common.unexpectedErrorDialog.supportLink}>
                {t("AddUserView.errorMessages.unexpectedError.support")} <a href={mailto}>support@blancco.com</a>
            </div>
            <div data-testid={testIds.common.unexpectedErrorDialog.requestId}>
                {t("AddUserView.errorMessages.unexpectedError.requestId", { requestId })}
            </div>
        </>
    );
};

export default UnexpectedErrorMessage;

import { StoreState } from "store";
import style from "components/header/header.scss";
import { connect, ConnectedProps } from "react-redux";
import ToggleablePanel from "components/header/ToggleablePanel";
import LanguageMenu from "components/language-menu/LanguageMenu";
import React, { ReactElement } from "react";
import { UserMenu } from "components/user-menu/UserMenu";
import * as LanguageRepository from "services/language/languageRepository";
import testIds from "testIds.json";

interface Panel {
    key: number;
    title: string;
    testId: string;
    children?: ReactElement;
}
const mapState = (state: StoreState) => {
    const user = state.userReducer.user;
    const username = user === null ? "" : user.username;

    const lang = state.languageReducer.lang;

    return { username, lang };
};
const connector = connect(mapState);
const MenuPanel = (props: ConnectedProps<typeof connector>) => {
    const username = props.username.split("@")[0];
    const language = props.lang || LanguageRepository.getLanguage();

    const menuItems: Panel[] = [
        {
            key: 1,
            title: language.locale,
            testId: testIds.header.languageMenu.button,
            children: <LanguageMenu />,
        },
        { key: 2, title: username, testId: testIds.header.userMenu.button, children: <UserMenu /> },
    ];

    return (
        <>
            {menuItems.map((menu) => {
                return (
                    <div className={style.innerMenu} key={menu.key}>
                        <ToggleablePanel title={menu.title} testId={menu.testId} menuType="headerMenu">
                            {menu.children}
                        </ToggleablePanel>
                    </div>
                );
            })}
        </>
    );
};

export default connector(MenuPanel);

import * as React from "react";
import { useEffect } from "react";
import ReactModal from "react-modal";

import style from "./modal.scss";

import testIds from "testIds.json";

interface Props {
    isOpen: boolean;
    hideModal: () => void;
    modalTitle: string;
    loginTitleStyle?: boolean;
}

const Modal: React.FunctionComponent<Props> = (props) => {
    const modalTitleStyle = props.loginTitleStyle === true ? style.loginModalTitle : style.standardModalTitle;
    const setBodyScrolling = (currentScrollingState: boolean) => {
        const body = document.body;
        body.classList.remove(currentScrollingState ? style.bodyStopScroll : style.bodyScroll);
        body.classList.add(currentScrollingState ? style.bodyScroll : style.bodyStopScroll);
    };

    useEffect(() => {
        if (props.isOpen) {
            setBodyScrolling(false);
        } else {
            setBodyScrolling(true);
        }
        return function cleanup() {
            setBodyScrolling(true);
        };
    }, [props.isOpen]);

    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={props.isOpen}
            onRequestClose={props.hideModal}
            className={{
                base: style.modalBase,
                afterOpen: style.modalAfterOpen,
                beforeClose: style.modalBeforeClose,
            }}
            overlayClassName={{
                base: style.overlay,
                afterOpen: "",
                beforeClose: style.overlayBeforeClose,
            }}
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={parseInt(style.animationDuration, 10)}
        >
            <div className={style.closeButtonContainer}>
                <div
                    role="close"
                    onClick={props.hideModal}
                    className={style.closeButton}
                    data-testid={testIds.common.dialog.xButton}
                />
            </div>
            <div className={modalTitleStyle}>{props.modalTitle ? props.modalTitle : "Modal title"}</div>
            {props.children}
        </ReactModal>
    );
};

export default Modal;

import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";
import { Menu, MenuItem } from "react-aria-menubutton";
import Modal from "components/modal/Modal";
import classNames from "classnames";
import buttons from "styles/buttons.scss";
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import testIds from "testIds.json";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import Delete from "components/icons/Delete";
import style from "./general-setting-view.scss";
import { apiKeysService } from "services/api-keys/ApiKeysService";
import Edit from "components/icons/Edit";
import Copy from "components/icons/Copy";
import TextWithTooltip from "components/table/TextWithTooltip";
import EditApiKeyForm, { FormValues } from "./EditApiKeyForm";

interface Result {
    title: string;
    message: string;
    resultVisible: boolean;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const ApiKeysKebabMenu = (
    props: ConnectedProps<typeof connector> & {
        uuid: string;
        name: string;
        description: string;
        onApiKeyDelete: () => void;
        onApiKeyEdit: () => void;
    }
): JSX.Element => {
    const { t } = useTranslation();
    const [deleteApiKeyModalVisible, setDeleteApiKeyModalVisible] = React.useState(false);
    const [result, setResult] = React.useState<Result>({
        title: "",
        message: "",
        resultVisible: false,
    });
    const [okClicked, setOkClicked] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const hideResultAndRedirectToRoot = () => {
        setResult({ title: result.title, message: result.message, resultVisible: false });
        props.onApiKeyDelete();
    };
    const [editApiKeyModalVisible, setEditApiKeyModalVisible] = React.useState(false);
    const [editInProgress, setEditInProgress] = React.useState(false);
    const clipboardId = "uuid-" + props.uuid;
    const [showTooltip, setShowTooltip] = React.useState(false);
    const [copied, setCopied] = React.useState(false);
    const [copyTooltipPosition, setCopyTooltipPosition] = React.useState("0px");
    // This value helps positioning the tooltip.
    const COPY_ICON_OFFSET_POSITION = 55;
    const copyToClipboard = () => {
        navigator.clipboard.writeText(props.uuid);
        setCopied(true);
    };

    const deleteApiKey = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        apiKeysService
            .deleteApiKey(props.uuid, abortController)
            .then(() => {
                setDeleteApiKeyModalVisible(false);
                setResult({
                    title: t("DeleteApiKey.apiKeyDeleted.apiKeyDeletedTitle"),
                    message: t("DeleteApiKey.apiKeyDeleted.successMessage", { name: props.name }),
                    resultVisible: true,
                });
                setOkClicked(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setOkClicked(false);
                    setDeleteApiKeyModalVisible(false);
                    setResult({
                        title: t("DeleteApiKey.apiKeyDeleted.apiKeyNotDeletedTitle"),
                        message: t("DeleteApiKey.apiKeyDeleted.failureMessage"),
                        resultVisible: true,
                    });
                }
            });
    };

    const editApiKey = async ({ name, description }: FormValues): Promise<void> => {
        setEditInProgress(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        apiKeysService
            .editApiKey(props.uuid, name, description, abortController)
            .then(() => {
                setResult({
                    title: t("EditApiKey.apiKeyEditTitle"),
                    message: t("EditApiKey.successMessage"),
                    resultVisible: true,
                });
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setResult({
                        title: t("EditApiKey.apiKeyEditFailedTitle"),
                        message: t("EditApiKey.failureMessage"),
                        resultVisible: true,
                    });
                }
            })
            .finally(() => {
                setEditInProgress(false);
                setEditApiKeyModalVisible(false);
            });
    };

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const handleApiKeyDelete = () => {
        setOkClicked(true);
        deleteApiKey();
    };

    return (
        <div className={style.apiKeysIcon}>
            <Menu className={style.kebabMenu}>
                <ul>
                    <li>
                        <span>
                            <MenuItem
                                id={clipboardId}
                                onClick={copyToClipboard}
                                onMouseLeave={() => {
                                    setShowTooltip(false);
                                    setCopied(false);
                                }}
                                onMouseOver={(event) => {
                                    setShowTooltip(true);
                                    setCopyTooltipPosition(
                                        (event.nativeEvent.clientY - COPY_ICON_OFFSET_POSITION).toString() + "px"
                                    );
                                }}
                            >
                                <span
                                    hidden={!showTooltip}
                                    className={style.tooltip}
                                    style={{ top: copyTooltipPosition }}
                                >
                                    {copied ? t("Common.copied") : t("Common.copyToClipboard")}
                                </span>
                                <Copy color={props.theme.primaryButtonBackgroundColor} />
                            </MenuItem>
                        </span>
                    </li>
                    <li>
                        <TextWithTooltip text={t("DeleteApiKey.tooltipDelete")}>
                            <MenuItem
                                onClick={() => {
                                    setDeleteApiKeyModalVisible(true);
                                    usageStatisticsService.sendEvent({
                                        category: Category.API_KEYS,
                                        action: Action.DELETE_API_KEY,
                                    });
                                }}
                            >
                                <Delete color={props.theme.primaryButtonBackgroundColor} />
                            </MenuItem>
                        </TextWithTooltip>
                    </li>
                    <li>
                        <TextWithTooltip text={t("EditApiKey.tooltipEdit")}>
                            <MenuItem
                                onClick={() => {
                                    setEditApiKeyModalVisible(true);
                                    usageStatisticsService.sendEvent({
                                        category: Category.API_KEYS,
                                        action: Action.EDIT_API_KEY,
                                    });
                                }}
                            >
                                <Edit color={props.theme.primaryButtonBackgroundColor} />
                            </MenuItem>
                        </TextWithTooltip>
                    </li>
                </ul>
            </Menu>
            <Modal
                isOpen={deleteApiKeyModalVisible}
                hideModal={() => setDeleteApiKeyModalVisible(false)}
                modalTitle={t("DeleteApiKey.title")}
            >
                {okClicked ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        {deleteApiKeyModalVisible ? (
                            <>
                                <div className={style.resultContainer}>
                                    {t("DeleteApiKey.introductionMessage", { name: props.name })}
                                </div>
                                <div className={style.buttonContainer}>
                                    <button
                                        className={classNames(buttons.secondaryButton, buttons.medium, style.okButton)}
                                        onClick={() => setDeleteApiKeyModalVisible(false)}
                                        data-testid={testIds.common.dialog.closeButton}
                                    >
                                        {t("Common.cancel")}
                                    </button>

                                    <button
                                        className={classNames(
                                            buttons.primaryButton,
                                            style.deleteButton,
                                            buttons.medium,
                                            style.okButton
                                        )}
                                        data-testid={testIds.common.confirmationDialog.confirmButton}
                                        onClick={handleApiKeyDelete}
                                    >
                                        {t("Common.delete")}
                                    </button>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                )}
            </Modal>
            <Modal isOpen={result.resultVisible} hideModal={hideResultAndRedirectToRoot} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                        onClick={hideResultAndRedirectToRoot}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
            <Modal
                isOpen={editApiKeyModalVisible}
                hideModal={() => setEditApiKeyModalVisible(false)}
                modalTitle={t("EditApiKey.title")}
            >
                {editInProgress ? (
                    <LoadingIndicator />
                ) : (
                    <EditApiKeyForm name={props.name} description={props.description} onSuccess={editApiKey} />
                )}
            </Modal>
        </div>
    );
};

export default connector(ApiKeysKebabMenu);

import * as React from "react";
import { useState } from "react";
import { ErrorMessage, Formik } from "formik";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";
import loginStyle from "./login.scss";
import classNames from "classnames";

import testIds from "testIds.json";
import { EMAIL_MAX_LENGTH } from "globalConstants";

interface Props {
    successfulLoginHandler: (values: FormValues) => void;
    loginErrorMessage: string | null;
}

export interface FormValues {
    email: string;
    password: string;
}

export default function LoginForm(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [passwordVisible, togglePasswordVisibility] = useState<boolean>(false);

    const handleInvalid = (e: React.SyntheticEvent) => e.preventDefault();
    return (
        <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values) => props.successfulLoginHandler(values)}
            validationSchema={object().shape({
                email: string()
                    .required(t("LoginForm.emptyEmail"))
                    .email(t("LoginForm.invalidEmail"))
                    .max(EMAIL_MAX_LENGTH),
                password: string().required(t("LoginForm.emptyPassword")),
            })}
        >
            {(formProps) => {
                const { values, errors, isSubmitting, handleChange, handleBlur, handleSubmit, touched } = formProps;
                return (
                    <form onSubmit={handleSubmit}>
                        <div className={loginStyle.field}>
                            <input
                                autoFocus
                                id="email"
                                data-testid={testIds.login.form.usernameInput.itself}
                                type="text"
                                className={loginStyle.input}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onInvalid={handleInvalid}
                                max={EMAIL_MAX_LENGTH}
                                required
                            />
                            <label
                                htmlFor="email"
                                className={classNames(loginStyle.label, {
                                    [loginStyle.inputError]: errors.email && touched.email,
                                })}
                            >
                                {t("LoginForm.emailAddress")}
                            </label>
                        </div>

                        <div className={loginStyle.error} data-testid={testIds.login.form.usernameInput.errorLabel}>
                            <ErrorMessage name="email" />
                        </div>

                        <div className={loginStyle.field}>
                            <a
                                className={loginStyle.passwordVisibilityToggle}
                                onClick={() => togglePasswordVisibility((prevState) => !prevState)}
                            >
                                {passwordVisible ? [t("LoginForm.hide")] : [t("LoginForm.show")]}
                            </a>
                            <input
                                id="password"
                                data-testid={testIds.login.form.passwordInput.itself}
                                type={passwordVisible ? "text" : "password"}
                                className={loginStyle.input}
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onInvalid={handleInvalid}
                                required
                            />

                            <label
                                htmlFor="password"
                                className={classNames(loginStyle.label, {
                                    [loginStyle.inputError]: errors.password && touched.password,
                                })}
                            >
                                {t("LoginForm.password")}
                            </label>
                        </div>

                        <div className={loginStyle.error} data-testid={testIds.login.form.passwordInput.errorLabel}>
                            <ErrorMessage name="password" />
                        </div>
                        <div className={loginStyle.loginError}>{props.loginErrorMessage}</div>
                        <button
                            type="submit"
                            className={loginStyle.submitButton}
                            disabled={isSubmitting}
                            data-testid={testIds.login.form.submitButton}
                        >
                            {t("Common.loginTitle")}
                        </button>
                    </form>
                );
            }}
        </Formik>
    );
}

import * as React from "react";
import ApplicationLayout from "components/layout/ApplicationLayout";
import ErasureReportsTable from "./ErasureReportsTable";
import { REPORTS_ROUTE } from "components/router/Routes";
import { useTranslation } from "react-i18next";

export default function AllErasureReportsView(): JSX.Element {
    const { t } = useTranslation();

    return (
        <ApplicationLayout
            viewTitle={t("Common.allErasureReports")}
            view={<ErasureReportsTable owned={false} />}
            mainRoute={REPORTS_ROUTE}
        />
    );
}

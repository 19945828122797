import * as React from "react";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";
import style from "./general-setting-view.scss";
import DateCell from "components/table/DateCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { ApiKeysData } from "domain/apiKeys";
import { RepositoryKey } from "services/utils/repository";
import { apiKeysService } from "services/api-keys/ApiKeysService";
import Modal from "components/modal/Modal";
import { ApiKeyDetailView } from "components/api-keys-settings/api-key-detail/ApiKeyDetailView";
import KebabMenu from "components/kebab-menu/KebabMenu";
import ApiKeysKebabMenu from "./ApiKeysKebabMenu";
interface TableState {
    apiKeysData: ApiKeysData[];
}
export function MyApiKeysTable(props: {
    initialApiKeyData: ApiKeysData[];
    onApiKeyDelete: () => void;
    onApiKeyEdit: () => void;
    count: number;
}): JSX.Element {
    const { t } = useTranslation();
    const [apiKeyProfileVisibility, setApiKeyProfileVisibility] = React.useState(false);
    const showApiKeyProfile = (apiKeysdata: ApiKeysData) => {
        setApiKeyProfileVisibility(true);
        setActiveApiKeyDetails(apiKeysdata);
    };

    const hideApiKeyProfile = () => {
        setApiKeyProfileVisibility(false);
    };

    const columns: Array<Column<ApiKeysData>> = [
        {
            Header: () => <TextWithTooltip text={t("ApiKeys.name")} key="1" />,
            accessor: "name",
            Cell: (cellInfo) => (
                <>
                    <KebabMenu>
                        <ApiKeysKebabMenu
                            uuid={cellInfo.cell.row.original.uuid}
                            name={cellInfo.cell.row.original.name}
                            description={cellInfo.cell.row.original.description}
                            onApiKeyDelete={props.onApiKeyDelete}
                            onApiKeyEdit={props.onApiKeyEdit}
                        />
                    </KebabMenu>
                    <div
                        className={style.apiKeysNameCell}
                        onClick={() => {
                            showApiKeyProfile(cellInfo.cell.row.original);
                        }}
                    >
                        <TextWithTooltip text={cellInfo.value} />
                    </div>
                </>
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("ApiKeys.description")} key="2" />,
            accessor: "description",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("ApiKeys.creationDate")} key="3" />,
            accessor: "created",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
        },
    ];

    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [tableState, setTableState] = React.useState<TableState>({ apiKeysData: props.initialApiKeyData });
    const [activeApiKeyDetails, setActiveApiKeyDetails] = React.useState<ApiKeysData>(props.initialApiKeyData[0]);
    const fetchData = (abortController: AbortController) => {
        setInitialLoading(true);
        apiKeysService
            .fetchApiKeys(true, abortController)
            .then((data) => {
                setTableState((prevState) => ({
                    ...prevState,
                    apiKeysData: data.sort(
                        (a, b) => a.name.localeCompare(b.name) || a.created.localeCompare(b.created)
                    ),
                }));
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("ApiKeys.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        if (props.initialApiKeyData.length > 0) {
            return;
        }
        const abortController = new AbortController();
        fetchData(abortController);

        return () => {
            abortController.abort();
        };
    }, [props.count]);

    const modal =
        activeApiKeyDetails != null ? (
            <Modal
                key={1}
                isOpen={apiKeyProfileVisibility}
                hideModal={hideApiKeyProfile}
                modalTitle={t("ApiKeysProfile.title", { apiKeyName: activeApiKeyDetails.name })}
            >
                <ApiKeyDetailView activeApiKey={activeApiKeyDetails} />
            </Modal>
        ) : null;

    return (
        <>
            <Table
                tableIdentity={RepositoryKey.MY_API_KEYS_TABLE}
                data={tableState.apiKeysData}
                columns={columns}
                loaded={!initialLoading}
                failureMessage={requestFailureMessage}
                tooltips={true}
                emptyMessage={t("ApiKeys.emptyStateMessage")}
            />
            {modal}
        </>
    );
}

import * as React from "react";
import style from "./device-detail-view.scss";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import testIds from "testIds.json";
import { DeviceDetail, DiagnosticReportResponse } from "domain/reports";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";

export interface DeviceDetailsProps {
    diagnosticReportResponse: DiagnosticReportResponse;
}

function createMakeAndModel(deviceDetail: DeviceDetail) {
    const value = deviceDetail.make ?? "";
    if (deviceDetail.model == null) {
        return value;
    }
    return value + " " + deviceDetail.model;
}
function createDeviceDetails(
    t: TFunction,
    translationKey: string,
    deviceDetailTestId: string,
    deviceDetail: string | undefined
) {
    if (deviceDetail == null) {
        return null;
    }
    return (
        <>
            <div className={style.label}>{t("DiagnosticReportsTable.deviceDetail." + translationKey)}</div>
            <div data-testid={deviceDetailTestId}>{deviceDetail}</div>
        </>
    );
}

export default function DeviceDetailView(props: DeviceDetailsProps): JSX.Element {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [diagnosticReportData, setDiagnosticReportData] = React.useState<DiagnosticReportResponse>();

    React.useEffect(() => {
        setDiagnosticReportData(props.diagnosticReportResponse);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    });

    if (typeof diagnosticReportData === "undefined") {
        return <LoadingIndicator />;
    }

    return (
        <>
            <div className={style.userProfile}>
                {createDeviceDetails(
                    t,
                    "id",
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.idLabel,
                    diagnosticReportData.deviceDetail.id
                )}
                {createDeviceDetails(
                    t,
                    "make",
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.makeLabel,
                    createMakeAndModel(diagnosticReportData.deviceDetail)
                )}
                {createDeviceDetails(
                    t,
                    "color",
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.colorLabel,
                    diagnosticReportData.deviceDetail.color
                )}
                {createDeviceDetails(
                    t,
                    "imei",
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.imeiLabel,
                    diagnosticReportData.deviceDetail.imei
                )}
                {createDeviceDetails(
                    t,
                    "system",
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.systemLabel,
                    diagnosticReportData.deviceDetail.operatingSystem
                )}
                {createDeviceDetails(
                    t,
                    "carrier",
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.carrierLabel,
                    diagnosticReportData.deviceDetail.carrier
                )}
                {createDeviceDetails(
                    t,
                    "capacity",
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.capacityLabel,
                    diagnosticReportData.deviceDetail.capacity
                )}
                {createDeviceDetails(
                    t,
                    "location",
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.locationLabel,
                    diagnosticReportData.deviceDetail.location
                )}
                {createDeviceDetails(
                    t,
                    "timezone",
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.timezoneLabel,
                    diagnosticReportData.deviceDetail.timezone
                )}
                {createDeviceDetails(
                    t,
                    "version",
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.versionLabel,
                    diagnosticReportData.deviceDetail.softwareVersion
                )}
                {createDeviceDetails(
                    t,
                    "tagCode",
                    testIds.workArea.report.diagnostic.reportDialog.deviceDetail.tagCodeLabel,
                    diagnosticReportData.deviceDetail.tagCode
                )}
            </div>
        </>
    );
}

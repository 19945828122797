import { LicenseData, Key, ConfigureData } from "domain/licenses";
import { apiGatewayService } from "services/api/ApiGatewayService";

import { SummaryLabelParameter } from "components/licenses/assign-bms-keys/Form";

export interface LicensesData {
    licenses: LicenseData[];
}

export interface LicenseKey {
    licenseKeys: Key[];
}

export interface ConfigurationData {
    configuration: ConfigureData[];
}

export interface CommonDto {
    start_date: string;
    expiration_date: string;
}

export interface KeyDto extends CommonDto {
    identifier: string;
    license_key: string;
    assigned: number;
    used: number;
    remaining: number;
    usage_hours_limit: number;
    license_type: string;
    short_url: string;
}

function toKey(dto: KeyDto): Key {
    return {
        licenseKey: dto.license_key,
        identifier: dto.identifier,
        assigned: dto.assigned,
        used: dto.used,
        remaining: dto.remaining,
        startDate: dto.start_date,
        expirationDate: dto.expiration_date,
        usageHoursLimit: dto.usage_hours_limit,
        licenseType: dto.license_type,
        shortUrl: dto.short_url,
    };
}

export interface LicenseKeyAssignment {
    keyCount: number;
    association: string;
    identifier?: string;
    hours: number;
    licenseType: string;
    available: number;
    assignCount: number;
    startDate: string;
    expirationDate: string;
}

interface LicenseKeyAssignmentDto extends CommonDto {
    key_count: number;
    identifier?: string;
    hours: number;
    assign_count: number;
    license_type: string;
}

class LicenseService {
    public fetchLicenses(abortController: AbortController): Promise<LicensesData> {
        return apiGatewayService.invokeApi("/licenses", "GET", null, { abortController });
    }

    public addLicenses(
        licenseId: string,
        amount: number,
        expirationDate: string,
        abortController: AbortController
    ): Promise<void> {
        return apiGatewayService.invokeApi(
            "/licenses",
            "POST",
            { license_id: licenseId, amount, expiration_date: expirationDate },
            { abortController }
        );
    }

    public fetchLicenseKeys(abortController: AbortController): Promise<LicenseKey> {
        return apiGatewayService
            .invokeApi("/license-keys", "GET", null, { abortController: abortController })
            .then((response: { licenseKeys: KeyDto[] }) => {
                return { licenseKeys: response.licenseKeys.map(toKey) };
            });
    }

    public assignLicenseKey(assignment: LicenseKeyAssignment, abortController?: AbortController): Promise<void> {
        const dto: LicenseKeyAssignmentDto = {
            key_count: assignment.keyCount,
            identifier: assignment.identifier,
            hours: assignment.hours,
            assign_count: assignment.assignCount,
            start_date: assignment.startDate,
            expiration_date: assignment.expirationDate,
            license_type: assignment.licenseType,
        };
        return apiGatewayService.invokeApi("/license-keys", "POST", dto, { abortController });
    }

    public addConfiguration(configuration: string, abortController: AbortController): Promise<void> {
        const configData = JSON.parse(configuration);
        return apiGatewayService.invokeApi("/client-configurations", "POST", configData, {
            abortController,
        });
    }

    public saveConfiguration(uuid: string, configuration: string, abortController: AbortController): Promise<void> {
        const configData = JSON.parse(configuration);
        return apiGatewayService.invokeApi("/client-configurations/" + uuid, "POST", configData, {
            abortController,
        });
    }

    public fetchConfigurationData(abortController: AbortController): Promise<ConfigurationData> {
        return apiGatewayService.invokeApi("/client-configurations", "GET", null, { abortController });
    }

    public fetchClientConfigurationData(uuid: string | undefined, abortController: AbortController): Promise<string> {
        return apiGatewayService.invokeApi("/client-configurations/" + uuid, "GET", null, {
            abortController,
        });
    }

    public fetchDefaultConfigurationData(abortController: AbortController): Promise<string> {
        return apiGatewayService.invokeApi("/client-configurations/default", "GET", null, {
            abortController,
        });
    }

    public fetchAvailableLicenses(
        type: string | undefined,
        abortController: AbortController
    ): Promise<SummaryLabelParameter> {
        return apiGatewayService.invokeApi("/licenses/" + type, "GET", null, { abortController });
    }
}

export const licenseService = new LicenseService();

import { Workflow, WorkflowTableData } from "domain/workflows";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

export interface WorkflowDto {
    name: string;
    uuid: string;
    sequence_id: number;
    profile: string;
    created_date: string;
    user_uuid: string;
    user_name: string;
    default_workflow: DefaultWorkflow;
    workflow: Workflow;
    own: boolean;
    modified: string;
    modified_by_user_uuid: string;
    modified_by_username: string;
}

export interface DefaultWorkflow {
    own: boolean;
    other: OtherDefaultWorkflow;
}

export interface OtherDefaultWorkflow {
    count: number;
    usernames: string[];
}

export interface WorkflowsResponseDto {
    workflows: WorkflowDto[];
}

export interface WorkflowEditorDto {
    version: string;
    profile: string;
    url: string;
}

export interface WorkflowEditorsResponseDto {
    editors: WorkflowEditorDto[];
}

export interface FetchProfileListResponse {
    editors: WorkflowEditorDto[];
    version: string;
    timestamp: string;
    url: string;
}

export interface SuccessResponse {
    message: string;
}

export interface SaveSuccessResponse extends SuccessResponse {
    uuid: string;
    modified: string;
    modifiedBy: string;
}

export interface SaveSuccessResponseDto {
    message: string;
    uuid: string;
    modified: string;
    modified_by: string;
}

export interface SetDefaultResponse extends SuccessResponse {
    oldDefaultUuid: string;
    oldDefaultName: string;
    newDefaultUuid: string;
    newDefaultName: string;
    profile: string;
}

export function toWorkflow(dto: WorkflowDto): WorkflowTableData {
    return {
        name: dto.name,
        uuid: dto.uuid,
        id: dto.sequence_id,
        // TODO BCC-816 Deprecate
        product: dto.profile,
        profile: dto.profile.toLowerCase() as Profile,
        createdDate: dto.created_date,
        userUuid: dto.user_uuid,
        creator: dto.user_name,
        defaultWorkflow: dto.default_workflow,
        workflow: dto.workflow,
        own: dto.own,
    };
}

export function toSaveSuccessResponse(dto: SaveSuccessResponseDto): SaveSuccessResponse {
    return {
        message: dto.message,
        uuid: dto.uuid,
        modified: dto.modified,
        modifiedBy: dto.modified_by,
    };
}

export enum Profile {
    ALL = "all",
    BDE = "bde",
    BMDE = "bmde",
}

export const profileToNameMap = new Map([
    [Profile.BDE, "Blancco Drive Eraser"],
    [Profile.BMDE, "Blancco Mobile Diagnostics and Erasure"],
]);

const PATH_API_WORKFLOWS = "/api/workflows";

class WorkflowService {
    // TODO BCC-816 Deprecate
    public fetchProfileListBMDE(abortController?: AbortController): Promise<FetchProfileListResponse> {
        return apiGatewayService.invokeApi("/workflow-editors/bmde/manifest.json.", "GET", null, { abortController });
    }

    // TODO BCC-816 Deprecate
    public fetchProfileListBDE(abortController?: AbortController): Promise<FetchProfileListResponse> {
        return apiGatewayService.invokeApi("/workflow-editors/bde/manifest.json.", "GET", null, { abortController });
    }

    public async deleteWorkflow(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi(`${PATH_API_WORKFLOWS}/${uuid}`, "DELETE", null, {
            abortController: abortController,
            refreshSession: true,
            apiType: ApiType.OLIVER,
            includeCredentials: true,
        });
    }

    public async fetchWorkflowEditors(
        profile: Profile,
        abortController?: AbortController
    ): Promise<WorkflowEditorDto[]> {
        return apiGatewayService
            .invokeApi("/workflow-editors/" + profile + "/manifest.json", "GET", null, {
                abortController: abortController,
                refreshSession: true,
                apiType: ApiType.STAN,
                includeCredentials: true,
            })
            .then((dto: WorkflowEditorsResponseDto) => dto.editors);
    }

    public async fetchWorkflows(abortController?: AbortController): Promise<WorkflowTableData[]> {
        return apiGatewayService
            .invokeApi(PATH_API_WORKFLOWS, "GET", null, {
                abortController: abortController,
                refreshSession: true,
                apiType: ApiType.OLIVER,
                includeCredentials: true,
            })
            .then((dto: WorkflowsResponseDto) => dto.workflows.map(toWorkflow));
    }

    public async create(workflow: Workflow, abortController?: AbortController): Promise<SaveSuccessResponse> {
        return apiGatewayService
            .invokeApi(
                PATH_API_WORKFLOWS,
                "POST",
                { workflow },
                {
                    abortController,
                    refreshSession: true,
                    apiType: ApiType.OLIVER,
                    includeCredentials: true,
                }
            )
            .then((dto: SaveSuccessResponseDto) => toSaveSuccessResponse(dto));
    }

    public async update(
        uuid: string,
        workflow: Workflow,
        abortController?: AbortController
    ): Promise<SaveSuccessResponse> {
        return apiGatewayService
            .invokeApi(
                `${PATH_API_WORKFLOWS}/${uuid}`,
                "PUT",
                { workflow },
                {
                    abortController,
                    refreshSession: true,
                    apiType: ApiType.OLIVER,
                    includeCredentials: true,
                }
            )
            .then((dto: SaveSuccessResponseDto) => toSaveSuccessResponse(dto));
    }

    public async fetch(uuid: string, abortController?: AbortController): Promise<WorkflowDto> {
        return apiGatewayService.invokeApi(`${PATH_API_WORKFLOWS}/${uuid}`, "GET", null, {
            abortController: abortController,
            refreshSession: true,
            apiType: ApiType.OLIVER,
            includeCredentials: true,
        });
    }

    public async setDefault(uuid: string, abortController?: AbortController): Promise<SetDefaultResponse> {
        return apiGatewayService.invokeApi(`${PATH_API_WORKFLOWS}/${uuid}/default`, "POST", null, {
            abortController,
            refreshSession: true,
            apiType: ApiType.OLIVER,
            includeCredentials: true,
        });
    }
}

export const workflowService = new WorkflowService();

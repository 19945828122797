import * as React from "react";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";

import DateCell from "components/table/DateCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { LicenseData } from "domain/licenses";
import { RepositoryKey } from "services/utils/repository";
import { licenseService } from "services/licenses/LicenseService";

import testIds from "testIds.json";
import { StoreState } from "store";
import { connect, ConnectedProps } from "react-redux";
import { setHasBmsBbtiLicenses, setHasBmsLicenses, setHasBmsValidationLicenses } from "store/license";

interface Props {
    requestFailureMessage: string;
    count: number;
}

const mapState = (state: StoreState) => ({
    hasBmsLicenses: state.licensesReducer.hasBmsLicenses,
});
const connector = connect(mapState, { setHasBmsLicenses, setHasBmsValidationLicenses, setHasBmsBbtiLicenses });

const LicensesTable = (props: Props & ConnectedProps<typeof connector>) => {
    const { t } = useTranslation();
    const columns: Array<Column<LicenseData>> = [
        {
            Header: () => <TextWithTooltip text={t("Licenses.products")} key="1" />,
            accessor: "product",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("Licenses.licenseType")} key="2" />,
            accessor: "license_type",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("Licenses.total")} key="3" />,
            accessor: "total",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value.toString()} />,
        },
        {
            Header: () => <TextWithTooltip text={t("Licenses.available")} key="4" />,
            accessor: "available",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value.toString()} />,
        },
        {
            Header: () => <TextWithTooltip text={t("Licenses.expirationDate")} key="5" />,
            accessor: "expiration_date",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} />,
        },
    ];

    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>(props.requestFailureMessage);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [licenses, setLicenses] = React.useState<LicenseData[]>([]);

    const fetchData = (initialLoading: boolean) => {
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licenseService
            .fetchLicenses(abortController)
            .then((licensesData) => {
                licensesData.licenses.sort(
                    (a, b) => a.product.localeCompare(b.product) || a.license_type.localeCompare(b.license_type)
                );
                setLicenses(licensesData.licenses);
                const hasValidationLicenses = licensesData.licenses.some(
                    (license) => license.type === "bms-validation"
                );
                props.setHasBmsValidationLicenses(hasValidationLicenses);
                const hasBbtiLicenses = licensesData.licenses.some((license) => license.type === "bms-bbti");
                props.setHasBmsBbtiLicenses(hasBbtiLicenses);
                props.setHasBmsLicenses(hasValidationLicenses || hasBbtiLicenses);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("Licenses.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        fetchData(true);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [props.count]);

    let dataCount = null;
    if (licenses.length > 0) {
        dataCount = t("Common.defaultSearchResultHint", { dataCount: licenses.length });
    }

    return (
        <>
            {dataCount}
            <Table
                tableIdentity={RepositoryKey.LICENSE_TABLE}
                data={licenses}
                columns={columns}
                loaded={!initialLoading}
                failureMessage={requestFailureMessage}
                tooltips={true}
                emptyMessage={t("Licenses.emptyStateMessage")}
                testId={testIds.workArea.license.table}
            />
        </>
    );
};

LicensesTable.defaultProps = {
    requestFailureMessage: "",
};

export default connector(LicensesTable);

import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";

import DateCell from "components/table/DateCell";
import Table from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import Export from "components/icons/Export";
import { StoreState } from "store";
import { Key } from "domain/licenses";
import { RepositoryKey } from "services/utils/repository";
import { LicenseKey, licenseService } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";

import testIds from "testIds.json";
import style from "./license-keys-table.scss";
import { getStanUrl } from "services/login/endpointRepository";
import { TOOLTIP_DELAY } from "globalConstants";
import { setHasBmsBbtiLicenses, setHasBmsLicenses, setHasBmsValidationLicenses } from "store/license";
import { ShortUrlTableCell } from "components/licenses/ShortUrlTableCell";

interface TableState {
    licenseKey: LicenseKey;
}

interface Props {
    initialLicensekeys: Key[];
    requestFailureMessage: string;
    count: number;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    hasBmsLicenses: state.licensesReducer.hasBmsLicenses,
});
const connector = connect(mapState, {
    setHasBmsLicenses,
    setHasBmsValidationLicenses,
    setHasBmsBbtiLicenses,
});

const LicenseKeysTable = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const licenseTypes: Map<string, string> = new Map([
        ["bms-validation", "Validation"],
        ["bms-bbti", "Buy-Back / Trade In"],
    ]);

    const columns: Array<Column<Key>> = [
        {
            Header: () => <TextWithTooltip text={t("LicenseKeys.licenseKey")} key="licenseKey" />,
            accessor: "licenseKey",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseKeys.license")} key="licenseType" />,
            accessor: "licenseType",
            Cell: ({ cell: { value } }) => (
                <TextWithTooltip text={licenseTypes.has(value) ? (licenseTypes.get(value) as string) : ""} />
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseKeys.identifier")} key="identifier" />,
            accessor: "identifier",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseKeys.assigned")} key="assigned" />,
            accessor: "assigned",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value.toString()} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseKeys.used")} key="used" />,
            accessor: "used",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value.toString()} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseKeys.remaining")} key="remaining" />,
            accessor: "remaining",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value.toString()} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseKeys.startDate")} key="startDate" />,
            accessor: "startDate",
            Cell: ({ cell: { value } }) => <DateCell withoutTime={true} tooltip={true} value={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseKeys.expirationDate")} key="expirationDate" />,
            accessor: "expirationDate",
            Cell: ({ cell: { value } }) => <DateCell withoutTime={true} tooltip={true} value={value} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseKeys.usageHoursLimit")} key="usageHoursLimit" />,
            accessor: "usageHoursLimit",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value.toString()} />,
        },
        {
            Header: () => <TextWithTooltip text={t("LicenseKeys.shortUrl")} key="shortUrl" />,
            accessor: "shortUrl",
            width: 230,
            Cell: (cellInfo) => <ShortUrlTableCell shortUrl={cellInfo.cell.row.original.shortUrl} />,
        },
    ];
    const [state, setLicensesData] = React.useState<TableState>({
        licenseKey: { licenseKeys: props.initialLicensekeys },
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>(props.requestFailureMessage);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);

    const fetchData = (initialLoading: boolean) => {
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        licenseService
            .fetchLicenseKeys(abortController)
            .then((licenseKey) => {
                // First, License Keys are sorted in descending order by Start Date
                // Second, License Keys are sorted in ascending order by identifier: if identifier is null, empty string is considered for sorting
                licenseKey.licenseKeys.sort(
                    (a, b) =>
                        b.startDate.localeCompare(a.startDate) || (a.identifier ?? "").localeCompare(b.identifier ?? "")
                );
                setLicensesData({ licenseKey });
                props.setHasBmsLicenses(true);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("LicenseKeys.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        if (props.initialLicensekeys.length > 0) {
            return;
        }
        fetchData(true);
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, [props.count]);

    const exportLicenseKeys = () => {
        usageStatisticsService.sendEvent({
            category: Category.LICENSE,
            action: Action.EXPORT_LICENSE_KEYS,
        });

        window.location.href = getStanUrl() + "/license-keys/export";
    };

    const exportButton =
        state.licenseKey.licenseKeys.length > 0 ? (
            <div className={style.exportButton}>
                <div>{t("Common.defaultSearchResultHint", { dataCount: state.licenseKey.licenseKeys.length })}</div>
                <div
                    className={style.export}
                    data-testid={testIds.workArea.license.bms.exportKeysButton}
                    data-tip={t("LicenseKeys.export.tooltip")}
                    data-delay-show={TOOLTIP_DELAY}
                    onClick={exportLicenseKeys}
                >
                    <Export color={props.theme.linkTextColor} />
                    <span>{t("LicenseKeys.export.title")}</span>
                </div>
            </div>
        ) : null;

    return (
        <>
            {exportButton}
            <Table
                tableIdentity={RepositoryKey.LICENSE_TABLE}
                data={state.licenseKey.licenseKeys}
                columns={columns}
                loaded={!initialLoading}
                failureMessage={requestFailureMessage}
                tooltips={true}
                emptyMessage={t("LicenseKeys.emptyStateMessage")}
                testId={testIds.workArea.license.bms.table.itself}
            />
        </>
    );
};

LicenseKeysTable.defaultProps = {
    initialLicensekeys: [],
    requestFailureMessage: "",
};

export default connector(LicenseKeysTable);

import { TableData } from "./table";

export interface ApiKeysData extends TableData {
    uuid: string;
    name: string;
    description: string;
    created: string;
    owner: string;
}

export interface ApiKeysDto {
    uuid: string;
    name: string;
    description: string;
    created: string;
    owner: string;
}

export function toApiKey(dto: ApiKeysDto): ApiKeysData {
    return {
        uuid: dto.uuid,
        name: dto.name,
        description: dto.description,
        created: dto.created,
        owner: dto.owner,
    };
}

export interface ApiKeyDetail {
    uuid: string;
    name: string;
    description: string;
    created: string;
}

export interface GenerateApiKeyResponse {
    apiKey: string;
}

import * as React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import AddUserForm, { FormValues } from "./AddUserForm";
import Modal from "components/modal/Modal";
import UnexpectedErrorMessage from "components/unexpected-error-message/UnexpectedErrorMessage";
import { userService } from "services/user/users/UserService";

import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import style from "./add-user.scss";

import testIds from "testIds.json";
import { usageStatisticsService, Category, Action } from "services/statistics/UsageStatisticsService";

interface Result {
    title: string;
    message: string | JSX.Element;
}

interface CreateUserResponse {
    code: string;
    message: string;
    requestId?: string;
}

const AddUserView = (props: { onUserAdded: () => void }): JSX.Element => {
    const { t } = useTranslation();

    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const { current: abortControllers } = React.useRef<AbortController[]>([]);

    React.useEffect(() => {
        return () => abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort());
    });

    const addUserSubmitEventHandler = async ({ fullName, email }: FormValues): Promise<void> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            await userService.createUser(fullName, email, abortController);
        } catch (e) {
            if (!signal.aborted) {
                let error: CreateUserResponse = { code: "", message: "" };
                try {
                    error = JSON.parse(e.message).error;
                } catch (ex) {
                    console.error("Error while parsing exception body:", ex);
                }
                showResult({
                    title: t("AddUserView.userFailure"),
                    message: getErrorMessage(error, email),
                });
                console.error("Failed to add a user:", e);
                if (error.message === "UsernameExistsException") {
                    throw new Error(error.message);
                }
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        hideAddUserForm();
        showResult({
            title: t("AddUserView.userSuccess"),
            message: fullName
                ? t("AddUserView.nameSuccessMessage", { name: fullName })
                : t("AddUserView.emailSuccessMessage", { email }),
        });
    };

    const getErrorMessage = (error: CreateUserResponse, email: string): string | JSX.Element => {
        let errorMessage: string;
        if (error.code !== "BAD_REQUEST") {
            return getUnexpectedErrorMessage(error.requestId as string);
        } else {
            if (error.message === "Invalid email") {
                if (email === "") {
                    errorMessage = t("AddUserView.errorMessages.missingEmail");
                } else {
                    errorMessage = t("AddUserView.errorMessages.invalidEmail", { email });
                }
            } else if (error.message === "UsernameExistsException") {
                errorMessage = t("AddUserView.errorMessages.conflictingEmail", { email });
            } else {
                return getUnexpectedErrorMessage(error.requestId as string);
            }
        }
        return errorMessage;
    };

    const getUnexpectedErrorMessage = (requestId: string): JSX.Element => {
        return <UnexpectedErrorMessage requestId={requestId as string} />;
    };

    const [addUserFormVisible, setAddUserFormVisible] = React.useState(false);
    const [resultVisible, setResultVisible] = React.useState(false);

    const showAddUserForm = () => {
        usageStatisticsService.sendEvent({
            category: Category.USER,
            action: Action.ADD_USER,
        });
        setAddUserFormVisible(true);
    };

    const hideAddUserForm = () => {
        setAddUserFormVisible(false);
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };

    const hideResult = () => {
        setResultVisible(false);
        props.onUserAdded();
    };

    return (
        <div>
            <button
                className={classNames(buttons.primaryButton, buttons.small)}
                onClick={showAddUserForm}
                data-testid={testIds.workArea.user.addUserButton}
            >
                {t("AddUserView.userButton")}
            </button>
            <Modal isOpen={addUserFormVisible} hideModal={hideAddUserForm} modalTitle={t("AddUserForm.title")}>
                <div className={form.fixedWidthModal}>
                    <div className={style.summaryText}>{t("AddUserForm.addUserSummaryText")}</div>
                    <AddUserForm submitEventHandler={addUserSubmitEventHandler} />
                </div>
            </Modal>
            <Modal isOpen={resultVisible} hideModal={hideResult} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                        onClick={hideResult}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default AddUserView;

import * as endpointRepository from "../login/endpointRepository";

const POST_HEADERS = { "Content-Type": "application/json" };
const CREDENTIALS = "include";
export enum ApiType {
    STAN = "stan",
    GLOBAL_STAN = "globalStan",
    OLIVER = "oliver",
    MAINSTREAM = "mainstream",
}

class ApiGatewayService {
    /**
     * Invokes desired API endpoint with the given method and request body.
     *
     * @param apiPath           Endpoint to invoke.
     * @param method            Method to use, e.g., "get" or "post".
     * @param body              Request body as an object.
     * @param abortController   The AbortController for the fetch call
     * @param refreshSession    When set to true, automatically attempts to refresh the session if the backend responds
     *                          with HTTP status 401 or 403.
     * Defaults to true.
     */
    public async invokeApi<K, T>(
        apiPath: string,
        method: string,
        body: K | null = null,
        {
            abortController = undefined,
            refreshSession = true,
            apiType = ApiType.STAN,
            headers = undefined,
            includeCredentials = true,
        }: {
            abortController?: AbortController;
            refreshSession?: boolean;
            apiType?: ApiType;
            headers?: HeadersInit;
            includeCredentials?: boolean;
        } = {}
    ): Promise<T> {
        const url = ApiGatewayService.getApiUrl(apiPath, apiType);

        refreshSession = refreshSession ?? true;

        method = method.toUpperCase();

        const commonRequestInit: RequestInit = {
            method,
            signal: abortController !== undefined ? abortController.signal : undefined,
        };

        if (includeCredentials) {
            commonRequestInit.credentials = CREDENTIALS;
        }

        const requestInit =
            method === "GET" || method === "HEAD"
                ? {
                      ...commonRequestInit,
                      ...{
                          headers: headers === null ? {} : headers,
                      },
                  }
                : {
                      ...commonRequestInit,
                      ...{
                          headers: {
                              ...POST_HEADERS,
                              ...(headers === null ? {} : headers),
                          },
                          body: JSON.stringify(body === null ? {} : body),
                      },
                  };
        const response = await fetch(url, requestInit);
        if (refreshSession && [401, 403].includes(response.status)) {
            try {
                await this.invokeApi("/authentication/refresh", "get", null, {
                    abortController,
                    refreshSession: false,
                });
            } catch (e) {
                const path = window.location.pathname;
                if (path !== "/logout") {
                    window.location.replace("/logout");
                }
            }
            return await this.invokeApi(apiPath, method, body, {
                abortController,
                refreshSession: false,
                apiType,
                headers,
                includeCredentials,
            });
        }
        if (200 <= response.status && response.status < 300) {
            return response.json();
        }
        throw new Error(await response.text());
    }

    static getApiUrl(apiPath: string, apiType: ApiType = ApiType.STAN): string {
        let url;
        if (apiType === ApiType.OLIVER) {
            url = endpointRepository.getOliverUrl();
        } else if (apiType === ApiType.MAINSTREAM) {
            url = process.env.MAINSTREAM_GLOBAL_URL;
        } else if (window.location.hostname === "localhost") {
            url = process.env.STAN_URL;
        } else if (apiType === ApiType.GLOBAL_STAN) {
            url = process.env.STAN_GLOBAL_URL;
        } else {
            url = endpointRepository.getStanUrl();
        }

        return url + apiPath;
    }
}

export const apiGatewayService = new ApiGatewayService();

import React from "react";
import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikProps, FormikConfig } from "formik";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";

import { EMAIL_MAX_LENGTH } from "globalConstants";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";

import style from "./add-user.scss";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";
import { userService } from "services/user/users/UserService";
import { CheckEmailAvailability } from "domain/users";

interface Props {
    submitEventHandler: (values: FormValues) => Promise<void>;
}

export interface FormValues {
    fullName: string;
    email: string;
}

export default function AddUserForm(props: Props): JSX.Element {
    const { t } = useTranslation();
    const abortController = new AbortController();
    const [loading, setLoading] = React.useState<boolean>(false);
    const submitHandler: FormikConfig<FormValues>["onSubmit"] = async (values, { setErrors, setSubmitting }) => {
        values.email = values.email.toLowerCase();
        setSubmitting(false);
        try {
            setLoading(true);
            const emailAvailability: CheckEmailAvailability = await userService.checkEmailAvailability(
                values.email,
                abortController
            );
            if (emailAvailability.emailIsAvailable) {
                await props.submitEventHandler(values);
                setSubmitting(true);
            } else {
                setLoading(false);
                setErrors({ email: t("Common.emailNotAvailable") });
            }
        } catch (e) {
            setLoading(false);
            setErrors({ email: t("Common.emailNotAvailable") });
        }
    };

    return (
        <Formik
            initialValues={{ email: "", fullName: "" }}
            onSubmit={submitHandler}
            validationSchema={object().shape({
                fullName: string().max(255),
                email: string()
                    .required(t("AddUserForm.emailRequired"))
                    .email(t("Common.invalidEmail"))
                    .max(EMAIL_MAX_LENGTH),
            })}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ values, errors, isSubmitting, handleChange, handleBlur }: FormikProps<FormValues>) => {
                if (isSubmitting) {
                    return <LoadingIndicator />;
                }

                const loader = loading ? (
                    <div className={style.loaderContainer}>
                        <LoadingIndicator small={true} />
                    </div>
                ) : (
                    ""
                );

                return (
                    <Form>
                        <div className={form.formFields}>
                            <span className={form.optional}>{t("Common.optional")}</span>
                            <label htmlFor="fullName" className={form.label}>
                                {t("AddUserForm.fullName")}
                            </label>
                            <input
                                id="fullName"
                                className={classNames(form.input, form.fixedWidthInput)}
                                maxLength={255}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.fullName}
                                autoFocus
                                data-testid={testIds.workArea.user.addUserDialog.nameInput.itself}
                            />
                        </div>

                        <div className={form.formFields}>
                            <label htmlFor="email" className={form.label + (errors.email ? " " + form.inputError : "")}>
                                {t("Common.emailWithColon")}
                            </label>

                            <input
                                id="email"
                                className={classNames(form.input, form.fixedWidthInput, {
                                    [form.inputError]: errors.email,
                                })}
                                maxLength={EMAIL_MAX_LENGTH}
                                onChange={handleChange}
                                value={values.email}
                                data-testid={testIds.workArea.user.addUserDialog.emailInput.itself}
                                onBlur={() => submitHandler}
                            />
                            {loader}

                            <div
                                className={form.error}
                                data-testid={testIds.workArea.user.addUserDialog.emailInput.errorLabel}
                            >
                                <ErrorMessage name="email" />
                            </div>
                        </div>
                        <div className={form.buttonContainer}>
                            <button
                                type="submit"
                                className={classNames(buttons.primaryButton, buttons.medium, form.submitButton)}
                                disabled={isSubmitting}
                                data-testid={testIds.workArea.user.addUserDialog.submitButton}
                            >
                                {t("AddUserView.userButton")}
                            </button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

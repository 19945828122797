import * as React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Modal from "components/modal/Modal";
import buttons from "styles/buttons.scss";
import testIds from "testIds.json";
import TextWithTooltip from "components/table/TextWithTooltip";
import { UserDetails } from "domain/user";
import WorkflowEditDialog from "../workflow-edit-dialog/WorkflowEditDialog";

interface Props {
    user: UserDetails | null;
    onUpdate: () => void;
}
const CreateWorkflowView = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [createWorkflowVisibility, setCreateWorkflowVisibility] = React.useState(false);
    const [confirmCancelVisible, setConfirmCancelVisible] = React.useState(false);
    const hideCreateWorkflow = (doCancel: boolean) => {
        if (doCancel) {
            setCreateWorkflowVisibility(false);
        }
        setConfirmCancelVisible(false);
    };
    const showCreateWorkflow = () => {
        setConfirmCancelVisible(false);
        setCreateWorkflowVisibility(true);
    };

    return (
        <div>
            <TextWithTooltip
                text={t("ManageWorkflowDialog.selectWorkflowEditor.createWorkflowToolTip", {
                    tenantName: props.user?.tenantName,
                })}
            >
                <button
                    className={classNames(buttons.primaryButton, buttons.small)}
                    onClick={showCreateWorkflow}
                    data-testid={testIds.workArea.workflows.createWorkflowButton}
                >
                    {t("ManageWorkflowDialog.selectWorkflowEditor.createWorkflowButton")}
                </button>
            </TextWithTooltip>
            <Modal
                key={1}
                isOpen={createWorkflowVisibility}
                hideModal={() => setConfirmCancelVisible(true)}
                modalTitle={t("ManageWorkflowDialog.title.create")}
            >
                <WorkflowEditDialog
                    onCancel={hideCreateWorkflow}
                    confirmCancelVisible={confirmCancelVisible}
                    onUpdateParentView={props.onUpdate}
                    tenantName={props.user?.tenantName}
                />
            </Modal>
        </div>
    );
};

export default CreateWorkflowView;

import * as React from "react";
import { RouteDefinition } from "components/router/Routes";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavigationTogglePanel from "./NavigationTogglePanel";
import style from "./side-navigation.scss";
import { MenuItem, Menu } from "react-aria-menubutton";
import { useLocation } from "react-router-dom";
import { useFeature } from "flagged";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";

interface Props {
    menuClass: string;
    currentItemClass: string;
    childItemClass?: string;
    routes: RouteDefinition[];
    appendChildRoutes: boolean;
}

const connector = connect((state: StoreState) => ({
    user: state.userReducer.user,
}));

const SideNavigation = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const [t] = useTranslation();
    const location = useLocation();

    let activeMenu: string;
    let openMenu = false;
    let inactiveMenu: string;

    const menuItems = props.routes.map((route, index) => {
        const flag = route.getFeatureFlag();
        if (flag != null && !useFeature(flag)) {
            return null;
        }
        const strIndex = index.toString();

        if (route.childRoutes.some((item) => location.pathname === item.path)) {
            activeMenu = strIndex;
            openMenu = true;
        } else {
            openMenu = false;
            if (route.childRoutes.length > 0) {
                inactiveMenu = strIndex;
            }
        }

        return route.childRoutes.length > 0 ? (
            <div key={index}>
                <NavigationTogglePanel
                    isOpen={openMenu}
                    id={strIndex}
                    title={t(route.title)}
                    openId={activeMenu}
                    closeId={inactiveMenu}
                >
                    <Menu className={style.menu}>
                        <MenuItem className={style.item}>
                            {route.childRoutes
                                .filter((childRoute) => {
                                    const flag = route.getFeatureFlag();
                                    return childRoute.isValidUser(props.user) && (flag == null || useFeature(flag));
                                })
                                .map((childRoute) => {
                                    return (
                                        <li key={childRoute.path}>
                                            <NavLink
                                                activeClassName={style.activeChildLink}
                                                className={props.childItemClass}
                                                exact
                                                to={childRoute.path}
                                            >
                                                {t(childRoute.title)}
                                            </NavLink>
                                        </li>
                                    );
                                })}
                        </MenuItem>
                    </Menu>
                </NavigationTogglePanel>
            </div>
        ) : (
            <li key={route.path}>
                <NavLink activeClassName={props.currentItemClass} to={route.path}>
                    {t(route.title)}
                </NavLink>
            </li>
        );
    });

    return (
        <nav className={props.menuClass}>
            <ul>{menuItems}</ul>
        </nav>
    );
};

const connectedSideNavigation = connector(SideNavigation);
export { connectedSideNavigation as SideNavigation };

import * as React from "react";
import classNames from "classnames";
import { Menu, MenuItem } from "react-aria-menubutton";
import { StoreState } from "store";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";

import Delete from "components/icons/Delete";
import Edit from "components/icons/Edit";
import Modal from "components/modal/Modal";
import SetDefault from "components/icons/SetDefault";
import TextWithTooltip from "components/table/TextWithTooltip";
import WorkflowEditDialog from "./workflow-edit-dialog/WorkflowEditDialog";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { Profile, profileToNameMap, workflowService } from "services/workflows/WorkflowService";
import { hasTenantCookie } from "services/tenants/tenantCookieService";

import buttons from "styles/buttons.scss";
import style from "./workflow-kebab-menu.scss";
import testIds from "testIds.json";

interface Result {
    title: string;
    message: string;
    visible: boolean;
}

interface SetDefaultResult extends Result {
    loading: boolean;
    previousDefault?: string;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    userDetail: state.userReducer.user,
});

const connector = connect(mapState);

const WorkflowKebabMenu = (
    props: ConnectedProps<typeof connector> & {
        uuid: string;
        name: string;
        own: boolean;
        defaultWorkflow: boolean;
        onUpdate: () => void;
    }
): JSX.Element => {
    const { t } = useTranslation();
    const [result, setResult] = React.useState<Result>({ title: "", message: "", visible: false });
    const hideResult = () => {
        setResult({ title: result.title, message: result.message, visible: false });
        props.onUpdate();
    };
    const [setDefaultResult, setSetDefaultResult] = React.useState<SetDefaultResult>({
        title: "",
        message: "",
        visible: false,
        loading: true,
        previousDefault: undefined,
    });
    const hideSetDefaultResult = () => {
        setSetDefaultResult({
            title: setDefaultResult.title,
            message: setDefaultResult.message,
            visible: false,
            loading: false,
            previousDefault: setDefaultResult.previousDefault,
        });
        props.onUpdate();
    };
    const [deleteWorkflowModalVisible, setDeleteWorkflowModalVisible] = React.useState(false);
    const [okClicked, setOkClicked] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [confirmationState, setConfirmationState] = React.useState(true);
    const [editWorkflowVisible, setEditWorkflowVisible] = React.useState(false);
    const [confirmCancelVisible, setConfirmCancelVisible] = React.useState(false);
    const hideEditWorkflow = (doCancel: boolean) => {
        if (doCancel) {
            setEditWorkflowVisible(false);
        }
        setConfirmCancelVisible(false);
    };
    const showEditWorkflow = () => {
        setConfirmCancelVisible(false);
        setEditWorkflowVisible(true);
    };

    function handleConfirmation() {
        setConfirmationState(
            (document.getElementById("confirmInput") as HTMLInputElement).value !== t("Common.delete").toUpperCase()
        );
    }
    const deleteWorkflow = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        workflowService
            .deleteWorkflow(props.uuid, abortController)
            .then(() => {
                setDeleteWorkflowModalVisible(false);
                setConfirmationState(true);
                setResult({
                    title: t("DeleteWorkflow.workflowDeleted.workflowDeletedTitle"),
                    message: t("DeleteWorkflow.workflowDeleted.successMessage", { name: props.name }),
                    visible: true,
                });
                setOkClicked(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setOkClicked(false);
                    setDeleteWorkflowModalVisible(false);
                    setConfirmationState(true);
                    setResult({
                        title: t("DeleteWorkflow.workflowDeleted.workflowNotDeletedTitle"),
                        message: t("DeleteWorkflow.workflowDeleted.failureMessage"),
                        visible: true,
                    });
                }
            });
    };

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const setDefault = (workflowUuid: string, showConfirmation: boolean) => {
        usageStatisticsService.sendEvent({
            category: Category.WORKFLOW,
            action: Action.SET_DEFAULT_WORKFLOW,
        });

        const abortController = new AbortController();
        abortControllers.push(abortController);
        setSetDefaultResult({
            title: t("Common.pleaseWait"),
            message: setDefaultResult.message,
            visible: true,
            loading: true,
            previousDefault: undefined,
        });
        workflowService
            .setDefault(workflowUuid, abortController)
            .then((response) => {
                if (showConfirmation) {
                    let message = t("SetDefaultWorkflow.successMessage", {
                        newDefault: response.newDefaultName,
                        product: profileToNameMap.get(response.profile.toLowerCase() as Profile),
                    });
                    if (response.oldDefaultUuid != null) {
                        message +=
                            " " +
                            t("SetDefaultWorkflow.successMessageWithOldDefault", {
                                oldDefault: response.oldDefaultName,
                            });
                    }
                    setSetDefaultResult({
                        title: t("SetDefaultWorkflow.successTitle"),
                        message: message,
                        visible: true,
                        loading: false,
                        previousDefault: response.oldDefaultUuid,
                    });
                } else {
                    setSetDefaultResult({
                        title: t("Common.pleaseWait"),
                        message: setDefaultResult.message,
                        visible: false,
                        loading: true,
                        previousDefault: undefined,
                    });
                    props.onUpdate();
                }
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setResult({
                        title: t("SetDefaultWorkflow.failureTitle"),
                        message: t("SetDefaultWorkflow.failureMessage"),
                        visible: true,
                    });
                }
            });
    };

    const undoSetDefault = () => {
        const previousDefaultUuid = setDefaultResult.previousDefault;
        if (previousDefaultUuid != undefined) {
            setDefault(previousDefaultUuid, false);
        }
    };

    const handleWorkflowDelete = () => {
        setOkClicked(true);
        deleteWorkflow();
    };
    return (
        <div className={style.workflowsIcon}>
            <Menu className={style.kebabMenu} data-testid={testIds.common.table.kebabMenu.itself}>
                <ul>
                    <li>
                        <TextWithTooltip text={t("WorkflowsTable.tooltip.edit")}>
                            <MenuItem
                                onClick={() => {
                                    showEditWorkflow();
                                }}
                                data-testid={testIds.workArea.workflows.table.kebabMenu.editButton}
                            >
                                <Edit color={props.theme.primaryButtonBackgroundColor} />
                            </MenuItem>
                        </TextWithTooltip>
                    </li>
                    {!hasTenantCookie() && !props.defaultWorkflow && (
                        <li>
                            <TextWithTooltip text={t("WorkflowsTable.tooltip.setDefault")}>
                                <MenuItem
                                    onClick={() => {
                                        setDefault(props.uuid, true);
                                    }}
                                    data-testid={testIds.workArea.workflows.table.kebabMenu.setDefaultButton}
                                >
                                    <SetDefault color={props.theme.primaryButtonBackgroundColor} />
                                </MenuItem>
                            </TextWithTooltip>
                        </li>
                    )}
                    <li>
                        <TextWithTooltip
                            text={t("DeleteWorkflow.tooltipDelete", {
                                tenantName: props.userDetail?.tenantName,
                            })}
                        >
                            <MenuItem
                                onClick={() => {
                                    setDeleteWorkflowModalVisible(true);
                                }}
                                data-testid={testIds.workArea.workflows.table.kebabMenu.deleteButton}
                            >
                                <Delete color={props.theme.primaryButtonBackgroundColor} />
                            </MenuItem>
                        </TextWithTooltip>
                    </li>
                </ul>
            </Menu>
            <Modal
                isOpen={deleteWorkflowModalVisible}
                hideModal={() => setDeleteWorkflowModalVisible(false)}
                modalTitle={t("DeleteWorkflow.title")}
            >
                {okClicked ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        {deleteWorkflowModalVisible ? (
                            <>
                                <div className={style.resultContainer}>
                                    {t("DeleteWorkflow.introductionMessage", {
                                        tenantName: props.userDetail?.tenantName,
                                        name: props.name,
                                    })}
                                </div>
                                <div className={style.resultContainer}>{t("DeleteWorkflow.confirmationMessage")}</div>
                                <div className={style.resultContainer}>
                                    <input
                                        id="confirmInput"
                                        type="text"
                                        data-testid={testIds.common.confirmationDialog.confirmInput}
                                        placeholder={t("Common.delete").toUpperCase()}
                                        onChange={handleConfirmation}
                                    />
                                </div>

                                <div className={style.okButtonContainer}>
                                    <button
                                        className={classNames(buttons.secondaryButton, buttons.medium, style.okButton)}
                                        onClick={() => setDeleteWorkflowModalVisible(false)}
                                        data-testid={testIds.common.dialog.closeButton}
                                    >
                                        {t("Common.cancel")}
                                    </button>

                                    <button
                                        className={classNames(
                                            buttons.primaryButton,
                                            style.deleteButton,
                                            buttons.medium,
                                            style.okButton
                                        )}
                                        data-testid={testIds.common.confirmationDialog.confirmButton}
                                        onClick={handleWorkflowDelete}
                                        disabled={confirmationState}
                                    >
                                        {t("Common.delete")}
                                    </button>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                )}
            </Modal>
            <Modal isOpen={result.visible} hideModal={hideResult} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                        onClick={hideResult}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
            <Modal
                isOpen={editWorkflowVisible}
                hideModal={() => setConfirmCancelVisible(true)}
                modalTitle={t("ManageWorkflowDialog.title.manage")}
            >
                <WorkflowEditDialog
                    onCancel={hideEditWorkflow}
                    confirmCancelVisible={confirmCancelVisible}
                    onUpdateParentView={props.onUpdate}
                    workflowUuid={props.uuid}
                />
            </Modal>
            <Modal
                isOpen={setDefaultResult.visible}
                hideModal={() => hideSetDefaultResult()}
                modalTitle={setDefaultResult.title}
            >
                {setDefaultResult.loading && <LoadingIndicator />}
                {!setDefaultResult.loading && (
                    <>
                        <div className={style.resultContainer}>{setDefaultResult.message}</div>
                        <div className={style.buttonContainer}>
                            {setDefaultResult.previousDefault != null && (
                                <button
                                    className={classNames(buttons.secondaryButton, buttons.medium)}
                                    onClick={undoSetDefault}
                                    data-testid={testIds.common.confirmationDialog.undoButton}
                                >
                                    {t("Common.undo")}
                                </button>
                            )}
                            <button
                                className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                                onClick={hideSetDefaultResult}
                                data-testid={testIds.common.confirmationDialog.confirmButton}
                            >
                                {t("Common.ok")}
                            </button>
                        </div>
                    </>
                )}
            </Modal>
        </div>
    );
};

export default connector(WorkflowKebabMenu);

import React from "react";

import style from "./page.scss";
import alertIcon from "assets/images/icons/alertIcon.svg";

interface Props {
    error: JSX.Element | string;
}

const Error = (props: Props): JSX.Element => {
    return (
        <div className={style.error}>
            <img src={alertIcon} />
            {props.error}
        </div>
    );
};

export default Error;

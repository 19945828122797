import * as React from "react";
import { Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { CardDetails, EsgData } from "domain/dashboard";
import LandfillWastePreventedChart from "components/dashboard/esg/LandfillWastePreventedChart";
import { Card } from "components/dashboard/Card";
import style from "../dashboard.scss";
import EmissionsPreventedChart from "components/dashboard/esg/EmissionsPreventedChart";
import Co2EquivalentsChart from "./Co2EquivalentsChart";
import * as DashboardRepository from "services/dashboard/dashboardRepository";
import { dashboardService } from "services/dashboard/DashboardService";
import TotalProcessedDevicesChart from "components/dashboard/esg/TotalProcessedDevicesChart";
import { useTranslation } from "react-i18next";

interface CardLayout {
    cols: Record<string, number>;
    rowHeight: number;
    isResizable: boolean;
    margin: [number, number];
    isBounded: boolean;
}

interface State {
    dashboardData: EsgData;
    dataFetched: boolean;
}

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const Esg = (props: CardLayout): JSX.Element => {
    const { t } = useTranslation();

    const landfillWastePrevented = "landfillWastePrevented";
    const emissionsPrevented = "emissionsPrevented";
    const totalProcessedDevices = "totalProcessedDevices";
    const co2Equivalents = "co2Equivalents";
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [state, setState] = React.useState<State>({
        dashboardData: { months: [] },
        dataFetched: false,
    });

    function fetchDashboardData() {
        const abortController = new AbortController();
        dashboardService
            .fetchDashboardData(abortController)
            .then((data) => {
                setState({ dashboardData: data, dataFetched: true });
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("EsgDashboardView.requestFailed"));
                }
            });
    }

    const cards: CardDetails[] = [
        {
            key: landfillWastePrevented,
            title: "EsgDashboardView.landfillWastePreventedChart.landfillWastePrevented",
            children: (
                <LandfillWastePreventedChart dataFetched={state.dataFetched} widgetData={state.dashboardData.months} />
            ),
        },
        {
            key: emissionsPrevented,
            title: "EsgDashboardView.co2EmissionsPreventedChart.emissionsPrevented",
            children: (
                <EmissionsPreventedChart dataFetched={state.dataFetched} widgetData={state.dashboardData.months} />
            ),
        },
        {
            key: totalProcessedDevices,
            title: "EsgDashboardView.totalOfProcessedDevicesChart.totalProcessedDevices",
            children: (
                <TotalProcessedDevicesChart dataFetched={state.dataFetched} widgetData={state.dashboardData.months} />
            ),
        },
        {
            key: co2Equivalents,
            title: "EsgDashboardView.co2EquivalentsChart.co2Equivalents",
            children: <Co2EquivalentsChart dataFetched={state.dataFetched} widgetData={state.dashboardData.months} />,
        },
    ];

    const defaultLayout = {
        lg: [
            { w: 1, h: 17, x: 0, y: 0, i: landfillWastePrevented },
            { w: 1, h: 17, x: 1, y: 0, i: emissionsPrevented },
            { w: 1, h: 17, x: 0, y: 17, i: totalProcessedDevices },
            { w: 1, h: 17, x: 1, y: 35, i: co2Equivalents },
        ],
        md: [
            { w: 1, h: 17, x: 0, y: 0, i: landfillWastePrevented },
            { w: 1, h: 17, x: 1, y: 0, i: emissionsPrevented },
            { w: 1, h: 17, x: 0, y: 17, i: totalProcessedDevices },
            { w: 1, h: 17, x: 1, y: 35, i: co2Equivalents },
        ],
        sm: [
            { w: 1, h: 15, x: 0, y: 0, i: landfillWastePrevented },
            { w: 1, h: 15, x: 1, y: 0, i: emissionsPrevented },
            { w: 1, h: 15, x: 0, y: 15, i: totalProcessedDevices },
            { w: 1, h: 15, x: 1, y: 30, i: co2Equivalents },
        ],
        xs: [
            { w: 1, h: 15, x: 0, y: 0, i: landfillWastePrevented },
            { w: 1, h: 15, x: 1, y: 15, i: emissionsPrevented },
            { w: 1, h: 15, x: 2, y: 30, i: totalProcessedDevices },
            { w: 1, h: 15, x: 3, y: 45, i: co2Equivalents },
        ],
        xxs: [
            { w: 1, h: 15, x: 0, y: 0, i: landfillWastePrevented },
            { w: 1, h: 15, x: 1, y: 15, i: emissionsPrevented },
            { w: 1, h: 15, x: 2, y: 30, i: totalProcessedDevices },
            { w: 1, h: 15, x: 3, y: 45, i: co2Equivalents },
        ],
    };

    const layout: Layouts = DashboardRepository.getESGCardOrderLayout() || defaultLayout;
    React.useEffect(() => {
        fetchDashboardData();
    }, []);
    if (requestFailureMessage != "") {
        return <div className={style.failureMessage}>{requestFailureMessage}</div>;
    } else {
        return (
            <>
                <ResponsiveReactGridLayout
                    className={style.dashboardViewContainer}
                    layouts={layout}
                    onLayoutChange={(_layout, layouts) => DashboardRepository.setESGCardOrderLayout(layouts)}
                    {...props}
                >
                    {cards.map((card) => {
                        return (
                            <div key={card.key} className={style.cardContainer}>
                                <Card key={card.key} title={card.title}>
                                    {card.children}
                                </Card>
                            </div>
                        );
                    })}
                </ResponsiveReactGridLayout>
            </>
        );
    }
};

Esg.defaultProps = {
    cols: { lg: 2, md: 2, sm: 2, xs: 1, xxs: 1 },
    margin: [40, 40],
    isBounded: true,
    rowHeight: 1,
    isResizable: false,
};

export default Esg;

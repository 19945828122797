import { ApiKeysData, GenerateApiKeyResponse, ApiKeysDto, toApiKey } from "domain/apiKeys";
import { apiGatewayService } from "services/api/ApiGatewayService";

export interface ApiKeyData {
    apiKeys: ApiKeysData[];
}

interface ApiKeysResponseDto {
    api_keys: ApiKeysDto[];
}

interface ApiKeyDto {
    api_key: string;
}

function ToApiKeyGet(dto: ApiKeyDto): GenerateApiKeyResponse {
    return {
        apiKey: dto.api_key,
    };
}

class ApiKeysService {
    public async fetchApiKeys(owned: boolean, abortController: AbortController): Promise<ApiKeysData[]> {
        const result: ApiKeysResponseDto = await apiGatewayService.invokeApi("/api-keys?owned=" + owned, "GET", null, {
            abortController,
        });
        return result["api_keys"].map(toApiKey);
    }

    public deleteApiKey(uuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi("/api-keys/" + uuid, "DELETE", null, { abortController: abortController });
    }

    public editApiKey(
        uuid: string,
        name: string,
        description: string,
        abortController: AbortController
    ): Promise<void> {
        const postData = { name, description };
        return apiGatewayService.invokeApi("/api-keys/" + uuid, "POST", postData, {
            abortController,
        });
    }

    public generateApiKey(
        name: string,
        description: string,
        abortController: AbortController
    ): Promise<GenerateApiKeyResponse> {
        return apiGatewayService
            .invokeApi("/api-keys", "POST", { name, description }, { abortController })
            .then((dtos: ApiKeyDto) => ToApiKeyGet(dtos));
    }
}

export const apiKeysService = new ApiKeysService();

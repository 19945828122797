import * as React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import {
    resetLicenseChildRoutes,
    enableLicenseChildRoutes,
    LICENSES_ALL_LICENSES_ROUTE,
    LOGIN_ROUTE,
    ROUTES,
    LICENSES_BMS_LICENSES_ROUTE,
    CONFIGURATION_LICENSES_ROUTE,
} from "components/router/Routes";
import { SecuredRoute } from "components/router/SecuredRoute";
import style from "styles/style.scss";
import { connect, ConnectedProps } from "react-redux";
import { setUser } from "store/user";
import { ThemeName } from "store/theme";
import { StoreState } from "store";
import { userSessionService } from "services/user/UserSessionService";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import i18n from "i18n";

const mapState = (state: StoreState) => ({
    authenticated: state.userReducer.user !== null,
    themeName: state.userReducer.user !== null ? state.themeReducer.themeName : ThemeName.DEFAULT,
    user: state.userReducer.user,
    hasBmsLicenses: state.licensesReducer.hasBmsLicenses,
});
const connector = connect(mapState, { setUser });

function setBodyClass(themeName: ThemeName) {
    const classList = document.body.classList;
    for (const themeClass of Object.values(ThemeName)) {
        classList.remove(themeClass);
    }
    classList.add(themeName);
    resetLicenseChildRoutes();
}

const BlanccoCommonCloud = (props: ConnectedProps<typeof connector>) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [isLanguageLoaded, setIsLanguageLoaded] = useState<boolean>(false);

    i18n.on("loaded", () => setIsLanguageLoaded(true));

    useEffect(() => {
        if (!props.authenticated && loading && isLanguageLoaded) {
            const user = userSessionService.currentUserDetails();
            setLoading(false);
            i18n.off("loaded");
            if (user != null) {
                props.setUser(user);
            }
        }
    }, [isLanguageLoaded]);

    setBodyClass(props.themeName);
    return (
        <div className={style.applicationContainer}>
            {loading ? (
                <LoadingIndicator />
            ) : (
                <Router>
                    <Switch>
                        {ROUTES.map((route, index) => {
                            if (!route.isValidUser(props.user)) {
                                return null;
                            }
                            if (
                                route.childRoutes.includes(LICENSES_ALL_LICENSES_ROUTE) ||
                                route.path.includes(LICENSES_BMS_LICENSES_ROUTE.path) ||
                                route.path.includes(CONFIGURATION_LICENSES_ROUTE.path)
                            ) {
                                if (props.hasBmsLicenses) {
                                    enableLicenseChildRoutes(props.hasBmsLicenses);
                                }
                            }
                            return (
                                <SecuredRoute
                                    path={route.path}
                                    key={index}
                                    component={route.component}
                                    secured={route.secured}
                                    authenticated={props.authenticated}
                                />
                            );
                        })}
                        <Redirect from="/" to={LOGIN_ROUTE.path} />
                    </Switch>
                </Router>
            )}
        </div>
    );
};

export default connector(BlanccoCommonCloud);

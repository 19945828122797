import Modal from "components/modal/Modal";
import ReportView from "./ReportView";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ToggleablePanel from "components/header/ToggleablePanel";
import style from "./report-view.scss";
import { MenuItem, Menu } from "react-aria-menubutton";
import testIds from "testIds.json";
import { getLanguage } from "services/language/languageRepository";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { getOliverUrl } from "services/login/endpointRepository";

export default function ErasureReportModal(props: { reportUuid: string; closeHandler: () => void }): JSX.Element {
    const languageCode = getLanguage().code;
    const visible = props.reportUuid !== "";
    if (!visible) {
        return <></>;
    }

    const [t] = useTranslation();

    const baseUrl = getOliverUrl() + "/reports/" + props.reportUuid + "?language=" + languageCode + "&format=";
    const menuItemParts = [
        ["pdf", t("ErasureReport.export.pdf")],
        ["xml", t("ErasureReport.export.xml")],
    ];
    const menuItems = menuItemParts.map((each, index) => (
        <a key={index} href={baseUrl + each[0]}>
            {each[1]}
        </a>
    ));

    const title = useTranslation()["t"]("ErasureReport.title");

    return (
        <>
            <Modal isOpen={visible} hideModal={props.closeHandler} modalTitle={title}>
                <ToggleablePanel
                    title="Export"
                    testId={testIds.workArea.report.erasure.exportMenu}
                    menuType="reportMenu"
                >
                    <Menu className={style.menu}>
                        <ul>
                            {menuItems.map((item, key) => (
                                <li key={key}>
                                    <MenuItem
                                        className={style.item}
                                        onClick={() => {
                                            usageStatisticsService.sendEvent({
                                                action: Action.EXPORT_ERASURE_REPORT,
                                                category: Category.REPORTS,
                                                label: item.props.href.replace(baseUrl, ""),
                                            });
                                        }}
                                    >
                                        {item}
                                    </MenuItem>
                                </li>
                            ))}
                        </ul>
                    </Menu>
                </ToggleablePanel>
                <ReportView title={title} reportUuid={props.reportUuid} languageCode={languageCode} />
            </Modal>
        </>
    );
}

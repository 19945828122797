import * as React from "react";
import style from "./report-view.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { getOliverUrl } from "services/login/endpointRepository";

const ReportView = (props: { title: string; reportUuid: string; languageCode: string }): JSX.Element => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const loadingIndicator = loading ? <LoadingIndicator /> : null;
    return (
        <>
            <div>
                <div className={style.loadContainer}>{loadingIndicator}</div>
                <div hidden={loading}>
                    <iframe
                        title={props.title}
                        src={
                            getOliverUrl() +
                            "/reports/" +
                            props.reportUuid +
                            "?language=" +
                            props.languageCode +
                            "&format=HTML"
                        }
                        className={style.reportContainer}
                        onLoad={() => setLoading(false)}
                        referrerPolicy="same-origin"
                    />
                </div>
            </div>
        </>
    );
};

export default ReportView;

import classNames from "classnames";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import {
    Profile,
    SaveSuccessResponse,
    WorkflowDto,
    WorkflowEditorDto,
    workflowService,
} from "services/workflows/WorkflowService";
import VisualEditorView, {
    WorkflowEditor,
} from "components/workflows/workflow-edit-dialog/visual-editor/VisualEditorView";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { Workflow } from "domain/workflows";
import JsonEditorView from "components/workflows/workflow-edit-dialog/json-editor/JsonEditorView";

import testIds from "testIds.json";

import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import style from "./workflow-edit-dialog.scss";
import { formatTimestamp } from "components/table/DateCell";

import crossIcon from "assets/images/icons/cross.svg";
import Modal from "components/modal/Modal";

enum DialogState {
    FETCHING_WORKFLOW_EDITORS,
    FETCHING_WORKFLOW_EDITORS_FAILED,
    FETCHING_WORKFLOW,
    FETCHING_WORKFLOW_FAILED,
    SELECTING_WORKFLOW_EDITOR,
    EDITING,
    ASYNC_SAVING_WORKFLOW,
    ASYNC_SAVING_WORKFLOW_SUCCEEDED,
    ASYNC_SAVING_WORKFLOW_FAILED,
    SYNC_SAVING_WORKFLOW,
    SYNC_SAVING_WORKFLOW_SUCCEEDED,
    SYNC_SAVING_WORKFLOW_FAILED,
}

enum TabIndex {
    VISUAL_EDITOR,
    JSON_EDITOR,
}

interface Props {
    workflowUuid?: string;
    onCancel: (doCancel: boolean) => void;
    confirmCancelVisible?: boolean;
    onUpdateParentView: () => void;
    tenantName?: string;
}

const WORKFLOW_NAME_MAX_LENGTH = 255;

const WorkflowEditDialog: React.FunctionComponent<Props> = (props) => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [workflow, setWorkflow] = useState<Workflow | undefined>(undefined);
    const [workflowUuid, setWorkflowUuid] = useState(props.workflowUuid);
    const [workflowEditors, setWorkflowEditors] = useState<WorkflowEditorDto[]>([]);
    const workflowEditor = useRef<WorkflowEditor | undefined>(undefined);
    const [dialogState, setDialogState] = React.useState(DialogState.FETCHING_WORKFLOW);
    const [selectedProfile, setSelectedProfile] = React.useState<Profile>(Profile.BMDE);
    const [selectedVersion, setSelectedVersion] = React.useState<string | undefined>(undefined);
    const [optionList, setOptionList] = React.useState<JSX.Element[] | undefined>(undefined);
    const [selectedTab, setSelectedTab] = React.useState(TabIndex.VISUAL_EDITOR);
    const [lastModification, setLastModification] = React.useState<{ username: string; timestamp: string } | undefined>(
        undefined
    );
    const [saveError, setSaveError] = React.useState<{ field: string; message: string } | string | undefined>(
        undefined
    );
    const [workflowName, setWorkflowName] = React.useState("");
    const [workflowNameInputValue, setWorkflowNameInputValue] = React.useState("");

    const fetchWorkflow = (uuid: string): Promise<WorkflowDto> => {
        setDialogState(DialogState.FETCHING_WORKFLOW);

        const abortController = new AbortController();
        abortControllers.push(abortController);

        return workflowService.fetch(uuid, abortController);
    };

    const fetchWorkflowEditors = (profile: Profile): Promise<WorkflowEditorDto[]> => {
        setDialogState(DialogState.FETCHING_WORKFLOW);

        const abortController = new AbortController();
        abortControllers.push(abortController);

        return workflowService.fetchWorkflowEditors(profile, abortController);
    };

    const saveWorkflow = (workflow: Workflow, uuid?: string): Promise<SaveSuccessResponse> => {
        const abortController = new AbortController();
        abortControllers.push(abortController);

        if (typeof uuid === "string") {
            return workflowService.update(uuid, workflow, abortController);
        }

        return workflowService.create(workflow, abortController);
    };

    const extractWorkflowProfile = (targetWorkflow: Workflow) => {
        return (targetWorkflow.editorMetadata?.profile?.toLowerCase() as Profile) ?? Profile.BMDE;
    };

    const deduceSource = (): Workflow | undefined => {
        if (TabIndex.VISUAL_EDITOR === selectedTab) {
            // The visual editor doesn't support updating the state so the workflow data needs to be queried
            try {
                const json = workflowEditor.current?.serializeWorkflow();
                if (typeof json === "string") {
                    return JSON.parse(json);
                }
            } catch (e) {
                console.error("Visual editor failed to serialize the workflow during save: " + e);
            }
        } else if (TabIndex.JSON_EDITOR === selectedTab) {
            return workflow;
        }
        console.error("Deduce workflow source failed");
    };

    const handleSaveClick = () => {
        const source = deduceSource();

        if (source !== undefined) {
            setDialogState(DialogState.ASYNC_SAVING_WORKFLOW);
            setSaveError(undefined);
            saveWorkflow(source, workflowUuid)
                .then((response) => {
                    setWorkflowUuid(response.uuid);
                    setLastModification({ username: response.modifiedBy, timestamp: response.modified });
                    setDialogState(DialogState.ASYNC_SAVING_WORKFLOW_SUCCEEDED);
                })
                .catch((error) => {
                    try {
                        const response = JSON.parse(error.message);
                        setSaveError({ field: response.fieldName, message: response.errorMessage });
                    } catch (e) {
                        // This error message doesn't have field details
                    }
                    setDialogState(DialogState.ASYNC_SAVING_WORKFLOW_FAILED);
                });
        } else {
            setSaveError(t("ManageWorkflow.saveWorkflow.failureMessage"));
            setDialogState(DialogState.ASYNC_SAVING_WORKFLOW_FAILED);
        }
    };

    const handleSaveCloseClick = () => {
        const source = deduceSource();

        if (source !== undefined) {
            setDialogState(DialogState.SYNC_SAVING_WORKFLOW);
            saveWorkflow(source, workflowUuid)
                .then(() => {
                    setDialogState(DialogState.SYNC_SAVING_WORKFLOW_SUCCEEDED);
                })
                .catch(() => setDialogState(DialogState.SYNC_SAVING_WORKFLOW_FAILED));
        } else {
            setSaveError(t("ManageWorkflow.saveWorkflow.failureMessage"));
            setDialogState(DialogState.SYNC_SAVING_WORKFLOW_SUCCEEDED);
        }
    };

    const handleTabSelect = (index: number, lastIndex: number) => {
        if (index !== lastIndex) {
            if (TabIndex.VISUAL_EDITOR === lastIndex) {
                const source = deduceSource();
                if (source !== undefined) {
                    setWorkflow(source);
                    setWorkflowName(source.name);
                    setWorkflowNameInputValue(source.name);
                }
            }
            setSelectedTab(index as TabIndex);
        }
        return true;
    };

    const handleSelectedProfileChanged = (event: React.FormEvent<HTMLSelectElement>) => {
        setSelectedProfile(event.currentTarget.value as Profile);
    };

    const handleSelectedVersionChanged = (event: React.FormEvent<HTMLSelectElement>) => {
        setSelectedVersion(event.currentTarget.value);
    };

    const confirmWorkflowEditorSelection = () => {
        setDialogState(DialogState.EDITING);
    };

    const handleBackToEditorClick = () => {
        setDialogState(DialogState.EDITING);
    };

    const handleWorkflowNameInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        setWorkflowNameInputValue(event.currentTarget.value);
    };

    const handleWorkflowNameInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleWorkflowNameChange();
        }
    };

    /**
     * Handles state change when the user has changed the workflow's name in the input element. The new name is updated
     * to the workflow stored in the state.
     */
    const handleWorkflowNameChange = () => {
        if (workflow !== undefined) {
            setWorkflow({
                ...workflow,
                name: workflowNameInputValue,
            });
        } else {
            const source = deduceSource();
            if (source !== undefined) {
                setWorkflow({
                    ...source,
                    name: workflowNameInputValue,
                });
            }
        }
        setWorkflowName(workflowNameInputValue);
    };

    /**
     * Handles state change when the user has used one of the editors to change the workflow. The name in the workflow
     * is updated to the workflow name stored in the state.
     */
    const handleWorkflowChange = (workflow: Workflow) => {
        setWorkflow(workflow);
        if (workflow !== undefined) {
            setWorkflowNameInputValue(workflow.name);
            setWorkflowName(workflow.name);
        } else {
            const source = deduceSource();
            if (source !== undefined) {
                setWorkflow({
                    ...source,
                    name: workflowNameInputValue,
                });
            }
        }
    };

    /**
     * Handles state change where the workflow name might have been changed both in the name input and in the editor.
     * The name in the name field takes priority unless it is not set there.
     */
    const handleWorkflowChangeWithNameSync = (workflow: Workflow) => {
        if (workflow === undefined) {
            return;
        }

        const nameFromEditor = workflow.name.trim();
        const nameFromInput = workflowName.trim();

        const nameSetWithEditor = nameFromEditor.length > 0;
        const nameSetWithInput = nameFromInput.length > 0;

        if (nameSetWithEditor && !nameSetWithInput) {
            // The name was set with the visual editor but not with the name input.
            // Sync the name from the output of the visual editor to the name input
            setWorkflowName(workflow.name);
        } else if (!nameSetWithEditor && nameSetWithInput) {
            // The name wasn't set with the visual editor but was set with the name input.
            // Sync the name from the input to the output of the visual editor.
            workflow.name = nameFromInput;
        } else if (nameSetWithInput) {
            workflow.name = nameFromInput;
        }

        setWorkflow(workflow);
        return workflow;
    };

    useEffect(() => {
        if (typeof workflowUuid === "string") {
            fetchWorkflow(workflowUuid)
                .then((workflow) => {
                    handleWorkflowChange(workflow.workflow);
                    setLastModification({ username: workflow.modified_by_username, timestamp: workflow.modified });
                    fetchWorkflowEditors(Profile.ALL)
                        .then((workflowEditors) => {
                            setWorkflowEditors(workflowEditors);
                            setDialogState(DialogState.EDITING);
                        })
                        .catch(() => {
                            setDialogState(DialogState.FETCHING_WORKFLOW_EDITORS_FAILED);
                        });
                })
                .catch(() => setDialogState(DialogState.FETCHING_WORKFLOW_FAILED));
        } else {
            fetchWorkflowEditors(Profile.ALL)
                .then((workflowEditors) => {
                    setWorkflowEditors(workflowEditors);
                    setSelectedProfile(Profile.BMDE);
                    setDialogState(DialogState.SELECTING_WORKFLOW_EDITOR);
                })
                .catch(() => setDialogState(DialogState.FETCHING_WORKFLOW_EDITORS_FAILED));
        }

        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    useEffect(() => {
        const filteredEditors = workflowEditors.filter((editor) => editor.profile === selectedProfile);
        if (filteredEditors.length == 0) {
            return;
        }
        setSelectedVersion(filteredEditors[0].version);
        setOptionList(
            filteredEditors
                .sort((left, right) => {
                    const versionLeft = left.version.toLowerCase();
                    const versionRight = right.version.toLowerCase();
                    if (versionLeft < versionRight) {
                        return 1;
                    }
                    if (versionLeft > versionRight) {
                        return -1;
                    }
                    return 0;
                })
                .map((editor) => <option key={editor.version}>{editor.version}</option>)
        );
    }, [selectedProfile, workflowEditors]);

    const confirmationModal = (
        <Modal
            isOpen={props.confirmCancelVisible ?? false}
            hideModal={() => {
                props.onCancel(false);
            }}
            modalTitle={t("ManageWorkflowDialog.confirmCancelEditingDialog.title")}
        >
            <p>{t("ManageWorkflowDialog.confirmCancelEditingDialog.confirmationText")}</p>
            <div className={style.buttonContainer}>
                <button
                    className={classNames(buttons.deleteButton, buttons.medium, style.button)}
                    onClick={() => {
                        props.onCancel(true);
                    }}
                    data-testid={testIds.common.confirmationDialog.confirmButton}
                >
                    {t("ManageWorkflowDialog.confirmCancelEditingDialog.closeDontSaveButton")}
                </button>
                <button
                    className={classNames(buttons.primaryButton, buttons.medium, style.button)}
                    onClick={() => {
                        props.onCancel(false);
                    }}
                    data-testid={testIds.common.dialog.closeButton}
                >
                    {t("ManageWorkflowDialog.confirmCancelEditingDialog.keepEditingButton")}
                </button>
            </div>
        </Modal>
    );

    let saveStatus = <></>;
    switch (dialogState) {
        case DialogState.ASYNC_SAVING_WORKFLOW:
            saveStatus = (
                <div className={style.loadingMessage}>{t("ManageWorkflowDialog.saveWorkflow.loadingMessage")}</div>
            );
            break;
        case DialogState.ASYNC_SAVING_WORKFLOW_FAILED:
            saveStatus = (
                <div className={style.failureMessage}>
                    <img src={crossIcon} />
                    {saveError !== undefined
                        ? typeof saveError === "string"
                            ? saveError
                            : t("ManageWorkflowDialog.saveWorkflow.failureMessageDetails", saveError)
                        : t("ManageWorkflowDialog.saveWorkflow.failureMessage")}
                </div>
            );
            break;
        case DialogState.ASYNC_SAVING_WORKFLOW_SUCCEEDED:
        case DialogState.EDITING:
            saveStatus =
                lastModification === undefined ? (
                    <div className={style.statusMessage}>{t("ManageWorkflowDialog.saveWorkflow.notSaved")}</div>
                ) : (
                    <div className={style.statusMessage}>
                        {t("ManageWorkflowDialog.saveWorkflow.lastSaved", {
                            timestamp: formatTimestamp(lastModification.timestamp),
                            username: lastModification.username,
                        })}
                    </div>
                );
            break;
    }

    const editingActionsDisabled = !(
        [
            DialogState.EDITING,
            DialogState.ASYNC_SAVING_WORKFLOW_FAILED,
            DialogState.ASYNC_SAVING_WORKFLOW_SUCCEEDED,
        ].includes(dialogState) && workflowName.length > 0
    );
    let content = <></>;
    switch (dialogState) {
        case DialogState.FETCHING_WORKFLOW_EDITORS:
            content = (
                <div className={style.fetchStatus}>
                    <LoadingIndicator />
                    <div className={style.loadingMessage}>
                        {t("ManageWorkflowDialog.fetchWorkflowEditors.loadingMessage")}
                    </div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.FETCHING_WORKFLOW:
            content = (
                <div className={style.fetchStatus}>
                    <LoadingIndicator />
                    <div className={style.loadingMessage}>{t("ManageWorkflowDialog.fetchWorkflow.loadingMessage")}</div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.FETCHING_WORKFLOW_EDITORS_FAILED:
            content = (
                <div className={style.fetchStatus}>
                    <div className={style.failureMessage}>
                        {t("ManageWorkflowDialog.fetchWorkflowEditors.failureMessage")}
                    </div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.FETCHING_WORKFLOW_FAILED:
            content = (
                <div className={style.fetchStatus}>
                    <div className={style.failureMessage}>{t("ManageWorkflowDialog.fetchWorkflow.failureMessage")}</div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.EDITING:
        case DialogState.ASYNC_SAVING_WORKFLOW:
        case DialogState.ASYNC_SAVING_WORKFLOW_SUCCEEDED:
        case DialogState.ASYNC_SAVING_WORKFLOW_FAILED:
            content = (
                <>
                    <div>
                        <input
                            autoFocus
                            id="workflowName"
                            data-testid={testIds.workArea.workflows.manageWorkflowDialog.nameInput}
                            type="text"
                            value={workflowNameInputValue}
                            className={classNames(form.input, style.workflowNameInput)}
                            placeholder={t("ManageWorkflowDialog.workflowName")}
                            onChange={handleWorkflowNameInputChange}
                            onBlur={handleWorkflowNameChange}
                            onKeyPress={handleWorkflowNameInputKeyDown}
                            max={WORKFLOW_NAME_MAX_LENGTH}
                        />
                    </div>
                    <Tabs onSelect={handleTabSelect}>
                        <TabList>
                            <Tab data-testid={testIds.workArea.workflows.manageWorkflowDialog.visualEditor.tab}>
                                {t("ManageWorkflowDialog.visualEditor.title")}
                            </Tab>
                            <Tab data-testid={testIds.workArea.workflows.manageWorkflowDialog.jsonEditor.tab}>
                                {t("ManageWorkflowDialog.jsonEditor.title")}
                            </Tab>
                        </TabList>
                        <TabPanel>
                            {/* The component needs to be created and destroyed based on the tab visibility */}
                            {TabIndex.VISUAL_EDITOR === selectedTab && (
                                <VisualEditorView
                                    key={workflowName}
                                    profile={workflow ? extractWorkflowProfile(workflow) : selectedProfile}
                                    version={workflow ? workflow.version : selectedVersion}
                                    workflowEditorReference={workflowEditor}
                                    workflowEditors={workflowEditors}
                                    workflow={workflow}
                                    setWorkflow={handleWorkflowChangeWithNameSync}
                                    data-testid={testIds.workArea.workflows.manageWorkflowDialog.visualEditor.itself}
                                />
                            )}
                        </TabPanel>
                        <TabPanel>
                            {/* The component needs to be created and destroyed based on the tab visibility */}
                            {TabIndex.JSON_EDITOR === selectedTab && (
                                <JsonEditorView
                                    workflow={workflow}
                                    setWorkflow={handleWorkflowChange}
                                    data-testid={testIds.workArea.workflows.manageWorkflowDialog.jsonEditor.itself}
                                />
                            )}
                        </TabPanel>
                    </Tabs>
                    <div
                        className={style.asyncSaveStatus}
                        data-test-id={testIds.workArea.workflows.manageWorkflowDialog.saveStatusLabel}
                    >
                        {saveStatus}
                    </div>
                    <div className={classNames(style.buttonContainer)}>
                        <button
                            className={classNames(
                                editingActionsDisabled
                                    ? buttons.disabledButton
                                    : [buttons.secondaryButton, buttons.medium],
                                style.button
                            )}
                            onClick={handleSaveClick}
                            disabled={editingActionsDisabled}
                            data-testid={testIds.workArea.workflows.manageWorkflowDialog.saveButton}
                        >
                            {t("Common.save")}
                        </button>
                        <button
                            className={classNames(
                                editingActionsDisabled
                                    ? buttons.disabledButton
                                    : [buttons.primaryButton, buttons.medium],
                                style.button
                            )}
                            onClick={handleSaveCloseClick}
                            disabled={editingActionsDisabled}
                            data-testid={testIds.workArea.workflows.manageWorkflowDialog.saveCloseButton}
                        >
                            {t("Common.saveClose")}
                        </button>
                    </div>
                    {confirmationModal}
                </>
            );
            break;
        case DialogState.SELECTING_WORKFLOW_EDITOR:
            content = (
                <div className={style.selectWorkflowEditor}>
                    <p>
                        {t("ManageWorkflowDialog.selectWorkflowEditor.introductionText", {
                            tenantName: props.tenantName ?? "",
                        })}
                    </p>
                    <div className={form.formFields}>
                        <label htmlFor="product" className={form.label}>
                            {t("ManageWorkflowDialog.selectWorkflowEditor.productLabel")}
                        </label>

                        <select
                            id="product"
                            onChange={handleSelectedProfileChanged}
                            className={classNames(form.select, form.fixedWidthInput)}
                            data-testid={
                                testIds.workArea.workflows.manageWorkflowDialog.productVersionSelection.productSelect
                            }
                        >
                            <option key={1} value={Profile.BMDE}>
                                BMDE
                            </option>
                            <option key={2} value={Profile.BDE}>
                                BDE
                            </option>
                        </select>
                    </div>
                    <div className={form.formFields}>
                        <label htmlFor="product" className={form.label}>
                            {t("ManageWorkflowDialog.selectWorkflowEditor.versionLabel")}
                        </label>

                        <select
                            id="version"
                            onChange={handleSelectedVersionChanged}
                            className={classNames(form.select, form.fixedWidthInput)}
                            data-testid={
                                testIds.workArea.workflows.manageWorkflowDialog.productVersionSelection.versionSelect
                            }
                        >
                            {optionList}
                        </select>
                    </div>
                    <div className={classNames(form.buttonContainer, style.nextButton)}>
                        <button
                            type="submit"
                            className={classNames(buttons.primaryButton, buttons.medium, form.submitButton)}
                            onClick={confirmWorkflowEditorSelection}
                            data-testid={
                                testIds.workArea.workflows.manageWorkflowDialog.productVersionSelection
                                    .designWorkflowButton
                            }
                        >
                            {t("ManageWorkflowDialog.selectWorkflowEditor.designWorkflowButton")}
                        </button>
                    </div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.SYNC_SAVING_WORKFLOW:
            content = (
                <div className={style.syncSaveStatus}>
                    <LoadingIndicator />
                    <div className={style.loadingMessage}>{t("ManageWorkflowDialog.saveWorkflow.loadingMessage")}</div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.SYNC_SAVING_WORKFLOW_SUCCEEDED:
            content = (
                <>
                    <div className={style.syncSaveStatus}>
                        <div className={style.successMessage}>
                            {t("ManageWorkflowDialog.saveWorkflow.successMessage")}
                        </div>
                    </div>
                    <div className={classNames(style.buttonContainer)}>
                        <button
                            className={classNames(buttons.primaryButton, buttons.medium, style.button)}
                            data-testid={testIds.common.dialog.closeButton}
                            onClick={() => {
                                props.onCancel(true);
                                props.onUpdateParentView();
                            }}
                        >
                            {t("Common.close")}
                        </button>
                    </div>
                    {confirmationModal}
                </>
            );
            break;
        case DialogState.SYNC_SAVING_WORKFLOW_FAILED:
            content = (
                <>
                    <div className={style.syncSaveStatus}>
                        <div className={style.failureMessage}>
                            {t("ManageWorkflowDialog.saveWorkflow.failureMessage")}
                        </div>
                    </div>
                    <div className={classNames(style.buttonContainer)}>
                        <button
                            className={classNames(buttons.secondaryButton, buttons.medium, style.button)}
                            data-testid={testIds.workArea.workflows.manageWorkflowDialog.saveFailed.backToEditorButton}
                            onClick={handleBackToEditorClick}
                        >
                            {t("ManageWorkflowDialog.saveWorkflow.backToEditorButton")}
                        </button>
                        <button
                            className={classNames(buttons.primaryButton, buttons.medium, style.button)}
                            data-testid={testIds.common.dialog.closeButton}
                            onClick={() => props.onCancel(true)}
                        >
                            {t("Common.close")}
                        </button>
                    </div>
                    {confirmationModal}
                </>
            );
            break;
    }

    return content;
};

export default WorkflowEditDialog;

import React from "react";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import Page from "./Page";
import Error from "./Error";
import { downloadPageService } from "services/download-page/DownloadPageService";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import {
    URLParameters,
    pageState,
    DownloadPageConfiguration,
    DownloadPageConfigurationResponse,
} from "domain/downloadPage";

import style from "./page.scss";

const DownloadPageView = (): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<string | JSX.Element>("");

    const [data, setData] = React.useState<pageState>({
        configuration: {
            appIcon: "",
            headerLogo: "",
            title: "",
            androidFirebaseLink: "",
            huaweiFirebase: "",
            iosFirebaseLink: "",
        },
    });
    const { licenseKey } = useParams<URLParameters>();

    const toDownloadPageConfiguration = (data: DownloadPageConfiguration): DownloadPageConfigurationResponse => {
        return {
            appIcon: data.appIcon,
            headerLogo: data.headerLogo,
            title: data.title,
            androidFirebaseLink: data.androidFirebaseLink,
            huaweiFirebase: data.huaweiFirebase,
            iosFirebaseLink: data.iOSFirebaseLink,
        };
    };

    const fetchConfiguration = async () => {
        downloadPageService
            .getConfiguration(licenseKey)
            .then((data: DownloadPageConfiguration) => {
                setData({ configuration: toDownloadPageConfiguration(data) });
            })
            .catch(() => {
                setError(getUnexpectedErrorMessage(licenseKey));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getUnexpectedErrorMessage = (licenseKey: string): JSX.Element => {
        const subject = encodeURI("Blancco Management Portal - Unexpected Error Report: " + licenseKey);
        const body = encodeURI(
            "Hi there\n\n" +
                "I've experienced a persistent issue with the license key: " +
                licenseKey +
                "\nWould you be able to assist?\n\n"
        );
        const mailToLink = "mailto:support@blancco.com?subject=" + subject + "&body=" + body;

        return (
            <>
                <div className={style.title}>{t("DownloadPage.errorMessages.unexpectedError.title")} </div>
                <div className={style.message}>
                    {t("DownloadPage.errorMessages.unexpectedError.issuePersists")}{" "}
                    <a
                        href="https://servicedesk.blancco.com/servicedesk/customer/portal/6"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t("DownloadPage.errorMessages.unexpectedError.here")}
                    </a>
                </div>
                <div className={style.message}>{t("DownloadPage.errorMessages.unexpectedError.or")}</div>
                <div className={style.message}>
                    {t("DownloadPage.errorMessages.unexpectedError.support")}{" "}
                    <a href={mailToLink}>support@blancco.com</a>{" "}
                    {t("DownloadPage.errorMessages.unexpectedError.licenseKey")}
                </div>
                <div className={style.message}>{licenseKey}</div>
            </>
        );
    };

    React.useEffect(() => {
        if (typeof licenseKey === "undefined") {
            setError(<div className={style.title}>{t("DownloadPage.errorMessages.licenseKeyMissing")}</div>);
            return;
        }

        if (isMobile) {
            fetchConfiguration();
        } else {
            setError(<div className={style.title}>{t("DownloadPage.errorMessages.mobileDevicesOnly")}</div>);
        }
    }, []);

    return error ? (
        <Error error={error} />
    ) : loading ? (
        <LoadingIndicator />
    ) : (
        <Page licenseKey={licenseKey} configuration={data.configuration} />
    );
};

export default DownloadPageView;

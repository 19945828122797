import * as React from "react";
import { switchTheme } from "store/theme";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";
import style from "./tenants.scss";
import TenantAccessIcon from "components/icons/TenantAccessIcon";
import { Menu, MenuItem } from "react-aria-menubutton";
import Modal from "components/modal/Modal";
import classNames from "classnames";
import buttons from "styles/buttons.scss";
import { useTranslation } from "react-i18next";
import { tenantService } from "services/tenants/TenantService";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { setTenantAccessUrls } from "services/login/endpointRepository";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import testIds from "testIds.json";
import Tooltip from "components/tooltip/Tooltip";
import Edit from "components/icons/Edit";
import TextWithTooltip from "components/table/TextWithTooltip";
import buttonStyle from "styles/buttons.scss";

interface Result {
    title: string;
    message: string;
    resultVisible: boolean;
}

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
});

const connector = connect(mapState, { switchTheme });

const TenantMenuItems = (
    props: ConnectedProps<typeof connector> & {
        uuid: string;
        customerName: string;
        status: boolean;
        onTenantEdit: () => void;
    }
): JSX.Element => {
    const { t } = useTranslation();
    const [result, setResult] = React.useState<Result>({
        title: "",
        message: "",
        resultVisible: false,
    });
    const [accessInProgress, setAccessInProgress] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [accessTenantModalVisible, setAccessTenantModalVisible] = React.useState(false);
    const hideResultAndRedirectToRoot = () => {
        setResult({ title: result.title, message: result.message, resultVisible: false });

        // If tenant access granted redirected to root path, for now it will redirect to "/dashboard"
        if (hasTenantCookie() === true) {
            window.location.replace("/");
        }
        props.onTenantEdit();
    };

    const fetchData = () => {
        setAccessInProgress(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        tenantService
            .accessTenant(props.uuid, abortController)
            .then((data) => {
                props.switchTheme();
                setResult({
                    title: t("AccessTenantView.accessTitle"),
                    message: t("AccessTenantView.accessSuccessMessage", { CustomerName: props.customerName }),
                    resultVisible: true,
                });
                setTenantAccessUrls(data.stan, data.oliver, data.publicApi, data.publicApiDocumentation);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setResult({
                        title: t("AccessTenantView.accessTitle"),
                        message: t("AccessTenantView.accessFailureMessage", { customerName: props.customerName }),
                        resultVisible: true,
                    });
                }
            })
            .finally(() => {
                setAccessInProgress(false);
                setAccessTenantModalVisible(false);
            });
    };

    const [editTenantModalVisible, setEditTenantModalVisible] = React.useState(false);
    const [editInProgress, setEditInProgress] = React.useState(false);

    const oldStatus = props.status;
    const [newStatus, setNewStatus] = React.useState<boolean>(oldStatus);
    const statusChanged = newStatus !== oldStatus;
    const onCheckBoxClick = () => {
        setNewStatus(!newStatus);
    };

    const editTenantStatus = () => {
        setEditInProgress(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        tenantService
            .editTenant(props.uuid, newStatus, abortController)
            .then(() => {
                setResult({
                    title: t("EditTenantView.editTenantTitle"),
                    message: t("EditTenantView.successMessage", { customerName: props.customerName }),
                    resultVisible: true,
                });
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setResult({
                        title: t("EditTenantView.editTenantFailedTitle"),
                        message: t("EditTenantView.failureMessage", { customerName: props.customerName }),
                        resultVisible: true,
                    });
                }
            })
            .finally(() => {
                setEditInProgress(false);
                setEditTenantModalVisible(false);
            });
    };

    return (
        <div className={style.tenantIcon}>
            <Menu className={style.kebabMenu}>
                <ul>
                    <li>
                        <TextWithTooltip text={t("AccessTenantView.accessTenantAccount")}>
                            <MenuItem
                                onClick={() => {
                                    setAccessTenantModalVisible(true);
                                    usageStatisticsService.sendEvent({
                                        category: Category.TENANT,
                                        action: Action.ACCESS_TENANT,
                                    });
                                }}
                            >
                                <TenantAccessIcon color={props.theme.primaryButtonBackgroundColor} />
                            </MenuItem>
                        </TextWithTooltip>
                    </li>
                    <li>
                        <TextWithTooltip text={t("EditTenantView.editTenantStatus")}>
                            <MenuItem
                                onClick={() => {
                                    setEditTenantModalVisible(true);
                                    usageStatisticsService.sendEvent({
                                        category: Category.TENANT,
                                        action: Action.EDIT_TENANT,
                                    });
                                }}
                            >
                                <Edit color={props.theme.primaryButtonBackgroundColor} />
                            </MenuItem>
                        </TextWithTooltip>
                    </li>
                </ul>
            </Menu>
            <Modal
                isOpen={accessTenantModalVisible}
                hideModal={() => setAccessTenantModalVisible(false)}
                modalTitle={t("AccessTenantView.title")}
            >
                <div className={style.resultContainer}>
                    {t("AccessTenantView.message", { customerName: props.customerName })}
                </div>

                {accessInProgress ? (
                    <LoadingIndicator />
                ) : (
                    <div className={style.okButtonContainer}>
                        <button
                            className={classNames(buttons.secondaryButton, buttons.medium, style.okButton)}
                            onClick={() => setAccessTenantModalVisible(false)}
                        >
                            {t("Common.cancel")}
                        </button>

                        <button
                            className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                            onClick={fetchData}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                )}
            </Modal>
            <Modal isOpen={result.resultVisible} hideModal={hideResultAndRedirectToRoot} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                        onClick={hideResultAndRedirectToRoot}
                        data-testid={testIds.common.confirmationDialog.confirmButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
            <Modal
                isOpen={editTenantModalVisible}
                hideModal={() => setEditTenantModalVisible(false)}
                modalTitle={t("EditTenantView.title", { customerName: props.customerName })}
            >
                {editInProgress ? (
                    <LoadingIndicator />
                ) : (
                    <div>
                        <div className={style.resultContainer}>{t("EditTenantView.warningOnChangeStatus")}</div>
                        <div className={style.status}>
                            {t("EditTenantView.status", { customerName: props.customerName })}
                            <div className={style.checkbox}>
                                <label>
                                    <input
                                        type="checkbox"
                                        name="status"
                                        defaultChecked={oldStatus}
                                        onClick={() => {
                                            onCheckBoxClick();
                                            usageStatisticsService.sendEvent({
                                                category: Category.TENANT,
                                                action: Action.ENABLE_TENANT,
                                                label: newStatus.toString(),
                                            });
                                        }}
                                        data-testid={testIds.workArea.tenant.editTenantDialog.status}
                                    />
                                </label>
                            </div>
                            <Tooltip
                                content={
                                    <div className={style.warningMessage}>{t("EditTenantView.warningTooltip")}</div>
                                }
                                placement={"right"}
                            >
                                <div className={style.warningIcon} />
                            </Tooltip>
                        </div>

                        <div className={classNames(style.editTenantButtons, style.okButtonContainer)}>
                            <button
                                className={classNames(buttons.secondaryButton, buttons.medium, style.okButton)}
                                onClick={() => setEditTenantModalVisible(false)}
                                data-testid={testIds.common.dialog.closeButton}
                            >
                                {t("Common.cancel")}
                            </button>
                            <button
                                className={classNames(
                                    !statusChanged ? buttonStyle.disabledButton : buttons.primaryButton,
                                    buttons.medium,
                                    style.okButton
                                )}
                                onClick={editTenantStatus}
                                data-testid={testIds.common.confirmationDialog.confirmButton}
                                disabled={!statusChanged}
                            >
                                {t("Common.save")}
                            </button>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default connector(TenantMenuItems);

import { TableState } from "react-table";

import { getObject, setObject, RepositoryKey } from "services/utils/repository";

const DEFAULT_TABLE_STATE = { columnResizing: { columnWidths: {} }, hiddenColumns: [] };

export function getTableState(key: RepositoryKey): Record<string, unknown> {
    return getObject(key) || DEFAULT_TABLE_STATE;
}

export function setTableState(tableState: TableState, key: RepositoryKey): void {
    setObject(key, tableState);
}

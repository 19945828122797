import * as React from "react";
import { Navigation } from "components/navigation/Navigation";
import { SideNavigation } from "components/navigation/SideNavigation";
import style from "./layout.scss";
import sideMenuNavigation from "components/navigation/side-navigation.scss";
import topNavigation from "components/navigation/top-navigation.scss";
import {
    RouteDefinition,
    DASHBOARD_ROUTE,
    REPORTS_ROUTE,
    TENANTS_ROUTE,
    USERS_ROUTE,
    LICENSES_ROUTE,
    SUPPORT_ROUTE,
    SETTINGS_ROUTE,
    WORKFLOWS_ROUTE,
} from "components/router/Routes";
import { Header } from "components/header/Header";
import { DropTenantAccessBanner } from "./DropTenantAccessBanner";
import { hasTenantCookie } from "services/tenants/tenantCookieService";

interface Props {
    viewTitle: string;
    view: JSX.Element;
    viewActions?: JSX.Element;
    viewContainer?: boolean;
    mainRoute: RouteDefinition;
}

export const viewActionsContext = React.createContext<JSX.Element | undefined>(undefined);

export default function ApplicationLayout(props: Props): JSX.Element {
    return (
        <>
            <Header />
            <Navigation
                menuClass={topNavigation.navigation}
                currentItemClass={topNavigation.current}
                routes={[
                    DASHBOARD_ROUTE,
                    REPORTS_ROUTE,
                    LICENSES_ROUTE,
                    WORKFLOWS_ROUTE,
                    USERS_ROUTE,
                    TENANTS_ROUTE,
                    SUPPORT_ROUTE,
                    SETTINGS_ROUTE,
                ]}
                appendChildRoutes={false}
            />
            <div className={style.content}>
                <SideNavigation
                    menuClass={sideMenuNavigation.navigation}
                    currentItemClass={sideMenuNavigation.current}
                    childItemClass={sideMenuNavigation.child}
                    routes={props.mainRoute.childRoutes}
                    appendChildRoutes={true}
                />
                <div className={style.view}>
                    {hasTenantCookie() ? <DropTenantAccessBanner /> : ""}
                    <div className={style.mainView}>
                        <div className={style.viewHeader}>
                            <h1 className={style.viewTitle}>{props.viewTitle}</h1>
                            <div className={style.viewActions}>{props.viewActions ? props.viewActions : null}</div>
                        </div>
                        <viewActionsContext.Provider value={props.viewActions}>
                            <div className={props.viewContainer ? style.viewContent : ""}>{props.view}</div>
                        </viewActionsContext.Provider>
                    </div>
                </div>
            </div>
        </>
    );
}

ApplicationLayout.defaultProps = {
    viewContainer: true,
};

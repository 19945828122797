import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";
import { Menu, MenuItem } from "react-aria-menubutton";
import Modal from "components/modal/Modal";
import classNames from "classnames";
import buttons from "styles/buttons.scss";
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import testIds from "testIds.json";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import Delete from "components/icons/Delete";
import style from "./users.scss";
import { userService } from "services/user/users/UserService";

interface Result {
    title: string;
    message: string;
    resultVisible: boolean;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    userDetail: state.userReducer.user,
});

const connector = connect(mapState);

const UserKebabMenu = (
    props: ConnectedProps<typeof connector> & { uuid: string; userName: string; onUserDelete: () => void }
): JSX.Element => {
    const { t } = useTranslation();
    const [deleteUserModalVisible, setDeleteUserModalVisible] = React.useState(false);
    const [result, setResult] = React.useState<Result>({ title: "", message: "", resultVisible: false });
    const [okClicked, setOkClicked] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const hideResultAndRedirectToRoot = () => {
        setResult({ title: result.title, message: result.message, resultVisible: false });
        props.onUserDelete();
    };
    const [confirmationState, setConfirmationState] = React.useState(true);

    function handleConfirmation() {
        setConfirmationState(
            (document.getElementById("confirmInput") as HTMLInputElement).value !== t("Common.delete").toUpperCase()
        );
    }

    const deleteUser = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        userService
            .deleteUser(props.uuid, abortController)
            .then(() => {
                setDeleteUserModalVisible(false);
                setConfirmationState(true);
                setResult({
                    title: t("DeleteUser.userDeleted.userDeletedTitle"),
                    message: t("DeleteUser.userDeleted.successMessage", { userName: props.userName }),
                    resultVisible: true,
                });
                setOkClicked(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setOkClicked(false);
                    setDeleteUserModalVisible(false);
                    setConfirmationState(true);
                    setResult({
                        title: t("DeleteUser.userDeleted.userNotDeletedTitle"),
                        message: t("DeleteUser.userDeleted.failureMessage"),
                        resultVisible: true,
                    });
                }
            });
    };

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const handleUserDelete = () => {
        setOkClicked(true);
        deleteUser();
    };
    const userEmail = props.userDetail ? props.userDetail.uuid : "";
    return (
        <div className={style.deleteIcon}>
            <Menu className={style.kebabMenu}>
                <ul>
                    <MenuItem
                        disabled={userEmail === props.uuid}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onClick={
                            userEmail !== props.uuid
                                ? () => {
                                      setDeleteUserModalVisible(true);
                                      usageStatisticsService.sendEvent({
                                          category: Category.USER,
                                          action: Action.DELETE_USER,
                                      });
                                  }
                                : () => {
                                      /* do nothing */
                                  }
                        }
                    >
                        <Delete
                            color={
                                userEmail === props.uuid
                                    ? props.theme.disabledButtonBackgroundColor
                                    : props.theme.primaryButtonBackgroundColor
                            }
                        />
                    </MenuItem>
                </ul>
            </Menu>
            <Modal
                isOpen={deleteUserModalVisible}
                hideModal={() => setDeleteUserModalVisible(false)}
                modalTitle={t("DeleteUser.title")}
            >
                {okClicked ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        {deleteUserModalVisible ? (
                            <>
                                <div className={style.resultContainer}>
                                    {t("DeleteUser.introductionMessage", { userName: props.userName })}
                                </div>
                                <div className={style.resultContainer}>
                                    {t("DeleteUser.confirmationMessage", { userName: props.userName })}
                                </div>
                                <div className={style.resultContainer}>
                                    <input
                                        id="confirmInput"
                                        type="text"
                                        data-testid={testIds.common.confirmationDialog.confirmInput}
                                        placeholder={t("Common.delete").toUpperCase()}
                                        onChange={handleConfirmation}
                                    />
                                </div>

                                <div className={style.okButtonContainer}>
                                    <button
                                        className={classNames(buttons.secondaryButton, buttons.medium, style.okButton)}
                                        onClick={() => setDeleteUserModalVisible(false)}
                                        data-testid={testIds.common.dialog.closeButton}
                                    >
                                        {t("Common.cancel")}
                                    </button>

                                    <button
                                        className={classNames(
                                            buttons.primaryButton,
                                            style.deleteButton,
                                            buttons.medium,
                                            style.okButton
                                        )}
                                        data-testid={testIds.common.confirmationDialog.confirmButton}
                                        onClick={handleUserDelete}
                                        disabled={confirmationState}
                                    >
                                        {t("Common.delete")}
                                    </button>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                )}
            </Modal>
            <Modal isOpen={result.resultVisible} hideModal={hideResultAndRedirectToRoot} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                        onClick={hideResultAndRedirectToRoot}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default connector(UserKebabMenu);

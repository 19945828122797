import * as React from "react";
import classNames from "classnames";
import { string } from "yup";
import { useTranslation } from "react-i18next";

import Modal from "components/modal/Modal";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { authenticationService } from "services/security/AuthenticationService";

import buttonsStyle from "styles/buttons.scss";
import fontStyle from "styles/font.scss";
import formStyle from "styles/form.scss";
import loginStyle from "./login.scss";
import { EMAIL_MAX_LENGTH } from "globalConstants";

const EMAIL_VALIDATOR = string().required().email().max(EMAIL_MAX_LENGTH);

export interface EmailContext {
    // Email address that the user has typed.
    address: string;
    // Whether address is a valid email address.
    valid: boolean;
    // Whether error should be shown to the user.
    error: boolean;
}

interface Confirmation {
    visible: boolean;
    done: boolean;
    success: boolean;
}

/**
 * Create initial context passed to ForgotPasswordModal.
 */
function createDefaultEmailContext(): EmailContext {
    return { address: "", valid: false, error: false };
}

interface Props {
    hide: () => void;
    visible: boolean;
}

export function ForgotPasswordModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [emailContext, setEmailContext] = React.useState<EmailContext>(createDefaultEmailContext());
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        const trimmed = email.trim();
        const shortEnough = trimmed.length <= 255;
        let valid = shortEnough;
        try {
            EMAIL_VALIDATOR.validateSync(trimmed);
        } catch (error) {
            valid = false;
        }
        if (shortEnough) {
            setEmailContext({
                address: email,
                valid,
                error: valid || email.length === 0 ? false : emailContext.error,
            });
        }
    };
    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (emailContext.address !== "" && !emailContext.valid) {
                setEmailContext({
                    address: emailContext.address,
                    valid: emailContext.valid,
                    error: true,
                });
            }
        }, 700);
        return () => clearTimeout(timer);
    }, [emailContext.address]);

    const initialConfirmation = { visible: false, done: false, success: false };
    const [confirmation, setConfirmation] = React.useState<Confirmation>(initialConfirmation);
    const hideConfirmation = () => setConfirmation(initialConfirmation);

    const onSendResetClick = () => {
        props.hide();
        function configure(done: boolean, success: boolean) {
            setConfirmation({
                visible: true,
                done,
                success,
            });
        }
        configure(false, false);
        authenticationService
            .requestPasswordReset(emailContext.address)
            .then(() => {
                configure(true, true);
                setEmailContext(createDefaultEmailContext());
            })
            .catch(() => {
                configure(true, false);
            });
    };

    const validationError = !emailContext.error ? null : (
        <div className={formStyle.error}>{t("Common.invalidEmail")}</div>
    );
    const confirmationText = t(
        confirmation.success
            ? "Login.forgotPasswordConfirmationModal.successMessage"
            : "Login.forgotPasswordConfirmationModal.failureMessage"
    );
    const confirmationContent = !confirmation.done ? (
        <LoadingIndicator />
    ) : (
        <div className={loginStyle.forgotPasswordModalContainer}>
            <div className={classNames({ [loginStyle.errorText]: !confirmation.success })}>{confirmationText}</div>
            <div className={loginStyle.forgotPasswordEmailSubmitButton}>
                <button
                    onClick={hideConfirmation}
                    className={classNames(buttonsStyle.primaryButton, buttonsStyle.medium, formStyle.submitButton)}
                >
                    {t("Common.ok")}
                </button>
            </div>
        </div>
    );
    return (
        <>
            <Modal
                isOpen={props.visible}
                hideModal={props.hide}
                modalTitle={t("Login.forgotPasswordModal.title")}
                loginTitleStyle={true}
            >
                <div className={loginStyle.forgotPasswordModalContainer}>
                    <div className={fontStyle.loginPopupBodyFont}>{t("Login.forgotPasswordModal.description")}</div>
                    <div className={classNames({ [loginStyle.inputError]: emailContext.error })}>
                        <input
                            type="email"
                            className={loginStyle.input}
                            onChange={onChange}
                            value={emailContext.address}
                            max={EMAIL_MAX_LENGTH}
                            autoFocus
                        />
                        {validationError}
                    </div>
                    <div className={loginStyle.forgotPasswordEmailSubmitButton}>
                        <button
                            type="submit"
                            className={classNames(
                                emailContext.valid ? buttonsStyle.primaryButton : buttonsStyle.disabledButton,
                                buttonsStyle.medium,
                                formStyle.submitButton
                            )}
                            onClick={onSendResetClick}
                            disabled={!emailContext.valid}
                        >
                            {t("Login.forgotPasswordModal.submitButton")}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={confirmation.visible}
                hideModal={hideConfirmation}
                modalTitle={t("Login.forgotPasswordModal.title")}
            >
                {confirmationContent}
            </Modal>
        </>
    );
}

export function createDateLabel(date: string): string {
    return new Intl.DateTimeFormat("en-GB", {
        month: "short",
        day: "2-digit",
        year: "numeric",
    }).format(new Date(date));
}

export function formatIsoDate(date: Date): string {
    const year = date.getFullYear();
    const month = ("" + (date.getMonth() + 1)).padStart(2, "0");
    const day = ("" + date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}

export function currency(value: number, currency: string): string {
    const PRICE = (value / 100).toString();
    switch (currency) {
        case "USD":
            return "$" + PRICE;
        case "EUR":
        case "E":
            return PRICE + "€";
        case "GBP":
            return PRICE + "£";
        default:
            return PRICE + currency;
    }
}

import * as React from "react";
import { CardDetails } from "domain/dashboard";
import { useTranslation } from "react-i18next";

import style from "./dashboard.scss";

export const Card = (props: CardDetails): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className={style.card}>
            <div className={style.cardHeader}>
                {t(props.title)}
                <div className={style.dropdown}>
                    <div className={style.cardMenuButton} />
                </div>
            </div>
            {props.children}
        </div>
    );
};

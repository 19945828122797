import { DefaultWorkflow } from "services/workflows/WorkflowService";
import { Profile } from "services/workflows/WorkflowService";
import { TableData } from "./table";

export interface EditorMetadata {
    profile: Profile;
}

export interface Workflow {
    name: string;
    version: string;
    editorMetadata?: EditorMetadata;
}

export function isWorkflow(candidate: any): candidate is Workflow {
    const workflow = candidate as Workflow;
    return workflow.name !== undefined && workflow.version !== undefined;
}

export interface WorkflowTableData extends TableData {
    name: string;
    uuid: string;
    id: number;
    product: string;
    profile: Profile;
    createdDate: string;
    creator: string;
    defaultWorkflow: DefaultWorkflow;
    workflow: Workflow;
    userUuid: string;
    own: boolean;
}

import * as React from "react";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { REPORTS_ROUTE } from "components/router/Routes";
import DiagnosticReportsTable from "./DiagnosticReportsTable";
import { useTranslation } from "react-i18next";

export default function MobileSolutionsReportsView(): JSX.Element {
    const { t } = useTranslation();

    return (
        <ApplicationLayout
            viewTitle={t("Common.mobileSolutionsReports")}
            view={<DiagnosticReportsTable owned={true} />}
            mainRoute={REPORTS_ROUTE}
        />
    );
}

import React from "react";
import { TFunctionResult } from "i18next";
import { TOOLTIP_DELAY } from "globalConstants";

interface Props<T> {
    text: T;
    multiline?: boolean;
}

const TextWithTooltip: React.FunctionComponent<Props<string | number | undefined | null | boolean | TFunctionResult>> =
    (props): JSX.Element => {
        return (
            <span data-tip={props.text} data-delay-show={TOOLTIP_DELAY} data-multiline={props.multiline ?? false}>
                {props.children ? props.children : props.text}
            </span>
        );
    };

export default TextWithTooltip;

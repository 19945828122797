// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._24qy9Alb8wRVaAtlzXII2F{display:flex;justify-content:flex-end}._2c7p748csUOC6kq_uGnLgA{min-width:88px;margin:0 10px}._2Jv8zXkug2uBiXwkF8MT_4{padding-top:40px}.me5Gs1oVqQr9DmBDtPEjX{margin-bottom:20px}._2e83SN6cVsWPBEQUK97eR4,._2JbXFKL1W0Uqa9or3onSSb{margin:20px 10px}._2e83SN6cVsWPBEQUK97eR4 .qIBmew-yVHeMqZn6YasLp,._2e83SN6cVsWPBEQUK97eR4 ._2qCLpGU6iyzjt6J6wWpS8A,._2e83SN6cVsWPBEQUK97eR4 ._3fhPoxKUtk9jIEPpfD6LeD,._2JbXFKL1W0Uqa9or3onSSb .qIBmew-yVHeMqZn6YasLp,._2JbXFKL1W0Uqa9or3onSSb ._2qCLpGU6iyzjt6J6wWpS8A,._2JbXFKL1W0Uqa9or3onSSb ._3fhPoxKUtk9jIEPpfD6LeD{margin-top:20px;text-align:center}._2NXqloKTEBZlU-cCvxxxYj{margin:20px 10px}._2NXqloKTEBZlU-cCvxxxYj ._3vYkGb-7ZRq52lvBtopQFj,._2NXqloKTEBZlU-cCvxxxYj .qIBmew-yVHeMqZn6YasLp,._2NXqloKTEBZlU-cCvxxxYj ._2qCLpGU6iyzjt6J6wWpS8A,._2NXqloKTEBZlU-cCvxxxYj ._3fhPoxKUtk9jIEPpfD6LeD{text-align:right}._2NXqloKTEBZlU-cCvxxxYj img{margin-right:5px}._2lB7EdlTIuxJkCIh77NhyW{width:400px}.react-tabs__tab-list,.react-tabs__tab--selected,.react-tabs__tab{border:none}.defaultTheme .react-tabs__tab-list,.defaultTheme .react-tabs__tab--selected,.defaultTheme .react-tabs__tab{background-color:#fff}.tenantTheme .react-tabs__tab-list,.tenantTheme .react-tabs__tab--selected,.tenantTheme .react-tabs__tab{background-color:#fff}.defaultDarkTheme .react-tabs__tab-list,.defaultDarkTheme .react-tabs__tab--selected,.defaultDarkTheme .react-tabs__tab{background-color:#333}.tenantDarkTheme .react-tabs__tab-list,.tenantDarkTheme .react-tabs__tab--selected,.tenantDarkTheme .react-tabs__tab{background-color:#333}.react-tabs__tab-list{border-bottom:none !important}", ""]);
// Exports
exports.locals = {
	"activeBackgroundColor": "#db2828",
	"activeForegroundColor": "#db2828",
	"primaryButtonBackgroundColor": "#db2828",
	"secondaryButtonForegroundColor": "#f46464",
	"bannerBackgroundColor": "rgba(219,40,40,.5)",
	"linkTextColor": "#f46464",
	"primaryButtonBackgroundHoverColor": "#f46464",
	"secondaryButtonBackgroundHoverColor": "#f0f0f0",
	"destructiveButtonBackgroundHoverColor": "#f46464",
	"primaryButtonBackgroundActiveColor": "#f46464",
	"secondaryButtonBackgroundActiveColor": "#f7f7f7",
	"destructiveButtonBackgroundColor": "#f09191",
	"chartNegativeColor": "#f09191",
	"chartPositiveColor": "#5fe3a1",
	"chartNeutralColor": "#cecece",
	"errorBackgroundColor": "#f46464",
	"errorBorderColor": "#f46464",
	"errorTextColor": "#f46464",
	"warningBackgroundColor": "#f2711c",
	"warningBorderColor": "#f2711c",
	"warningTextColor": "#f2711c",
	"secondaryWarningBackgroundColor": "#ffc915",
	"secondaryWarningBorderColor": "#ffc915",
	"secondaryWarningTextColor": "#ffc915",
	"successBorderColor": "#5fe3a1",
	"successTextColor": "#5fe3a1",
	"pageBackgroundColor": "#222",
	"wrapperBackgroundColor": "#f2c5c5",
	"wrapperBorderColor": "#f09191",
	"modelBoxBackgroundColor": "#4c4c4c",
	"contentBackgroundColor": "#333",
	"secondaryButtonBackgroundColor": "#333",
	"textColor": "#fff",
	"primaryButtonForegroundColor": "#fff",
	"bannerForegroundColor": "#4c4c4c",
	"chartOutlineColor": "#222",
	"modalOverlayColor": "rgba(34,34,34,.4)",
	"contentDividerColor": "#222",
	"contentBorderColor": "#111",
	"disabledButtonBackgroundColor": "#222",
	"disabledButtonForegroundColor": "#4c4c4c",
	"inputHintColor": "#cecece",
	"inputBackgroundColor": "#4c4c4c",
	"hoverBackgroundColor": "#111",
	"toggleCheckedBackgroundColor": "#91b9f0",
	"toggleUncheckedBackgroundColor": "#cecece",
	"toggleForegroundColor": "#fff",
	"mapForegroundColor": "#f0f0f0",
	"mapBackgroundColor": "#222",
	"emptyStateEllipseBackgroundColor": "#111",
	"emptyStateBiggestCircleBackgroundColor": "#3c3960",
	"emptyStateSmallestCircleBackgroundColor": "#111",
	"emptyStatePersonColor": "#4a4a52",
	"defaultTheme": "defaultTheme",
	"tenantTheme": "tenantTheme",
	"defaultDarkTheme": "defaultDarkTheme",
	"tenantDarkTheme": "tenantDarkTheme",
	"smallestScreen": "1400px",
	"screen-sm": "960px",
	"screen-md": "1368px",
	"screen-lg": "1600px",
	"buttonContainer": "_24qy9Alb8wRVaAtlzXII2F",
	"button": "_2c7p748csUOC6kq_uGnLgA",
	"nextButton": "_2Jv8zXkug2uBiXwkF8MT_4",
	"workflowNameInput": "me5Gs1oVqQr9DmBDtPEjX",
	"syncSaveStatus": "_2e83SN6cVsWPBEQUK97eR4",
	"fetchStatus": "_2JbXFKL1W0Uqa9or3onSSb",
	"failureMessage": "qIBmew-yVHeMqZn6YasLp",
	"successMessage": "_2qCLpGU6iyzjt6J6wWpS8A",
	"loadingMessage": "_3fhPoxKUtk9jIEPpfD6LeD",
	"asyncSaveStatus": "_2NXqloKTEBZlU-cCvxxxYj",
	"statusMessage": "_3vYkGb-7ZRq52lvBtopQFj",
	"selectWorkflowEditor": "_2lB7EdlTIuxJkCIh77NhyW"
};
module.exports = exports;

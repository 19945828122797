import * as React from "react";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { DASHBOARD_ROUTE } from "components/router/Routes";
import { useTranslation } from "react-i18next";
import EsgDashboard from "components/dashboard/esg/EsgDashboard";

export default function EsgDashboardView(): JSX.Element {
    const { t } = useTranslation();

    return (
        <ApplicationLayout
            viewTitle={t("EsgDashboardView.esgDashboard")}
            view={<EsgDashboard />}
            viewContainer={false}
            mainRoute={DASHBOARD_ROUTE}
        />
    );
}

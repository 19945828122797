import * as React from "react";
import style from "./chart.scss";
import { Line } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import Checkbox from "./Checkbox";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";

interface ChartProps {
    displayLegend?: boolean;
}

interface ChartState {
    toggleSuccesses: boolean;
    toggleFailures: boolean;
    successData?: number[];
    failureData?: number[];
    displayLegend?: boolean;
    dataFetched: boolean;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

type Props = ChartProps & ConnectedProps<typeof connector>;

class ErasureProgressChart extends React.Component<Props, ChartState> {
    public static defaultProps = {
        displayLegend: false,
    };

    public successCheckState = true;
    public failureCheckState = true;
    private readonly timeout: ReturnType<typeof setTimeout>;

    constructor(props: Props) {
        super(props);
        this.state = {
            toggleFailures: true,
            toggleSuccesses: true,
            successData: [],
            failureData: [],
            displayLegend: this.props.displayLegend,
            dataFetched: false,
        };
        // TODO : Remove this delay when actual data loading is in place
        this.timeout = setTimeout(() => {
            this.setState({ successData: [6, 4, 7], failureData: [1, 2, 1], dataFetched: true });
        }, 2000);
    }

    public componentWillUnmount(): void {
        clearTimeout(this.timeout);
    }

    public getChartData(): ChartData {
        return {
            datasets: [
                {
                    label: "Successes",
                    // ToDo, This data should be fetched & populated dynamically from API
                    data: this.state.successData,
                    fill: false,
                    borderColor: this.props.theme.chartPositiveColor,
                    borderWidth: 1,
                },
                {
                    label: "Failures",
                    // ToDo, This data should be fetched & populated dynamically from API
                    data: this.state.failureData,
                    fill: false,
                    borderColor: this.props.theme.chartNegativeColor,
                    borderWidth: 1,
                },
            ],
            labels: ["1 October", "15 October", "1 November"],
        };
    }

    public toggleSuccessData(): void {
        if (!this.state.toggleSuccesses) {
            // add success data
            this.successCheckState = true;
            this.setState((prevState) => {
                return {
                    ...prevState,
                    // ToDo, This data should be fetched & populated dynamically from API
                    successData: [6, 4, 7],
                    toggleSuccesses: !prevState.toggleSuccesses,
                };
            });
        } else {
            // delete success data
            this.successCheckState = false;
            this.setState((prevState) => {
                return {
                    ...prevState,
                    successData: [],
                    toggleSuccesses: !prevState.toggleSuccesses,
                };
            });
        }
    }

    public toggleFailureData(): void {
        if (!this.state.toggleFailures) {
            // add failure data
            this.failureCheckState = true;
            this.setState((prevState) => {
                return {
                    ...prevState,
                    // ToDo, This data should be fetched & populated dynamically from API
                    failureData: [1, 2, 1],
                    toggleFailures: !prevState.toggleFailures,
                };
            });
        } else {
            // delete failure data
            this.failureCheckState = false;
            this.setState((prevState) => {
                return {
                    ...prevState,
                    failureData: [],
                    toggleFailures: !prevState.toggleFailures,
                };
            });
        }
    }

    public render(): JSX.Element {
        if (!this.state.dataFetched) {
            return (
                <div className={style.loaderContainer}>
                    <LoadingIndicator />
                </div>
            );
        }
        return (
            <div className={style.erasureProgressChartContainer}>
                <div className={style.topSummaryContainer}>
                    <p className={style.summaryText}>
                        {/* ToDo, This data should be fetched & populated dynamically from API */}
                        <span className={style.summaryCount}>1700</span>
                        <br />
                        total erasure
                        <br />
                        (avg. 12 erasures / day since 1 Oct 2019)
                    </p>
                    <p className={style.chartTitle}>Success rate of erasures</p>
                </div>
                <Line
                    data={this.getChartData()}
                    options={getChartOptions(this.props.theme.contentBorderColor, this.props.theme.textColor)}
                />
                <div className={style.legendView}>
                    <Checkbox
                        checked={this.state.toggleSuccesses}
                        tickColor={this.props.theme.chartPositiveColor}
                        Label="Successes"
                        onClick={() => this.toggleSuccessData()}
                    />
                    <Checkbox
                        checked={this.state.toggleFailures}
                        tickColor={this.props.theme.chartNegativeColor}
                        Label="Failures"
                        onClick={() => this.toggleFailureData()}
                    />
                </div>
                <div className={style.seeErasureProgressContainer}>
                    <button className={style.seeErasureProgress}>See erasure progress</button>
                </div>
            </div>
        );
    }
}

function getChartOptions(gridLineColor: string, textColor: string): ChartOptions {
    return {
        legend: {
            display: false,
            position: "bottom",
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        fontColor: textColor,
                    },
                },
            ],
            yAxes: [
                {
                    display: true,
                    gridLines: {
                        color: gridLineColor,
                        zeroLineColor: gridLineColor,
                    },
                    ticks: {
                        beginAtZero: true,
                        suggestedMax: 10,
                        stepSize: 2,
                        fontColor: textColor,
                    },
                },
            ],
        },
        tooltips: {
            enabled: false,
        },
        elements: {
            point: {
                radius: 0,
            },
        },
    };
}

export default connector(ErasureProgressChart);

import * as React from "react";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { TENANTS_ROUTE } from "components/router/Routes";
import { TenantsTable } from "./TenantsTable";
import { useTranslation } from "react-i18next";
import AddTenantsView from "./add-tenant/AddTenantView";
import { useState } from "react";

export default function AllTenantsView(): JSX.Element {
    const { t } = useTranslation();

    const [state, setState] = useState({ count: 0 });

    function updateState() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    return (
        <ApplicationLayout
            viewTitle={t("Common.customerTitle")}
            view={<TenantsTable count={state.count} onTenantEdit={updateState} initialTenantData={[]} />}
            mainRoute={TENANTS_ROUTE}
            viewActions={<AddTenantsView onTenantAdded={updateState} />}
        />
    );
}

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./user";
import languageReducer from "./language";
import themeReducer from "./theme";
import licensesReducer from "./license";

export const rootReducer = combineReducers({
    userReducer,
    languageReducer,
    themeReducer,
    licensesReducer,
});

export type createStore = ReturnType<typeof rootReducer>;

export type StoreState = ReturnType<typeof rootReducer>;

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === "development",
});

import * as React from "react";
import ApplicationLayout from "components/layout/ApplicationLayout";

import { LICENSES_ROUTE } from "components/router/Routes";
import { useTranslation } from "react-i18next";
import LicensesTable from "./LicensesTable";
import AddLicenseView from "components/licenses/add-license/AddLicenseView";
import { useState } from "react";

export default function AllLicensesView(): JSX.Element {
    const { t } = useTranslation();

    const [state, setState] = useState({ count: 0 });

    function onLicenseAdded() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    return (
        <ApplicationLayout
            viewTitle={t("Licenses.licenses")}
            view={<LicensesTable count={state.count} />}
            mainRoute={LICENSES_ROUTE}
            viewActions={<AddLicenseView onLicenseAdded={onLicenseAdded} />}
        />
    );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tw9UXkL516Ymsg8xGlf7M{width:1400px;height:60vh !important;overflow-y:scroll;overflow-x:auto;background-color:#fff}", ""]);
// Exports
exports.locals = {
	"editorContainer": "tw9UXkL516Ymsg8xGlf7M"
};
module.exports = exports;

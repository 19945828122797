import * as React from "react";
import { UserProfile } from "./UserProfile";
import { StoreState } from "store";
import { connect, ConnectedProps } from "react-redux";

const mapState = (state: StoreState) => ({
    user: state.userReducer.user,
});
const connector = connect(mapState);

const UserProfileView = (props: ConnectedProps<typeof connector>): JSX.Element => {
    return <UserProfile user={props.user} />;
};

export default connector(UserProfileView);

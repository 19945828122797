import * as React from "react";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { SETTINGS_ROUTE } from "components/router/Routes";
import { useTranslation } from "react-i18next";
import { MyApiKeysTable } from "./MyApiKeysTable";
import { useState } from "react";
import GenerateApiKeyView from "./generate-api-key/GenerateApiKeyView";

export default function MyApiKeysView(): JSX.Element {
    const { t } = useTranslation();
    const [state, setState] = useState({ count: 0 });

    function onApiKeyDelete() {
        return setState((prevState) => ({ count: prevState.count - 1 }));
    }

    function onAppend() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    return (
        <ApplicationLayout
            viewTitle={t("Common.myApiKeys")}
            view={
                <MyApiKeysTable
                    initialApiKeyData={[]}
                    count={state.count}
                    onApiKeyDelete={onApiKeyDelete}
                    onApiKeyEdit={onAppend}
                />
            }
            mainRoute={SETTINGS_ROUTE}
            viewActions={<GenerateApiKeyView onApiKeyGenerated={onAppend} />}
        />
    );
}

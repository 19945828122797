import { getPublicApiUrl } from "services/login/endpointRepository";
import * as React from "react";
import style from "./user-profile.scss";
import { UserDetails } from "domain/user";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import testIds from "testIds.json";
import { createDateLabel } from "services/utils/format";

function createUserObject(label: string, testId: string, value?: string, style?: string) {
    return {
        label,
        testId,
        value,
        style,
    };
}

interface Props {
    user: UserDetails | null;
}

function createUserObjects(t: TFunction, props: Props) {
    // As of now the compiler doesn't understand that this is impossible because
    // this check is already performed earlier and this method is never called
    // if this is null. Still, compilation fails without this check so it must
    // be here.
    if (props.user === null) {
        return [];
    }
    return [
        createUserObject(
            t("UserProfile.username"),
            testIds.header.userProfileDialog.user.usernameLabel,
            props.user.username
        ),
        createUserObject(
            t("UserProfile.name"),
            testIds.header.userProfileDialog.user.nameLabel,
            props.user.name !== "" ? props.user.name : t("Common.notProvided"),
            props.user.name === "" ? style.emptyField : ""
        ),
        createUserObject(
            t("Common.emailWithColon"),
            testIds.header.userProfileDialog.user.emailLabel,
            props.user.email
        ),
        createUserObject(
            t("UserProfile.userIdentifier"),
            testIds.header.userProfileDialog.user.uuidLabel,
            props.user.uuid
        ),
        createUserObject(
            t("UserProfile.created"),
            testIds.header.userProfileDialog.user.createdLabel,
            createDateLabel(props.user.created)
        ),
        createUserObject(
            t("UserProfile.modified"),
            testIds.header.userProfileDialog.user.modifiedLabel,
            createDateLabel(props.user.modified)
        ),
    ];
}

export const UserProfile = (props: Props): JSX.Element => {
    if (props.user === null) {
        return <LoadingIndicator />;
    }

    const { t } = useTranslation();
    const userFragments = createUserObjects(t, props).map((each, index) => (
        <React.Fragment key={index}>
            <div className={style.label}>{each.label}</div>
            <div className={each.style} data-testid={each.testId}>
                {each.value}
            </div>
        </React.Fragment>
    ));
    return (
        <>
            <div className={style.userProfile}>{userFragments}</div>

            <div className={style.standardModalTitle}>{t("UserProfile.tenantDetails")}</div>
            <div className={style.userProfile}>
                <div className={style.label}>{t("UserProfile.tenantName")}</div>
                <div data-testid={testIds.header.userProfileDialog.tenant.nameLabel}>{props.user.tenantName}</div>

                <div className={style.label}>{t("UserProfile.tenantIdentifier")}</div>
                <div data-testid={testIds.header.userProfileDialog.tenant.uuidLabel}>{props.user.tenantUuid}</div>
            </div>

            <div className={style.standardModalTitle}>{t("UserProfile.erasureClientSettings")}</div>
            <div className={style.userProfile}>
                <div className={style.label}>{t("UserProfile.erasureClientEndpoint")}</div>
                <div data-testid={testIds.header.userProfileDialog.erasureClient.endpoint}>
                    {props.user.erasureClientEndpoint}
                </div>
                <div className={style.label}>{t("UserProfile.erasureClientPort")}</div>
                <div data-testid={testIds.header.userProfileDialog.erasureClient.port}>443</div>
            </div>

            <div className={style.standardModalTitle}>{t("UserProfile.publicApiSettings")}</div>
            <div className={style.userProfile}>
                <div className={style.label}>{t("UserProfile.publicApiEndpoint")}</div>
                <div data-testid={testIds.header.userProfileDialog.publicApi.endpointLabel}>{getPublicApiUrl()}</div>
                <div className={style.label}>{t("UserProfile.publicApiPort")}</div>
                <div data-testid={testIds.header.userProfileDialog.publicApi.portLabel}>443</div>
            </div>
        </>
    );
};

import * as React from "react";

interface Props {
    tickColor: string;
}
const Tick = (props: Props): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.071" height="13.417" viewBox="0 0 11.071 8.417">
        <path
            d="M19.748,13.5l-6.278,6L11.5,17.166"
            transform="translate(-8.091 -11.086)"
            fill="none"
            stroke={props.tickColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
        />
    </svg>
);

export default Tick;

import * as React from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import * as settingRepository from "services/settings/settingsRepository";
import { ThemeMode } from "domain/themeMode";
import { useTranslation } from "react-i18next";
import { applyMode } from "store/theme";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";
import style from "./general-setting-view.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { createDateLabel } from "services/utils/format";
import Modal from "components/modal/Modal";
import loginStyle from "components/login/login.scss";
import Export from "components/icons/Export";
import testIds from "testIds.json";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { tenantService } from "services/tenants/TenantService";
import form from "styles/form.scss";
import classNames from "classnames";
import buttonStyle from "styles/buttons.scss";
import { Field, Form, Formik, FormikConfig, FormikProps } from "formik";
import Tooltip from "components/tooltip/Tooltip";

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
    user: state.userReducer.user,
});

export interface FormValues {
    statistics: boolean;
}

interface Result {
    title: string;
    message: string;
}

const connector = connect(mapState, { applyMode });
const GeneralSettingForm = (props: ConnectedProps<typeof connector>): JSX.Element => {
    if (props.user == null) {
        return <LoadingIndicator />;
    }
    const [t] = useTranslation();
    const [mode, setModeState] = React.useState<ThemeMode>(settingRepository.getThemeMode());
    const [statisticsEnabled, setStatisticsEnabled] = React.useState<boolean>(
        settingRepository.getDataCollection() ?? props.user.usageStatistics
    );
    const [changed, setChanged] = React.useState<boolean>(false);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const [resultVisible, setResultVisible] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const submitHandler: FormikConfig<FormValues>["onSubmit"] = async (values, { setSubmitting }) => {
        if (props.user == null) {
            throw new Error("User is not set");
        }
        setSubmitting(true);
        const newValue = values.statistics;
        setStatisticsEnabled(newValue);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            await tenantService.toggleStatistics(props.user.tenantUuid, newValue, abortController);
            settingRepository.setDataCollection(newValue);
            setChanged(false);
            showResult({
                title: t("Settings.successTitle"),
                message: t("Settings.successMessage"),
            });
        } catch (e) {
            if (!signal.aborted) {
                showResult({
                    title: t("Settings.errorTitle"),
                    message: t("Settings.errorMessage"),
                });
            }
        }
        setSubmitting(false);
    };

    React.useEffect(() => {
        setModeState(settingRepository.getThemeMode());
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const toggleDarkMode = () => {
        const themeMode = settingRepository.getThemeMode() == ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK;
        usageStatisticsService.sendEvent({
            category: Category.HEADER,
            action: Action.SWITCH_USER_THEME,
            label: themeMode,
        });
        setModeState(themeMode);
        settingRepository.setThemeMode(themeMode);
        props.applyMode();
    };

    const onOpenModal = () => {
        usageStatisticsService.sendEvent({
            category: Category.HEADER,
            action: Action.VIEW_TERMS_AND_CONDITIONS,
        });
        setShowModal(!showModal);
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };

    const hideResult = () => {
        setResultVisible(false);
    };

    const toggleStatistics = () => {
        setStatisticsEnabled(!statisticsEnabled);
        setChanged(!changed);
    };

    return (
        <Formik initialValues={{ statistics: statisticsEnabled }} onSubmit={submitHandler}>
            {({ isSubmitting }: FormikProps<FormValues>) => {
                if (isSubmitting || props.user == null) {
                    return <LoadingIndicator />;
                }
                return (
                    <div className={style.formContainer}>
                        <Form>
                            <div>
                                <div className={style.standardModalTitle}>{t("Settings.themeTitle")}</div>
                                <div className={style.popUp}>
                                    <span>{t("Settings.darkMode")}</span>
                                    <Toggle
                                        className={style.settingForm}
                                        defaultChecked={mode === ThemeMode.DARK}
                                        icons={false}
                                        onChange={toggleDarkMode}
                                    />
                                    {mode == ThemeMode.DARK ? t("Settings.on") : t("Settings.off")}
                                </div>
                            </div>
                            <div>
                                <div className={style.standardModalTitle}>{t("Settings.termsAndConditionsTitle")}</div>
                                <div className={style.popUp}>
                                    {t("Settings.termsAndConditions")}
                                    <div className={style.termsDialog}>
                                        <div data-testid={testIds.header.settingDialog.tos.acceptedDate}>
                                            {t("Settings.acceptedDate")}
                                            {props.user.eulaAcceptedDate
                                                ? createDateLabel(props.user.eulaAcceptedDate)
                                                : ""}
                                        </div>
                                        <a
                                            href="#"
                                            onClick={onOpenModal}
                                            data-testid={testIds.header.settingDialog.tos.termsAndConditionsLink}
                                        >
                                            {t("Settings.viewTermsAndConditions")}
                                        </a>
                                        <Modal
                                            isOpen={showModal}
                                            hideModal={() => setShowModal(false)}
                                            modalTitle={t("Login.acceptEulaModal.title")}
                                        >
                                            <div className={loginStyle.eulaActionsContainer}>
                                                <Export color={props.theme.linkTextColor} />
                                                <a
                                                    href="/public/eula/eula.pdf"
                                                    target="_blank"
                                                    data-testid={testIds.header.settingDialog.tos.downloadPdfLink}
                                                    onClick={() => {
                                                        usageStatisticsService.sendEvent({
                                                            category: Category.HEADER,
                                                            action: Action.DOWNLOAD_TERMS_AND_CONDITIONS,
                                                        });
                                                    }}
                                                >
                                                    {t("Login.acceptEulaModal.downloadPdf")}
                                                </a>
                                            </div>
                                            <iframe src="/public/eula/eula.html" className={loginStyle.eulaContainer} />
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={style.standardModalTitle}>{t("Settings.statisticsTitle")}</div>
                                <div className={style.popUp}>
                                    {t("Settings.gatherStatistics")}
                                    <div className={style.checkbox}>
                                        <label>
                                            <Field
                                                type="checkbox"
                                                name="statistics"
                                                className={style.settingForm}
                                                checked={statisticsEnabled}
                                                onClick={toggleStatistics}
                                                data-testid={testIds.header.settingDialog.statistics.collectCheckbox}
                                            />
                                        </label>
                                    </div>
                                    <div className={style.warningMessage}>
                                        <Tooltip content={<>{t("Settings.warningTooltip")}</>} placement={"top"}>
                                            <div className={style.warningIcon} />
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={style.warningText}>
                                    <div data-testid={testIds.header.settingDialog.tos.acceptedDate}>
                                        {t("Settings.warning")}
                                    </div>
                                </div>
                            </div>
                            <div className={form.buttonContainer}>
                                <button
                                    type="submit"
                                    className={classNames(
                                        changed ? buttonStyle.disabledButton : buttonStyle.primaryButton,
                                        buttonStyle.small,
                                        style.submitButton
                                    )}
                                    data-testid={testIds.header.settingDialog.submitButton}
                                    disabled={changed}
                                >
                                    {t("Common.save")}
                                </button>
                            </div>
                        </Form>
                        <Modal isOpen={resultVisible} hideModal={hideResult} modalTitle={result.title}>
                            <div className={style.resultContainer}>{result.message}</div>
                            <div className={style.okButtonContainer}>
                                <button
                                    className={classNames(buttonStyle.primaryButton, buttonStyle.small, style.okButton)}
                                    onClick={hideResult}
                                    data-testid={testIds.common.dialog.closeButton}
                                >
                                    {t("Common.ok")}
                                </button>
                            </div>
                        </Modal>
                    </div>
                );
            }}
        </Formik>
    );
};

export default connector(GeneralSettingForm);

import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";
import { details } from "domain/endpoint";
/**
 * Handles interactions with authentication API.
 */
class AuthenticationService {
    public async authenticate(username: string, password: string): Promise<details> {
        try {
            const requestBody = {
                username,
                password,
            };

            const response: details = await apiGatewayService.invokeApi("/authentication", "post", requestBody, {
                refreshSession: false,
                apiType: ApiType.GLOBAL_STAN,
            });
            return Promise.resolve(response);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async requestPasswordReset(email: string): Promise<void> {
        return apiGatewayService.invokeApi("/forgot-password?username=" + encodeURIComponent(email), "get", null);
    }

    public passwordReset(email: string, verificationCode: string, newPassword: string): Promise<void> {
        return apiGatewayService.invokeApi(`/reset-password`, "post", {
            username: email,
            code: verificationCode,
            password: newPassword,
        });
    }

    public async logout() {
        try {
            await apiGatewayService.invokeApi("/logout", "get");
        } catch (e) {
            console.error("Logout failed: " + e);
        }
    }
}

export const authenticationService = new AuthenticationService();

import { Tenant, CommonTenant, FetchRegionsListResponse } from "domain/tenants";
import { apiGatewayService } from "services/api/ApiGatewayService";

interface TenantDto extends CommonTenant {
    contact_email: string;
    contact_name: string;
    country_code: string;
    created_by: string;
    created_timestamp: string;
    erasure_client_endpoint: string;
    public_api_endpoint: string;
    usage_statistics: boolean;
}

interface AccessTenantResponse {
    message: string;
    stan: string;
    oliver: string;
    publicApi: string;
    publicApiDocumentation: string;
}

export interface CreateTenantResponse {
    message: string;
    warnings: string[] | undefined;
}

function toTenant(dto: TenantDto): Tenant {
    return {
        name: dto.name,
        notes: dto.notes,
        region: dto.region,
        uuid: dto.uuid,
        countryCode: dto.country_code,
        contactName: dto.contact_name,
        contactEmail: dto.contact_email,
        createdTimestamp: dto.created_timestamp,
        createdBy: dto.created_by,
        erasureClientEndpoint: dto.erasure_client_endpoint,
        publicApiEndpoint: dto.public_api_endpoint,
        usageStatistics: dto.usage_statistics,
        status: dto.status,
    };
}

class TenantService {
    public fetchTenants(abortController: AbortController): Promise<Tenant[]> {
        return apiGatewayService
            .invokeApi("/tenants", "GET", null, { abortController })
            .then((dtos: TenantDto[]) => dtos.map(toTenant));
    }

    public createTenant(
        name: string,
        region: string,
        countryCode: string,
        notes: string,
        contactName: string,
        contactEmail: string,
        abortController: AbortController
    ): Promise<CreateTenantResponse> {
        return apiGatewayService.invokeApi(
            "/tenants",
            "POST",
            {
                name,
                region,
                country_code: countryCode,
                notes,
                contact_name: contactName,
                contact_email: contactEmail,
            },
            { abortController }
        );
    }

    public accessTenant(uuid: string, abortController: AbortController): Promise<AccessTenantResponse> {
        return apiGatewayService.invokeApi("/tenants/" + uuid + "/access", "GET", null, {
            abortController,
        });
    }

    public fetchTenant(uuid: string | undefined, abortController: AbortController): Promise<Tenant> {
        return apiGatewayService
            .invokeApi("/tenants/" + uuid, "GET", null, {
                abortController,
            })
            .then((dtos: TenantDto) => toTenant(dtos));
    }

    public fetchRegionsList(): Promise<FetchRegionsListResponse> {
        return apiGatewayService.invokeApi("/regions", "GET", null);
    }

    public toggleStatistics(uuid: string, enabled: boolean, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi("/tenants/" + uuid + "/statistics?enabled=" + enabled, "POST", null, {
            abortController,
        });
    }

    public editTenant(uuid: string, tenantStatus: boolean, abortController: AbortController): Promise<void> {
        const postData = { status: tenantStatus };
        return apiGatewayService.invokeApi("/tenants/" + uuid, "POST", postData, {
            abortController,
        });
    }
}

export const tenantService = new TenantService();

import * as React from "react";
import style from "./view-tenant-details.scss";
import { useTranslation } from "react-i18next";
import testIds from "testIds.json";
import { createDateLabel } from "services/utils/format";
import { Tenant } from "domain/tenants";

export const ViewTenantDetails = (props: { activeTenant: Tenant }): JSX.Element => {
    const { t } = useTranslation();

    return (
        <>
            <div className={style.standardModalTitle}>{t("TenantProfile.genericDetail")}</div>
            <div className={style.tenantProfile}>
                <div className={style.label}>{t("TenantProfile.tenantIdentifier")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.uuidLabel}>
                    {props.activeTenant.uuid}
                </div>

                <div className={style.label}>{t("TenantProfile.region")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.regionLabel}>
                    {props.activeTenant.region}
                </div>

                <div className={style.label}>{t("TenantProfile.country")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.countryLabel}>
                    {props.activeTenant.countryCode}
                </div>

                <div className={style.label}>{t("TenantProfile.createdDate")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.createdDateLabel}>
                    {createDateLabel(props.activeTenant.createdTimestamp)}
                </div>

                <div className={style.label}>{t("TenantProfile.createdBy")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.createdByLabel}>
                    {props.activeTenant.createdBy}
                </div>

                <div className={style.label}>{t("TenantProfile.notes")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.generic.notesLabel}>
                    {props.activeTenant.notes !== "" ? (
                        props.activeTenant.notes
                    ) : (
                        <div className={style.emptyField}>{t("TenantProfile.noNotesAvailable")}</div>
                    )}
                </div>
            </div>

            <div className={style.standardModalTitle}>{t("TenantProfile.erasureClientSettings")}</div>
            <div className={style.tenantProfile}>
                <div className={style.label}>{t("TenantProfile.endpoint")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.erasureClient.endpointLabel}>
                    {props.activeTenant.erasureClientEndpoint}
                </div>

                <div className={style.label}>{t("TenantProfile.port")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.erasureClient.portLabel}>443</div>
            </div>

            <div className={style.standardModalTitle}>{t("UserProfile.publicApiSettings")}</div>
            <div className={style.tenantProfile}>
                <div className={style.label}>{t("UserProfile.publicApiEndpoint")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.publicApi.endpointLabel}>
                    {props.activeTenant.publicApiEndpoint}
                </div>

                <div className={style.label}>{t("UserProfile.publicApiPort")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.publicApi.portLabel}>443</div>
            </div>

            <div className={style.standardModalTitle}>{t("TenantProfile.contactDetails")}</div>
            <div className={style.tenantProfile}>
                <div className={style.label}>{t("TenantProfile.mainContactName")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.contact.mainContactNameLabel}>
                    {props.activeTenant.contactName !== "" ? (
                        props.activeTenant.contactName
                    ) : (
                        <div className={style.emptyField}>{t("Common.notProvided")}</div>
                    )}
                </div>

                <div className={style.label}>{t("TenantProfile.email")}</div>
                <div data-testid={testIds.workArea.tenant.viewTenantDialog.contact.emailLabel}>
                    {props.activeTenant.contactEmail !== "" ? (
                        props.activeTenant.contactEmail
                    ) : (
                        <div className={style.emptyField}>{t("Common.notProvided")}</div>
                    )}
                </div>
            </div>
        </>
    );
};

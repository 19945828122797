import * as React from "react";

const EmptyStateIcon = (props: {
    ellipseBackgroundColor: string;
    biggestCircleBackgroundColor: string;
    smallestCircleBackgroundColor: string;
    personBackgroundColor: string;
}): JSX.Element => {
    return (
        <svg
            id="emptystate_illustrationDark"
            xmlns="http://www.w3.org/2000/svg"
            width="173.138"
            height="189.449"
            viewBox="0 0 173.138 189.449"
        >
            <ellipse
                id="Ellipse_221"
                data-name="Ellipse 221"
                cx="69.297"
                cy="12.242"
                rx="69.297"
                ry="12.242"
                transform="translate(0 164.965)"
                fill={props.ellipseBackgroundColor}
                opacity="0.516"
            />
            <circle
                id="Ellipse_222"
                data-name="Ellipse 222"
                cx="67.724"
                cy="67.724"
                r="67.724"
                transform="translate(37.689)"
                fill={props.biggestCircleBackgroundColor}
                opacity="1"
            />
            <circle
                id="Ellipse_223"
                data-name="Ellipse 223"
                cx="55.908"
                cy="55.908"
                r="55.908"
                transform="translate(55.505 13.816)"
                fill={props.smallestCircleBackgroundColor}
                opacity="0.05"
            />
            <circle
                id="Ellipse_224"
                data-name="Ellipse 224"
                cx="45.822"
                cy="45.822"
                r="45.822"
                transform="translate(65.592 23.902)"
                fill={props.smallestCircleBackgroundColor}
                opacity="0.05"
            />
            <circle
                id="Ellipse_225"
                data-name="Ellipse 225"
                cx="32.853"
                cy="32.853"
                r="32.853"
                transform="translate(78.56 36.871)"
                fill={props.smallestCircleBackgroundColor}
                opacity="0.05"
            />
            <path
                id="Path_1884"
                data-name="Path 1884"
                d="M386.117,361.232s-5.324,14.987-2.958,20.312A66.12,66.12,0,0,0,389.272,392S387.892,362.218,386.117,361.232Z"
                transform="translate(-341.828 -285.447)"
                fill="#cecece"
            />
            <path
                id="Path_1885"
                data-name="Path 1885"
                d="M386.117,361.232s-5.324,14.987-2.958,20.312A66.12,66.12,0,0,0,389.272,392S387.892,362.218,386.117,361.232Z"
                transform="translate(-341.828 -285.447)"
                fill="#b9b9b9"
                opacity="0.7"
            />
            <path
                id="Path_1886"
                data-name="Path 1886"
                d="M390.522,514.385a31.964,31.964,0,0,1-.592,3.944c-.2.2.2.592,0,1.183s-.394,1.38,0,1.578-2.169,17.551-2.169,17.551-6.31,8.282-3.747,21.3l.789,13.213s6.113.394,6.113-1.775a36.132,36.132,0,0,1-.394-3.747c0-1.183.986-1.183.394-1.775s-.592-.986-.592-.986.986-.789.789-.986,1.775-14.2,1.775-14.2,2.169-2.169,2.169-3.352v-1.183s.986-2.564.986-2.761,5.324-12.227,5.324-12.227l2.169,8.677,2.366,12.424s1.183,11.241,3.55,15.579c0,0,4.141,14.2,4.141,13.8s6.9-1.38,6.7-3.155-4.141-26.622-4.141-26.622l.986-36.877Z"
                transform="translate(-342.486 -403.893)"
                fill={props.personBackgroundColor}
            />
            <g id="Head_Front_Curly" data-name="Head/Front/Curly" transform="translate(25.666 -11.583) rotate(25)">
                <g id="Head_Front_Curly-2" data-name="Head/Front/Curly" transform="translate(51.153 18.308)">
                    <g id="Head" transform="translate(6.143 10.949)">
                        <path
                            id="Head-2"
                            data-name="Head"
                            d="M0,28.232,3.207,13.338A10.456,10.456,0,0,1,.41,5.811c.583-8.276,12.146-6.456,14.4-2.278s1.982,14.773-.925,15.52c-1.159.3-3.629-.432-6.143-1.941L9.316,28.232Z"
                            transform="translate(0 0)"
                            fill="#b28b67"
                        />
                    </g>
                    <path
                        id="hair"
                        d="M16.814,9.35a12.1,12.1,0,0,0-3.867-.426,6.6,6.6,0,0,0,.471-3.35,10.184,10.184,0,0,0-1.091-3.029,4.142,4.142,0,0,0-.807-1.253,2.758,2.758,0,0,0-1.305-.7,3.505,3.505,0,0,0-1.5,0c-.219.046-.415.145-.629.2S7.756.714,7.6.576A2.755,2.755,0,0,0,6.164.023,3.708,3.708,0,0,0,4.533.217a3.193,3.193,0,0,0-1.358.867,2.268,2.268,0,0,0-.407.621c-.039.094-.046.15-.14.185s-.19.033-.28.063A3.517,3.517,0,0,0,.323,3.783,5.778,5.778,0,0,0,0,6.165a10.334,10.334,0,0,0,1.772,4.529A5.659,5.659,0,0,0,4.7,12.337a8.316,8.316,0,0,0-1.526,2.836,13.833,13.833,0,0,0-.7,3.445,11.2,11.2,0,0,0,.173,3.4A3.68,3.68,0,0,0,4.3,24.41,7.9,7.9,0,0,0,5.57,25a8.391,8.391,0,0,0,1.31.418,2.636,2.636,0,0,0,2.488-.53,4.656,4.656,0,0,0,.669-.846c.062-.107.1-.177.024-.281a2.017,2.017,0,0,0-.352-.319,3.3,3.3,0,0,1-1.173-2.189,2.336,2.336,0,0,1,1.015-2.451c1.082-.484,2.275.072,3.237.59A5.275,5.275,0,0,1,15.351,16c1.456-.73,3.127-.815,4.624-1.441a2.76,2.76,0,0,0,1.074-.681,1.383,1.383,0,0,0,.309-.5.813.813,0,0,0-.214-.632A11.8,11.8,0,0,0,16.814,9.35Z"
                        transform="translate(0 0)"
                        fill="#db2828"
                    />
                </g>
            </g>
            <path
                id="Path_1887"
                data-name="Path 1887"
                d="M381.058,764.81s-5.324,10.452-1.775,10.846,4.93.394,6.508-1.183a26.255,26.255,0,0,1,4.015-2.892,5.193,5.193,0,0,0,2.464-4.938c-.1-.965-.465-1.76-1.352-1.834-2.366-.2-5.127-2.366-5.127-2.366Z"
                transform="translate(-338.347 -596.537)"
                fill={props.personBackgroundColor}
            />
            <path
                id="Path_1888"
                data-name="Path 1888"
                d="M511.869,799.049s-5.324,10.452-1.775,10.846,4.93.394,6.508-1.183a26.263,26.263,0,0,1,4.015-2.892,5.192,5.192,0,0,0,2.464-4.938c-.1-.965-.464-1.76-1.352-1.834-2.366-.2-5.127-2.366-5.127-2.366Z"
                transform="translate(-439.774 -623.085)"
                fill={props.personBackgroundColor}
            />
            <path
                id="Path_1890"
                data-name="Path 1890"
                d="M421.03,294.878s-11.129-9.8-12.294-9.6-13.591,11.241-13.4,15.776a98.9,98.9,0,0,0,1.747,12.029s.583,20.9,1.747,21.1-.194,3.747.194,3.747,27.181,0,27.376-.592S421.03,294.878,421.03,294.878Z"
                transform="translate(-351.252 -226.55)"
                fill="#cecece"
            />
            <path
                id="Path_1891"
                data-name="Path 1891"
                d="M529.873,522.329s-3.048,4.9-3.745,2.715.143-2.011.143-2.011Z"
                transform="translate(-455.835 -407.019)"
                fill="#b28b67"
            />
            <path
                id="Path_1892"
                data-name="Path 1892"
                d="M476.953,310.243s-7.3,1.578-6.113,11.438-.179,16.151-.179,16.151l6.7,17.954,1.165,4.742,4.062-1.954-3.924-21.416s3.616-25.535,1.841-26.324A7.637,7.637,0,0,0,476.953,310.243Z"
                transform="matrix(0.998, -0.07, 0.07, 0.998, -432.404, -208.655)"
                fill="#cecece"
            />
            <path
                id="Path_1893"
                data-name="Path 1893"
                d="M277.5,411.959l9.071,16.17-7.643-17.039Z"
                transform="translate(-218.167 -315.749)"
                opacity="0.1"
            />
            <path
                id="Path_1894"
                data-name="Path 1894"
                d="M6.95-.9,6.356.392,3.39,3.346l-1.373.6"
                transform="translate(67.5 115.517)"
                fill="none"
                stroke="#464459"
                strokeLinecap="round"
                strokeWidth="0.5"
            />
        </svg>
    );
};

export default EmptyStateIcon;

import * as React from "react";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { SETTINGS_ROUTE } from "components/router/Routes";
import { useTranslation } from "react-i18next";
import GeneralSettingForm from "./GeneralSettingForm";

export default function GeneralSettingView(): JSX.Element {
    const { t } = useTranslation();

    return (
        <ApplicationLayout
            viewTitle={t("GeneralSettings.title")}
            view={<GeneralSettingForm />}
            mainRoute={SETTINGS_ROUTE}
            viewContainer={false}
        />
    );
}

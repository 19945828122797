// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"activeBackgroundColor": "#3b5dab",
	"activeForegroundColor": "#3b5dab",
	"primaryButtonBackgroundColor": "#3b5dab",
	"secondaryButtonForegroundColor": "#91b9f0",
	"bannerBackgroundColor": "rgba(59,93,171,.5)",
	"linkTextColor": "#91b9f0",
	"primaryButtonBackgroundHoverColor": "#345193",
	"secondaryButtonBackgroundHoverColor": "#f0f0f0",
	"destructiveButtonBackgroundHoverColor": "#f46464",
	"primaryButtonBackgroundActiveColor": "#2c447b",
	"secondaryButtonBackgroundActiveColor": "#f7f7f7",
	"destructiveButtonBackgroundColor": "#f09191",
	"chartNegativeColor": "#f09191",
	"chartPositiveColor": "#5fe3a1",
	"chartNeutralColor": "#cecece",
	"errorBackgroundColor": "#f46464",
	"errorBorderColor": "#f46464",
	"errorTextColor": "#f46464",
	"warningBackgroundColor": "#f2711c",
	"warningBorderColor": "#f2711c",
	"warningTextColor": "#f2711c",
	"secondaryWarningBackgroundColor": "#ffc915",
	"secondaryWarningBorderColor": "#ffc915",
	"secondaryWarningTextColor": "#ffc915",
	"successBorderColor": "#5fe3a1",
	"successTextColor": "#5fe3a1",
	"pageBackgroundColor": "#222",
	"wrapperBackgroundColor": "#dfe4f2",
	"wrapperBorderColor": "#a1b0d5",
	"modelBoxBackgroundColor": "#4c4c4c",
	"contentBackgroundColor": "#333",
	"secondaryButtonBackgroundColor": "#333",
	"textColor": "#fff",
	"primaryButtonForegroundColor": "#fff",
	"bannerForegroundColor": "#4c4c4c",
	"chartOutlineColor": "#222",
	"modalOverlayColor": "rgba(34,34,34,.4)",
	"contentDividerColor": "#222",
	"contentBorderColor": "#111",
	"disabledButtonBackgroundColor": "#222",
	"disabledButtonForegroundColor": "#4c4c4c",
	"inputHintColor": "#cecece",
	"inputBackgroundColor": "#4c4c4c",
	"hoverBackgroundColor": "#111",
	"toggleCheckedBackgroundColor": "#91b9f0",
	"toggleUncheckedBackgroundColor": "#cecece",
	"toggleForegroundColor": "#fff",
	"mapForegroundColor": "#f0f0f0",
	"mapBackgroundColor": "#222",
	"emptyStateEllipseBackgroundColor": "#111",
	"emptyStateBiggestCircleBackgroundColor": "#3c3960",
	"emptyStateSmallestCircleBackgroundColor": "#111",
	"emptyStatePersonColor": "#4a4a52"
};
module.exports = exports;

import style from "./table.scss";
import * as React from "react";
import TextWithTooltip from "./TextWithTooltip";

export enum Status {
    Error,
    Ok,
    Warning,
}

interface Content {
    status: Status;
    title: string;
}

const STATUS_TO_CLASSNAME = new Map([
    [Status.Error, style.statusFailed],
    [Status.Ok, style.statusSuccessful],
    [Status.Warning, style.statusWarning],
]);

function toClassname(status: Status): string {
    const className = STATUS_TO_CLASSNAME.get(status);
    return className != null ? className : style.statusWarning;
}

const StatusCell = (props: { values: Content[]; tooltip?: boolean }): JSX.Element => (
    <div>
        {props.values.map((value, index) =>
            props.tooltip ? (
                <TextWithTooltip text={value.title} key={index}>
                    <div className={toClassname(value.status)} />
                </TextWithTooltip>
            ) : (
                <div title={value.title} className={toClassname(value.status)} key={index} />
            )
        )}
    </div>
);

export default StatusCell;

import * as React from "react";
import style from "./buyback-tradein-view.scss";
import { useTranslation } from "react-i18next";
import testIds from "testIds.json";
import { DiagnosticReportResponse } from "domain/reports";
import checkIcon from "assets/images/icons/checkMarkInCircle.svg";
import crossIcon from "assets/images/icons/cross.svg";
import { createDateLabel, currency } from "services/utils/format";

export interface BuybackTradeinDetailsProps {
    diagnosticReportResponse: DiagnosticReportResponse;
}

export default function BuybackTradeinView(props: BuybackTradeinDetailsProps): JSX.Element {
    const { t } = useTranslation();
    const diagnosticReportData = props.diagnosticReportResponse;
    return (
        <>
            {typeof diagnosticReportData.buybackTradein.estimate !== "undefined" && (
                <>
                    <div className={style.standardModalTitle}>
                        {t("DiagnosticReportsTable.buyBackTradeInDetail.estimation")}
                    </div>
                    <div className={style.buyBackView}>
                        {typeof diagnosticReportData.buybackTradein.estimate.price !== "undefined" && (
                            <>
                                <div className={style.label}>
                                    {t("DiagnosticReportsTable.buyBackTradeInDetail.value")}
                                </div>
                                <div
                                    data-testid={
                                        testIds.workArea.report.diagnostic.reportDialog.buybackTradeinDetail.estimate
                                            .valueLabel
                                    }
                                >
                                    {currency(
                                        diagnosticReportData.buybackTradein.estimate.price,
                                        diagnosticReportData.buybackTradein.estimate.currency
                                    )}
                                </div>
                            </>
                        )}

                        {typeof diagnosticReportData.buybackTradein.estimate.eligible !== "undefined" && (
                            <>
                                <div className={style.label}>
                                    {t("DiagnosticReportsTable.buyBackTradeInDetail.eligible")}
                                </div>
                                <div
                                    data-testid={
                                        testIds.workArea.report.diagnostic.reportDialog.buybackTradeinDetail.estimate
                                            .eligibleLabel
                                    }
                                >
                                    <img
                                        src={
                                            diagnosticReportData.buybackTradein.estimate.eligible
                                                ? checkIcon
                                                : crossIcon
                                        }
                                    />
                                </div>
                            </>
                        )}

                        {typeof diagnosticReportData.buybackTradein.estimate.date !== "undefined" && (
                            <>
                                <div className={style.label}>
                                    {t("DiagnosticReportsTable.buyBackTradeInDetail.date")}
                                </div>
                                <div
                                    data-testid={
                                        testIds.workArea.report.diagnostic.reportDialog.buybackTradeinDetail.estimate
                                            .dateLabel
                                    }
                                >
                                    {createDateLabel(diagnosticReportData.buybackTradein.estimate.date)}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
            {typeof diagnosticReportData.buybackTradein.quote !== "undefined" && (
                <>
                    <div className={style.standardModalTitle}>
                        {t("DiagnosticReportsTable.buyBackTradeInDetail.quotation")}
                    </div>
                    <div className={style.buyBackView}>
                        {typeof diagnosticReportData.buybackTradein.quote.price !== "undefined" && (
                            <>
                                <div className={style.label}>
                                    {t("DiagnosticReportsTable.buyBackTradeInDetail.value")}
                                </div>
                                <div
                                    data-testid={
                                        testIds.workArea.report.diagnostic.reportDialog.buybackTradeinDetail.quote
                                            .valueLabel
                                    }
                                >
                                    {currency(
                                        diagnosticReportData.buybackTradein.quote.price,
                                        diagnosticReportData.buybackTradein.quote.currency
                                    )}
                                </div>
                            </>
                        )}

                        {typeof diagnosticReportData.buybackTradein.quote.eligible !== "undefined" && (
                            <>
                                <div className={style.label}>
                                    {t("DiagnosticReportsTable.buyBackTradeInDetail.eligible")}
                                </div>
                                <div
                                    data-testid={
                                        testIds.workArea.report.diagnostic.reportDialog.buybackTradeinDetail.quote
                                            .eligibleLabel
                                    }
                                >
                                    <img
                                        src={diagnosticReportData.buybackTradein.quote.eligible ? checkIcon : crossIcon}
                                    />
                                </div>
                            </>
                        )}
                        {typeof diagnosticReportData.buybackTradein.quote.date !== "undefined" && (
                            <>
                                <div className={style.label}>
                                    {t("DiagnosticReportsTable.buyBackTradeInDetail.date")}
                                </div>
                                <div
                                    data-testid={
                                        testIds.workArea.report.diagnostic.reportDialog.buybackTradeinDetail.quote
                                            .dateLabel
                                    }
                                >
                                    {createDateLabel(diagnosticReportData.buybackTradein.quote.date)}
                                </div>
                            </>
                        )}

                        {typeof diagnosticReportData.buybackTradein.quote.tradeId !== "undefined" && (
                            <>
                                <div className={style.label}>
                                    {t("DiagnosticReportsTable.buyBackTradeInDetail.tradeId")}
                                </div>
                                <div
                                    data-testid={
                                        testIds.workArea.report.diagnostic.reportDialog.buybackTradeinDetail.quote
                                            .tradeIdLabel
                                    }
                                >
                                    {diagnosticReportData.buybackTradein.quote.tradeId}
                                </div>
                            </>
                        )}

                        {typeof diagnosticReportData.buybackTradein.quote.expiration !== "undefined" && (
                            <>
                                <div className={style.label}>
                                    {t("DiagnosticReportsTable.buyBackTradeInDetail.offerExpiry")}
                                </div>
                                <div
                                    data-testid={
                                        testIds.workArea.report.diagnostic.reportDialog.buybackTradeinDetail.quote
                                            .offerExpiryLabel
                                    }
                                >
                                    {createDateLabel(diagnosticReportData.buybackTradein.quote.expiration)}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3D4Zjz7Sc_NTGXLHVsztSL{width:450px;height:500px;resize:none;border:1px solid #999}.LHMIe9f6QPKyDQzrY8taA{display:inline-flex;margin-left:10px;position:absolute}.rvEj5A0VZpaMop7dxv0jS{margin-right:20px}", ""]);
// Exports
exports.locals = {
	"textarea": "_3D4Zjz7Sc_NTGXLHVsztSL",
	"loaderContainer": "LHMIe9f6QPKyDQzrY8taA",
	"addButton": "rvEj5A0VZpaMop7dxv0jS"
};
module.exports = exports;

import * as React from "react";
import style from "./header.scss";
import { Button, Wrapper } from "react-aria-menubutton";
import { useState, ReactElement } from "react";
import Chevron from "components/icons/Chevron";
import classNames from "classnames";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";
import Export from "components/icons/Export";

interface ToggleablePanelProps {
    title: string;
    testId: string;
    children?: ReactElement;
    menuType: string;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const ToggleablePanel = (props: ToggleablePanelProps & ConnectedProps<typeof connector>): JSX.Element => {
    const [menuExpanded, toggleMenu] = useState<boolean>(false);
    const arrowClass: string = classNames(style.arrow, { [style.down]: menuExpanded });
    const titleClass: string = classNames(style.titleFont, {
        [style.coloredTitleText]: props.menuType == "reportMenu",
    });
    const colour =
        props.menuType == "headerMenu" ? props.theme.primaryButtonForegroundColor : props.theme.linkTextColor;
    const exportCloudColour = props.menuType === "headerMenu" ? "" : props.theme.linkTextColor;
    const exportCloudIcon = props.menuType !== "headerMenu" ? <Export color={exportCloudColour} /> : "";

    return (
        <Wrapper onMenuToggle={(state) => toggleMenu(state.isOpen)}>
            <Button className={style.button} data-testid={props.testId}>
                {exportCloudIcon}
                <span className={titleClass}>{props.title}</span>
                <Chevron color={colour} className={arrowClass} />
            </Button>
            {props.children}
        </Wrapper>
    );
};

export default connector(ToggleablePanel);

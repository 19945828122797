import * as React from "react";
import L, { LatLngExpression, Map } from "leaflet";
import "leaflet/dist/leaflet.css";
import style from "./erasure-operator-performance-chart.scss";
// TODO: Fetch this from the backend.
import countriesGeoJson from "./countries.geo.json";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { connect, ConnectedProps } from "react-redux";
import { StoreState } from "store";

interface DataPoint {
    coordinates: LatLngExpression;
    count: number;
}

interface MapProps {
    displayZoomControl?: boolean;
    mapContainerElementId: string;
}

interface MapState {
    dataPoints: DataPoint[];
    dataState: boolean;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

type Props = MapProps & ConnectedProps<typeof connector>;

class ErasureOperatorPerformanceChart extends React.Component<Props, MapState> {
    public map: Map;
    private readonly timeout: ReturnType<typeof setTimeout>;

    // ToDo This data should be fetched & populated dynamically from API
    private mapDataPoints: DataPoint[] = [
        {
            coordinates: [51.508, -0.11],
            count: 250000,
        },
        {
            coordinates: [11.996535, 8.526476],
            count: 750000,
        },
        {
            coordinates: [14.667052, 77.601087],
            count: 1000000,
        },
        {
            coordinates: [29.649046, -95.346559],
            count: 500000,
        },
    ];

    constructor(props: Props) {
        super(props);
        this.state = { dataPoints: [], dataState: false };

        // TODO : Remove this delay when actual data loading is in place
        this.timeout = setTimeout(() => {
            this.setState({
                dataPoints: this.mapDataPoints,
                dataState: true,
            });
            this.map = L.map(props.mapContainerElementId, {
                // Some place in Africa. Centered for better view. Yet to decide which place to center on loading.
                center: [11.996535, 8.526476],
                zoom: 0.3,
                zoomControl: false,
                attributionControl: false,
                dragging: false,
                minZoom: 0.3,
            });

            L.control
                .zoom({
                    position: "topright",
                })
                .addTo(this.map);

            const worldGeoJson = countriesGeoJson as GeoJSON.GeoJsonObject;
            L.geoJSON(worldGeoJson, {
                style: () => {
                    return {
                        weight: 1,
                        // Theme change doesn't have effect to these properties until the view is refreshed
                        fillColor: props.theme.mapForegroundColor,
                        fillOpacity: 1.0,
                        stroke: false,
                    };
                },
            }).addTo(this.map);

            this.state.dataPoints.forEach((dataPoint) => {
                L.circle(dataPoint.coordinates, {
                    // Theme change doesn't have effect to these properties until the view is refreshed
                    color: props.theme.activeForegroundColor,
                    fillColor: props.theme.activeForegroundColor,
                    fillOpacity: 0.6,
                    radius: dataPoint.count,
                    stroke: false,
                }).addTo(this.map);
            });
        }, 3000);
    }

    public componentWillUnmount(): void {
        clearTimeout(this.timeout);
    }

    public render(): JSX.Element {
        return !this.state.dataState ? (
            <div className={style.operatorPerformanceChartLoaderContainer}>
                <LoadingIndicator />
            </div>
        ) : (
            <div className={style.operatorPerformanceChartContainer}>
                <div className={style.topSummaryContainer}>
                    <p className={style.summaryText}>
                        {/* ToDo This data should be fetched & populated dynamically from API */}
                        <span className={style.summaryCount}>150</span>
                        <br />
                        active operators today
                        <br />
                        (avg. 180 operators on-duty/ day since 1 Oct 2019)
                    </p>
                    <p className={style.chartTitle}>Location of erasures</p>
                </div>
                <div className={style.mapContainer} id={this.props.mapContainerElementId} />
                <div className={style.seeOperatorPerformanceContainer}>
                    <button className={style.seeOperatorPerformance}>See Operator Performance</button>
                </div>
            </div>
        );
    }
}

export default connector(ErasureOperatorPerformanceChart);

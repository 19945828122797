import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";

import ApplicationLayout from "components/layout/ApplicationLayout";
import CreateWorkflowView from "components/workflows/create-workflow/CreateWorkflowView";
import { AllWorkflowsTable } from "components/workflows/AllWorkflowsTable";
import { StoreState } from "store";
import { WORKFLOWS_ROUTE } from "components/router/Routes";
import { workflowService } from "services/workflows/WorkflowService";

const mapState = (state: StoreState) => ({
    user: state.userReducer.user,
});
const connector = connect(mapState);

const AllWorkflowsView = (props: ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [state, setState] = React.useState({ count: 0 });

    function updateState() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    return (
        <ApplicationLayout
            viewTitle={t("Common.workflowsTitle")}
            view={<AllWorkflowsTable workflowService={workflowService} onUpdate={updateState} count={state.count} />}
            mainRoute={WORKFLOWS_ROUTE}
            viewActions={<CreateWorkflowView user={props.user} onUpdate={updateState} />}
        />
    );
};
export default connector(AllWorkflowsView);

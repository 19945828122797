import * as React from "react";
import { useTranslation } from "react-i18next";

import AddConfigurationForm, { FormValues } from "./AddConfigurationForm";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { licenseService } from "services/licenses/LicenseService";

export interface Props {
    onSuccess: (values: FormValues) => Promise<void>;
}

export default function AddConfigurationFormContainer(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [configuration, setConfiguration] = React.useState<string>();
    const [failureMessage, setFailureMessage] = React.useState<string>("");

    const fetchDefaultConfiguration = async (): Promise<void> => {
        const abortController = new AbortController();
        const { signal } = abortController;
        try {
            const response = await licenseService.fetchDefaultConfigurationData(abortController);
            setConfiguration(JSON.stringify(response, undefined, 4));
        } catch (e) {
            if (!signal.aborted) {
                console.error("Failed to load default configuration:", e);
                setFailureMessage(t("configuration.AddConfigureDialog.requestFailed"));
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
    };

    React.useEffect(() => {
        fetchDefaultConfiguration();
    }, []);

    return typeof configuration === "undefined" && failureMessage === "" ? (
        <LoadingIndicator />
    ) : (
        <AddConfigurationForm
            onSuccess={props.onSuccess}
            configuration={configuration}
            failureMessage={failureMessage}
        />
    );
}

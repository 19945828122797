// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1HoPiXsxtjpLPZ0sc4bjEB{width:1400px;height:60vh !important;background-color:#fff;color:#000}._1HoPiXsxtjpLPZ0sc4bjEB select{background-image:none !important}", ""]);
// Exports
exports.locals = {
	"editorContainer": "_1HoPiXsxtjpLPZ0sc4bjEB"
};
module.exports = exports;

import * as React from "react";
import { Card } from "./Card";
import style from "./dashboard.scss";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { CardDetails } from "domain/dashboard";
import * as DashboardRepository from "services/dashboard/dashboardRepository";
import BmsDiagnosticsChart from "./BmsDiagnosticsChart";
import ErasureOperatorPerformanceChart from "./ErasureOperatorPerformanceChart";
import ErasureProgressChart from "./ErasureProgressChart";
import { WidthProvider, Responsive, Layouts } from "react-grid-layout";

interface CardLayout {
    cols: Record<string, number>;
    rowHeight: number;
    isResizable: boolean;
    margin: [number, number];
    isBounded: boolean;
}

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default class Overview extends React.PureComponent {
    public static defaultProps = {
        cols: { lg: 3, md: 2, sm: 2, xs: 1, xxs: 1 },
        margin: [40, 40],
        isBounded: true,
        rowHeight: 1,
        isResizable: false,
    };

    private static readonly erasureProgressKey = "erasureProgress";
    private static readonly bmsDiagnosticsKey = "bmsDiagnostics";
    private static readonly erasureOperatorPerformanceKey = "erasureOperatorPerformance";

    // TODO BCC-248: Remove the following duplicate cards
    private static readonly erasureProgressKeyDuplicate = "erasureProgressDuplicate";
    private static readonly bmsDiagnosticsKeyDuplicate = "bmsDiagnosticsDuplicate";
    private static readonly erasureOperatorPerformanceKeyDuplicate = "erasureOperatorPerformanceDuplicate";

    public cards: CardDetails[] = [
        {
            key: Overview.erasureProgressKey,
            title: "Overview.erasureSuccessRate",
            children: <ErasureProgressChart />,
        },
        {
            key: Overview.bmsDiagnosticsKey,
            title: "Overview.bmsDiagnostics",
            children: <BmsDiagnosticsChart />,
        },
        {
            key: Overview.erasureOperatorPerformanceKey,
            title: "Overview.erasureOperatorPerformance",
            children: <ErasureOperatorPerformanceChart mapContainerElementId="map1" />,
        },
        {
            key: Overview.erasureProgressKeyDuplicate,
            title: "Erasure success rate duplicate",
            children: <ErasureProgressChart />,
        },
        {
            key: Overview.bmsDiagnosticsKeyDuplicate,
            title: "BMS Diagnostics duplicate",
            children: <BmsDiagnosticsChart />,
        },
        {
            key: Overview.erasureOperatorPerformanceKeyDuplicate,
            title: "Erasure operator performance duplicate",
            children: <ErasureOperatorPerformanceChart mapContainerElementId="map2" />,
        },
    ];

    public defaultLayout = {
        lg: [
            { w: 1, h: 16, x: 0, y: 0, i: Overview.erasureProgressKey },
            { w: 1, h: 15, x: 1, y: 0, i: Overview.bmsDiagnosticsKey },
            { w: 1, h: 16, x: 2, y: 0, i: Overview.erasureOperatorPerformanceKey },
            { w: 1, h: 16, x: 1, y: 14, i: Overview.erasureOperatorPerformanceKeyDuplicate },
            { w: 1, h: 16, x: 2, y: 16, i: Overview.erasureProgressKeyDuplicate },
            { w: 1, h: 15, x: 0, y: 15, i: Overview.bmsDiagnosticsKeyDuplicate },
        ],
        md: [
            { w: 1, h: 16, x: 0, y: 0, i: Overview.erasureProgressKey },
            { w: 1, h: 15, x: 1, y: 0, i: Overview.bmsDiagnosticsKey },
            { w: 1, h: 16, x: 0, y: 16, i: Overview.erasureOperatorPerformanceKey },
            { w: 1, h: 16, x: 0, y: 31, i: Overview.erasureOperatorPerformanceKeyDuplicate },
            { w: 1, h: 16, x: 1, y: 15, i: Overview.erasureProgressKeyDuplicate },
            { w: 1, h: 15, x: 1, y: 32, i: Overview.bmsDiagnosticsKeyDuplicate },
        ],
        sm: [
            { w: 1, h: 16, x: 0, y: 0, i: Overview.erasureProgressKey },
            { w: 1, h: 15, x: 1, y: 0, i: Overview.bmsDiagnosticsKey },
            { w: 1, h: 16, x: 0, y: 16, i: Overview.erasureOperatorPerformanceKey },
            { w: 1, h: 16, x: 0, y: 31, i: Overview.erasureOperatorPerformanceKeyDuplicate },
            { w: 1, h: 16, x: 1, y: 15, i: Overview.erasureProgressKeyDuplicate },
            { w: 1, h: 16, x: 1, y: 32, i: Overview.bmsDiagnosticsKeyDuplicate },
        ],
        xs: [
            { w: 1, h: 16, x: 0, y: 0, i: Overview.erasureProgressKey },
            { w: 1, h: 15, x: 0, y: 16, i: Overview.bmsDiagnosticsKey },
            { w: 1, h: 16, x: 0, y: 31, i: Overview.erasureOperatorPerformanceKey },
            { w: 1, h: 16, x: 0, y: 47, i: Overview.erasureOperatorPerformanceKeyDuplicate },
            { w: 1, h: 16, x: 0, y: 63, i: Overview.erasureProgressKeyDuplicate },
            { w: 1, h: 16, x: 0, y: 79, i: Overview.bmsDiagnosticsKeyDuplicate },
        ],
        xxs: [
            { w: 1, h: 16, x: 0, y: 0, i: Overview.erasureProgressKey },
            { w: 1, h: 15, x: 0, y: 16, i: Overview.bmsDiagnosticsKey },
            { w: 1, h: 16, x: 0, y: 31, i: Overview.erasureOperatorPerformanceKey },
            { w: 1, h: 16, x: 0, y: 47, i: Overview.erasureOperatorPerformanceKeyDuplicate },
            { w: 1, h: 16, x: 0, y: 63, i: Overview.erasureProgressKeyDuplicate },
            { w: 1, h: 16, x: 0, y: 79, i: Overview.bmsDiagnosticsKeyDuplicate },
        ],
    };

    public originalLayout = DashboardRepository.getCardOrderLayout() || this.defaultLayout;

    private layout: Layouts = this.originalLayout;

    constructor(props: CardLayout) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <ResponsiveReactGridLayout
                className={style.dashboardViewContainer}
                layouts={this.layout}
                {...this.props}
                onLayoutChange={(_layout, layouts) => DashboardRepository.setCardOrderLayout(layouts)}
            >
                {this.cards.map((card) => {
                    return (
                        <div key={card.key} className={style.cardContainer}>
                            <Card key={card.key} title={card.title}>
                                {card.children}
                            </Card>
                        </div>
                    );
                })}
            </ResponsiveReactGridLayout>
        );
    }
}

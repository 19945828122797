import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { DownloadPageConfigurationResponse } from "domain/downloadPage";
import { isAndroid, getUA, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Clipboard from "clipboard";
import testIds from "testIds.json";

import style from "./page.scss";
import buttons from "styles/buttons.scss";
import defaultAppIcon from "assets/images/logo/blanccoDSAppIconPlaceholder.svg";

interface Props {
    licenseKey: string;
    configuration: DownloadPageConfigurationResponse;
}

const Page = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const encodedLicenseKey = Buffer.from(props.licenseKey).toString("base64");
    new Clipboard("#downloadButton", {
        text: function () {
            return encodedLicenseKey;
        },
    });

    const showDefaultAppIcon = (e: React.ChangeEvent<HTMLImageElement>) => {
        e.target.onerror = null;
        e.target.src = defaultAppIcon;
    };

    const downloadTxtFile = () => {
        if (props.licenseKey == "") {
            return true;
        } else {
            const element = document.createElement("a");
            const file = new Blob([encodedLicenseKey], { type: "text/plain" });
            element.href = URL.createObjectURL(file);
            element.download = "SmartConnect.txt";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            return true;
        }
    };

    const downloadApp = async () => {
        if (props.licenseKey !== "") {
            let firebaseLink = "";
            if (isAndroid) {
                // If Borwser Agent incudes HuaweiBrowser && HMSCore then use Huawei Firebase link.
                if (getUA.includes("HuaweiBrowser") && getUA.includes("HMSCore")) {
                    firebaseLink = props.configuration.huaweiFirebase;
                } else {
                    firebaseLink = props.configuration.androidFirebaseLink;
                }
            }

            if (isIOS) {
                firebaseLink = props.configuration.iosFirebaseLink;
            }

            if (firebaseLink && props.licenseKey != "") {
                if (isAndroid) {
                    await downloadTxtFile();
                }

                firebaseLink = firebaseLink.replace("{PIN_NUMBER}", props.licenseKey);
                window.location.href = firebaseLink;
            }
        }
    };

    return (
        <div className={style.downloadPage}>
            <Header logo={props.configuration.headerLogo} />
            <div className={style.container}>
                <div className={style.title} data-testid={testIds.workArea.downloadPage.title}>
                    {t("DownloadPage.downloadAppTitle")}
                </div>
                <div className={style.appIcon}>
                    <img
                        src={props.configuration.appIcon}
                        onError={showDefaultAppIcon}
                        data-testid={testIds.workArea.downloadPage.appIcon}
                    />
                    <div className={style.appTitle} data-testid={testIds.workArea.downloadPage.appTitle}>
                        {props.configuration.title}
                    </div>
                </div>
                <div className={style.buttonContainer}>
                    <button
                        id="downloadButton"
                        onClick={downloadApp}
                        className={buttons.primaryButton}
                        data-testid={testIds.workArea.downloadPage.downloadAppButton}
                    >
                        {t("DownloadPage.downloadAppButton")}
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Page;
